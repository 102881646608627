import { Typography } from '@mui/material'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Table, useTable } from 'components/Table'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import { addQueryParams } from 'utils/request'

import { paymentsListColumns } from './utils'

export default function PaymentsList() {
  const table = useTable(
    'payments',
    addQueryParams('/payments/', {
      ordering: '-created',
      invoice__isnull: 'false',
    }),
    {
      columns: paymentsListColumns,
    }
  )

  return (
    <PageTemplate title="Payments">
      <PageHeader>
        <Typography variant="h6">Transactions</Typography>
        <Breadcrumbs />
      </PageHeader>
      <Table {...table} disableAddButton dataType="payments" />
    </PageTemplate>
  )
}
