import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { ListReturnType, Supplier } from 'utils/global-types'
import { addQueryParams } from 'utils/request'

type SuppliersParams = Partial<{
  search: string
  is_active: boolean
}>
type SuppliersKey = ['suppliers', SuppliersParams]
type SuppliersResponse = ListReturnType<Supplier>

const getSuppliers: QueryFunction<SuppliersResponse, SuppliersKey> = async ({
  queryKey,
}) => {
  const [, params] = queryKey

  const response = await axios.get<SuppliersResponse>(
    addQueryParams(`/suppliers/`, params)
  )

  return response.data
}

export const suppliersQueryOptions = (
  params: SuppliersKey[1]
): WithRequired<
  UseQueryOptions<SuppliersResponse, AxiosError, Supplier[], SuppliersKey>,
  'queryKey'
> => ({
  queryKey: ['suppliers', params],
  queryFn: getSuppliers,
  meta: { message: 'Failed to get suppliers' },
})

const useSuppliers = (params: SuppliersParams = {}) => {
  return useQuery<SuppliersResponse, AxiosError, Supplier[], SuppliersKey>({
    ...suppliersQueryOptions(params),
    select: (data) => data.results,
  })
}

export default useSuppliers
