import { IconButton } from '@mui/material'

import { usePreviewContext } from 'components/PreviewDrawer/context'
import { PreviewIcon } from 'components/PreviewTableCell'
import { Quote } from 'utils/global-types'

type PreviewQuotePdfProps = {
  orderID: Quote['order_id']
}

export default function PreviewQuotePdf({ orderID }: PreviewQuotePdfProps) {
  const openPreview = usePreviewContext()

  const handleClick = () => {
    openPreview({ type: 'quote', orderID, showViewButton: true })
  }

  return (
    <IconButton
      size="small"
      onClick={handleClick}
      sx={{ ml: 1, mt: '-10px', transform: 'translateY(5px)' }}
    >
      <PreviewIcon />
    </IconButton>
  )
}
