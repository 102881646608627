import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import useOrder from 'api/order/useOrder'
import useProduct from 'api/product/useProduct'
import useOrderProductItems from 'api/product-item/useOrderProductItems'
import { Search } from 'components/Search'
import { useDialog } from 'context/Dialog/DialogContext'
import { OrderProductItemCreate, Product } from 'utils/global-types'
import { getProductLabel } from 'utils/product'

import OrderProductItem from '../components/OrderProductItem'

import WarningProductExists from './WarningProductExists'

export type AddProductFormI = Omit<
  OrderProductItemCreate,
  'lead_time_overwrite' | 'product'
> & {
  productSKU: string | null
  product: string | null
  lead_time_overwrite: string
}

export default function AddProductForm() {
  const [, closeDialog] = useDialog()
  const { id } = useParams()
  const { data: orderData } = useOrder(id!)
  const { order } = orderData || {}
  const { data: productsData } = useOrderProductItems(id!)
  const products = productsData!

  const { reset, setValue } = useFormContext<AddProductFormI>()
  const [isAdded, setIsAdded] = useState(false)
  const productSKU: string | undefined = useWatch({ name: 'productSKU' })

  const { isFetching, data } = useProduct(productSKU ?? '')
  const { product, discountGroup } = data || {}

  useEffect(() => {
    return reset
  }, [])

  const onChangeProduct = (value: Product | null) => {
    const isAlreadyInOrder = products.some(
      ({ product }) => product === value?.uuid
    )

    setIsAdded(isAlreadyInOrder)
    setValue('productSKU', value?.sku ?? null)
    setValue('product', value?.uuid ?? null)
  }

  const onChangeQuantity = async () => {
    closeDialog(false)
    const button = document.querySelector<HTMLDivElement>(
      `#product-quantity-${product?.uuid}-readonly`
    )

    button?.click()

    const input = document.querySelector<HTMLInputElement>(
      `#product-quantity-${product?.uuid}-input`
    )

    input?.focus()
  }

  const onDelete = () => void reset()

  if (isFetching) {
    return <div>Loading...</div>
  }

  if (productSKU != null && !isAdded) {
    return (
      <OrderProductItem
        onDelete={onDelete}
        product={product!}
        discountGroup={discountGroup}
        currency={order!.currency}
        exchangeRate={order!.exchange_rate}
      />
    )
  }

  return (
    <>
      <Search
        isProductSearch
        name="productSKU"
        label="Find a Product"
        url="/products/search/"
        onChange={onChangeProduct}
        getOptionLabel={getProductLabel}
        getOptionDisabled={({ is_active }) => !is_active}
      />
      {isAdded && (
        <WarningProductExists
          label={getProductLabel(product!)}
          onClick={onChangeQuantity}
        />
      )}
    </>
  )
}
