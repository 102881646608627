import { StyleSheet, View } from '@react-pdf/renderer'

import { Link, Row, Space } from 'pdf/components'
import Typography from 'pdf/components/Typography'
import { InvoiceContext, QuoteContext } from 'pdf/types'
import { printPrice } from 'pdf/utils'
import { formatGramsToKg } from 'utils/helpers'

import {
  ProductItemsTableCell,
  ProductItemsTableHead,
  ProductItemsTableRow,
} from '.'

const styles = StyleSheet.create({
  table: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})

type ProductItemTableProps = QuoteContext | InvoiceContext

export default function ProductItemsTable(context: ProductItemTableProps) {
  const { product_bundle: items, weight_net, weight_gross } = context

  return (
    <View style={styles.table}>
      <ProductItemsTableHead />
      <View>
        {items
          .sort((a, b) => a.ordering - b.ordering)
          .map((item, index) => (
            <ProductItemsTableRow key={item.uuid}>
              <ProductItemsTableCell width={24}>
                {index + 1}
              </ProductItemsTableCell>
              <ProductItemsTableCell width={148}>
                <Typography medium>
                  {item.manufacturer_name} - {item.manufacturer_part_number}
                </Typography>
                {'\n'}
                <Link
                  style={{ color: '#000', opacity: 0.6 }}
                  src={`https://www.omnical.co/products/p/p/${item.sku}`}
                >
                  SKU: {item.sku}
                </Link>
                {item.series ? (
                  <Typography size={7} opacity={0.8}>
                    {'\n'}Series: {item.series}
                  </Typography>
                ) : null}
                {item.type ? (
                  <Typography size={7} opacity={0.8}>
                    {'\n'}Type: {item.type}
                  </Typography>
                ) : null}
                {item.product_category ? (
                  <Typography size={7} opacity={0.8}>
                    {'\n'}Category: {item.product_category}
                  </Typography>
                ) : null}
              </ProductItemsTableCell>
              <ProductItemsTableCell width={48}>
                {item.quantity_offered * item.product_amount}{' '}
                {item.product_unit}
                {'\n'}
                <Typography secondary size={7}>
                  {item.quantity_offered} x {item.product_amount}{' '}
                  {item.product_unit}
                </Typography>
              </ProductItemsTableCell>
              <ProductItemsTableCell width={48}>
                {printPrice(item.sales_price_relevant, context.currency, 4)}
                {'\n'}
                <Typography secondary size={7}>
                  per {item.price_amount} {item.price_unit}
                </Typography>
              </ProductItemsTableCell>
              <ProductItemsTableCell width={52}>
                {printPrice(item.total_sales_price_display, context.currency)}
              </ProductItemsTableCell>
              <ProductItemsTableCell width={24}>
                {formatGramsToKg(item.total_weight)} kg
              </ProductItemsTableCell>
              <ProductItemsTableCell width={155} textAlign="right">
                <Typography size={7}>
                  {item.notes ? `${item.notes}\n` : null}
                  {item.is_details ? item.details_original : null}
                </Typography>
              </ProductItemsTableCell>
            </ProductItemsTableRow>
          ))}
      </View>
      <Space height={12} />
      <Row>
        <Typography medium size={7}>
          Total Weight
        </Typography>
        <Typography medium size={7}>
          {formatGramsToKg(weight_net)} KG Net (~{formatGramsToKg(weight_gross)}{' '}
          KG Gross)
        </Typography>
      </Row>
    </View>
  )
}
