import { ChangeEventHandler, ReactElement } from 'react'
import { Container, TextField } from '@mui/material'

import { useFocusSearch } from 'utils/hooks'

interface TableToolbarProps {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  actionButton?: ReactElement
}

export default function TableToolbar(props: TableToolbarProps) {
  const { value, onChange, placeholder, actionButton } = props

  const inputRef = useFocusSearch()

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChange(event.target.value)
  }

  const stylesForSearchWithActionButton = actionButton
    ? {
        display: 'flex',
        justifyContent: 'space-between',
      }
    : {}

  return (
    <Container sx={{ p: 2, ...stylesForSearchWithActionButton }}>
      <TextField
        inputRef={inputRef}
        fullWidth={actionButton ? false : true}
        label="Search"
        sx={{ width: actionButton ? '300px' : '100%' }}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />
      {actionButton}
    </Container>
  )
}
