import { View } from '@react-pdf/renderer'
import { trim } from 'lodash'

import { Column, Space, Typography } from 'pdf/components'
import { QuoteContext } from 'pdf/types'
import { printDate } from 'utils/date'

type MainInfoProps = QuoteContext

const MainInfo = (quote: MainInfoProps) => (
  <View>
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Column width={160}>
        <Typography secondary medium upperCase size={7}>
          Bill to
        </Typography>
        <Space height={6} />
        <Typography bold>
          {quote.billing_organization_name || quote.organization_name}
        </Typography>
        <Typography>{quote.billing_address_line_1}</Typography>
        <Typography>{quote.billing_address_line_2}</Typography>
        <Typography>
          {trim(`${quote.billing_postal_code} ${quote.billing_city}`)}
        </Typography>
        <Typography>{quote.billing_country}</Typography>
        <Typography>
          Tel: {quote.billing_phone_number || quote.customer_phone_number}
        </Typography>
      </Column>
      <Column width={160}>
        <Typography secondary medium upperCase size={7}>
          Ship to
        </Typography>
        <Space height={6} />
        <Typography bold>
          {quote.shipping_organization_name || quote.organization_name}
        </Typography>
        <Typography>{quote.shipping_address_line_1}</Typography>
        <Typography>{quote.shipping_address_line_2}</Typography>
        <Typography>
          {trim(`${quote.shipping_postal_code} ${quote.shipping_city}`)}
        </Typography>
        <Typography>{quote.shipping_country}</Typography>
        <Typography>
          Tel: {quote.shipping_phone_number || quote.customer_phone_number}
        </Typography>
      </Column>
      <Column width={160} alignItems="flex-end">
        <Space height={15} />
        <Typography bold>{quote.quote_from_name}</Typography>
        <Typography>
          {quote.quote_from_address_line_1} {quote.quote_from_address_line_2}
        </Typography>
        <Typography>{quote.quote_from_city}</Typography>
        <Typography>
          {trim(`${quote.quote_from_postal_code} ${quote.quote_from_country}`)}
        </Typography>
        <Typography>Tel: {quote.quote_from_phone}</Typography>
        <Typography>Fax: {quote.quote_from_fax}</Typography>
        <Typography>E-mail: {quote.quote_from_email}</Typography>
      </Column>
    </View>
    <Space height={8} />
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Column width={200} gap={3}>
        {quote.rfq_id && (
          <Typography>
            <Typography secondary>RFQ ID: </Typography>
            {quote.rfq_id}
          </Typography>
        )}
        <Typography>
          <Typography secondary>Name: </Typography>
          {quote.customer_name}
        </Typography>
        <Typography>
          <Typography secondary>Email: </Typography>
          {quote.customer_email}
        </Typography>
        <Typography>
          <Typography secondary>Shipping method: </Typography>
          {quote.shipping_method_name}
          {quote.shipping_instructions
            ? ` - ${quote.shipping_instructions}`
            : ''}
        </Typography>
        {quote.shipping_attn ? (
          <Typography>
            <Typography secondary>ATTN: </Typography>
            {quote.shipping_attn}
          </Typography>
        ) : null}
        {quote.shipping_notes ? (
          <Typography>
            <Typography secondary>Shipping instructions: </Typography>
            {quote.shipping_notes}
          </Typography>
        ) : null}
      </Column>
      <Column width={200} gap={3} alignItems="flex-end">
        <Typography>
          <Typography secondary>Quote ID: </Typography>
          {quote.order_id}
        </Typography>
        <Typography>
          <Typography secondary>Date issued: </Typography>
          {quote.issue_date ? printDate(quote.issue_date) : '-'}
        </Typography>
        <Typography>
          <Typography secondary>Valid until: </Typography>
          {quote.expiration_date ? printDate(quote.expiration_date) : '-'}
        </Typography>
      </Column>
    </View>
  </View>
)

export default MainInfo
