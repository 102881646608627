import { useNavigate } from 'react-router-dom'

import useUser from 'api/user/useUser'

export const useIsAuthenticated = () => {
  const { data: user, error } = useUser()

  return user !== undefined && !error
}

type GoBackMethod = () => void

/**
 * Return a function that navigates to the previous route or a default route
 * @param {string} defaultRoute - The default route to navigate to if there is no previous route
 * @returns {GoBackMethod} A function that navigates to the previous route or a default route
 */
export const useGoBack = (defaultRoute?: string): GoBackMethod => {
  const navigate = useNavigate()

  const goBack = () => {
    if (history.length > 1) {
      return navigate(-1)
    }
    if (defaultRoute) {
      return navigate(defaultRoute)
    }
    navigate('/')
  }

  return goBack
}
