import { MutationFunction, useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'

export type ExtendQuoteResponse = Record<string, never>
export type ExtendQuoteVariables = { uuid: string }

const extendQuote: MutationFunction<
  ExtendQuoteResponse,
  ExtendQuoteVariables
> = async ({ uuid }) => {
  const response = await axiosInstance.post<ExtendQuoteResponse>(
    `/customers-portal/quotes/${uuid}/extend-period/`
  )

  return response.data
}

const useExtendQuote = () => {
  return useMutation<
    ExtendQuoteResponse,
    AxiosError<Record<string, string[]>>,
    ExtendQuoteVariables
  >({
    useErrorBoundary: true,
    mutationFn: extendQuote,
    meta: { useGlobalError: false },
  })
}

export default useExtendQuote
