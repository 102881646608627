import { PropsWithChildren } from 'react'
import { Container, styled, Toolbar } from '@mui/material'

import { useDrawer } from 'pages/Layout/Layout'

type StyledFooterProps = {
  open: boolean
}

const StyledFooter = styled(Container)<StyledFooterProps>(
  ({ theme, open }) => ({
    bottom: 0,
    position: 'fixed',
    padding: theme.spacing(1.5, 3),
    width: `calc(100% - ${theme.shape.drawerWidth[open ? 'max' : 'min']}px)`,
    borderTop: `1px solid ${theme.palette.divider}`,
    background: theme.palette.common.white,
    zIndex: theme.zIndex.fab,

    display: 'flex',
    justifyContent: 'space-between',

    left: theme.shape.drawerWidth[open ? 'max' : 'min'],
    ...theme.mixins.drawer[open ? 'open' : 'close'],
  })
)

export default function Footer({ children }: PropsWithChildren) {
  const [open] = useDrawer()

  return (
    <>
      <Toolbar />
      <StyledFooter open={open}>{children}</StyledFooter>
    </>
  )
}
