import { useWatch } from 'react-hook-form'

import { ReadonlyField } from 'components/ReadonlyField'
import { ReadonlyFieldProps } from 'components/ReadonlyField/ReadonlyField'
import { Currency } from 'utils/global-types'
import { formatPrice } from 'utils/price'

type CalculatedShippingFeeProps = Omit<ReadonlyFieldProps, 'label'> & {
  value: number
  name?: string
  currency?: Currency
}

const CalculatedShippingFee = ({
  value,
  name = 'shipping_fee_overwrite',
  currency = 'eur',
  ...props
}: CalculatedShippingFeeProps) => {
  const alternative: string = useWatch({ name })
  const isAlternative = alternative !== '' && alternative !== null

  return (
    <ReadonlyField
      {...props}
      label="Calculated DAP/DHL"
      sx={{
        ...props,
        textDecoration: isAlternative ? 'line-through' : 'none',
      }}
    >
      {formatPrice(value, currency)}
    </ReadonlyField>
  )
}

export default CalculatedShippingFee
