import { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SxProps, Tab, Tabs as MuiTabs, Theme } from '@mui/material'

export interface LinkTabI {
  label: string
  path: string
  disabled?: boolean
}

interface TabsProps {
  tabs: LinkTabI[]
}

const tabStyle: SxProps<Theme> = (theme) => ({
  minHeight: '42px',
  padding: theme.spacing(2, 2, 0),
  marginBottom: 0.5,
  '& .MuiTabs-scroller': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  '& .MuiTab-root': {
    minHeight: '42px',
    padding: '6px 16px',
  },
})

/**
 * Tabs component that holds links
 * Important: use full relative path in tab path field
 * @param {TabsProps} {tabs}
 * @returns {ReactElement}
 */
export default function LinkTabs({ tabs }: TabsProps): ReactElement {
  const { pathname } = useLocation()

  return (
    <MuiTabs
      scrollButtons="auto"
      variant="scrollable"
      value={pathname}
      sx={tabStyle}
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.path}
          to={tab.path}
          value={tab.path}
          component={Link}
          label={tab.label}
          disabled={tab.disabled}
          replace
        />
      ))}
    </MuiTabs>
  )
}
