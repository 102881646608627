import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Quote } from 'utils/global-types'
import { downloadFile, generatePDFName, parsePDFError } from 'utils/helpers'

type DownloadQuoteResponse = File
type DownloadQuoteQueryKey = ['quote-pdf', Quote['order_id'], string]

const getQuotePDF: QueryFunction<
  DownloadQuoteResponse,
  DownloadQuoteQueryKey
> = async ({ queryKey }) => {
  const [, orderID, organizationName] = queryKey

  const response = await axios.get<DownloadQuoteResponse>(
    `/orders/${orderID}/print-pdf/`,
    {
      responseType: 'blob',
    }
  )

  const downloadURL = window.URL.createObjectURL(response.data)

  downloadFile(
    downloadURL,
    generatePDFName({ fileType: 'Quote', organizationName, id: orderID })
  )

  window.URL.revokeObjectURL(downloadURL)

  return response.data
}

const useDownloadQuote = (
  orderID: Quote['order_id'],
  organizationName: string
) => {
  return useQuery<
    DownloadQuoteResponse,
    AxiosError,
    DownloadQuoteResponse,
    DownloadQuoteQueryKey
  >({
    queryKey: ['quote-pdf', orderID, organizationName],
    queryFn: getQuotePDF,
    onError: parsePDFError,
    enabled: false,
  })
}

export default useDownloadQuote
