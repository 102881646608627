import { Typography } from '@mui/material'
import { DateTime } from 'luxon'

import useConfig from 'api/config/useConfig'
import { localeDateTime } from 'utils/date'

type LastSyncProps = {
  initiator?: string
  isSynchronizing?: boolean
}

export default function LastSync({
  initiator,
  isSynchronizing,
}: LastSyncProps) {
  const { data: config } = useConfig()

  const lastSync =
    config?.[
      isSynchronizing
        ? 'PRODUCT_FEED_LAST_SYNC_STARTED'
        : 'PRODUCT_FEED_LAST_SYNC_FINISHED'
    ]?.value
  const lastSyncDateTime = DateTime.fromISO(`${lastSync}` ?? '')

  if (!lastSyncDateTime.isValid) {
    return null
  }

  return (
    <Typography
      variant="caption"
      color="text.secondary"
      pl={2}
      textAlign="right"
    >
      {isSynchronizing ? 'Sync started' : 'Last updated sync'}:{' '}
      {localeDateTime(lastSyncDateTime.toJSDate())}
      {initiator && (
        <>
          <br />
          by{' '}
          <Typography variant="caption" fontWeight={500}>
            {initiator}
          </Typography>
        </>
      )}
    </Typography>
  )
}
