import { ChangeEvent } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material'

import useShippingMethod from 'api/shipping/useShippingMethod'
import { Autocomplete, ControlledInput, Select } from 'components/Form'
import Checkbox from 'components/Form/Checkbox'
import { FieldsGroup } from 'components/FormHelpers'
import { useDialog } from 'context/Dialog/DialogContext'
import {
  getIsShippingMethodDefault,
  mapShippingMethodOption,
} from 'utils/address'
import { mapOrganizationOptions } from 'utils/customer'
import { AutocompleteOption } from 'utils/global-types'
import { getEmailDomain, isUUID } from 'utils/helpers'
import { useToggle } from 'utils/hooks'
import { percentageInputOptions } from 'utils/price'

import { discardConfirmationDialog } from '../dialogs'

import AddressFields from './AddressFields'

export default function OrganizationStepFields() {
  const [isCustomerIndividual, toggleIsCustomerIndividual] = useToggle(false)

  const [openDialog] = useDialog()

  const { getValues, setValue, resetField, register } = useFormContext()

  const [
    organizationNameWatch,
    organizationUuidWatch,
    organizationCurrencyWatch,
    shippingMethodUUID,
    sameShippingAndBillingAddressWatch,
  ] = useWatch({
    name: [
      'organization.name',
      'organization.uuid',
      'organization.currency',
      'organization.shipping_method',
      'address.same_shipping_and_billing',
    ],
  })
  const { data: shippingMethod } = useShippingMethod(shippingMethodUUID)

  const isFormFieldsDisplayed = !!organizationNameWatch
  const isOrganizationSelected = !!organizationUuidWatch
  const isIndividualCheckboxDisabled =
    (!isCustomerIndividual && isFormFieldsDisplayed) || isOrganizationSelected
  const showShippingInstructions =
    shippingMethod?.rate_calculation_type === 'no-fees'

  const defaultEmailDomain = getEmailDomain(getValues('customer.email'))

  const confirmOrganizationTypeChange = async () => {
    if (isFormFieldsDisplayed) {
      const isChangeConfirmed = await openDialog(
        discardConfirmationDialog('change')
      )

      return isChangeConfirmed
    }

    return true
  }

  const onAutoCompleteClear = async () => {
    const isChangeConfirmed = await confirmOrganizationTypeChange()

    if (isChangeConfirmed) {
      resetField('organization')
    }
  }

  const handleAutoCompleteChange = async (
    value?: AutocompleteOption | null
  ) => {
    if (!value) {
      resetField('organization')

      return
    }

    if (isCustomerIndividual) {
      toggleIsCustomerIndividual()
    }
    resetField('organization.shipping_method')

    const isNewOrganization = !isUUID(value.id)

    if (isNewOrganization) {
      resetField('organization.uuid')
      setValue('organization.name', value.id)
      setValue('organization.email_domain', defaultEmailDomain)
    } else {
      setValue('organization.uuid', value.id)
      setValue('organization.name', '')
    }
  }

  const handleIsCustomerIndividualChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked
    const isChangeConfirmed = await confirmOrganizationTypeChange()

    if (!isChangeConfirmed) {
      return
    }

    if (isChecked) {
      const customerName = getValues('customer.name')
      setValue('organization.uuid', undefined)
      setValue('organization.name', customerName)
      setValue('organization.email_domain', defaultEmailDomain)
    } else {
      setValue('organization.name', '')
    }

    toggleIsCustomerIndividual()
  }

  const autocompleteHelperText = isFormFieldsDisplayed
    ? // eslint-disable-next-line max-len
      'To change organization or make it individual clean this field, You will lose part of the data that you’ve added'
    : undefined

  return (
    <>
      <Checkbox
        label="Customer is individual"
        checked={isCustomerIndividual}
        disabled={isIndividualCheckboxDisabled}
        onChange={handleIsCustomerIndividualChange}
        withBottomMargin
      />

      {organizationNameWatch ? (
        <TextField
          value={organizationNameWatch}
          label="Organization name"
          disabled
          helperText={autocompleteHelperText}
          InputProps={{
            endAdornment: !isCustomerIndividual && (
              <IconButton onClick={onAutoCompleteClear}>
                <CloseIcon />
              </IconButton>
            ),
          }}
        />
      ) : (
        <Autocomplete
          url="customers/organizations/"
          name="organization.autocomplete"
          label="Organization name"
          mapOptions={mapOrganizationOptions}
          onChange={handleAutoCompleteChange}
          creatable
          helperText={autocompleteHelperText}
        />
      )}

      {isFormFieldsDisplayed && (
        <>
          <FieldsGroup>
            <ControlledInput
              name="organization.email_domain"
              label="Email domain"
              disabled={isCustomerIndividual}
            />
            <ControlledInput
              name="organization.order_confirmation_email"
              label="Order confirmation email"
              type="email"
            />
            <ControlledInput
              name="organization.invoice_email"
              label="Invoice email"
              type="email"
            />
          </FieldsGroup>

          <Divider sx={{ my: 3 }} />

          <ControlledInput name="organization.notes" label="Notes" multiline />

          <Typography variant="body1" sx={{ mb: 2 }}>
            Payment & billing
          </Typography>

          <FieldsGroup mb={3}>
            <Select
              name="organization.currency"
              label="Preferred currency"
              defaultValue="eur"
            >
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="eur">EUR</MenuItem>
              <MenuItem value="cad">CAD</MenuItem>
            </Select>
            <ControlledInput
              label="Max outstanding"
              type="number"
              inputProps={{ min: 0 }}
              name="organization.max_outstanding_amount"
              InputProps={{
                startAdornment: (
                  <Typography
                    marginRight={1}
                    marginTop={0.3}
                    color="text.secondary"
                  >
                    {organizationCurrencyWatch?.toUpperCase() ?? 'EUR'}
                  </Typography>
                ),
              }}
            />
          </FieldsGroup>

          <FieldsGroup mb={3}>
            <ControlledInput
              label="Advance %"
              type="number"
              inputProps={percentageInputOptions}
              name="organization.advance_limit"
            />
            <ControlledInput
              label="Upon shipping %"
              type="number"
              inputProps={percentageInputOptions}
              name="organization.upon_shipping_limit"
            />
            <ControlledInput
              label="NET %"
              type="number"
              name="organization.net_limit"
              inputProps={percentageInputOptions}
            />
            <ControlledInput
              label="Outstanding days"
              type="number"
              name="organization.max_outstanding_days"
              inputProps={{ min: 0 }}
            />
          </FieldsGroup>

          <FieldsGroup mb={3}>
            <ControlledInput
              sx={{ flex: 1 }}
              label="VAT number"
              name="organization.vat_number"
            />
            <Box sx={{ flex: 1 }}>
              <FormControlLabel
                labelPlacement="end"
                label="Charge VAT"
                control={
                  <Switch
                    {...register('organization.is_charge_vat')}
                    readOnly
                    defaultChecked={getValues('organization.is_charge_vat')}
                  />
                }
              />
            </Box>
          </FieldsGroup>

          <Typography variant="body1" sx={{ mb: 2 }}>
            Shipping address
          </Typography>
          <AddressFields prefix="address" />

          <Typography variant="body1" sx={{ my: 2 }}>
            Shipping details
          </Typography>

          <FieldsGroup sameWidth>
            <Autocomplete
              name="organization.shipping_method"
              label="Shipping method"
              url="customers/shipping-methods/"
              enabled
              mapOptions={mapShippingMethodOption}
              setDefaultValueAsync={getIsShippingMethodDefault}
            />
            {showShippingInstructions ? (
              <ControlledInput
                name="organization.shipping_instructions"
                label="Shipping account number"
              />
            ) : (
              <Box />
            )}
          </FieldsGroup>
          {!sameShippingAndBillingAddressWatch && (
            <>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Billing address
              </Typography>

              <AddressFields prefix="billing_address" />
            </>
          )}
        </>
      )}
    </>
  )
}
