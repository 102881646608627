import { ReactElement } from 'react'
import {
  Container,
  styled,
  SxProps,
  TablePagination as MuiTablePagination,
  Theme,
} from '@mui/material'

import { TableConfig } from './types'

const PaginationWrapper = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: 'auto',
})

const paginationStyle: SxProps<Theme> = (theme) => ({
  height: '8%',
  ml: 'auto',
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.background.paper,
  overflowY: 'hidden',
})

interface TablePaginationProps {
  count: number
  config: TableConfig
  extraContent?: ReactElement
}

export default function TablePagination({
  config,
  count,
  extraContent,
}: TablePaginationProps) {
  const { page, onPageChange, rowsPerPage, onRowsPerPageChange } = config

  return (
    <PaginationWrapper>
      {extraContent}
      <MuiTablePagination
        sx={paginationStyle}
        component="div"
        count={count}
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </PaginationWrapper>
  )
}
