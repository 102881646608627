import { DateTime, Info, UnitLength } from 'luxon'

export function localeDate(
  date: Date | string,
  options?: Intl.DateTimeFormatOptions
) {
  const dateToTransform = typeof date === 'string' ? new Date(date) : date

  const dateOptions = options ?? {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  }

  return dateToTransform.toLocaleDateString('nl-NL', dateOptions)
}

export function localeDateTime(date: Date | string) {
  const dateToTransform = typeof date === 'string' ? new Date(date) : date

  return dateToTransform.toLocaleString('nl-NL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  })
}

export function isValidDateFormat(dateString: string): boolean {
  try {
    const date = DateTime.fromFormat(dateString, 'yyyy-MM-dd')
    const time = DateTime.fromFormat(dateString, "yyyy-MM-dd'T'HH:mm:ss'Z'")
    return time.isValid || date.isValid
  } catch {
    return false
  }
}

export function printDate(date: Date | string) {
  const jsDate = typeof date === 'string' ? new Date(date) : date

  return DateTime.fromJSDate(jsDate).toFormat('LLLL dd, yyyy')
}

export function getValidityDaysLabel(
  validity_days?: number,
  expiration_date?: Date | string
) {
  const formattedExpirationDate = expiration_date
    ? localeDate(expiration_date)
    : null
  const expirationDateText = formattedExpirationDate ?? ''

  if (validity_days) {
    return formattedExpirationDate
      ? `${expirationDateText} (${validity_days} days)`
      : `${validity_days} days`
  }

  return formattedExpirationDate || '-'
}

export function getMonthName(
  monthNumber: number,
  monthUnitLength: UnitLength = 'long'
) {
  return Info.months(monthUnitLength, { locale: 'en-US' })[monthNumber - 1]
}
