import { PropsWithChildren } from 'react'
import { useNavigation } from 'react-router-dom'
import { Container, styled } from '@mui/material'

import { RelativeBackdrop } from 'components/Backdrop'

import { useDrawer } from '../Layout'

type StyledBodyProps = {
  open: boolean
}

const StyledBody = styled(Container)<StyledBodyProps>(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(4, 3),
  backgroundColor: '#f4f5f7',
  position: 'relative',

  minWidth: `calc(1424px - ${theme.shape.drawerWidth.max}px)`,
  maxWidth: `calc(100vw - ${theme.shape.drawerWidth[open ? 'max' : 'min']}px)`,
  ...theme.mixins.drawer[open ? 'open' : 'close'],
}))

export default function Body({ children }: PropsWithChildren) {
  const [open] = useDrawer()
  const { state } = useNavigation()

  return (
    <StyledBody disableGutters open={open}>
      {state === 'loading' ? <RelativeBackdrop open /> : children}
    </StyledBody>
  )
}
