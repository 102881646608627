import { Typography } from '@mui/material'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { PreviewTableCell } from 'components/PreviewTableCell'
import { ShipmentTableActionButtons } from 'components/ShipmentTableButtons'
import { Table, useTable } from 'components/Table'
import { HeadCell } from 'components/Table/types'
import { useAbility } from 'context/Ability'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import { localeDate } from 'utils/date'
import { Shipment } from 'utils/global-types'

const getShipmentColumns = (canViewOrder: boolean): HeadCell<Shipment>[] => {
  return [
    {
      label: 'Shipment Date',
      width: 125,
      value: ({ shipment_date }) => localeDate(shipment_date!),
    },
    {
      label: 'Order ID',
      value: 'order_id',
      width: 110,
      getHref: canViewOrder ? (row) => `/orders/${row.order_id}` : undefined,
    },
    {
      label: 'Invoice ID',
      width: 130,
      value: ({ invoice, invoice_id }) => (
        <PreviewTableCell uuid={invoice} invoice_id={invoice_id} />
      ),
    },
    { label: 'Tracking number', value: 'tracking_number', width: 168 },
    { label: 'Customer', value: 'customer_name', width: 168 },
    { label: 'Address', value: 'address', width: 168 },
    { label: 'Shipping method', value: 'carrier_name', width: 168 },
    {
      label: '',
      value: (shipment) => <ShipmentTableActionButtons shipment={shipment} />,
    },
  ]
}

export default function ShipmentsList() {
  const ability = useAbility()

  const table = useTable<Shipment>('shipments', '/shipments/shipments/', {
    columns: getShipmentColumns(ability.can('view', 'order')),
  })

  return (
    <PageTemplate title="Shipments">
      <PageHeader>
        <Typography variant="h6">Shipments</Typography>
        <Breadcrumbs />
      </PageHeader>
      <Table
        dataType="shipments"
        {...table}
        searchPlaceholder="Search by order, invoice and maybe tracking number"
        disableAddButton
      />
    </PageTemplate>
  )
}
