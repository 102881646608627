import { ReactElement } from 'react'
import { LoaderFunction, Params } from 'react-router-dom'
import { QueryClient } from '@tanstack/react-query'

import queryClient from 'configs/query'

// The same as type from react-router-dom but handle is extended
export interface Match {
  id: string
  pathname: string
  params: Params<string>
  data: unknown
  handle: {
    crumb?: (match: Match) => ReactElement
  }
}

type LoaderCallback = (
  params: Parameters<LoaderFunction>[0],
  queryClient: QueryClient
) => ReturnType<LoaderFunction>

export const createLoader =
  (loaderCallback: LoaderCallback): LoaderFunction =>
  (params) => {
    return loaderCallback(params, queryClient)
  }
