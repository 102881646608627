import { MutationFunction, useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'

export type RequestInvoiceResponse = void
export type RequestInvoiceVariables = {
  uuid: string
  po_number: string
}

const requestInvoice: MutationFunction<
  RequestInvoiceResponse,
  RequestInvoiceVariables
> = async ({ po_number, uuid }) => {
  const response = await axiosInstance.post<RequestInvoiceResponse>(
    `/customers-portal/quotes/${uuid}/request-invoice/`,
    { po_number }
  )

  return response.data
}

const useRequestInvoice = () => {
  return useMutation<
    RequestInvoiceResponse,
    AxiosError<Record<string, string[]>>,
    RequestInvoiceVariables
  >({
    useErrorBoundary: true,
    mutationFn: requestInvoice,
    meta: { useGlobalError: false },
  })
}

export default useRequestInvoice
