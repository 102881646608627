import toast from 'react-hot-toast'
import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { uninterceptedAxios } from 'configs/axios'

type UploadRatesResponse = boolean
type UploadRatesError = AxiosError<Record<keyof UploadRatesVariables, string>>
export type UploadRatesVariables = {
  rates: File
  countries_zones: File
}

const postRates: MutateFunction<
  UploadRatesResponse,
  UploadRatesError,
  UploadRatesVariables
> = async (shipmentFiles) => {
  const formData = new FormData()
  formData.append('rates', shipmentFiles.rates)
  formData.append('countries_zones', shipmentFiles.countries_zones)

  const response = await uninterceptedAxios.post<void>(
    '/shipments/upload-rates/',
    formData
  )

  return response.status === 204
}

const useUploadRates = () => {
  const queryClient = useQueryClient()

  return useMutation<
    UploadRatesResponse,
    UploadRatesError,
    UploadRatesVariables
  >({
    mutationKey: ['ratesUpload'],
    mutationFn: postRates,
    onSuccess: () => {
      toast.success('File uploading started')
      queryClient.invalidateQueries(['config'])
    },
    meta: { useGlobalError: false },
    onError: (error) => {
      const message = Object.values(error.response?.data ?? {})[0]
      ;('Failed to upload supplier data')
      toast.error(message)
    },
  })
}

export default useUploadRates
