import { Navigate, To, useLocation } from 'react-router-dom'

import { Layout } from 'pages/Layout'
import { useIsAuthenticated } from 'utils/routing'

interface ProtectedRouteProps {
  to?: To
  from?: To
}

export default function ProtectedRoute({ to = '/login' }: ProtectedRouteProps) {
  const location = useLocation()
  const authenticated = useIsAuthenticated()

  return authenticated ? (
    <Layout />
  ) : (
    <Navigate to={to} state={{ from: location.pathname }} />
  )
}
