import { Button, Container, styled, Typography } from '@mui/material'
import { groupBy, mapValues, orderBy } from 'lodash'

import useAddresses from 'api/address/useAddresses'
import useCreateAddress from 'api/address/useCreateAddress'
import { FormSection } from 'components/FormHelpers'
import { DialogSubmitHandler, useDialog } from 'context/Dialog/DialogContext'
import { Address, Organization } from 'utils/global-types'

import AddressItem from './AddressItem'
import { addAddressDialog } from './dialogs'

const AddressesWrapper = styled(Container)({
  marginBottom: 16,
  display: 'flex',
  gap: 16,
})

const AddressesColumn = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  rowGap: 16,
  margin: 0,
})

interface AddressesSectionProps {
  organizationID: Organization['uuid']
}

type GroupedAddresses = {
  [key: string]: Address[]
}

export default function AddressesSection({
  organizationID,
}: AddressesSectionProps) {
  const [openDialog] = useDialog()
  const { mutateAsync: addNewAddress } = useCreateAddress()

  const { data: addresses } = useAddresses({
    organization: organizationID,
  })

  const submitNewAddress: DialogSubmitHandler = async (result) =>
    await addNewAddress({
      ...(result as Address),
      organization: organizationID,
    })

  const openModalToAddNewAddress = async () => {
    await openDialog(addAddressDialog, submitNewAddress)
  }

  if (!addresses) {
    return null
  }

  const groupedAddresses: GroupedAddresses = mapValues(
    groupBy(addresses, 'type'),
    (addressesByType) =>
      orderBy(addressesByType, ['is_active', 'is_preferred'], ['desc', 'desc'])
  )

  return (
    <FormSection title="Addresses" sx={{ mb: 0 }} titleSx={{ mb: 0 }}>
      <Typography color="text.secondary" mb={2.5}>
        When creating a new quote, you have the option to use the selected
        shipping address as the billing address
      </Typography>
      <AddressesWrapper>
        <AddressesColumn>
          {groupedAddresses.shipping?.map((address) => (
            <AddressItem key={address.uuid} {...address} />
          ))}
        </AddressesColumn>
        <AddressesColumn>
          {groupedAddresses.billing?.map((address) => (
            <AddressItem key={address.uuid} {...address} />
          ))}
        </AddressesColumn>
      </AddressesWrapper>
      <Button variant="contained" onClick={openModalToAddNewAddress}>
        Add new address
      </Button>
    </FormSection>
  )
}
