import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Invoice, Order } from 'utils/global-types'

type DebitInvoiceResponse = Invoice
type DebitInvoiceVariables = void

function createDebitInvoice(invoiceUUID: Invoice['uuid']) {
  const debitInvoice: MutateFunction<
    DebitInvoiceResponse,
    DebitInvoiceVariables
  > = async () => {
    const response = await axios.post<DebitInvoiceResponse>(
      '/invoices/debit/',
      { invoice: invoiceUUID }
    )

    return response.data
  }

  return debitInvoice
}

type DebitInvoiceProps = {
  invoiceUUID: Invoice['uuid']
  orderID: Order['order_id']
}

const useDebitInvoice = ({ invoiceUUID, orderID }: DebitInvoiceProps) => {
  const queryClient = useQueryClient()

  return useMutation<DebitInvoiceResponse, AxiosError, DebitInvoiceVariables>({
    mutationFn: createDebitInvoice(invoiceUUID),
    onSuccess: ({ order }) => {
      queryClient.invalidateQueries(['invoices'])
      queryClient.invalidateQueries(['order', orderID])
      queryClient.invalidateQueries(['product-items', order])
      queryClient.invalidateQueries(['preview-invoice', invoiceUUID])
      queryClient.invalidateQueries(['invoice', invoiceUUID])
      queryClient.invalidateQueries(['invoice-product-items', invoiceUUID])
    },
    meta: { message: 'Failed to Debit invoice' },
  })
}

export default useDebitInvoice
