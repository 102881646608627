import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Typography } from '@mui/material'
import * as yup from 'yup'

import useLogin, { LoginVariables } from 'api/user/useLogin'
import { LoadingButton } from 'components/Buttons'
import { ControlledInput, PasswordInput } from 'components/Form'
import { Form } from 'components/FormHelpers'

const loginSchema = yup
  .object({
    email: yup
      .string()
      .email('Must be a valid email')
      .required('Please enter your email'),
    password: yup.string().required('Please enter your password'),
  })
  .required()

export default function LoginForm() {
  const form = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    } as LoginVariables,
  })

  const { handleSubmit, setError, formState } = form
  const { isDirty } = formState

  const { mutateAsync: login, isLoading } = useLogin()

  const onSubmit = handleSubmit(async (values) => {
    const successfulLogin = await login(values)

    if (!successfulLogin) {
      setError('email', { message: 'Email or password are not valid' })
      setError('password', { message: 'Email or password are not valid' })
    }
  })

  return (
    <Stack justifyContent="center" gap={1} width={544}>
      <Typography variant="h5" sx={{ pb: 4 }}>
        Login into Omnical
      </Typography>
      <Form handlers={form} onSubmit={onSubmit}>
        <Stack
          justifyContent="center"
          gap={1}
          sx={{
            '& .MuiFormHelperText-root.Mui-error': {
              position: 'absolute',
              top: '100%',
            },
          }}
        >
          <ControlledInput
            name="email"
            type="text"
            label="Email address"
            InputLabelProps={{}}
          />
          <PasswordInput name="password" label="Password" />
          <LoadingButton
            isLoading={isLoading}
            disabled={!isDirty}
            type="submit"
            variant="contained"
          >
            Log In
          </LoadingButton>
        </Stack>
      </Form>
    </Stack>
  )
}
