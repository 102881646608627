import toast from 'react-hot-toast'
import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { uninterceptedAxios } from 'configs/axios'

type SupplierDataResponse = { supplier_products: string }
type SupplierDataError = AxiosError<SupplierDataResponse>
type SupplierDataVariables = File

const postSupplierData: MutateFunction<
  SupplierDataResponse,
  SupplierDataError,
  SupplierDataVariables
> = async (files) => {
  const formData = new FormData()
  formData.append('supplier_products', files)

  const response = await uninterceptedAxios.post<SupplierDataResponse>(
    '/product-items/order-products/upload-supplier-data/',
    formData
  )

  return response.data
}

const useUploadSupplier = () => {
  const queryClient = useQueryClient()

  return useMutation<
    SupplierDataResponse,
    SupplierDataError,
    SupplierDataVariables
  >({
    mutationFn: postSupplierData,
    onSuccess: () => {
      toast.success('File uploading started')
      queryClient.invalidateQueries(['config'])
    },
    meta: { useGlobalError: false },
    onError: (error) => {
      const message =
        error.response?.data?.supplier_products ??
        'Failed to upload supplier data'
      toast.error(message)
    },
  })
}

export default useUploadSupplier
