import { styled, Typography } from '@mui/material'

import { copyToClipboard } from 'utils/helpers'

const StyledContextLabel = styled(Typography)({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
})

interface ContextLabelProps {
  data: [string, string | number]
}

export function ContextLabel({ data: [key, value] }: ContextLabelProps) {
  const wrappedKey = `{{ ${key} }}`
  const label = `${wrappedKey}: ${value}`

  const handleClick = () => copyToClipboard(wrappedKey)

  return <StyledContextLabel onClick={handleClick}>{label}</StyledContextLabel>
}
