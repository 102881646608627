import axios, { CreateAxiosDefaults, isAxiosError } from 'axios'
import Cookies from 'js-cookie'

export function isAuthError(error: unknown) {
  return isAxiosError(error) && error.response?.status === 401
}

export function isForbiddenError(error: unknown) {
  return isAxiosError(error) && error.response?.status === 403
}

const axiosConfig: CreateAxiosDefaults = {
  baseURL: process.env.API_URL,
  withCredentials: true,
}

const axiosInstance = axios.create(axiosConfig)

axiosInstance.interceptors.request.use((config) => {
  if (config?.headers) {
    config.headers['content-type'] = 'application/json'
    config.headers['X-CSRFToken'] = Cookies.get('c')
  }
  return config
})

const uninterceptedAxios = axios.create(axiosConfig)

uninterceptedAxios.interceptors.request.use((config) => {
  config.headers = config?.headers ?? {}
  config.headers['X-CSRFToken'] = Cookies.get('c')
  return config
})

const emptyRequestWithFetch = (
  url: string,
  method: RequestInit['method'] = 'POST'
) =>
  fetch(`${process.env.API_URL}${url}`, {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  })

export default axiosInstance
export { emptyRequestWithFetch, uninterceptedAxios }
