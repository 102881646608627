import { View } from '@react-pdf/renderer'

export default function Divider() {
  return (
    <View>
      <View
        style={{
          height: 1,
          width: '100%',
          backgroundColor: 'black',
          opacity: 0.12,
        }}
      />
    </View>
  )
}
