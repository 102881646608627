import { Currency } from 'utils/global-types'
import { formatPrice } from 'utils/price'

import { DocumentMetaInfoFields, InvoiceContext, QuoteContext } from './types'

// all assets src should be wrapped in this helper function
export const getSrc = (path: string) =>
  typeof window === 'undefined' ? `${__dirname}/${path}` : path

export const printPrice = (
  price: number,
  currency: Currency,
  maximumDecimals = 2
) => formatPrice(price, currency, { priceFirst: true, maximumDecimals })

const removePrefix = (key: string) => key.replace(/quote_|invoice_/, '')

export const getDocumentMetaInfoFields = (
  context: QuoteContext | InvoiceContext
): DocumentMetaInfoFields => {
  const s = Object.entries(context).reduce((acc, [key, value]) => {
    if (key.includes('bank') || key.includes('from')) {
      return { ...acc, [removePrefix(key)]: value }
    }
    return acc
  }, {} as DocumentMetaInfoFields)

  return s
}
