import toast from 'react-hot-toast'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { Product } from 'utils/global-types'

type EditProductResponse = Product

export type EditProductVariables = Omit<Product, 'uuid'> & {
  productSku: string
}

const editProduct: MutationFunction<
  EditProductResponse,
  EditProductVariables
> = async ({ productSku, ...product }) => {
  const response = await axiosInstance.put<EditProductResponse>(
    `/products/${productSku}/`,
    product
  )

  return response.data
}

const useEditProduct = () => {
  const queryClient = useQueryClient()

  return useMutation<
    EditProductResponse,
    AxiosError<Record<string, string[]>>,
    EditProductVariables
  >({
    mutationFn: editProduct,
    onSuccess: (_, { sku }) => {
      queryClient.invalidateQueries(['products'])
      queryClient.invalidateQueries(['product', sku])

      toast.success('Product details updated successfully')
    },
    meta: {
      message: 'Failed to update product details',
      useGlobalError: false,
    },
  })
}

export default useEditProduct
