import { IconButton } from '@mui/material'

import { usePreviewContext } from 'components/PreviewDrawer/context'
import { Invoice } from 'utils/global-types'

import PreviewIcon from './PreviewIcon'

type PreviewTableCellProps = Pick<Invoice, 'uuid' | 'invoice_id'>

export default function PreviewTableCell({
  uuid,
  invoice_id,
}: PreviewTableCellProps) {
  const openPreview = usePreviewContext()

  const handleClick = () => {
    openPreview({ type: 'invoice', uuid })
  }

  if (!invoice_id) {
    return <>-</>
  }

  return (
    <>
      {invoice_id}
      <IconButton
        size="small"
        onClick={handleClick}
        sx={{ ml: 1, mt: '-10px', transform: 'translateY(5px)' }}
      >
        <PreviewIcon />
      </IconButton>
    </>
  )
}
