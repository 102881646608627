import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Order, Payment, PaymentUpdate } from 'utils/global-types'

type EditPaymentResponse = Payment & {
  order: Order['uuid']
}
type EditPaymentVariables = Partial<PaymentUpdate>

const editPayment = (paymentID: Payment['uuid']) => {
  const patchPayment: MutationFunction<
    EditPaymentResponse,
    EditPaymentVariables
  > = async (payment) => {
    const response = await axios.patch<EditPaymentResponse>(
      `/payments/${paymentID}/`,
      payment
    )

    return response.data
  }

  return patchPayment
}

const useEditPayment = (paymentID: Payment['uuid']) => {
  const queryClient = useQueryClient()

  return useMutation<EditPaymentResponse, AxiosError, EditPaymentVariables>({
    mutationFn: editPayment(paymentID),
    onSuccess: ({ order: orderUUID, order_id: orderID }) => {
      queryClient.invalidateQueries(['order', orderID])
      queryClient.invalidateQueries(['payments', orderUUID])
      queryClient.invalidateQueries(['invoices', orderUUID])
    },
    meta: {
      message: 'Failed to update payment',
      useGlobalError: false,
    },
  })
}

export default useEditPayment
