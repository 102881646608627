import { View } from '@react-pdf/renderer'

interface SpaceProps {
  width?: number
  height?: number
  horizontal?: boolean
}

export default function Space({
  width = 8,
  height = 8,
  horizontal = false,
}: SpaceProps) {
  if (horizontal) {
    return <View style={{ width }} />
  }

  return <View style={{ height }} />
}
