import { Controller, useFormContext } from 'react-hook-form'
import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
  FormControlLabel,
} from '@mui/material'

type CheckboxProps = MUICheckboxProps & {
  label: string | React.ReactNode
  withBottomMargin?: boolean
}

export default function Checkbox({
  label,
  name,
  withBottomMargin,
  ...props
}: CheckboxProps) {
  const { control } = useFormContext()

  return (
    <FormControlLabel
      sx={{ whiteSpace: 'nowrap', mb: withBottomMargin ? 2 : 0 }}
      label={label}
      control={
        name ? (
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <MUICheckbox
                {...field}
                {...props}
                checked={field.value}
                inputRef={field.ref}
              />
            )}
          />
        ) : (
          <MUICheckbox {...props} />
        )
      }
    />
  )
}
