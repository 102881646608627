import { Fragment } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'
import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
} from '@mui/material'

import { ControlledInput } from 'components/Form'

export default function MarginFieldsArray() {
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'margin_rules',
  })

  const handleAddRule = () => {
    append({})
  }

  return (
    <Stack alignItems="start">
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <Stack direction="row" alignItems="start" gap={1} pl={1.5}>
            <ControlledInput
              type="number"
              label="Order Value (From)"
              name={`margin_rules.${index}.min_value`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">EUR</InputAdornment>
                ),
              }}
            />
            <ControlledInput
              type="number"
              label="Order Value (To)"
              name={`margin_rules.${index}.max_value`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">EUR</InputAdornment>
                ),
              }}
            />
            <ControlledInput
              type="number"
              label="Target Margin"
              name={`margin_rules.${index}.target`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
            />
            <IconButton onClick={() => remove(index)}>
              <Delete />
            </IconButton>
          </Stack>
          <Divider flexItem sx={{ mb: 3 }} />
        </Fragment>
      ))}
      <Button variant="outlined" startIcon={<Add />} onClick={handleAddRule}>
        Add rule
      </Button>
    </Stack>
  )
}
