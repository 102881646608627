import { useNavigate } from 'react-router-dom'
import { ButtonProps } from '@mui/material'

import useCreateQuote from 'api/order/useCreateQuote'
import { LoadingButton } from 'components/Buttons'
import { Customer } from 'utils/global-types'

interface CreateQuoteButtonProps extends ButtonProps {
  customerUUID: Customer['uuid']
  enabled: boolean
}

export default function CreateQuoteButton({
  customerUUID,
  enabled,
  ...props
}: CreateQuoteButtonProps) {
  const navigate = useNavigate()
  const { mutateAsync: createQuote, isLoading } = useCreateQuote()

  const handleCreateQuote = async () => {
    const quote = await createQuote({ customer: customerUUID })
    navigate(`/quotes/${quote.order_id}/edit`)
  }

  return (
    <LoadingButton
      disabled={!enabled}
      isLoading={isLoading}
      onClick={handleCreateQuote}
      {...props}
    >
      Create a quote
    </LoadingButton>
  )
}
