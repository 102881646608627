import { yupResolver } from '@hookform/resolvers/yup'

import { DialogConfig } from 'context/Dialog/DialogContext'

import DownloadReport from './DownloadReport'
import { reportDownloadSchema } from './validation'

export const downloadReport = (): DialogConfig => ({
  title: 'Download report',
  content: <DownloadReport />,
  mutationKeys: ['downloadReport'],
  actions: {
    confirm: 'Download report',
  },
  // @ts-expect-error ts(2322) Resolver infer type is not equal to form values type
  resolver: yupResolver(reportDownloadSchema),
})
