import { OrderProductItem, Product } from './global-types'

export const getProductLabel = (option: Product) =>
  `${option.sku} ${option.manufacturer_name} ${option.manufacturer_part_number} ${option.type}`

export const getShortProductLabel = (option: Product | OrderProductItem) =>
  `${option.sku} — ${option.manufacturer_name} — ${option.manufacturer_part_number}`

export type ProductOption = {
  unit: string
  amount: number
}

export const getPriceLabel = (title: string, options: ProductOption) => {
  if (!title) {
    return ''
  }
  if (!options.amount || !options.unit) {
    return title
  }

  if (options.amount === 1) {
    return `${title}/${options.unit}`
  }

  return `${title}/${options.amount} ${options.unit}`
}
