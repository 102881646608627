import { QueryFunction, useQuery } from '@tanstack/react-query'

import axios from 'configs/axios'
import { Address, ListReturnType } from 'utils/global-types'
import { addQueryParams } from 'utils/request'

export type AddressesResponse = ListReturnType<Address>
type AddressesQueryKey = [query: 'addresses', params: AddressesRequestParams]
type AddressesRequestParams = Partial<
  Address & {
    ordering: string
    search: string
    limit: number
    offset: number
  }
>

const getAddresses: QueryFunction<
  AddressesResponse,
  AddressesQueryKey
> = async ({ queryKey }) => {
  const [, params] = queryKey

  const url = addQueryParams('/customers/addresses/', params)

  const response = await axios.get<AddressesResponse>(url)

  return response.data
}

const useAddresses = (params: AddressesRequestParams = {}) => {
  return useQuery<AddressesResponse, Error, Address[], AddressesQueryKey>({
    queryKey: ['addresses', params],
    queryFn: getAddresses,
    select: (addresses) => addresses.results,
    meta: { message: 'Failed to get addresses' },
  })
}

export default useAddresses
