import { get } from 'lodash'
import * as yup from 'yup'
import { AnyObject } from 'yup/lib/types'

import { nanableNumber } from 'utils/validations'

const invalidEmailErrorMessage = 'Email is not valid'

const hasOrganization = (
  value: string | undefined,
  context: yup.TestContext<AnyObject>
) => {
  if (get(context, 'from[1].value.organization.uuid')) {
    return true
  }
  return !!value
}

const customerSchema = yup.object({
  email: yup
    .string()
    .email(invalidEmailErrorMessage)
    .max(255, 'Email is too long. Max email length is 255 characters')
    .required('Email is required'),
  name: yup.string().required('Name is required'),
  phone_number: yup
    .string()
    .max(255, 'Phone number is too long. Max length is 255 characters'),
  notes: yup.string().max(255, 'Max length is 255 characters'),
})

const organizationSchema = yup.object({
  is_active: yup.boolean(),
  slug: yup.string(),
  name: yup.string(),
  email_domain: yup.string(),
  notes: yup.string(),
  currency: yup.string().required().default('cad'),
  advance_limit: nanableNumber,
  upon_shipping_limit: nanableNumber,
  net_limit: nanableNumber,
  max_outstanding_amount: nanableNumber,
  max_outstanding_days: nanableNumber,
  vat_number: yup.string(),
  order_confirmation_email: yup.string().email(invalidEmailErrorMessage),
  invoice_email: yup.string().email(invalidEmailErrorMessage),
})

const addressSchema = yup.object({
  address_line_1: yup
    .string()
    .test('city', 'Address line is required', hasOrganization),
  address_line_2: yup.string(),
  city: yup.string().test('city', 'City is required', hasOrganization),
  postal_code: yup
    .string()
    .test(
      'len',
      'Postal code must be less than 255 characters',
      (val) => val === undefined || val.length <= 255
    ),
  country: yup.string().test('city', 'Country is required', hasOrganization),
  phone_number: yup.string(),
  notes: yup
    .string()
    .test(
      'len',
      'Notes must be less than 255 characters',
      (val) => val === undefined || val.length <= 255
    ),
})

export const customerWizardSchema = yup.object({
  customer: customerSchema,
  organization: organizationSchema,
  address: addressSchema,
})

export const customerExistsErrorMessage =
  'Looks like this customer already exist, use search to find it in the customers list'
