import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material'

import emptyTableImage from 'assets/img/empty-table.png'

type NoResultsPlaceholderProps = {
  dataType: string
  url?: string
  disableAddButton?: boolean
}
export default function NoResultsPlaceholder({
  dataType,
  url,
  disableAddButton,
}: NoResultsPlaceholderProps) {
  const navigate = useNavigate()

  const singleDataType = dataType.slice(0, -1)

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: 6,
      }}
    >
      <Card sx={{ width: 361, boxShadow: 'none' }}>
        <CardMedia
          component="img"
          height={240}
          image={emptyTableImage}
          title="Empty table"
        />
        <CardContent>
          <Stack gap={2}>
            <Typography fontSize={20} align="center">
              No {dataType} found
            </Typography>
            <Typography align="center" variant="body2" color="text.secondary">
              Add, view and edit your {dataType} all in one place.
            </Typography>
          </Stack>
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
          {!disableAddButton && (
            <Button
              onClick={() => navigate(url ?? `/${dataType}/new`)}
              startIcon={<AddIcon />}
              variant="contained"
            >
              new {singleDataType}
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  )
}
