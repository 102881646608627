import { useMemo } from 'react'
import {
  Button,
  Chip,
  Container,
  Paper,
  styled,
  Typography,
} from '@mui/material'
import { startCase } from 'lodash'

import useEditAddress from 'api/address/useEditAddress'
import { DialogSubmitHandler, useDialog } from 'context/Dialog/DialogContext'
import { Address, AddressUpdate } from 'utils/global-types'

import { createEditAddressDialog } from './dialogs'

const AddressPaper = styled(Paper)({
  padding: 16,
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
})

const ChipWrapper = styled(Container)({
  marginTop: 8,
  flexBasis: '100%',

  display: 'flex',
  alignItems: 'center',
  columnGap: 16,

  '&:last-child': {
    marginLeft: 'auto',
  },
})

export default function AddressItem(props: Address) {
  const {
    uuid,
    address_line_1,
    address_line_2,
    city,
    postal_code,
    country_name,
    is_active,
    is_preferred,
    organization_name,
    type,
  } = props

  const [openDialog] = useDialog()

  const { mutateAsync: editAddress } = useEditAddress(uuid)

  const submitAddressUpdate: DialogSubmitHandler = async (result) =>
    await editAddress(result as AddressUpdate)

  const openEditModal = async () =>
    await openDialog(createEditAddressDialog(props), submitAddressUpdate)

  const colors = useMemo(
    () => ({
      primary: is_active ? 'text.primary' : 'text.disabled',
      secondary: is_active ? 'text.secondary' : 'text.disabled',
    }),
    [is_active]
  )

  return (
    <AddressPaper variant="outlined">
      <Container sx={{ width: 'calc(100% - 60px)', m: 0, overflow: 'hidden' }}>
        {organization_name && (
          <Typography variant="body2" color="text.secondary" fontWeight={700}>
            {organization_name}
          </Typography>
        )}
        <Typography
          sx={{
            wordBreak: 'break-word',
          }}
          overflow="hidden"
          textOverflow="ellipsis"
          color={colors.primary}
        >
          {address_line_1}
        </Typography>
        {address_line_2 ? (
          <Typography
            sx={{
              wordBreak: 'break-word',
            }}
            overflow="hidden"
            textOverflow="ellipsis"
            color={colors.primary}
          >
            {address_line_2}
          </Typography>
        ) : (
          <br />
        )}
        <Typography variant="body2" color={colors.secondary}>
          {city}, {postal_code}, {country_name}
        </Typography>
      </Container>
      <Typography m="0 auto" variant="body2" color={colors.secondary}>
        {is_active ? 'Active' : 'Inactive'}
      </Typography>
      <ChipWrapper>
        <Chip label={startCase(type)} disabled={!is_active} />
        {is_preferred && <Chip label="Preferred" color="success" />}
        <Button onClick={openEditModal} sx={{ ml: 'auto' }}>
          Edit
        </Button>
      </ChipWrapper>
    </AddressPaper>
  )
}
