import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { cloneDeep } from 'lodash'

import useOrder from 'api/order/useOrder'
import axios from 'configs/axios'
import { ListReturnType, Order, Payment } from 'utils/global-types'

type PaymentsResponse = ListReturnType<Payment>
type PaymentQueryKey = ['payments', Order['uuid'] | null]

const getPayments: QueryFunction<PaymentsResponse, PaymentQueryKey> = async ({
  queryKey,
}) => {
  const [, orderID] = queryKey

  const response = await axios.get<PaymentsResponse>('/payments/', {
    params: {
      order: orderID ?? undefined,
      // type__in: 'PAYMENT,WITHDRAWAL,REFUND'
    },
  })

  return response.data
}

export const paymentsQueryOptions = (
  orderUUID: PaymentQueryKey[1]
): WithRequired<
  UseQueryOptions<PaymentsResponse, AxiosError, Payment[], PaymentQueryKey>,
  'queryKey'
> => ({
  queryKey: ['payments', orderUUID],
  queryFn: getPayments,
  meta: { message: 'Failed to get payments' },
})

const usePayments = (orderID: Order['order_id']) => {
  const { data } = useOrder(orderID)
  const order_uuid = data?.order?.uuid

  return useQuery<PaymentsResponse, AxiosError, Payment[], PaymentQueryKey>({
    ...paymentsQueryOptions(order_uuid || null),
    select: (data) => {
      const results = cloneDeep(data.results)
      const paymentsWithoutSubpayments = results.filter(
        (payment) => payment.parent === null
      )
      const onlySubPaiments = results.filter((payment) => payment.parent)
      onlySubPaiments.forEach((payment) => {
        const parent = paymentsWithoutSubpayments.findIndex(
          (p) => p.uuid === payment.parent
        )
        if (parent !== -1) {
          const mainPayment = paymentsWithoutSubpayments[parent]
          if (
            !mainPayment.subPayments &&
            mainPayment.total_amount - payment.total_amount > 0
          ) {
            mainPayment.subPayments = [
              {
                ...mainPayment,
                total_amount: mainPayment.total_amount - payment.total_amount,
              },
            ]
          }
          if (payment.invoice_id && !mainPayment.invoice_id) {
            mainPayment.invoice_id = payment.invoice_id
            mainPayment.destination = payment.destination
          } else if (!payment.invoice_id && mainPayment.invoice_id) {
            mainPayment.destination = payment.destination
            mainPayment.method = payment.method
          }
          mainPayment.subPayments?.push(payment)
        }
      })
      return paymentsWithoutSubpayments
    },
  })
}

export default usePayments
