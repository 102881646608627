import { useNavigate } from 'react-router-dom'
import DoneIcon from '@mui/icons-material/Done'
import { Button } from '@mui/material'

import useChangeOrderStatus from 'api/order/useChangeOrderStatus'
import { LoadingButton } from 'components/Buttons'
import { Can } from 'context/Ability'
import { Quote } from 'utils/global-types'

type QuoteButtonProps = {
  orderID: Quote['order_id']
  status: Quote['status']
  onClose: () => void
  showViewButton?: boolean
}

export default function QuoteButtons({
  orderID,
  status,
  onClose,
  showViewButton = false,
}: QuoteButtonProps) {
  const navigate = useNavigate()

  const { mutateAsync: changeQuoteStatus, isLoading } =
    useChangeOrderStatus(orderID)

  const isDraft = status === 'DRAFT'

  const handleViewQuoteClick = () => {
    navigate(`/quotes/${orderID}`)
    onClose()
  }

  const handleConvertStatus = async () => {
    await changeQuoteStatus({ status: isDraft ? 'QUOTE' : 'ORDER' })
    onClose()
  }

  return (
    <>
      <Can I="view" a="order">
        {showViewButton && (
          <Button onClick={handleViewQuoteClick} variant="text">
            View Quote
          </Button>
        )}
      </Can>
      <Can I="manage" a="order">
        <LoadingButton
          variant="outlined"
          isLoading={isLoading}
          startIcon={<DoneIcon />}
          onClick={handleConvertStatus}
        >
          Convert to {isDraft ? 'quote' : 'order'}
        </LoadingButton>
      </Can>
    </>
  )
}
