import { Link } from 'react-router-dom'
import { ArrowDropDown } from '@mui/icons-material'
import {
  Avatar,
  Button,
  Divider,
  Popover,
  Stack,
  styled,
  Typography,
} from '@mui/material'

import useLogout from 'api/user/useLogout'
import useUser from 'api/user/useUser'
import { useMenuAnchor } from 'utils/hooks'

const SettingsWrapper = styled(Button)(({ theme }) => ({
  margin: 0,
  marginLeft: 'auto',
  width: 'auto',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.primary,
}))

type DropArrowIconProps = { open: boolean }

const DropArrowIcon = styled(ArrowDropDown)<DropArrowIconProps>(
  ({ theme, open }) => ({
    transform: open ? 'rotate(180deg)' : undefined,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  })
)

export default function UserSettings() {
  const { open, anchorEl, handleClick, handleClose } = useMenuAnchor()
  const { data: user } = useUser()
  const { mutate: logout } = useLogout()

  const name = `${user?.first_name} ${user?.last_name}`
  const email = user?.email

  const handleLogout = () => {
    logout()
  }

  return (
    <>
      <SettingsWrapper onClick={handleClick}>
        <Avatar />
        <Stack alignItems="flex-start">
          <Typography variant="body1" ml={1} textTransform="capitalize">
            {name}
          </Typography>
          <Typography variant="caption" ml={1} textTransform="lowercase">
            {email}
          </Typography>
        </Stack>
        <DropArrowIcon open={open} />
      </SettingsWrapper>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack minWidth={200}>
          <Stack p={2} gap={0.5} alignItems="start">
            <Typography variant="body1">{name}</Typography>
            <Typography variant="caption" color="text.secondary">
              {email}
            </Typography>
            <Button
              variant="outlined"
              component={Link}
              to="/account"
              onClick={handleClose}
            >
              Go to profile
            </Button>
          </Stack>
          <Divider />
          <Button fullWidth variant="text" onClick={handleLogout}>
            Log out
          </Button>
        </Stack>
      </Popover>
    </>
  )
}
