import { MutationFunction, useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { DeclineReason, FutureOffersOption, Ratings } from 'utils/global-types'

export type DeclineQuoteResponse = Record<string, never>

export type DeclineQuoteVariables = {
  uuid: string
  reason?: DeclineReason
  reason_text?: string
  future_offers: FutureOffersOption
  other_feedback?: string
} & Partial<Ratings>

const declineQuote: MutationFunction<
  DeclineQuoteResponse,
  DeclineQuoteVariables
> = async ({ uuid, ...variables }) => {
  const response = await axiosInstance.post<DeclineQuoteResponse>(
    `/customers-portal/quotes/${uuid}/decline/`,
    variables
  )

  return response.data
}

const useDeclineQuote = () => {
  return useMutation<
    DeclineQuoteResponse,
    AxiosError<Record<string, string[]>>,
    DeclineQuoteVariables
  >({
    useErrorBoundary: true,
    mutationFn: declineQuote,
    meta: { useGlobalError: false },
  })
}

export default useDeclineQuote
