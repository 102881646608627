import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { InvoiceContext } from 'pdf/types'
import { Invoice } from 'utils/global-types'

type PreviewInvoiceResponse = InvoiceContext
type PreviewInvoiceQueryKey = ['preview-invoice', Invoice['uuid']]

const getPreviewInvoiceContext: QueryFunction<
  PreviewInvoiceResponse,
  PreviewInvoiceQueryKey
> = async ({ queryKey }) => {
  const [, invoiceID] = queryKey

  const response = await axios.get<PreviewInvoiceResponse>(
    `/invoices/${invoiceID}/preview-pdf/`
  )

  return response.data
}

const usePreviewInvoice = (invoiceID?: Invoice['uuid'], enabled?: boolean) => {
  return useQuery<
    PreviewInvoiceResponse,
    AxiosError,
    PreviewInvoiceResponse,
    PreviewInvoiceQueryKey
  >({
    queryKey: ['preview-invoice', invoiceID!],
    queryFn: getPreviewInvoiceContext,
    enabled: enabled && !!invoiceID,
  })
}

export default usePreviewInvoice
