import { DateTime } from 'luxon'

import { useDialog } from 'context/Dialog/DialogContext'
import {
  addPaymentDialog,
  deletePaymentDialog,
  editRefundDialog,
} from 'pages/OrderEdit/dialogs'
import { Invoice, Order, Payment } from 'utils/global-types'

import useCreatePayment from './useCreatePayment'
import useDeletePayment from './useDeletePayment'
import useEditPayment from './useEditPayment'

type PaymentActionsProps = {
  uuid: Invoice['uuid']
  orderID: Order['order_id']
}

export default function usePaymentActions({
  uuid,
  orderID,
}: PaymentActionsProps) {
  const [openDialog] = useDialog()

  const { mutateAsync: createPayment } = useCreatePayment(uuid)
  const { mutateAsync: editPayment } = useEditPayment(uuid)
  const { mutateAsync: deletePayment } = useDeletePayment(uuid, orderID)

  const handlePayInvoice = (invoice: Invoice) => async () => {
    openDialog(
      addPaymentDialog({ ...invoice, order_id: orderID }),
      async (result) => {
        if (typeof result === 'boolean') {
          return result
        }

        return await createPayment({
          ...result,
          payment_date: (result.payment_date as DateTime).toISODate(),
        })
      }
    )
  }

  const handleEditPayment = (payment: Payment) => () => {
    openDialog(editRefundDialog(payment), async (result) => {
      if (typeof result === 'boolean') {
        return result
      }
      return await editPayment({
        ...result,
        payment_date: (result.payment_date as DateTime).toISODate(),
      })
    })
  }

  const handleDeletePayment = async () => {
    const isDeleteConfirmed = await openDialog(deletePaymentDialog)

    if (isDeleteConfirmed) {
      await deletePayment()
    }
  }

  return {
    handlePayInvoice,
    handleEditPayment,
    handleDeletePayment,
  }
}
