import { capitalize } from 'lodash'

import { PreviewTableCell } from 'components/PreviewTableCell'
import { DataStatsBarItemI } from 'components/StatsBar'
import {
  PaymentStatusCell,
  ShipmentStatusCell,
} from 'components/TableStatusCell'
import { LinkTabI } from 'components/Tabs'
import CreateQuoteButton from 'pages/CustomersList/CreateQuoteButton'
import { getAddressString } from 'utils/address'
import {
  Address,
  CustomerList,
  Invoice,
  Order,
  Organization,
  Payment,
  Shipment,
  StatementList,
} from 'utils/global-types'
import { convertInvoiceType } from 'utils/helpers'
import { getManufacturerNamesLabel } from 'utils/order'
import { formatPrice, getPaymentMethodLabel } from 'utils/price'

import DeleteAddress from './DeteleAddress'
import { OrganizationDetailsTab, StatementStats, TableConfig } from './types'

export const getOrganizationDetailsTabs = (id?: string): LinkTabI[] => [
  {
    label: 'Customers',
    path: `/organizations/${id}/customers`,
  },
  {
    label: 'Orders',
    path: `/organizations/${id}/orders`,
  },
  {
    label: 'Quotes',
    path: `/organizations/${id}/quotes`,
  },
  {
    label: 'Invoices',
    path: `/organizations/${id}/invoices`,
  },
  {
    label: 'Transactions',
    path: `/organizations/${id}/transactions`,
  },
  {
    label: 'Shipments',
    path: `/organizations/${id}/shipments`,
  },
  {
    label: 'Addresses',
    path: `/organizations/${id}/addresses`,
  },
  {
    label: 'Statement',
    path: `/organizations/${id}/statements`,
  },
]

const customersTableConfig: TableConfig<CustomerList> = {
  url: '/customers/customers/',
  columns: [
    {
      value: 'name',
      label: 'Name',
      width: 200,
      getHref: (row) =>
        `/organizations/${row.organization.uuid}/customers/${row.uuid}/edit/`,
    },
    {
      value: 'email',
      label: 'Email',
      width: 200,
    },
    {
      value: 'address',
      label: 'Address',
      width: 200,
    },
    {
      label: '',
      align: 'right',
      value: ({ uuid, is_active }) => (
        <CreateQuoteButton customerUUID={uuid} enabled={is_active} />
      ),
    },
  ],
}

const ordersTableConfig: TableConfig<Order> = {
  url: '/orders/',
  columns: [
    {
      value: 'updated',
      label: 'Date',
      width: 100,
    },
    {
      value: 'order_id',
      label: 'Order',
      width: 100,
      getHref: (order) => `/orders/${order.order_id}`,
    },
    {
      value: 'po_number',
      label: 'PO #',
      width: 100,
    },
    {
      value: (order) =>
        formatPrice(order.total_amount, order.currency, { priceFirst: true }),
      label: 'Amount',
      width: 100,
      align: 'right',
    },
    {
      value: ({ payment_status }) => (
        <PaymentStatusCell paymentStatus={payment_status} />
      ),
      label: 'Payment status',
      width: 100,
    },
    {
      value: ({ shipment_status }) => (
        <ShipmentStatusCell shipmentStatus={shipment_status} />
      ),
      label: 'Delivery status',
      width: 100,
    },
  ],
  params: {
    status__in: ['ORDER', 'CLOSED'].join(','),
  },
}

const quotesTableConfig: TableConfig<Order> = {
  url: '/orders/',
  columns: [
    {
      value: 'created',
      label: 'Date',
      width: 100,
    },
    {
      value: 'order_id',
      label: 'Quote',
      width: 100,
      getHref: (quote) => `/quotes/${quote.order_id}`,
    },
    {
      value: 'customer_email',
      label: 'Email address',
      width: 200,
    },
    {
      value: ({ manufacturer_names }) =>
        getManufacturerNamesLabel(manufacturer_names),
      label: 'Brands quoted',
      width: 100,
    },
    {
      value: (quote) =>
        formatPrice(quote.total_amount, quote.currency, { priceFirst: true }),
      label: 'Total amount',
      width: 200,
      align: 'right',
    },
  ],
  params: {
    status: 'QUOTE',
  },
}

const invoicesTableConfig: TableConfig<Invoice> = {
  url: '/invoices/',
  columns: [
    {
      value: 'created',
      label: 'Date',
      width: 90,
    },
    {
      label: 'Invoice #',
      width: 110,
      value: (invoice) => <PreviewTableCell {...invoice} />,
    },
    {
      value: 'order_id',
      label: 'Order',
      width: 100,
      getHref: ({ order_id }) => `/orders/${order_id}`,
    },
    {
      value: 'po_number',
      label: 'PO #',
      width: 100,
    },
    {
      value: 'customer_email',
      label: 'Customer email',
      width: 200,
    },
    {
      value: (invoice) =>
        formatPrice(invoice.total_amount, invoice.currency, {
          priceFirst: true,
        }),
      label: 'Amount',
      width: 100,
    },
    {
      label: 'Type',
      value: ({ type }) => convertInvoiceType(type),
    },
    {
      value: ({ payment_status }) => (
        <PaymentStatusCell paymentStatus={payment_status} />
      ),
      label: 'Payment status',
      width: 120,
    },
    {
      value: ({ shipment_status }) => (
        <ShipmentStatusCell shipmentStatus={shipment_status} />
      ),
      label: 'Shipment status',
      width: 120,
    },
  ],
}

const transactionsTableConfig: TableConfig<Payment> = {
  url: '/payments/',
  params: {
    invoice__isnull: 'false',
  },
  columns: [
    {
      value: 'updated',
      label: 'Date',
      width: 100,
    },

    {
      label: 'Invoice #',
      width: 100,
      value: ({ invoice, invoice_id }) => (
        <PreviewTableCell uuid={invoice} invoice_id={invoice_id} />
      ),
    },
    {
      value: 'order_id',
      label: 'Order',
      width: 100,
      getHref: ({ order_id }) => `/orders/${order_id}`,
    },
    {
      value: 'po_number',
      label: 'PO #',
      width: 100,
    },
    {
      label: 'Source',
      value: ({ source }) => capitalize(source),
    },
    {
      label: 'Destination',
      value: ({ destination }) => capitalize(destination),
    },
    {
      label: 'Method',
      value: ({ method }) => getPaymentMethodLabel(method),
    },
    {
      value: (payment) =>
        formatPrice(payment.total_amount, payment.currency, {
          priceFirst: true,
        }),
      label: 'Amount',
      width: 100,
      align: 'right',
    },
  ],
}

const shipmentsTableConfig: TableConfig<Shipment> = {
  url: '/shipments/shipments/',
  columns: [
    {
      value: 'updated',
      label: 'Date',
      width: 100,
    },
    {
      label: 'Invoice #',
      width: 100,
      value: ({ invoice, invoice_id }) => (
        <PreviewTableCell uuid={invoice} invoice_id={invoice_id} />
      ),
    },
    {
      value: 'order_id',
      label: 'Order',
      width: 100,
      getHref: ({ order_id }) => `/orders/${order_id}`,
    },
    {
      value: 'po_number',
      label: 'PO #',
      width: 100,
    },
    {
      value: 'carrier_name',
      label: 'Shipment method',
      width: 100,
    },
  ],
}

const getAddressTableConfig = (
  showDeleteAddress: boolean
): TableConfig<Address> => {
  const addressesTableConfig: TableConfig<Address> = {
    url: '/customers/addresses/',
    columns: [
      {
        value: ({ address_line_1, address_line_2 }) =>
          getAddressString(address_line_1, address_line_2),
        label: 'Address',
        width: 200,
      },
      {
        value: 'city',
        label: 'City',
        width: 100,
      },
      {
        value: 'postal_code',
        label: 'Postal code',
        width: 100,
      },
      {
        value: 'country_name',
        label: 'Country',
        width: 100,
      },
      {
        value: 'type',
        label: 'Type',
        width: 100,
      },
      {
        value: ({ is_preferred }) => (is_preferred ? 'Yes' : 'No'),
        label: 'Preferred',
        width: 100,
      },
    ],
  }

  if (showDeleteAddress) {
    addressesTableConfig.columns.push({
      value: ({ uuid }) => <DeleteAddress addressUUID={uuid} />,
      label: '',
    })
  }

  return addressesTableConfig
}

const getStatementsTableConfig = (id: string): TableConfig<StatementList> => ({
  url: `/customers/organizations/${id}/statement-of-account-list/`,
  columns: [
    {
      value: 'date',
      label: 'Invoice date',
      width: 100,
    },
    {
      value: 'invoice_id',
      label: 'Invoice #',
      width: 100,
    },
    {
      value: 'order',
      label: 'Order',
      width: 100,
      getHref: ({ order }) => `/orders/${order}`,
    },
    {
      value: 'shipped',
      label: 'Shipped date',
      width: 100,
    },
    {
      value: 'po',
      label: 'PO #',
      width: 100,
    },
    {
      value: (statement) =>
        formatPrice(statement.total ?? 0, statement.currency, {
          priceFirst: true,
        }),
      label: 'Total',
      width: 100,
      align: 'right',
    },
    {
      value: (statement) =>
        formatPrice(statement?.paid_to_date ?? 0, statement.currency, {
          priceFirst: true,
        }),
      label: 'Paid to date',
      width: 100,
      align: 'right',
    },
    {
      value: (statement) =>
        formatPrice(statement?.due_now ?? 0, statement.currency, {
          priceFirst: true,
        }),
      label: 'Due now',
      width: 100,
      align: 'right',
    },
  ],
})

export const statementStats: DataStatsBarItemI<StatementStats>[] = [
  {
    id: 'total_amount',
    label: 'Total',
  },
  {
    id: 'total_paid',
    label: 'Paid',
  },
  {
    id: 'total_unpaid',
    label: 'Unpaid',
  },
  {
    id: 'terms',
    label: 'Terms type',
  },
]

export const tabToTableConfigMap = (
  tab: OrganizationDetailsTab,
  id: Organization['uuid'],
  showDeleteAddress: boolean
) => {
  const configs = {
    customers: customersTableConfig,
    orders: ordersTableConfig,
    quotes: quotesTableConfig,
    invoices: invoicesTableConfig,
    transactions: transactionsTableConfig,
    shipments: shipmentsTableConfig,
    addresses: getAddressTableConfig(showDeleteAddress),
    statements: getStatementsTableConfig(id),
  } as const

  return configs[tab]
}
