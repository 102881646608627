import { createTheme, ThemeOptions } from '@mui/material'

declare module '@mui/system/createTheme/shape' {
  interface Shape {
    drawerWidth: {
      min: number
      max: number
    }
  }
}

declare module '@mui/material/styles/createMixins' {
  interface Mixins {
    drawer: {
      open: CSSProperties
      close: CSSProperties
    }
  }
}

const defaultTheme = createTheme()

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#008BCB',
    },
  },
  typography: {
    fontFamily: 'Roboto',
  },
  shape: {
    drawerWidth: {
      min: 68,
      max: 240,
    },
  },
  mixins: {
    drawer: {
      open: {
        transition: defaultTheme.transitions.create(
          ['width', 'max-width', 'margin', 'left'],
          {
            easing: defaultTheme.transitions.easing.sharp,
            duration: defaultTheme.transitions.duration.enteringScreen,
          }
        ),
      },
      close: {
        transition: defaultTheme.transitions.create(
          ['width', 'max-width', 'margin', 'left'],
          {
            easing: defaultTheme.transitions.easing.sharp,
            duration: defaultTheme.transitions.duration.leavingScreen,
          }
        ),
      },
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
        disableGutters: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        size: 'small',
        // Disable change value on wheel
        // @ts-expect-error blur is not in the type definition
        onWheel: () => document.activeElement?.blur(),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginBottom: '1rem',
        },
      },
    },
  },
}

const theme = createTheme(themeOptions)

export const globalStyles = {
  html: {
    scrollPadding: '70px',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  },
}

export default theme
