import { ChangeEvent, useEffect } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'

import { UploadRatesVariables } from 'api/shipping/useUploadRates'
import { formatBytes } from 'utils/helpers'

const styles: Record<string, SxProps> = {
  closeIconButton: {
    width: 48,
    height: 48,
  },
  uploadedFile: {
    width: 'calc(100% - 48px)',
  },
}

type UploadButtonProps = {
  fieldName: string
}

const UploadButton = ({ fieldName }: UploadButtonProps) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext()
  const errorMessage = errors[fieldName]?.message as string

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setValue(fieldName, file)
    }
  }

  return (
    <>
      <Button variant="outlined" component="label">
        Select file
        <input
          hidden
          accept="*"
          type="file"
          name={fieldName}
          onChange={onChange}
        />
      </Button>
      {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
    </>
  )
}

type UploadedFileProps = {
  file: File
  fieldName: string
}

const UploadedFile = ({ file, fieldName }: UploadedFileProps) => {
  const { resetField } = useFormContext()
  const deleteFile = () => {
    resetField(fieldName)
  }

  return (
    <Stack
      direction="row"
      paddingTop={1}
      paddingBottom={2}
      justifyContent="space-between"
      flexWrap="wrap"
      borderBottom="1px solid"
      borderColor="divider"
    >
      <Box sx={styles.uploadedFile}>
        <Typography
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {file.name}
        </Typography>
        <Typography variant="caption">{formatBytes(file.size)}</Typography>
      </Box>
      <IconButton onClick={deleteFile} sx={styles.closeIconButton}>
        <CloseIcon />
      </IconButton>
    </Stack>
  )
}

export default function ShippingRatesUploadForm() {
  const { reset } = useFormContext<UploadRatesVariables>()

  const rates = useController<UploadRatesVariables>({
    name: 'rates',
    defaultValue: undefined,
    rules: { required: 'This field is required' },
  })

  const countries_zones = useController<UploadRatesVariables>({
    name: 'countries_zones',
    defaultValue: undefined,
    rules: { required: 'This field is required' },
  })

  useEffect(() => {
    return () =>
      reset({
        rates: undefined,
        countries_zones: undefined,
      })
  }, [])

  return (
    <Stack width="552px">
      <Typography variant="h6" mb={1}>
        Upload Shipping Rates
      </Typography>

      {rates.field.value ? (
        <UploadedFile file={rates.field.value} fieldName="rates" />
      ) : (
        <UploadButton fieldName="rates" />
      )}

      <Typography variant="h6" mt={2} mb={1}>
        Upload Countries Table
      </Typography>

      {countries_zones.field.value ? (
        <UploadedFile
          file={countries_zones.field.value}
          fieldName="countries_zones"
        />
      ) : (
        <UploadButton fieldName="countries_zones" />
      )}
    </Stack>
  )
}
