import { useController, useFormContext } from 'react-hook-form'
import { Rating, styled } from '@mui/material'

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-icon': {
    marginRight: theme.spacing(3),
  },
  '& .MuiRating-iconFilled': {
    color: theme.palette.primary.main,
  },
  '& .MuiRating-iconEmpty': {
    color: '#60B6DF',
  },
}))

type ControlledRatingProps = {
  name: string
}

export default function ControlledRating({ name }: ControlledRatingProps) {
  const { control, setValue } = useFormContext()
  const { field } = useController({
    name,
    control,
  })

  return (
    <StyledRating
      {...field}
      onChange={(_e, value) => {
        setValue(name, value ?? 0)
      }}
    />
  )
}
