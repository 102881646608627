import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { emptyRequestWithFetch } from 'configs/axios'
import { FetchError, Order, Payment } from 'utils/global-types'

type DeletePaymentResponse = boolean
type DeletePaymentVariables = void

const deletePayment = (paymentID: Payment['uuid']) => {
  const deletePaymentMutation: MutateFunction<
    DeletePaymentResponse,
    DeletePaymentVariables
  > = async () => {
    const response = await emptyRequestWithFetch(
      `payments/${paymentID}/`,
      'DELETE'
    )

    return response.status === 204 || Promise.reject(response)
  }

  return deletePaymentMutation
}

const useDeletePayment = (
  paymentID: Payment['uuid'],
  orderID: Order['order_id']
) => {
  const queryClient = useQueryClient()

  return useMutation<DeletePaymentResponse, FetchError, DeletePaymentVariables>(
    {
      mutationFn: deletePayment(paymentID),
      onSuccess: () => {
        queryClient.invalidateQueries(['payments'])
        queryClient.invalidateQueries(['invoices'])
        queryClient.invalidateQueries(['orders'])
        queryClient.invalidateQueries(['order', orderID])
      },
      meta: { message: 'Failed to delete payment' },
    }
  )
}

export default useDeletePayment
