import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { DiscountGroup, Product } from 'utils/global-types'

export type ProductResponse = {
  product: Product
  discountGroup: DiscountGroup
}
type ProductQueryKey = ['product', Product['sku']]

const getProduct: QueryFunction<ProductResponse, ProductQueryKey> = async ({
  queryKey,
}) => {
  const [, sku] = queryKey

  const { data: product } = await axiosInstance.get<Product>(
    `/products/${sku}/`
  )

  const { discount_group } = product

  const { data: discountGroup } = await axiosInstance.get<DiscountGroup>(
    `/products/discount-groups/${discount_group}/`
  )

  return {
    product,
    discountGroup,
  }
}

export const productDetailsQueryOptions = (
  sku: Product['sku']
): WithRequired<
  UseQueryOptions<
    ProductResponse,
    AxiosError,
    ProductResponse,
    ProductQueryKey
  >,
  'queryKey'
> => ({
  queryKey: ['product', sku],
  queryFn: getProduct,
  meta: { message: 'Failed to get product details' },
})

const useProduct = (sku: Product['sku']) => {
  return useQuery<
    ProductResponse,
    AxiosError,
    ProductResponse,
    ProductQueryKey
  >({
    ...productDetailsQueryOptions(sku),
    enabled: !!sku,
  })
}

export default useProduct
