import { PropsWithChildren } from 'react'
import { Text } from '@react-pdf/renderer'

type TypographyProps = PropsWithChildren<{
  bold?: boolean
  medium?: boolean
  secondary?: boolean
  size?: number
  upperCase?: boolean
  opacity?: number
}>

export default function Typography({
  bold,
  medium,
  secondary = false,
  size = 8,
  upperCase,
  children,
  opacity: _opacity,
}: TypographyProps) {
  const fontWeight = bold ? 700 : medium ? 500 : 400
  const opacity = secondary ? 0.6 : _opacity ?? 0.9

  return (
    <Text
      style={{
        color: '#202124',
        opacity,
        lineHeight: 1.3,
        fontSize: size,
        fontWeight,
        textTransform: upperCase ? 'uppercase' : undefined,
      }}
    >
      {children}
    </Text>
  )
}
