import { ProductItemsTableCell, ProductItemsTableRow } from './'

export default function ProductItemsTableHead() {
  return (
    <ProductItemsTableRow head>
      <ProductItemsTableCell head width={24}>
        Pos.
      </ProductItemsTableCell>
      <ProductItemsTableCell head width={148}>
        Product
      </ProductItemsTableCell>
      <ProductItemsTableCell head width={48}>
        Qty
      </ProductItemsTableCell>
      <ProductItemsTableCell head width={48}>
        Price
      </ProductItemsTableCell>
      <ProductItemsTableCell head width={52}>
        Total
      </ProductItemsTableCell>
      <ProductItemsTableCell head width={24}>
        Weight
      </ProductItemsTableCell>
      <ProductItemsTableCell head width={155} textAlign="right">
        Details
      </ProductItemsTableCell>
    </ProductItemsTableRow>
  )
}
