import { Outlet } from 'react-router-dom'
import { AppBar, Container, styled, Toolbar } from '@mui/material'

import Logo from 'pages/Layout/Drawer/Logo'

const ViewHeader = styled(AppBar)({
  backgroundColor: 'white',
  borderBottom: '1px solid #E6F1FA',
  boxShadow:
    '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.2)',
})

const ViewLayout = styled(Container)({
  maxWidth: '100% !important',
  margin: 0,
  width: '100%',
  minHeight: '100vh',
  backgroundColor: '#F6FAFD',
})

const ViewContainer = styled(Container)(({ theme }) => ({
  width: '100%',
  maxWidth: 366,
  margin: '0 auto',
  padding: theme.spacing(3, 1.5),

  [theme.breakpoints.up('sm')]: {
    maxWidth: 640,
    padding: theme.spacing(4, 0),
  },

  '.MuiRadio-root': { color: '#60B6DF' },
}))

export default function CustomerActionView() {
  return (
    <>
      <ViewHeader elevation={0}>
        <Toolbar>
          <Logo />
        </Toolbar>
      </ViewHeader>
      <ViewLayout>
        <Toolbar />
        <ViewContainer>
          <Outlet />
        </ViewContainer>
      </ViewLayout>
    </>
  )
}
