import {
  Backdrop as MuiBackdrop,
  BackdropProps as MuiBackdropProps,
  CircularProgress,
  styled,
  SxProps,
  Theme,
} from '@mui/material'

const StyledBackdrop = styled(MuiBackdrop)(({ theme }) => ({
  color: theme.palette.primary.main,
  background: theme.palette.common.white,
  zIndex: theme.zIndex.tooltip + 1,
}))

type BackdropProps = MuiBackdropProps & {
  iconSx?: SxProps<Theme>
}

export default function Backdrop({ open, sx, iconSx }: BackdropProps) {
  return (
    <StyledBackdrop open={open} sx={sx} invisible>
      <CircularProgress sx={iconSx} color="inherit" />
    </StyledBackdrop>
  )
}
