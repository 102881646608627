import { ReactNode } from 'react'
import { Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

import { isValidDateFormat, localeDate } from 'utils/date'

import { HeadCellStyle } from './types'

export function getCellContent(data: unknown): ReactNode {
  if (data === '' || data == null) {
    return '-'
  }

  if (typeof data === 'string' && isValidDateFormat(data)) {
    return localeDate(data)
  }
  if (typeof data === 'object' && 'name' in data) {
    return data.name as string
  }

  return data as ReactNode
}

export const getCellStyles = ({
  width,
  align,
}: HeadCellStyle): SystemStyleObject<Theme> => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: width || 'auto',
  minWidth: width || 'auto',
  textAlign: align,
})
