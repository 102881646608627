import { Document, View } from '@react-pdf/renderer'
import { startCase } from 'lodash'

import {
  Divider,
  Footer,
  Header,
  Page,
  Pagination,
  Space,
  TotalInfo,
  Typography,
} from 'pdf/components'
import { ProductItemsTable } from 'pdf/components/ProductItemsTable'
import { InvoiceContext } from 'pdf/types'
import { getDocumentMetaInfoFields } from 'pdf/utils'
import { invoiceTypeToPreviewTitle } from 'utils/invoice'

import MainInfo from './MainInfo'

export default function Invoice(invoice: InvoiceContext) {
  const title = startCase(invoiceTypeToPreviewTitle[invoice.type])
  const subtitle =
    invoice.type === 'CREDIT'
      ? `${invoice.invoice_id} for Invoice ${invoice.credit_of_invoice_id}`
      : invoice.invoice_id

  return (
    <Document title="Invoice">
      <Page>
        <Header title={title} subtitle={subtitle} />
        <Space height={24} />
        <MainInfo {...invoice} />
        <Space height={8} />
        {invoice.product_bundle.length > 0 && (
          <>
            <Divider />
            <Space height={4} />
            <ProductItemsTable {...invoice} />
            <Space height={4} />
          </>
        )}
        <TotalInfo {...invoice} />
        {invoice.type !== 'CREDIT' ? (
          <>
            <Space height={24} />
            <Divider />
            <Space height={18} />
            <View>
              <Typography medium size={7}>
                {invoice.payment_terms}
              </Typography>
            </View>
            <Space height={8} />
          </>
        ) : null}
        <Space height={8} />
        <Divider />
        <Space height={16} />
        <Footer {...getDocumentMetaInfoFields(invoice)} />
        <Pagination />
      </Page>
    </Document>
  )
}
