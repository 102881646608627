import { configQueryOptions } from 'api/config/useConfig'
import { customerDetailsQueryOptions } from 'api/customer/useCustomer'
import { organizationDetailsQueryOptions } from 'api/customer/useOrganization'
import { invoiceDetailsQueryOptions } from 'api/invoice/useInvoice'
import { invoicesQueryOptions } from 'api/invoice/useInvoices'
import { orderDetailsQueryOptions } from 'api/order/useOrder'
import { paymentsQueryOptions } from 'api/payments/usePayments'
import { productDetailsQueryOptions } from 'api/product/useProduct'
import { syncProductsHistoryQueryOptions } from 'api/product/useSyncProductsHistory'
import { orderProductItemsQueryOptions } from 'api/product-item/useOrderProductItems'
import {
  orderMetricsYearQueryOptions,
  OrderMetricsYearsResponse,
} from 'api/reports/useOrderMetricsYears'
import { reportQueryOptions } from 'api/reports/useReportData'
import { shipmentsQueryOptions } from 'api/shipping/useShipments'
import { userQueryOptions } from 'api/user/useUser'
import { defaultTableOptions, getData } from 'components/Table/useTable'
import queryClient from 'configs/query'
import defineAbilityFor from 'context/Ability/ability'
import { createLoader } from 'routing/common'
import { TopOrders } from 'utils/global-types'
import { addQueryParams } from 'utils/request'

const getUser = async () => {
  return await queryClient.ensureQueryData(userQueryOptions)
}

const getAbility = async () => {
  const user = await getUser()
  return defineAbilityFor(user)
}

const userDetails = createLoader(async (_, queryClient) => getUser)

const dashboard = createLoader(async (params, queryClient) => {
  const ability = await getAbility()
  if (ability.cannot('view', 'dashboard')) {
    return false
  }

  const { itemsPerPage } = defaultTableOptions
  const { years } = await queryClient.ensureQueryData(
    orderMetricsYearQueryOptions
  )

  await Promise.all([
    queryClient.ensureQueryData(
      [
        'order-metrics',
        {
          page: 0,
          itemsPerPage,
          search: '',
          filters: {},
          url: addQueryParams('/reports/order-metrics/', {
            year: String(years[0]),
          }),
        },
      ],
      getData<OrderMetricsYearsResponse>
    ),
    queryClient.ensureQueryData(
      [
        'top-orders',
        {
          page: 0,
          itemsPerPage,
          search: '',
          filters: {},
          url: '/reports/top-orders/',
        },
      ],
      getData<TopOrders>
    ),
  ])

  return true
})

const customerDetails = createLoader(async ({ params }, queryClient) => {
  const customerID = params.customerId
  const organizationID = params.organizationId

  const ability = await getAbility()
  if (ability.cannot('view', 'customer')) {
    return false
  }

  if (organizationID) {
    if (customerID) {
      await queryClient.ensureQueryData(customerDetailsQueryOptions(customerID))
    }
    await queryClient.ensureQueryData(
      organizationDetailsQueryOptions(organizationID)
    )
  } else {
    const customer = await queryClient.ensureQueryData(
      customerDetailsQueryOptions(customerID!)
    )
    await queryClient.ensureQueryData(
      organizationDetailsQueryOptions(customer.organization)
    )
  }

  return true
})

const organizationDetails = createLoader(async ({ params }, queryClient) => {
  const organizationID = params.id as string

  const ability = await getAbility()
  if (ability.cannot('view', 'customer')) {
    return false
  }

  await queryClient.ensureQueryData(
    organizationDetailsQueryOptions(organizationID)
  )

  return true
})

const quoteDetails = createLoader(async ({ params }, queryClient) => {
  const id = params.id as string

  const ability = await getAbility()
  if (ability.cannot('view', 'order')) {
    return false
  }

  const { order } = await queryClient.ensureQueryData(
    orderDetailsQueryOptions(id)
  )
  await Promise.all([
    queryClient.ensureQueryData(orderProductItemsQueryOptions(order.uuid)),
    queryClient.ensureQueryData(configQueryOptions),
  ])

  return true
})

const orderDetails = createLoader(async ({ params }, queryClient) => {
  const id = params.id as string

  const ability = await getAbility()
  if (ability.cannot('view', 'order')) {
    return false
  }

  const { order } = await queryClient.ensureQueryData(
    orderDetailsQueryOptions(id)
  )

  await Promise.all([
    queryClient.ensureQueryData(configQueryOptions),
    queryClient.ensureQueryData(orderProductItemsQueryOptions(order.uuid)),
    queryClient.ensureQueryData(invoicesQueryOptions(order.uuid)),
    queryClient.ensureQueryData(paymentsQueryOptions(order.uuid)),
    queryClient.ensureQueryData(shipmentsQueryOptions(order.uuid)),
  ])

  return true
})

const invoiceDetails = createLoader(async ({ params }, queryClient) => {
  const id = params.invoiceId as string

  const ability = await getAbility()
  if (ability.cannot('view', 'invoice')) {
    return false
  }

  await queryClient.ensureQueryData(invoiceDetailsQueryOptions(id))

  return true
})

const productDetails = createLoader(async ({ params }, queryClient) => {
  const sku = params.sku as string

  const ability = await getAbility()
  if (ability.cannot('view', 'product')) {
    return false
  }

  await queryClient.ensureQueryData(productDetailsQueryOptions(sku))
  return true
})

const reports = createLoader(async (_, queryClient) => {
  const ability = await getAbility()
  if (ability.cannot('view', 'report')) {
    return false
  }

  const { years } = await queryClient.ensureQueryData(
    orderMetricsYearQueryOptions
  )
  await queryClient.ensureQueryData(reportQueryOptions(String(years[0])))
  return true
})

const configsTab = createLoader(async (_, queryClient) => {
  const ability = await getAbility()
  if (ability.cannot('view', 'config')) {
    return false
  }

  await queryClient.ensureQueryData(configQueryOptions)
  return true
})

const syncTab = createLoader(async (_, queryClient) => {
  const ability = await getAbility()
  if (ability.cannot('force_sync', 'product')) {
    return false
  }

  await Promise.all([
    queryClient.ensureQueryData(configQueryOptions),
    queryClient.ensureQueryData(syncProductsHistoryQueryOptions),
  ])
  return true
})

export const loaders = {
  userDetails,
  dashboard,
  customerDetails,
  organizationDetails,
  quoteDetails,
  orderDetails,
  invoiceDetails,
  productDetails,
  reports,
  configsTab,
  syncTab,
} as const
