import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Stack,
} from '@mui/material'
import { AxiosError } from 'axios'

import useCreateProduct from 'api/product/useCreateProduct'
import useEditProduct from 'api/product/useEditProduct'
import useCountries from 'api/useCountries'
import productPlaceholder from 'assets/img/productPlaceholder.png'
import { ActiveSwitch } from 'components/ActiveSwitch'
import { BackButton, LoadingButton } from 'components/Buttons'
import { Footer } from 'components/Footer'
import { Autocomplete, Select } from 'components/Form'
import ControlledInput from 'components/Form/ControlledInput'
import { FieldsGroup, Form, FormSection } from 'components/FormHelpers'
import { useDialog } from 'context/Dialog/DialogContext'
import { AutocompleteOption, DiscountGroup, Product } from 'utils/global-types'
import { useToggle } from 'utils/hooks'
import { buyingPriceInputOptions, salesPriceInputOptions } from 'utils/price'
import { getPriceLabel } from 'utils/product'
import { useGoBack } from 'utils/routing'

import { getProductDefaultValues, productSchema } from './utils'

interface ProductFormProps {
  product?: Product
  id?: string
  discountGroup?: DiscountGroup
}

export default function ProductForm({
  product,
  discountGroup,
}: ProductFormProps) {
  const goBack = useGoBack('/products')
  const [openDialog] = useDialog()
  const { sku } = useParams()
  const [isCreateAnother, toggleIsCreateAnother] = useToggle(false)

  const isEdit = sku !== undefined

  const form = useForm<Product>({
    // @ts-expect-error ts(2322) Resolver infer type is not equal to form values type
    resolver: yupResolver(productSchema),
    defaultValues: getProductDefaultValues(product),
  })

  const {
    reset,
    setError,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
  } = form

  const { mutateAsync: createProduct } = useCreateProduct()
  const { mutateAsync: editProduct } = useEditProduct()

  const { data: countries } = useCountries()

  const productUnit = watch('product_unit')
  const productAmount = watch('product_amount')

  const discountGroupDefaultOption: AutocompleteOption | undefined =
    discountGroup?.uuid && discountGroup?.name
      ? {
          id: discountGroup?.uuid ?? '',
          label: discountGroup?.name ?? '',
        }
      : undefined

  const getHelperText = (text?: string | number) =>
    isEdit && text ? `Original: ${text}` : undefined

  const onMutationError = useCallback(
    (error: AxiosError<Record<string, string[]>>) => {
      Object.entries(error.response!.data).forEach(([key, value]) =>
        value.forEach((e: string) =>
          setError(key as keyof Product, { message: e })
        )
      )
    },
    [setError]
  )

  const onCancel = async () => {
    if (!isDirty) {
      goBack()
      return
    }

    const isDiscardConfirmed = await openDialog({ variant: 'discard' })

    if (isDiscardConfirmed) {
      goBack()
    }
  }

  const handleCreateProduct = async (product: Product) => {
    await createProduct(product, {
      onError: onMutationError,
    })
  }

  const handleEditProduct = async (product: Product) => {
    await editProduct(
      { ...product, productSku: sku! },
      {
        onError: onMutationError,
      }
    )
  }

  const onSubmit = handleSubmit(async (product) => {
    const submit = isEdit ? handleEditProduct : handleCreateProduct

    await submit(product)

    if (isCreateAnother) {
      reset({})
    } else {
      goBack()
    }
  })

  return (
    <Form handlers={form} onSubmit={onSubmit}>
      <Stack direction="row" gap={3}>
        <Box sx={{ flex: 7 }}>
          <FormSection title="Product information">
            <FieldsGroup sameWidth>
              <ControlledInput
                name="manufacturer_part_number"
                label="Part number"
              />
              <ControlledInput name="manufacturer_name" label="Manufacturer" />
            </FieldsGroup>
            <ControlledInput
              name="sku"
              label="SKU"
              required
              disabled={isEdit}
            />
            <Divider light sx={{ mb: 4 }} />
            <FieldsGroup sameWidth>
              <ControlledInput name="series" label="Series" />
              <ControlledInput name="type" label="Type" />
            </FieldsGroup>
            <ControlledInput name="product_category" label="Category" />
            <ControlledInput name="ean_code" label="EAN" />
          </FormSection>
          <FormSection title="Logistical data" sx={{ mb: 0 }}>
            <FieldsGroup sameWidth>
              <ControlledInput
                name="stock_level"
                label="Stock"
                required
                type="number"
                inputProps={{ min: 0, decimalScale: 0 }}
              />
              <ControlledInput
                name="standard_lead_time"
                label="Standard lead time"
                type="number"
                inputProps={{ min: 0 }}
              />
              <ControlledInput
                name="minimum_order_quantity"
                label="Min. order quantity (MOQ)"
                required
                type="number"
                inputProps={{ min: 0 }}
              />
              <ControlledInput
                name="increment"
                label="Increment"
                type="number"
                inputProps={{ min: 0 }}
              />
            </FieldsGroup>
            <FieldsGroup sameWidth>
              <ControlledInput name="product_unit" label="Product unit" />
              <ControlledInput
                type="number"
                name="product_amount"
                label="Product amount "
                disabled={isEdit}
              />
              <ControlledInput
                type="number"
                name="price_conversion_factor"
                label="Conversion factor"
                disabled={isEdit}
              />
              <ControlledInput
                type="number"
                name="price_amount"
                label="Price amount"
                disabled={isEdit}
              />
            </FieldsGroup>
            <FieldsGroup sameWidth>
              <ControlledInput name="packaging" label="Packaging" />
              <ControlledInput
                name="dimensions"
                label="Dimensions (L-W-H) (cm)"
              />
            </FieldsGroup>
            <Divider light sx={{ mb: 3 }} />

            <FieldsGroup sameWidth>
              <ControlledInput
                label="Weight"
                name={isEdit ? 'weight_overwrite' : 'weight'}
                type="number"
                inputProps={{ min: 0 }}
                helperText={getHelperText(`${product?.weight} g`)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">g</InputAdornment>
                  ),
                }}
              />
              <ControlledInput
                label="HS (Harmonized system) code"
                name={isEdit ? 'hs_code_overwrite' : 'hs_code'}
                helperText={getHelperText(product?.hs_code)}
              />
              <Select
                label="Country of origin"
                name={
                  isEdit ? 'country_of_origin_overwrite' : 'country_of_origin'
                }
                helperText={getHelperText(product?.country_of_origin)}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {countries!.map((country) => (
                  <MenuItem key={country.uuid} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FieldsGroup>
          </FormSection>
        </Box>
        <Box sx={{ flex: 3 }}>
          <ActiveSwitch defaultChecked={product?.is_active ?? true} />
          <FormSection title="Image">
            <ControlledInput name="image" label="Image URL" />
            {isEdit && (
              <Box
                sx={{
                  width: '100%',
                }}
                component="img"
                alt="Product image"
                src={product?.image || productPlaceholder}
              />
            )}
          </FormSection>
          <FormSection title="Price data" sx={{ mb: 0 }}>
            <ControlledInput
              name="list_price"
              label={getPriceLabel('List price', {
                unit: productUnit,
                amount: productAmount,
              })}
              required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">EUR</InputAdornment>
                ),
              }}
              type="number"
              inputProps={buyingPriceInputOptions}
            />
            <ControlledInput
              name="web_price"
              label={getPriceLabel('Web price', {
                unit: productUnit,
                amount: productAmount,
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">EUR</InputAdornment>
                ),
              }}
              required
              type="number"
              inputProps={salesPriceInputOptions}
            />
            <Autocomplete
              name="discount_group"
              label="Omnical discount group"
              url="/products/discount-groups/"
              defaultValue={discountGroupDefaultOption}
              InputLabelProps={{
                required: true,
                shrink: true,
              }}
              mapOptions={(option: DiscountGroup) => ({
                label: option.name,
                id: option.uuid,
              })}
            />
            <ControlledInput
              name="manufacturer_discount_group"
              label="Manufacturer discount group"
            />
          </FormSection>
        </Box>
      </Stack>
      <Footer>
        <BackButton onBack={onCancel} />
        <Stack direction="row" gap={2}>
          {!isEdit && (
            <FormControlLabel
              label="Create another"
              control={<Checkbox />}
              checked={isCreateAnother}
              onChange={toggleIsCreateAnother}
            />
          )}
          <LoadingButton
            type="submit"
            variant="contained"
            isLoading={isSubmitting}
          >
            {isEdit ? 'Save' : 'Create'}
          </LoadingButton>
        </Stack>
      </Footer>
    </Form>
  )
}
