import { ReactElement } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Button,
  ButtonTypeMap,
  ExtendButtonBase,
  Stack,
  styled,
  SxProps,
  Theme,
} from '@mui/material'

import { LinkTabI } from './LinkTabs'

interface TabsProps {
  tabs: LinkTabI[]
}

const tabContainerStyles: SxProps<Theme> = (theme) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  flexDirection: 'row',
  columnGap: 1,
})

const StyledButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: '12px 12px 0px 0px',
  boxShadow: 'none',
  borderColor: theme.palette.action.selected,
  borderBottom: 'none',
  backgroundColor:
    variant === 'outlined' ? theme.palette.common.white : undefined,
  '&:hover, &[aria-disabled="true"]': {
    borderBottom: 'none',
    borderColor: theme.palette.action.selected,
  },
})) as ExtendButtonBase<ButtonTypeMap>

/**
 * Tabs component that holds links
 * Important: use full relative path in tab path field
 * @param {TabsProps} {tabs}
 * @returns {ReactElement}
 */
export default function ButtonTabs({ tabs }: TabsProps): ReactElement {
  const { pathname } = useLocation()

  return (
    <Stack sx={tabContainerStyles}>
      {tabs.map((tab) => (
        <StyledButton
          variant={pathname === tab.path ? 'contained' : 'outlined'}
          key={tab.path}
          to={tab.path}
          component={Link}
          disabled={tab.disabled}
          replace
        >
          {tab.label}
        </StyledButton>
      ))}
    </Stack>
  )
}
