import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { Organization } from 'utils/global-types'

type OrganizationsResponse = Organization

const getOrganization: QueryFunction<
  OrganizationsResponse,
  ['organization', string]
> = async ({ queryKey }) => {
  const [, orgId] = queryKey
  const response = await axiosInstance.get<OrganizationsResponse>(
    `/customers/organizations/${orgId}/`
  )

  return response.data
}

export const organizationDetailsQueryOptions = (
  ID: OrganizationsResponse['uuid']
): WithRequired<
  UseQueryOptions<
    OrganizationsResponse,
    AxiosError,
    OrganizationsResponse,
    ['organization', string]
  >,
  'queryKey'
> => ({
  queryKey: ['organization', ID],
  queryFn: getOrganization,
  meta: { message: 'Failed to get organization details' },
})

const useOrganization = (organizationUUID?: Organization['uuid']) => {
  return useQuery({
    ...organizationDetailsQueryOptions(organizationUUID!),
    enabled: !!organizationUUID,
  })
}

export default useOrganization
