import toast from 'react-hot-toast'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { Quote } from 'utils/global-types'

export type CreateQuoteResponse = Quote

export type CreateQuoteVariables = Partial<Omit<Quote, 'uuid'>>

const createQuote: MutationFunction<
  CreateQuoteResponse,
  CreateQuoteVariables
> = async (quote) => {
  const response = await axiosInstance.post<CreateQuoteResponse>(
    '/orders/',
    quote
  )

  return response.data
}

const useCreateQuote = () => {
  const queryClient = useQueryClient()

  return useMutation<CreateQuoteResponse, AxiosError, CreateQuoteVariables>({
    mutationFn: createQuote,
    onSuccess: () => {
      queryClient.invalidateQueries(['orders'])

      toast.success('Quote created successfully')
    },
    meta: { message: 'Failed to create quote' },
  })
}

export default useCreateQuote
