import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { OrderProductItem } from 'utils/global-types'

type EditOrderProductItemResponse = Partial<OrderProductItem>

export type EditOrderProductItemVariables = Partial<OrderProductItem>

const editOrderProductItem: MutationFunction<
  EditOrderProductItemResponse,
  EditOrderProductItemVariables
> = async (productItem) => {
  const response = await axiosInstance.patch<EditOrderProductItemResponse>(
    `/product-items/order-products/${productItem.uuid}/`,
    productItem
  )

  return response.data
}

const usePartialEditOrderProductItem = () => {
  const queryClient = useQueryClient()
  return useMutation<
    EditOrderProductItemVariables,
    AxiosError,
    EditOrderProductItemVariables
  >({
    mutationFn: editOrderProductItem,
    onSuccess: (data) => {
      queryClient.invalidateQueries(['product-items', data.order])
    },
    meta: { message: 'Failed to update order product item' },
  })
}

export default usePartialEditOrderProductItem
