import toast from 'react-hot-toast'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, TableRow } from '@mui/material'
import { isAxiosError } from 'axios'

import useDeleteOrderProductItem from 'api/product-item/useDeleteOrderProductItem'
import useEditOrderProductItem from 'api/product-item/useEditOrderProductItem'
import EditableTextField from 'components/Form/EditableTextField'
import { TableCell } from 'components/Table'
import { useDialog } from 'context/Dialog/DialogContext'
import { localeDate } from 'utils/date'
import { Order, OrderProductItemList } from 'utils/global-types'

interface ProductItemRowProps {
  product: OrderProductItemList
  orderID: Order['order_id']
}

export default function ProductItemRow({
  product,
  orderID,
}: ProductItemRowProps) {
  const [openDialog] = useDialog()

  const { mutateAsync: editProduct } = useEditOrderProductItem(
    product.uuid,
    orderID!,
    false
  )
  const { mutate: deleteProduct } = useDeleteOrderProductItem(
    product.uuid,
    orderID!
  )

  const changeOrderedValue = async (value: string) => {
    try {
      await editProduct({
        quantity_offered: parseInt(value, 10),
      })
    } catch (e) {
      if (isAxiosError(e)) {
        toast.error(e.response?.data.quantity_offered[0])
      }
    }
  }

  const handleDelete = async () => {
    const isDeletingConfirmed = await openDialog({
      title: 'Delete the product item?',
      content: 'This action cannot be reverted.',
      actions: {
        cancel: 'Cancel',
        confirm: 'Yes, delete',
      },
    })

    if (isDeletingConfirmed) {
      deleteProduct()
    }
  }

  const isDeleteDisabled = product.quantity_invoiced > 0

  return (
    <TableRow>
      <TableCell>{product.ordering}</TableCell>
      <TableCell>{product.sku}</TableCell>
      <TableCell sx={{ maxWidth: 160 }}>{product.manufacturer_name}</TableCell>
      <TableCell sx={{ maxWidth: 120 }}>
        {product.manufacturer_part_number}
      </TableCell>
      <TableCell sx={{ width: 130 }}>
        <EditableTextField
          id={`product-quantity-${product.product}`}
          value={product.quantity_offered}
          onChange={changeOrderedValue}
        />
      </TableCell>
      <TableCell>{product.quantity_reserved}</TableCell>
      <TableCell>{product.quantity_delivered}</TableCell>
      <TableCell>{product.quantity_invoiced}</TableCell>
      <TableCell>
        {product.quantity_available}{' '}
        {product.available_date
          ? `(${localeDate(product.available_date)})`
          : null}
      </TableCell>
      <TableCell>
        <IconButton disabled={isDeleteDisabled} onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
