import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Invoice, Payment, PaymentUpdate } from 'utils/global-types'

type CreatePaymentResponse = Payment
type CreatePaymentVariables = Partial<PaymentUpdate>

const createPayment = (invoiceID: Invoice['uuid']) => {
  const postPayment: MutationFunction<
    CreatePaymentResponse,
    CreatePaymentVariables
  > = async (payment) => {
    const response = await axios.post<CreatePaymentResponse>('/payments/', {
      ...payment,
      invoice: invoiceID,
    })

    return response.data
  }

  return postPayment
}

const useCreatePayment = (invoiceID: Invoice['uuid']) => {
  const queryClient = useQueryClient()

  return useMutation<CreatePaymentResponse, AxiosError, CreatePaymentVariables>(
    {
      mutationFn: createPayment(invoiceID),
      onSuccess: ({ order_id: orderID }) => {
        queryClient.invalidateQueries(['payments'])
        queryClient.invalidateQueries(['invoices'])
        queryClient.invalidateQueries(['orders'])
        queryClient.invalidateQueries(['order', orderID])
        queryClient.invalidateQueries(['preview-invoice', invoiceID])
      },
      meta: {
        message: 'Failed to create payment',
        useGlobalError: false,
      },
    }
  )
}

export default useCreatePayment
