import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { noop } from 'lodash'

import axios from 'configs/axios'
import { Order, OrderUpdate } from 'utils/global-types'
import { parseAPIError } from 'utils/helpers'

export type EditOrderResponse = OrderUpdate
export type EditOrderVariables = Partial<OrderUpdate>

function createPatchOrder(orderID: Order['order_id']) {
  const patchOrder: MutationFunction<
    EditOrderResponse,
    EditOrderVariables
  > = async (data) => {
    const response = await axios.patch<EditOrderResponse>(
      `/orders/${orderID}/`,
      data
    )

    return response.data
  }

  return patchOrder
}

const useEditOrder = (
  orderID: Order['order_id'],
  type?: 'order' | 'quote',
  suppressErrorHandler?: boolean
) => {
  const queryClient = useQueryClient()

  return useMutation<EditOrderResponse, AxiosError, EditOrderVariables>({
    mutationKey: ['order', orderID],
    mutationFn: createPatchOrder(orderID),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['orders'])
      queryClient.invalidateQueries(['order', orderID])
      queryClient.invalidateQueries(['invoices', data.uuid])
    },
    onError: suppressErrorHandler ? noop : parseAPIError,
    meta: {
      message: `Failed to edit ${type ?? 'order'}`,
      useGlobalError: suppressErrorHandler,
    },
  })
}

export default useEditOrder
