import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { createContextualCan } from '@casl/react'

import useUser from 'api/user/useUser'

import ability from './ability'
import { AppAbility } from './permissions'

export const AbilityContext = createContext<AppAbility>(
  null as unknown as AppAbility
)
export const Can = createContextualCan(AbilityContext.Consumer)

export const useAbility = () => {
  const context = useContext(AbilityContext)

  if (context === undefined) {
    throw new Error('useAbility must be used within a AbilityProvider')
  }

  return context
}

export function AbilityProvider({ children }: PropsWithChildren) {
  const { data: user } = useUser()

  const value = useMemo(() => ability(user), [user])

  return (
    <AbilityContext.Provider value={value}>{children}</AbilityContext.Provider>
  )
}
