import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import { useController } from 'react-hook-form'
import ClearIcon from '@mui/icons-material/Clear'
import {
  Autocomplete as MuiAutocomplete,
  CircularProgress,
  FilterOptionsState,
  InputLabelProps,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'

import { AutocompleteOption } from 'utils/global-types'
import { useDebounce } from 'utils/hooks'

import useSearch from './useSearch'

interface AutocompleteProps<T> {
  url: string
  name: string
  label: string
  mapOptions: (option: T) => AutocompleteOption
  sx?: SxProps<Theme>
  enabled?: boolean
  creatable?: boolean
  filters?: Record<string, string>
  defaultValue?: AutocompleteOption
  value?: AutocompleteOption
  onChange?: (value?: AutocompleteOption | null) => void
  helperText?: string
  placeholder?: string
  disabled?: boolean
  hideClearIcon?: boolean
  setDefaultValueAsync?: (option: T) => boolean
  InputLabelProps?: Partial<InputLabelProps>
}

export default function Autocomplete<T>({
  sx,
  url,
  name,
  label,
  filters,
  mapOptions,
  defaultValue,
  value,
  enabled,
  disabled,
  creatable = false,
  onChange = () => undefined,
  placeholder = 'Start typing to search',
  helperText,
  setDefaultValueAsync,
  hideClearIcon,
  InputLabelProps,
}: AutocompleteProps<T>) {
  const {
    field,
    fieldState: { error, isDirty },
  } = useController<{ [name: string]: string | undefined }>({
    name,
    defaultValue: defaultValue?.id ?? undefined,
  })

  const [inputValue, setInputValue] = useState<string>('')
  const autocompleteValue = useRef(defaultValue ?? null)
  const debouncedInputValue = useDebounce(inputValue, 500)

  const { data: options, isInitialLoading: isLoading } = useSearch<T>(
    url,
    debouncedInputValue,
    filters ?? {},
    enabled
  )

  useEffect(() => {
    if (!field.value) {
      autocompleteValue.current = null
      setInputValue('')
    }
  }, [field.value])

  useEffect(() => {
    if (
      typeof setDefaultValueAsync === 'function' &&
      options?.length &&
      !isDirty
    ) {
      const defaultOption = options.find((option) =>
        setDefaultValueAsync(option)
      )

      if (defaultOption) {
        setFieldValue(mapOptions(defaultOption))
      }
    }
  }, [options, setDefaultValueAsync, isDirty])

  useEffect(() => {
    if (value?.id) {
      setFieldValue(value)
      setInputValue(value.label)
    }
  }, [value?.id])

  const handleChange = (
    event: SyntheticEvent,
    value: AutocompleteOption | null
  ) => setFieldValue(value)

  const setFieldValue = (value: AutocompleteOption | null) => {
    field.onChange(value?.id ?? null)
    onChange(value)
    autocompleteValue.current = value
  }

  const filterOptions = (
    options: AutocompleteOption[],
    { inputValue }: FilterOptionsState<AutocompleteOption>
  ) => {
    if (creatable && debouncedInputValue.length && !isLoading) {
      const existInList = options.find(
        (item) => item.label.toLowerCase() === inputValue.toLowerCase()
      )
      if (existInList) {
        return options
      }
      return [...options, { id: inputValue, label: `Add "${inputValue}"` }]
    }

    return options
  }

  return (
    <MuiAutocomplete
      loading={isLoading}
      loadingText="Loading..."
      value={autocompleteValue.current}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      options={options?.map(mapOptions) ?? []}
      filterOptions={filterOptions}
      disabled={disabled}
      clearIcon={hideClearIcon ? null : <ClearIcon fontSize="small" />}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      noOptionsText={options?.length ? 'Start typing to search' : 'No options'}
      getOptionDisabled={({ isDisabled }) => isDisabled ?? false}
      sx={{ width: '100%', ...sx }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          label={label}
          inputRef={field.ref}
          error={error !== undefined}
          helperText={helperText || error?.message || ''}
          InputLabelProps={InputLabelProps}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
