import { LabelName } from 'components/LabelSelect/LabelSelect'

export interface ListReturnType<Data> {
  count: number
  next: string | null
  previous: string | null
  results: Data[]
}

export type Customer = {
  uuid: string
  name: string
  email: string
  phone_number: string
  organization: Organization['uuid']
  is_active: boolean
  created: string
  updated: string
}

export type CustomerList = Omit<Customer, 'organization'> & {
  organization: Pick<Organization, 'uuid' | 'name' | 'slug'>
}

export type DetailCustomer = Customer & {
  notes: string
  is_reviews: boolean
}

export type UpdateCustomer = Omit<
  DetailCustomer,
  'uuid' | 'created' | 'updated'
>

export type Quote = OrderUpdate

export type Order = {
  uuid: string
  total_amount: number
  total_amount_relevant_display: number
  total_buying_amount: number
  total_margin: number
  total_margin_percent: number
  invoiced_margin: number
  invoiced_margin_display: number
  invoiced_margin_percent: number
  subtotal_amount: number
  subtotal_amount_in_system_currency: number
  balance: number
  invoiced_amount: number
  paid_amount: number
  outstanding_amount: number
  order_balance: number
  weight_net: number
  shipping_fee: number
  shipping_fee_relevant_display: number
  shipping_fee_overwrite: number | null
  payment_status: PaymentStatus
  supplier: Supplier['uuid'] | null
  supplier_name: string
  shipment_status: ShipmentStatus
  manufacturer_names: string[]
  created: string
  closed_date: string | null
  reopened_date: string | null
  updated: string
  is_active: boolean
  order_id: string
  is_latest_updates: boolean
  is_all_products_have_weight: boolean
  status: OrderStatus
  labels: LabelName[]
  po_number: string
  channel: OrderSource
  source: OrderSource
  supplier_order_id: string
  supplier_cost: number
  is_reviewed: boolean
  is_blocked_shipping: boolean
  shipping_attn: string
  billing_attn: string
  notes: string
  rfq_id: string
  customer_email: string
  customer_name: string
  customer_phone_number: string
  customer_notes: string
  shipping_organization_name: string
  shipping_address_line_1: string
  shipping_address_line_2: string
  shipping_city: string
  shipping_postal_code: string
  shipping_country: string
  shipping_phone_number: string
  shipping_notes: string
  billing_organization_name: string
  billing_address_line_1: string
  billing_address_line_2: string
  billing_city: string
  billing_postal_code: string
  billing_country: string
  billing_phone_number: string
  billing_notes: string
  shipping_instructions: string
  terms_notes: string
  currency: Currency
  advance_limit: number
  upon_shipping_limit: number
  net_limit: number
  max_outstanding_days: number
  vat_number: string
  is_charge_vat: boolean
  margin_percent_overwrite: number
  discount_lump_sum: number
  handling_fee: number
  handling_fee_relevant_display: number
  issued_date: string | null
  expiration_date: string
  validity_days: number
  quote_terms_display: string
  quote_terms_template: string
  quote_terms: string
  quote_terms_context: TermsContext
  payment_terms_display: string
  payment_terms_template: string
  payment_terms: string
  payment_terms_context: TermsContext
  recommended_margin: number | null
  rejected_date: string | null
  customer: Customer['uuid']
  shipping_address: string
  billing_address: string
  shipping_method: string
  shipping_method_name: string
  lead_time: number
  sent_to_supplier: string | null
  exchange_rate: number
  is_all_products_invoiced: boolean
  is_all_products_invoiced_or_proforma: boolean
  post_invoiced_difference_display: number
}

export type TermsContext = Record<string, string | number>

export type OrderUpdate = Order & { organization: Organization }

export type InvoiceType = 'DRAFT' | 'DEBIT' | 'CREDIT'

export type Invoice = {
  uuid: string
  shipment_status: ShipmentStatus | null
  payment_status: PaymentStatus
  outstanding_amount: number
  order_outstanding_amount: number
  order_balance: number
  is_credited: boolean
  created: string
  updated: string
  is_active: boolean
  po_number: string
  type: InvoiceType
  notes: string | null
  shipping_instructions: string | null
  currency: Currency
  exchange_rate: number
  exchange_rate_date: string | null
  vat_number: string
  is_charge_vat: boolean
  margin_percent_overwrite: number | null
  discount_lump_sum: number | null
  handling_fee: number | null
  handling_fee_relevant_display: number
  shipping_fee_relevant_display: number
  is_shipping_fee_overwrite: boolean | null
  shipping_fee_overwrite: number | null
  manufacturer_names: string[]
  payment_terms: string
  payment_terms_display: string
  invoice_id: string | null
  is_blocked_shipping: boolean
  shipping_fee: number
  total_amount: number
  subtotal_amount: number
  has_payments: boolean

  total_buying_amount: number
  total_margin: number
  total_margin_percent: number
  total_paid_amount: number
  total_vat_amount: number
  weight_net: number
  weight_gross: number
  organization: Organization['uuid']
  organization_name: Organization['name']
  customer_email: Customer['email']
  customer_name: Customer['name']
  customer_phone_number: Customer['phone_number']
  shipment: Shipment
  shipping_organization_name: Address['organization_name']
  shipping_address_line_1: Address['address_line_1']
  shipping_address_line_2: Address['address_line_2']
  shipping_city: Address['city']
  shipping_postal_code: Address['postal_code']
  shipping_country: Address['country']
  shipping_phone_number: Address['phone_number']
  shipping_attn: Address['attn']
  shipping_notes: Address['notes']
  shipping_date: string | null
  billing_organization_name: Address['organization_name']
  billing_address_line_1: Address['address_line_1']
  billing_address_line_2: Address['address_line_2']
  billing_city: Address['city']
  billing_postal_code: Address['postal_code']
  billing_country: Address['country']
  billing_phone_number: Address['phone_number']
  billing_notes: Address['notes']
  shipping_method_name: ShippingMethod['name']
  customer: Customer['uuid'] | null
  shipping_address: Address['uuid'] | null
  billing_address: Address['uuid'] | null
  shipping_method: ShippingMethod['uuid'] | null
  order: Order['uuid']
  order_id: Order['order_id']
  credit_of: Invoice['uuid'] | null
}

export type InvoiceCreate = Pick<
  Invoice,
  | 'type'
  | 'notes'
  | 'shipping_instructions'
  | 'margin_percent_overwrite'
  | 'discount_lump_sum'
  | 'handling_fee'
  | 'shipping_fee_overwrite'
  | 'total_vat_amount'
  | 'shipping_date'
  | 'shipping_address'
  | 'billing_address'
  | 'shipping_method'
  | 'order'
>

export type InvoiceUpdate = Pick<
  Invoice,
  | 'type'
  | 'notes'
  | 'shipping_instructions'
  | 'is_charge_vat'
  | 'margin_percent_overwrite'
  | 'discount_lump_sum'
  | 'handling_fee'
  | 'shipping_fee_overwrite'
  | 'total_vat_amount'
  | 'customer'
  | 'shipping_address'
  | 'billing_address'
  | 'shipping_method'
>

export type InvoiceProductBundleItemCreate = Omit<
  InvoiceProductItemUpdate,
  'invoice'
>
export type InvoiceProductBundleItemUpdate = Omit<
  InvoiceProductItemUpdate,
  'invoice'
> & {
  invoice_product_item?: InvoiceProductItem['uuid']
}

export type InvoiceProductBundleItem = Omit<InvoiceProductItem, 'invoice'> & {
  invoice_product_item: InvoiceProductItem['uuid']
  order_product_item: OrderProductItem['uuid']
}

export type ProductItem = {
  uuid: string
  total_sales_price: number
  buying_price: number
  total_weight: number
  sales_price: number
  sales_price_original: number
  sales_price_relevant: number
  web_price_display: number
  web_price_original_display: number
  list_price_display: number
  buying_price_display: number
  buying_price_original: number
  total_sales_price_display: number
  total_buying_price: number
  total_buying_price_display: number
  quantity_invoiced: number
  quantity_available: number
  created: string
  updated: string
  is_active: boolean
  sku: string
  weight_original: number
  weight_overwrite: number
  web_price_original: number
  list_price: number
  quantity_offered: number
  quantity_requested: number
  margin_percent: number
  minimum_order_quantity: number
  lead_time_original: number
  lead_time_overwrite: number | null
  dimensions: string
  image: string
  hs_code: string
  country_of_origin: string
  price_unit: string
  price_amount: number
  product_unit: string
  product_amount: number
  stock_level: number
  increment: number
  manufacturer_name: string
  manufacturer_part_number: string
  ean_code: string
  series: string
  type: string
  product_category: string
  omnical_link: string
  van_egmond_link: string
  octopart_link: string
  discount_group: string
  discount_value: number
  ordering: number
  product: Product['uuid']
}

export type OrderProductItem = ProductItem & {
  margin_percent: number
  details_original: string
  details_overwrite: string
  moq_rules_message: string
  order: Order['uuid']
  quantity_available: number
  qty_tooltip: string
  is_details: boolean
  notes: string
}

export type OrderProductItemList = OrderProductItem & {
  quantity_delivered: number
  quantity_reserved: number
  available_date: string | null
}

export type OrderProductItemOverwrite = Pick<
  OrderProductItem,
  | 'lead_time_overwrite'
  | 'weight_overwrite'
  | 'quantity_requested'
  | 'quantity_offered'
  | 'ordering'
>

export type OrderProductItemCreate = Partial<OrderProductItemOverwrite> & {
  order: Order['uuid']
  product: Product['uuid']
}

export type InvoiceProductItem = ProductItem & {
  invoice: Invoice['uuid']
}

export type InvoiceProductItemUpdate = Partial<
  Pick<
    InvoiceProductItem,
    | 'buying_price'
    | 'weight_overwrite'
    | 'sales_price'
    | 'quantity_offered'
    | 'ordering'
    | 'invoice'
  >
> & {
  order_product_item?: OrderProductItem['uuid']
}

export type ShippingMethod = {
  uuid: string
  created: string
  updated: string
  is_active: boolean
  is_default: boolean
  name: string
  name_display: string
  name_overwrite: string
  rate_calculation_type: RateCalculationType
}

export type ShippingMethodUpdate = Pick<
  ShippingMethod,
  'is_active' | 'name' | 'rate_calculation_type'
>

export type AddressType = 'shipping' | 'billing'

export type Address = {
  uuid: string
  created: string
  updated: string
  type: AddressType
  is_active: boolean
  attn: string
  organization_name: string
  address_line_1: string
  address_line_2: string
  city: string
  postal_code: string
  country: Country['uuid']
  country_name: Country['name']
  phone_number: string
  is_preferred: boolean
  notes: string
  organization: string
  is_deleted: boolean
}

export type AddressUpdate = Omit<Address, 'uuid' | 'created' | 'updated'>

export type Organization = {
  uuid: string
  created: string
  balances: OrganizationBalance
  updated: string
  is_active: boolean
  slug: string
  name: string
  email_domain: string
  notes: string
  shipping_instructions: string
  terms_notes: string
  currency: Currency
  advance_limit: number
  upon_shipping_limit: number
  net_limit: number
  max_outstanding_amount: number
  max_outstanding_days: number
  vat_number: string
  order_confirmation_email: string
  invoice_email: string
  shipping_method: ShippingMethod['uuid']
  shipping_method_name: ShippingMethod['name']
  customers_count: number
}

export type OrganizationBalance = Record<Currency, number>

export type Product = {
  uuid: string
  buying_price: number
  sales_prices: Record<Currency, number>
  list_prices: Record<Currency, number>
  qty_tooltip: string
  is_active: boolean
  sku: string
  manufacturer_name: string
  manufacturer_part_number: string
  ean_code: string
  product_category: string
  series: string
  type: string
  stock_level: number
  unit_of_measurement: string
  minimum_order_quantity: number
  increment: number
  hs_code: string
  hs_code_overwrite: string
  country_of_origin: string
  country_of_origin_overwrite: string
  weight: number
  weight_overwrite: number
  manufacturer_discount_group: string
  standard_lead_time: number
  list_price: number
  web_price: number
  image: string
  is_available: boolean
  packaging: string
  price_unit: string
  price_amount: number
  product_unit: string
  product_amount: number
  dimensions: string
  discount_group: string
}

export type ProductListItem = Pick<
  Product,
  | 'uuid'
  | 'sku'
  | 'manufacturer_name'
  | 'manufacturer_part_number'
  | 'type'
  | 'product_category'
>

export type ProductDetail = Product & {
  is_custom: boolean
}

export type ProductUpdate = Omit<Product, 'uuid' | 'buying_price' | 'sku'>

export type ProductUpdated = ProductUpdate & Pick<Product, 'uuid'>

export type SyncHistoryItem = {
  created: string
  updated: string
  is_successful: boolean
  items_count: number
  status: string
  initiator: User | null
}

export type SyncHistory = ListReturnType<SyncHistoryItem>

export type DiscountGroup = {
  uuid: string
  created: string
  updated: string
  is_active: boolean
  name: string
  description: string
  discount: number
}

export type Country = {
  uuid: string
  name: string
  zone: number
}

export type PaymentMethod =
  | 'PAYPAL'
  | 'IDEAL'
  | 'WIRE'
  | 'STRIPE'
  | 'BRAINTREE'
  | 'INTERNAL'
export type PaymentSource = 'CUSTOMER' | 'BALANCE' | 'INVOICE'
export type PaymentDestination = 'CUSTOMER' | 'BALANCE' | 'INVOICE'
export type PaymentType = 'PAYMENT' | 'REFUND'

export type Payment = {
  uuid: string
  invoice_id: Invoice['invoice_id']
  parent_invoice_id: Invoice['invoice_id']
  order_id: Order['order_id']
  customer_email: Customer['email']
  po_number: string
  total_amount: number
  created: string
  updated: string
  parent: string | null
  destination: PaymentDestination
  method: PaymentMethod
  is_active: boolean
  source: PaymentSource
  payment_date: string
  amount: number
  true_up_amount: number | null
  currency: Currency
  notes: string
  organization: Organization['uuid']
  invoice: Invoice['uuid']
  parent_invoice: Invoice['uuid']
  subPayments: Payment[] | null
  add_from_balance: number
}

export type PaymentUpdate = Pick<
  Payment,
  | 'source'
  | 'payment_date'
  | 'amount'
  | 'true_up_amount'
  | 'currency'
  | 'notes'
  | 'invoice'
>

export type ShipmentStatus = 'UNSHIPPED' | 'SHIPPED' | 'PARTIALLY_SHIPPED'

export type PaymentStatus =
  | 'UNPAID'
  | 'PAID'
  | 'PARTIALLY_PAID'
  | 'OVERPAID'
  | 'TO_REFUND'
  | 'REFUNDED'

export type Shipment = {
  uuid: string
  po_number: string
  carrier: ShippingMethod['uuid'] | null
  carrier_name: ShippingMethod['name'] | null
  order: Order['uuid']
  order_id: Order['order_id']
  invoice: Invoice['uuid']
  invoice_id: Invoice['invoice_id']
  phone_number: string
  address: string
  created: string
  updated: string
  is_active: boolean
  shipment_date: string | null
  tracking_number: string | null
  status: ShipmentStatus | null
}

export type ShipmentUpdate = Pick<
  Shipment,
  | 'is_active'
  | 'shipment_date'
  | 'tracking_number'
  | 'status'
  | 'carrier'
  | 'invoice'
>

export type StatementList = {
  currency: Currency
  date: string
  invoice_id: number
  order: string
  shipped: string
  po: string
  total: number
  paid_to_date: number
  due_now: number
}

export type StatementDetail = {
  currency: Currency
  total_amount: number | null
  total_paid: number | null
  total_unpaid: number | null
  terms: TermsType
}

export type User = {
  email: string
  first_name: string
  last_name: string
  /**
   * @property Is superuser
   */
  is_staff: boolean
  groups: Role[]
}

export const roles = [
  'Managers',
  'Customer representatives',
  'Quote team',
  'Logistics',
] as const

export type Role = (typeof roles)[number]

export type ConfigItem = {
  key: string
  default: string | number | boolean
  help_text: string
  value: string | number | boolean
}

export type Config = Record<string, ConfigItem>

export type MarginRule = {
  min_value: number
  max_value: number
  target: number
}

export type ConfigClient = {
  fuel_surcharge: number
  emergency_surcharge: number
  benelux_free_shipping_cutoff: number
  benelux_handling_fee: number
  default_shipping_cutoff: number
  handling_fee: number
  quote_terms_template: string
  payment_terms_template: string
  vat: number
  margin_rules: MarginRule[]
}

export type ConfigItemStatus = 'in_progress' | 'success' | 'failed'

export type RateCalculationType = 'in-system' | 'out-of-system' | 'no-fees'

export type TermsType = 'advance' | 'net' | 'mixed'

export type Currency = 'eur' | 'usd' | 'cad'

export interface AutocompleteOption {
  id: string
  label: string
  isDisabled?: boolean
}

export type OrderStatus = 'DRAFT' | 'QUOTE' | 'ORDER' | 'CLOSED' | 'REOPENED'
export type OrderStatusChange = Exclude<OrderStatus, 'DRAFT'>

export type OrderSource = 'EMAIL' | 'SITE'

export type FetchError = TypeError | SyntaxError | { message: string }

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`
}[keyof ObjectType & (string | number)]

export type OrderMetrics = {
  month: number
  year: number
  quotes_count: number
  orders_count: number
  cancelled_orders_percent: number
  cancelled_quotes_percent: number
  cancelled_orders_count: number
  cancelled_quotes_count: number
  success_percent: number
  orders_per_day: number
  revenue_per_day: number
  closed_orders_count: number
}

export type TopOrders = {
  created: string
  order_id: Order['order_id']
  customer_email: string
  manufacturer_names: string[]
  payment_status: PaymentStatus
  shipment_status: ShipmentStatus | null
  currency: Currency
  total_amount: number
}

export type ReportData = {
  month: number
  selected_year: number
  comparing_year: number
  revenue_selected_year: number
  revenue_comparing_year: number
  margin_selected_year: number
  margin_comparing_year: number
  revenue_percent: number
  margin_percent: number
}

export type ReportType =
  | 'reviews_io'
  | 'order_metrics'
  | 'orders'
  | 'orders_with_items'
  | 'invoices'
  | 'transactions'
  | 'decline_feedback'

export const declineReasons = [
  'better_offer',
  'dont_need_anymore',
  'long_lead_time',
  'other',
] as const
export type DeclineReason = (typeof declineReasons)[number]

export type FutureOffersOption = 'yes' | 'no' | 'possibly' | 'unlikely'

export const ratingNames = [
  'product_pricing',
  'product_details',
  'shipping_details',
  'payment_details',
  'timing_of_quote',
  'communication',
] as const
export type RatingName = (typeof ratingNames)[number]

export type Ratings = Record<RatingName, number>

export type Supplier = {
  uuid: string
  created: string
  updated: string
  name: string
  handler: string
  notes: string
  position: number
  is_default: boolean
}
