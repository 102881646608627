import {
  DeclineReason,
  FutureOffersOption,
  RatingName,
} from 'utils/global-types'

export const declineReasonOptions: Array<{
  label: string
  value: DeclineReason
}> = [
  {
    label: 'I found a better offer',
    value: 'better_offer',
  },
  {
    label: 'I do not need the product(s) anymore',
    value: 'dont_need_anymore',
  },
  {
    label: 'Long lead time',
    value: 'long_lead_time',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const futureOffersOptions: Array<{
  label: string
  value: FutureOffersOption
}> = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
  {
    label: 'Possibly',
    value: 'possibly',
  },
  {
    label: 'Unlikely',
    value: 'unlikely',
  },
]

export const ratingLabelMap: Record<RatingName, string> = {
  product_pricing: 'Product pricing',
  product_details: 'Product detail',
  shipping_details: 'Shipping details',
  payment_details: 'Ordering and payment details',
  timing_of_quote: 'Timing of quote',
  communication: 'Emails from our team',
}
