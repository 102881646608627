import { MutableRefObject } from 'react'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import {
  alpha,
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  styled,
  SxProps,
} from '@mui/material'
import { useIsMutating } from '@tanstack/react-query'
import { isEmpty } from 'lodash'

import { LoadingButton } from 'components/Buttons'
import { DialogConfig, DialogToggleOpen } from 'context/Dialog/DialogContext'

const DialogForm = styled('form')(({ theme }) => ({
  '& .MuiTextField-root': {
    marginBottom: theme.spacing(3),
  },
}))

export type DialogProps = {
  open: boolean
  toggleState: DialogToggleOpen
  formMethods: MutableRefObject<UseFormReturn | undefined>
  onSubmit: DialogToggleOpen
} & DialogConfig

const StyledDialog = styled(Dialog)(() => ({
  [`& .${dialogClasses.paper}`]: {
    minWidth: '444px',
    maxWidth: 'unset',
  },
}))

const loaderStyles: SxProps = {
  position: 'absolute',
  backgroundColor: alpha('#fff', 0.88),
  zIndex: 2,
}

export default function DialogContainer({
  open,
  toggleState,
  title,
  actions = {},
  content = null,
  customActionButtons,
  submitButtonVariant = 'contained',
  submitButtonColor,
  resolver,
  formMethods,
  onSubmit: submitHandler,
  mutationKeys,
}: DialogProps) {
  const form = useForm({ resolver })

  const isLoading = useIsMutating({ mutationKey: mutationKeys })
  formMethods.current = form

  const onSubmit = form.handleSubmit((data) => {
    // if the form values is empty then dialog was only confirmation
    submitHandler(isEmpty(data) ? true : data)
  }, console.error)

  const onReset = () => {
    toggleState(false)
  }

  const confirmButtonText = actions.confirm || 'Confirm'

  return (
    <StyledDialog open={open} onClose={onReset}>
      <DialogForm onSubmit={onSubmit} onReset={onReset}>
        <FormProvider {...form}>
          {title && (
            <DialogTitle sx={{ m: 0, p: 3, pb: 2 }}>{title}</DialogTitle>
          )}
          <DialogContent sx={{ overflowY: 'visible' }}>
            <Container sx={{ pt: 1, position: 'relative' }}>
              {content}

              <Backdrop open={Boolean(isLoading)} sx={loaderStyles}>
                <CircularProgress />
              </Backdrop>
            </Container>
          </DialogContent>
          <DialogActions>
            {customActionButtons || (
              <>
                <Button type="reset">{actions.cancel || 'Cancel'}</Button>
                <LoadingButton
                  type="submit"
                  variant={submitButtonVariant}
                  isLoading={Boolean(isLoading)}
                  loadingText={confirmButtonText}
                  color={submitButtonColor}
                >
                  {confirmButtonText}
                </LoadingButton>
              </>
            )}
          </DialogActions>
        </FormProvider>
      </DialogForm>
    </StyledDialog>
  )
}
