import { DialogConfig } from 'context/Dialog/DialogContext'
import { Order } from 'utils/global-types'

export const convertQuoteToOrder = (
  quoteID: Order['order_id']
): DialogConfig => ({
  title: `Convert quote ${quoteID} to order?`,
  content: (
    <>
      Are you sure that you want to convert quote {quoteID}
      <br /> to order. This action can’t be undone.
    </>
  ),
  actions: { confirm: 'Convert to order' },
})

export const convertDraftToQuote = (
  quoteID: Order['order_id']
): DialogConfig => ({
  title: `Convert draft ${quoteID} to quote?`,
  content: (
    <>
      Are you sure that you want to convert draft {quoteID}
      <br /> to quote. This action can’t be undone.
    </>
  ),
  actions: { confirm: 'Convert to quote' },
})

export const rejectQuote = (quoteID: Order['order_id']): DialogConfig => ({
  title: `Reject quote ${quoteID}?`,
  content: `Are you sure that you want to reject quote ${quoteID}?`,
  actions: { confirm: 'Reject' },
  submitButtonVariant: 'outlined',
  submitButtonColor: 'error',
})

export const reopenQuote = (quoteID: Order['order_id']): DialogConfig => ({
  title: `Reopen quote ${quoteID}?`,
  content: `Are you sure that you want to reopen quote ${quoteID}?`,
  actions: { confirm: 'Reopen' },
})

export const extendQuote = (quoteID: Order['order_id']): DialogConfig => ({
  title: `Extend quote ${quoteID}?`,
  content: (
    <>
      Extend the quote by recalculating prices while keeping the same margin.
      <br />
      <br />
      Extended quote will be valid for 30 days from today.
    </>
  ),
  actions: { confirm: 'Extend quote validity' },
})
