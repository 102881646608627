import { ChangeEventHandler, ReactNode, useState } from 'react'
import WarningIcon from '@mui/icons-material/ReportProblemOutlined'
import { Button, Stack, Typography } from '@mui/material'

import useConfig from 'api/config/useConfig'
import useUploadDiscounts from 'api/product/useUploadDiscount'
import useUploadSupplier from 'api/product-item/useUploadSupplier'
import useUploadRates, {
  UploadRatesVariables,
} from 'api/shipping/useUploadRates'
import { useDialog } from 'context/Dialog/DialogContext'
import { localeDate } from 'utils/date'
import { ConfigItemStatus } from 'utils/global-types'

import { uploadShippingRates } from './dialogs'

type UploadDate = {
  DELIVERY_DATES_UPLOAD: string
  DISCOUNTS_UPLOAD: string
  SHIPPING_RATES_UPLOAD: string
}

const uploadDateDefault: UploadDate = {
  DELIVERY_DATES_UPLOAD: '',
  DISCOUNTS_UPLOAD: '',
  SHIPPING_RATES_UPLOAD: '',
}

export default function UploadButtons() {
  const { data: config } = useConfig()
  const [uploadDate, setUploadDate] = useState(uploadDateDefault)
  const { mutateAsync: uploadDiscounts } = useUploadDiscounts()
  const { mutateAsync: uploadDeliveryDates } = useUploadSupplier()
  const { mutateAsync: uploadRates } = useUploadRates()
  const [openDialog] = useDialog()

  const updateUploadDate = (key: keyof UploadDate) => {
    setUploadDate((prev) => ({ ...prev, [key]: new Date().toISOString() }))
  }

  const handleUploadDeliveryDates: ChangeEventHandler<
    HTMLInputElement
  > = async (e) => {
    const file = e.target.files?.[0]
    if (file) {
      await uploadDeliveryDates(file)
      updateUploadDate('DELIVERY_DATES_UPLOAD')
      e.target.value = ''
    }
  }

  const handleUploadDiscount: ChangeEventHandler<HTMLInputElement> = async (
    e
  ) => {
    const file = e.target.files?.[0]
    if (file) {
      await uploadDiscounts(file)
      updateUploadDate('DISCOUNTS_UPLOAD')
      e.target.value = ''
    }
  }

  const handleUploadShippingRates = async () => {
    openDialog(uploadShippingRates(), async (result) => {
      if (typeof result === 'boolean') {
        return result
      }
      const isSuccess = await uploadRates(result as UploadRatesVariables)

      if (isSuccess) {
        updateUploadDate('SHIPPING_RATES_UPLOAD')
      }

      return true
    })
  }

  const renderLastUpload = (keyPrefix: keyof UploadDate) => {
    const date =
      uploadDate[keyPrefix] || (config?.[`${keyPrefix}_DATE`]?.value as string)
    const status = config?.[`${keyPrefix}_STATUS`]?.value as ConfigItemStatus

    if (!date || !status) {
      return null
    }

    let text: ReactNode = 'Last upload:'
    if (status === 'failed') {
      text = (
        <>
          <WarningIcon color="error" sx={{ mr: 1 }} />
          Last upload failed:
        </>
      )
    }
    if (status === 'in_progress') {
      text = 'Upload started:'
    }

    return (
      <Typography
        variant="body2"
        color="text.secondary"
        display="inline-flex"
        alignItems="end"
      >
        {text} {localeDate(date)}
      </Typography>
    )
  }

  return (
    <Stack direction="row">
      <Stack alignItems="start" flex={1}>
        <Typography fontWeight={500}>Upload Delivery Dates</Typography>
        <Typography variant="body1" color="text.secondary">
          Press button to select file to upload
        </Typography>
        <Button sx={{ my: 2 }} component="label" variant="outlined">
          Upload Delivery Dates
          <input
            hidden
            accept="*"
            type="file"
            onChange={handleUploadDeliveryDates}
          />
        </Button>
        {renderLastUpload('DELIVERY_DATES_UPLOAD')}
      </Stack>
      <Stack alignItems="start" flex={1}>
        <Typography fontWeight={500}>Upload Discount</Typography>
        <Typography variant="body1" color="text.secondary">
          Press button to select file to upload
        </Typography>
        <Button sx={{ my: 2 }} component="label" variant="outlined">
          Upload Discount
          <input
            hidden
            accept="*"
            type="file"
            onChange={handleUploadDiscount}
          />
        </Button>
        {renderLastUpload('DISCOUNTS_UPLOAD')}
      </Stack>
      <Stack alignItems="start" flex={1}>
        <Typography fontWeight={500}>
          Upload DHL Express shipping rates
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Press button to select file to upload
        </Typography>
        <Button
          sx={{ my: 2 }}
          component="label"
          variant="outlined"
          onClick={handleUploadShippingRates}
        >
          Upload DHL Express shipping rates
        </Button>
        {renderLastUpload('SHIPPING_RATES_UPLOAD')}
      </Stack>
    </Stack>
  )
}
