import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/browser'

import Router from 'routing/Router'

const isProd = window.location.host === 'relay.omnical.co'

if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: isProd ? process.env.SENTRY_DSN_PROD : process.env.SENTRY_DSN_DEV,
    environment: isProd
      ? process.env.SENTRY_ENVIRONMENT_PROD
      : process.env.SENTRY_ENVIRONMENT_DEV,
  })
}

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
)
