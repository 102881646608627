import { Toast, useToaster } from 'react-hot-toast'
import { SnackbarOrigin } from '@mui/material'

import BasicToast from '../BasicToast'

export default function NotificationsProvider() {
  const { toasts, handlers } = useToaster()
  const { calculateOffset, updateHeight } = handlers

  const notificationRender = (toast: Toast) => {
    const { id, position, height, visible, message } = toast

    const anchorOriginObj: SnackbarOrigin = {
      vertical: 'bottom',
      horizontal: 'left',
    }

    if (position !== undefined) {
      const toastPositionArr = position.split('-')
      anchorOriginObj.vertical =
        toastPositionArr[0] as SnackbarOrigin['vertical']
      anchorOriginObj.horizontal =
        toastPositionArr[1] as SnackbarOrigin['horizontal']
    }

    const offset = calculateOffset(toast, {
      reverseOrder: false,
    })

    const ref = (el: HTMLDivElement): void => {
      if (el !== null && height == null) {
        const height = el.getBoundingClientRect().height
        updateHeight(id, height)
      }
    }

    return (
      <BasicToast
        key={id}
        duration={10000}
        reference={ref}
        open={visible}
        state={anchorOriginObj}
        message={message as string}
        severity={toast.type}
        offset={offset}
        id={id}
      />
    )
  }

  return <>{toasts.map(notificationRender)}</>
}
