import {
  CreateShipmentButton,
  ShipmentTableActionButtons,
} from 'components/ShipmentTableButtons'
import { Invoice } from 'utils/global-types'

type InvoiceShipmentButtonsProps = {
  invoice: Invoice
}

export default function InvoiceShipmentButtons({
  invoice,
}: InvoiceShipmentButtonsProps) {
  const { shipment } = invoice

  if (!shipment) {
    return <CreateShipmentButton invoice={invoice} />
  }

  return <ShipmentTableActionButtons shipment={shipment} />
}
