import { capitalize } from 'lodash'

import {
  InvoiceProductBundleItem,
  OrderProductItemList,
  PaymentStatus,
  ShipmentStatus,
} from './global-types'

export const getStatusLabel = (status: string) =>
  capitalize(status?.replace('_', ' '))

export const getManufacturerNamesLabel = (manufacturers?: string[]) => {
  const filteredManufacturers = manufacturers?.filter(
    (manufacturers) => manufacturers !== ''
  )

  if (!filteredManufacturers?.length) {
    return 'No brands'
  }

  return filteredManufacturers.join(', ')
}

export const getAvailableQuantity = (
  orderItem: OrderProductItemList,
  invoiceItem: InvoiceProductBundleItem | undefined
) => {
  return invoiceItem
    ? invoiceItem.quantity_available + invoiceItem.quantity_offered
    : orderItem.quantity_available
}

export const getShipmentStatusColor = (status: ShipmentStatus) => {
  const colors: Record<ShipmentStatus, string> = {
    PARTIALLY_SHIPPED: 'warning.main',
    SHIPPED: 'success.main',
    UNSHIPPED: 'warning.main',
  }
  return colors[status]
}

export const getPaymentStatusColor = (status: PaymentStatus) => {
  const colors: Record<PaymentStatus, string> = {
    PARTIALLY_PAID: 'warning.main',
    PAID: 'success.main',
    UNPAID: 'warning.main',
    OVERPAID: 'success.main',
    TO_REFUND: 'error.main',
    REFUNDED: 'success.main',
  }
  return colors[status]
}

const MAX_WEIGHT = 40000 // 40kg

export const getExcessWeightWarningMessage = (totalWeight: number): string => {
  if (totalWeight > MAX_WEIGHT) {
    return 'Order exceeds 40kg.'
  }

  return ''
}
