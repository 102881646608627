import {
  FocusEventHandler,
  KeyboardEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import EditIcon from '@mui/icons-material/Edit'
import {
  FormHelperText,
  IconButton,
  Stack,
  styled,
  SxProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'

import { ReadonlyFieldLabel } from 'components/ReadonlyField/ReadonlyField'
import { useToggle } from 'utils/hooks'

const StyledTextField = styled(TextField)({
  '.MuiInput-root': {
    minWidth: 80,
    height: 40,
    '&:before': {
      display: 'none',
    },
    '& .MuiInput-input': {
      paddingBottom: '2px',
    },
  },
  label: {
    top: -6,
    '& + .MuiInput-root': {
      marginTop: 2,
    },
  },
})

const StyledReadonlyWrapper = styled(Stack)({
  flex: 1,
  position: 'relative',
  whiteSpace: 'nowrap',
})

const textStyles: SxProps = {
  display: 'inline-flex',
  alignItems: 'flex-start',
  '& .MuiInputAdornment-root': {
    height: 'auto',
    maxHeight: 'none',
  },
}

type ReadonlyTextFieldProps = {
  id?: string
  sx?: SxProps
  label?: string
  value: string
  handleClick: () => void
  helperText?: ReactNode
  startAdornment?: ReactNode
}

export const ReadonlyTextField = ({
  id,
  sx,
  label,
  value,
  startAdornment,
  helperText,
  handleClick,
}: ReadonlyTextFieldProps) => (
  <StyledReadonlyWrapper id={id} onClick={handleClick} sx={sx}>
    {label && <ReadonlyFieldLabel>{label}</ReadonlyFieldLabel>}
    <Stack flexDirection="row" justifyContent="flex-start" alignItems="center">
      <Typography sx={textStyles}>
        {startAdornment}
        {value as string}&#8203;
      </Typography>
      <IconButton size="small">
        <EditIcon fontSize="small" />
      </IconButton>
    </Stack>
    {helperText && (
      <FormHelperText sx={{ mt: -0.05 }}>{helperText}</FormHelperText>
    )}
  </StyledReadonlyWrapper>
)

type EditableTextFieldProps = Omit<TextFieldProps, 'onChange'> & {
  onChange: (value: string) => void
  label?: string
}

export default function EditableTextField({
  id,
  onChange,
  value = '',
  ...props
}: EditableTextFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const isProgrammaticallyBlurred = useRef(false)
  const [inputValue, setInputValue] = useState(value)
  const [editMode, toggleEditMode] = useToggle(false)

  const editValue = (value: string) => {
    onChange(value)
    toggleEditMode()
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      const { value } = event.target as HTMLInputElement
      editValue(value)
      isProgrammaticallyBlurred.current = true
      inputRef.current?.blur()
    }
  }

  const handleClick = () => {
    if (!editMode) {
      activateEditMode()
    }
  }

  const handleBlur: FocusEventHandler = (event) => {
    if (isProgrammaticallyBlurred.current) {
      isProgrammaticallyBlurred.current = false
      return
    }

    const { value } = event.target as HTMLInputElement
    editValue(value)
  }

  const activateEditMode = () => {
    toggleEditMode()
    setTimeout(() => {
      inputRef.current?.focus()
    }, 100)
  }

  useEffect(() => {
    setInputValue(value)
  }, [value, editMode])

  if (!editMode) {
    return (
      <ReadonlyTextField
        id={`${id}-readonly`}
        label={props.label}
        value={value as string}
        handleClick={handleClick}
        startAdornment={props.InputProps?.startAdornment}
      />
    )
  }

  return (
    <StyledTextField
      id={`${id}-input`}
      inputRef={inputRef}
      variant="standard"
      onKeyDown={onKeyDown}
      onBlur={handleBlur}
      onChange={(event) => setInputValue(event.target.value)}
      {...props}
      value={inputValue}
      InputProps={{
        ...props.InputProps,
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}
