import { Navigate, useLocation } from 'react-router-dom'
import { Container, styled } from '@mui/material'

import { PageTitle } from 'components/PageTitle'
import { useIsAuthenticated } from 'utils/routing'

import LeftPanel from './LeftPanel'
import LoginForm from './LoginForm'

const LoginContainer = styled(Container)({
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  display: 'flex',
})

const MainContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export default function Login() {
  const location = useLocation()
  const authenticated = useIsAuthenticated()

  if (authenticated) {
    return <Navigate to={location.state?.from ?? '/'} />
  }

  return (
    <LoginContainer>
      <PageTitle title="Login" />
      <LeftPanel />
      <MainContainer>
        <LoginForm />
      </MainContainer>
    </LoginContainer>
  )
}
