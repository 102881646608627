import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Invoice, Order } from 'utils/global-types'

type CreditInvoiceResponse = Invoice
type CreditInvoiceVariables = void

function createCreditInvoice(invoiceID: Invoice['uuid']) {
  const creditInvoice: MutateFunction<
    CreditInvoiceResponse,
    CreditInvoiceVariables
  > = async () => {
    const response = await axios.post<CreditInvoiceResponse>(
      '/invoices/credit/',
      { invoice: invoiceID }
    )

    return response.data
  }

  return creditInvoice
}

const useCreditInvoice = (
  invoiceID: Invoice['uuid'],
  orderID: Order['order_id']
) => {
  const queryClient = useQueryClient()

  return useMutation<CreditInvoiceResponse, AxiosError, CreditInvoiceVariables>(
    {
      mutationFn: createCreditInvoice(invoiceID),
      onSuccess: ({ order }) => {
        queryClient.invalidateQueries(['invoices'])
        queryClient.invalidateQueries(['payments'])
        queryClient.invalidateQueries(['order', orderID])
        queryClient.invalidateQueries(['product-items', order])
      },
      meta: { message: 'Failed to credit invoice' },
    }
  )
}

export default useCreditInvoice
