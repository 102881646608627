import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, Link, Stack, SxProps, Typography } from '@mui/material'
import { last, map, omitBy, toPairs } from 'lodash'

import useConfig from 'api/config/useConfig'
import useOrganization from 'api/customer/useOrganization'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { BackButton } from 'components/Buttons'
import { Footer } from 'components/Footer'
import { Table, useTable } from 'components/Table'
import { HeadCell, TableFilters, TableRow } from 'components/Table/types'
import { LinkTabs } from 'components/Tabs'
import { Can } from 'context/Ability'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import { formatPriceValue } from 'utils/price'
import { addQueryParams } from 'utils/request'
import { useGoBack } from 'utils/routing'

import { StatementStats } from './StatementStats'
import { OrganizationDetailsTab } from './types'
import { getOrganizationDetailsTabs, tabToTableConfigMap } from './utils'

const statementTabContentStyles: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  m: 2,
  mt: 0,
  pb: 2,
  borderBottom: '1px solid',
  borderColor: 'divider',
}

export default function OrganizationDetailsPage() {
  const goBack = useGoBack('/organizations')

  const { id } = useParams()
  const location = useLocation()
  const { data: config } = useConfig()
  const tab = (last(location.pathname.split('/')) ||
    'customers') as OrganizationDetailsTab

  const { url, columns, params } = tabToTableConfigMap(
    tab,
    id!,
    config?.FEATURE_ALLOW_ADDRESS_DELETE?.value as boolean
  )

  const isStatementTab = tab === 'statements'

  const { data } = useOrganization(id!)
  const organization = data!

  const organizationParams = {
    organization: id!,
    ...params,
  } satisfies TableFilters

  const table = useTable(tab, addQueryParams(url, organizationParams), {
    columns: columns as HeadCell<TableRow>[],
    keepPreviousData: false,
  })

  const balances = omitBy(organization.balances, (value) => value === 0)

  const formattedBalances = map(toPairs(balances), ([currency, balance]) => ({
    currency: currency.toUpperCase(),
    balance: formatPriceValue(balance),
  }))
  const title = `Organization ${organization.name}`

  const statementTabContent = (
    <>
      <Can I="download" a="customer">
        <Box sx={statementTabContentStyles}>
          <Typography>Statement</Typography>
          <Button
            component={Link}
            variant="outlined"
            // eslint-disable-next-line max-len
            href={`${process.env.API_URL}customers/organizations/${id}/statement-of-account-report/`}
            download
          >
            Download excel Report
          </Button>
        </Box>
      </Can>
      <StatementStats />
    </>
  )

  const actionButton =
    tab === 'customers' ? (
      <Button
        startIcon={<AddIcon />}
        component={RouterLink}
        to={`/organizations/${id}/customers/add`}
        variant="outlined"
      >
        Add Customer
      </Button>
    ) : undefined

  return (
    <PageTemplate title={title} sx={{ height: '100%' }}>
      <PageHeader>
        <Box>
          <Typography variant="h6">{title}</Typography>
          <Breadcrumbs />
        </Box>

        <Button
          variant="contained"
          component={RouterLink}
          startIcon={<EditIcon />}
          sx={{ alignSelf: 'flex-start' }}
          to={`/organizations/${id}/edit`}
        >
          Edit organization
        </Button>
      </PageHeader>

      {formattedBalances.length > 0 && (
        <>
          <Typography color="text.secondary" variant="caption">
            Organization balance
          </Typography>
          <Stack mb={3} flexDirection="row" gap={12}>
            {formattedBalances.map(({ currency, balance }) => (
              <Box key={currency}>
                <Typography component="span" color="text.secondary">
                  {currency}{' '}
                </Typography>
                <Typography component="span" fontWeight="bold">
                  {balance}
                </Typography>
              </Box>
            ))}
          </Stack>
        </>
      )}

      <Table
        {...table}
        dataType={tab}
        tabsComponent={<LinkTabs tabs={getOrganizationDetailsTabs(id)} />}
        extraToolbarContent={isStatementTab ? statementTabContent : undefined}
        searchPlaceholder="Name, etc..."
        disableAddButton
        actionButton={actionButton}
      />
      <Footer>
        <BackButton onBack={goBack} />
      </Footer>
    </PageTemplate>
  )
}
