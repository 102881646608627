import { Table, useTable } from 'components/Table'
import { HeadCell } from 'components/Table/types'
import { localeDate } from 'utils/date'
import { OrderMetrics } from 'utils/global-types'
import { formatPriceValue } from 'utils/price'
import { addQueryParams } from 'utils/request'

const columns: HeadCell<OrderMetrics>[] = [
  {
    value: ({ month, year }) =>
      localeDate(`${year}-${month}-15`, { year: 'numeric', month: '2-digit' }),
    label: 'Period',
  },
  {
    value: 'quotes_count',
    label: 'Quotes',
  },
  {
    value: ({ success_percent }) => `${success_percent}%`,
    label: 'Success',
  },
  {
    value: 'orders_count',
    label: 'Orders',
  },
  {
    value: ({ cancelled_orders_percent }) => `${cancelled_orders_percent}%`,
    label: 'Cancelled',
  },
  {
    value: 'orders_per_day',
    label: 'Orders/Day',
  },
  {
    value: ({ revenue_per_day }) => formatPriceValue(revenue_per_day),
    label: 'Revenue/Day',
  },
]
type DashboardMetricsTableProps = {
  selectedYear: string
}

export default function DashboardMetricsTable({
  selectedYear,
}: DashboardMetricsTableProps) {
  const table = useTable(
    'order-metrics',
    addQueryParams('/reports/order-metrics/', { year: selectedYear }),
    { columns }
  )

  return (
    <Table
      {...table}
      dataType="order metrics"
      disableAddButton
      hideSearch
      hidePagination
      noElevation
    />
  )
}
