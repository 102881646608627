import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Invoice } from 'utils/global-types'
import { downloadFile, generatePDFName, parsePDFError } from 'utils/helpers'

type DownloadInvoiceResponse = File
type DownloadInvoiceQueryKey = [
  'invoice-pdf',
  Invoice['uuid'],
  Invoice['invoice_id'],
  string
]

const getInvoicePDF: QueryFunction<
  DownloadInvoiceResponse,
  DownloadInvoiceQueryKey
> = async ({ queryKey }) => {
  const [, invoiceUUID, invoiceID, organizationName] = queryKey

  const response = await axios.get<DownloadInvoiceResponse>(
    `/invoices/${invoiceUUID}/print-pdf/`,
    {
      responseType: 'blob',
    }
  )

  const downloadURL = window.URL.createObjectURL(response.data)

  downloadFile(
    downloadURL,
    generatePDFName({
      id: invoiceID,
      organizationName,
      fileType: invoiceID === 'proforma' ? 'Proforma' : 'Invoice',
    })
  )

  window.URL.revokeObjectURL(downloadURL)

  return response.data
}

const useDownloadInvoice = (
  invoiceUUID: Invoice['uuid'],
  invoiceID: Invoice['invoice_id'],
  organizationName: string
) => {
  return useQuery<
    DownloadInvoiceResponse,
    AxiosError,
    DownloadInvoiceResponse,
    DownloadInvoiceQueryKey
  >({
    queryKey: ['invoice-pdf', invoiceUUID, invoiceID, organizationName],
    queryFn: getInvoicePDF,
    onError: parsePDFError,
    enabled: false,
  })
}

export default useDownloadInvoice
