import { DataStatsBarItemI } from 'components/StatsBar'
import { Customer, Organization } from 'utils/global-types'

const customerStats: DataStatsBarItemI<Customer>[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'phone_number',
    label: 'Phone number',
  },
  {
    id: 'notes',
    label: 'Notes',
  },
]

const organizationStats: DataStatsBarItemI<Organization>[] = [
  {
    id: 'name',
    label: 'Name',
  },
]

export const stepToStatsMap = [customerStats, organizationStats] as const

export const stepToFormMap: string[] = ['customer', 'organization']
