import { Stack, SxProps, Typography } from '@mui/material'
import { get } from 'lodash'

import useConfig from 'api/config/useConfig'
import useSyncProducts from 'api/product/useSyncProducts'
import { LoadingButton } from 'components/Buttons'
import { Table, useTable } from 'components/Table'
import { HeadCell } from 'components/Table/types'
import LastSync from 'pages/ProductList/LastSync'
import { localeDateTime } from 'utils/date'
import { SyncHistoryItem, User } from 'utils/global-types'
import { getStatusLabel } from 'utils/order'
import { addQueryParams } from 'utils/request'

const approxTimeTextStyles: SxProps = {
  position: 'absolute',
  bottom: -20,
  left: 0,
  transform: 'translateX(50%)',
}

const getInitiator = (initiator: User | null) => {
  if (!initiator) {
    return 'System'
  }

  if (initiator.first_name || initiator.last_name) {
    return `${initiator.first_name} ${initiator.last_name}`
  }

  return initiator.email
}

const getTimeTaken = (created: string, updated: string) => {
  const createdDate = new Date(created)
  const updatedDate = new Date(updated)
  const timeTaken = updatedDate.getTime() - createdDate.getTime()
  const minutes = Math.floor(timeTaken / 1000 / 60)
  const seconds = Math.floor((timeTaken / 1000) % 60)
  return `${minutes}m ${seconds}s`
}

const syncTableColumns: HeadCell<SyncHistoryItem>[] = [
  {
    value: ({ created }) => localeDateTime(created),
    label: 'Last Run Date Time',
  },
  {
    value: ({ initiator }) => getInitiator(initiator),
    label: 'Initiator',
  },
  {
    value: ({ created, updated }) => getTimeTaken(created, updated),
    label: 'Time Taken',
  },
  {
    value: 'items_count',
    label: '# Lines Processed',
  },
  {
    value: ({ status }) => getStatusLabel(status),
    label: 'Status',
  },
]

const REFETCH_INTERVAL = 3 * 60 * 1000

const SyncSection = () => {
  const { data: config } = useConfig(REFETCH_INTERVAL)
  const { mutateAsync: syncProducts, failureCount } = useSyncProducts()

  const table = useTable(
    'sync-products-history',
    addQueryParams('/products/sync/history/', {
      ordering: '-created',
    }),
    {
      columns: syncTableColumns,
      refetchInterval: REFETCH_INTERVAL,
    }
  )

  const { data: syncItems } = table.query

  const syncInitiator = getInitiator(
    get(syncItems, 'results[0].initiator', null)
  )

  const handleSyncProducts = async () => {
    await syncProducts()
  }

  const isSynchronizing = Boolean(
    config?.PRODUCT_FEED_SYNC_IN_PROGRESS.value || failureCount > 0
  )

  return (
    <Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <Typography fontWeight={500} letterSpacing={0.15}>
          Summary of Products
        </Typography>
        <Stack direction="row" gap={2}>
          <LastSync
            isSynchronizing={isSynchronizing}
            initiator={syncInitiator}
          />
          <Stack direction="column" position="relative">
            <LoadingButton
              variant="contained"
              isLoading={isSynchronizing}
              onClick={handleSyncProducts}
              loadingText="Syncing..."
            >
              Force Sync products
            </LoadingButton>
            {isSynchronizing && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={approxTimeTextStyles}
              >
                ～ 30 mins
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Table {...table} hideSearch dataType="history" noElevation />
    </Stack>
  )
}

export default function SyncTab() {
  const { isLoading } = useConfig()

  if (isLoading) {
    return <div>Loading...</div>
  }

  return <SyncSection />
}
