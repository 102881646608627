import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Divider, Paper, Typography } from '@mui/material'
import * as yup from 'yup'

import useCreateQuote from 'api/order/useCreateQuote'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { BackButton, LoadingButton } from 'components/Buttons'
import { Footer } from 'components/Footer'
import { Autocomplete } from 'components/Form'
import { FieldsGroup, Form } from 'components/FormHelpers'
import { ReadonlyField } from 'components/ReadonlyField'
import { ButtonTabs, LinkTabI } from 'components/Tabs'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import { mapCustomerOptions } from 'utils/customer'
import { useGoBack } from 'utils/routing'

const schema = yup.object({
  customer: yup
    .string()
    .required('This field is required.')
    .typeError('Please select customer from suggested'),
})

const quoteCreateTabs: LinkTabI[] = [
  {
    label: 'Customer',
    path: '/quotes/new',
  },
  {
    label: 'Products',
    // There is no need to provide path as it's always disabled
    path: '',
    disabled: true,
  },
]

export default function QuoteCreatePage() {
  const navigate = useNavigate()
  const goBack = useGoBack('/quotes')
  const form = useForm({
    defaultValues: {
      customer: '',
    },
    resolver: yupResolver(schema),
  })

  const { handleSubmit } = form

  const { mutateAsync: createQuote, isLoading } = useCreateQuote()

  const onSubmit = handleSubmit(async (data) => {
    const quote = await createQuote(data)
    navigate(`/quotes/${quote.order_id}/edit`, { replace: true })
  }, console.error)

  return (
    <PageTemplate title="Create new quote" sx={{ height: '100%' }}>
      <PageHeader sx={{ justifyContent: 'end' }}>
        <Box>
          <Typography variant="h6">Create new quote</Typography>
          <Breadcrumbs />
        </Box>
        <ReadonlyField label="Status" noPadding>
          Draft
        </ReadonlyField>
      </PageHeader>
      <Divider />
      <FieldsGroup sameWidth p={3} pt={4}>
        <ReadonlyField label="Quote" />
        <ReadonlyField label="Estimated delivery">Unknown</ReadonlyField>
        <ReadonlyField label="Brands">No brands</ReadonlyField>
        <ReadonlyField label="Validity">0 days</ReadonlyField>
        <ReadonlyField label="Total amount" fontWeight="700">
          0
        </ReadonlyField>
      </FieldsGroup>
      <ButtonTabs tabs={quoteCreateTabs} />
      <Form handlers={form} onSubmit={onSubmit}>
        <Paper sx={{ p: 3.5, pt: 4 }}>
          <Autocomplete
            name="customer"
            label="Customer"
            url="/customers/customers/"
            placeholder="Find a customer"
            mapOptions={mapCustomerOptions}
            InputLabelProps={{ shrink: true }}
          />
        </Paper>
        <Footer>
          <BackButton onBack={goBack} />
          <LoadingButton
            variant="contained"
            type="submit"
            isLoading={isLoading}
          >
            Create
          </LoadingButton>
        </Footer>
      </Form>
    </PageTemplate>
  )
}
