import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  TextField,
} from '@mui/material'

import useEditOrder from 'api/order/useEditOrder'
import useSuppliers from 'api/order/useSuppliers'
import { Order, Supplier } from 'utils/global-types'

type SupplierAutocompleteProps = {
  orderID: Order['order_id']
  value?: Supplier
} & Omit<
  AutocompleteProps<Supplier | null, false, true, false>,
  'renderInput' | 'options'
>

export default function SupplierAutocomplete({
  orderID,
  value,
  ...props
}: SupplierAutocompleteProps) {
  const { mutate: editOrder } = useEditOrder(orderID!)

  const { data: suppliers = [], isFetching } = useSuppliers()

  const onChange = (supplier: Supplier) => {
    editOrder({ supplier: supplier?.uuid })
  }

  return (
    <Autocomplete
      {...props}
      size="small"
      sx={{ mx: 2 }}
      disableClearable
      loading={isFetching}
      // @ts-expect-error - value can be null to
      // prevent Autocomplete being uncontrolled
      value={value ?? null}
      options={suppliers}
      onChange={(_, supplier) => onChange(supplier)}
      getOptionLabel={(option) => option?.name ?? ''}
      isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Supplier"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
