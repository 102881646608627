import { useState } from 'react'
import {
  Box,
  Divider,
  Paper,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { amber, green } from '@mui/material/colors'

import useReportData from 'api/reports/useReportData'
import { Can } from 'context/Ability'

import LegendIcon from './LegendIcon'
import ReportChart from './ReportChart'
import ReportDownloadBtn from './ReportDownloadBtn'
import ReportPeriodSelector from './ReportPeriodSelector'
import ReportTable from './ReportTable'

export default function ReportData() {
  const [selectedYear, setYear] = useState(new Date().getFullYear().toString())
  const query = useReportData(selectedYear)

  const { data: reportData } = query

  const handleYearChange = (event: SelectChangeEvent<string>) => {
    setYear(event.target.value)
  }

  return (
    <Paper>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        borderBottom={1}
        borderColor="divider"
        px={2.5}
        py={2}
      >
        <Typography variant="h6">Revenue over year</Typography>
        <Can I="download" a="report">
          <ReportDownloadBtn />
        </Can>
      </Stack>
      <Box px={2.5} py={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" columnGap={3} pl={4}>
            <Typography
              variant="body2"
              fontSize={12}
              letterSpacing={0.4}
              alignItems="center"
              display="inline-flex"
            >
              <LegendIcon legendColor={amber[600]} /> Total for{' '}
              {Number(selectedYear) - 1}
            </Typography>
            <Typography
              variant="body2"
              fontSize={12}
              letterSpacing={0.4}
              alignItems="center"
              display="inline-flex"
            >
              <LegendIcon legendColor={green[800]} /> Total for {selectedYear}
            </Typography>
          </Stack>

          <ReportPeriodSelector
            selectedYear={selectedYear}
            handleYearChange={handleYearChange}
          />
        </Stack>
        {reportData && (
          <>
            <ReportChart
              reportData={reportData.results}
              selectedYear={Number(selectedYear)}
            />
            <Divider sx={{ mb: 2 }} />
            <ReportTable selectedYear={selectedYear} reportDataQuery={query} />
          </>
        )}
      </Box>
    </Paper>
  )
}
