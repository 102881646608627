import { DialogConfig } from 'context/Dialog/DialogContext'

import ShippingRatesFormSubmitBtn from './ShippingRatesFormSubmitBtn'
import ShippingRatesUploadForm from './ShippingRatesUploadForm'

export const uploadShippingRates = (): DialogConfig => ({
  title: '',
  content: <ShippingRatesUploadForm />,
  customActionButtons: <ShippingRatesFormSubmitBtn />,
})
