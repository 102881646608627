import { ReactElement } from 'react'
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  useForm,
} from 'react-hook-form'
import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { Paper, SxProps, Theme } from '@mui/material'
import { AnyObjectSchema } from 'yup'

import SuccessView, { SuccessViewProps } from './SuccessView'

const paperStyles: SxProps<Theme> = {
  p: 3,
  gap: 2,
  display: 'flex',
  borderRadius: 1.5,
  flexDirection: 'column',
  alignItems: 'flex-start',
  boxShadow:
    '0px 7px 15px 0px #A6CBDD1A, 0px 28px 28px 0px #A6CBDD17, 0px 62px 37px 0px #A6CBDD0D, 0px 111px 44px 0px #A6CBDD03, 0px 173px 48px 0px #A6CBDD00',
}

type CustomerActionFlowProps<Data extends FieldValues> = {
  schema?: AnyObjectSchema
  formComponent: ReactElement
  successProps: SuccessViewProps
  defaultValues?: DefaultValues<Data>
  onSubmit: (data: Data) => Promise<void>
}

export default function CustomerActionFlow<Data extends FieldValues>({
  schema,
  onSubmit,
  successProps,
  defaultValues,
  formComponent,
}: CustomerActionFlowProps<Data>) {
  const form = useForm<Data>({
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined,
  })

  if (form.formState.isSubmitSuccessful) {
    return <SuccessView {...successProps} />
  }

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      await onSubmit(data)
    } catch (error) {
      toast.error('Something went wrong: ' + error)
    }
  })

  return (
    <Paper sx={paperStyles} component="form" onSubmit={handleSubmit}>
      <FormProvider {...form}>{formComponent}</FormProvider>
    </Paper>
  )
}
