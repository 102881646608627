import { FieldPath, useFormContext } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Button, Stack } from '@mui/material'
import { isAxiosError } from 'axios'

import useCreateInvoice from 'api/invoice/useCreateInvoice'
import useEditInvoice from 'api/invoice/useEditInvoice'
import useOrder from 'api/order/useOrder'
import { BackButton } from 'components/Buttons'
import { Footer } from 'components/Footer'
import { usePreviewContext } from 'components/PreviewDrawer/context'
import { useDialog } from 'context/Dialog/DialogContext'
import { Invoice, InvoiceUpdate, Order } from 'utils/global-types'
import { useGoBack } from 'utils/routing'

import { InvoiceForm } from './types'

type InvoiceFooterProps = {
  edit: boolean
  orderID: Order['order_id']
  invoiceUUID?: Invoice['uuid']
}

const normalizeInvoiceData = (data: InvoiceForm) => {
  const { invoice_items, ...invoiceFields } = data

  const invoice: Partial<InvoiceUpdate> = {
    ...invoiceFields,
  }

  const items = invoice_items.filter((item) => item.quantity_offered !== 0)

  return {
    invoice,
    items,
  }
}

export default function InvoiceFooter({
  edit,
  orderID,
  invoiceUUID,
}: InvoiceFooterProps) {
  const [openDialog] = useDialog()
  const openPreview = usePreviewContext()
  const goBack = useGoBack(`/orders/${orderID}`)

  const { data: orderData } = useOrder(orderID)
  const {
    order: { uuid: orderUUID },
  } = orderData!

  const { mutateAsync: createInvoice } = useCreateInvoice(orderID)
  const { mutateAsync: editInvoice } = useEditInvoice(invoiceUUID!)

  const {
    setError,
    handleSubmit,
    getValues,
    formState: { isDirty, isValid },
  } = useFormContext<InvoiceForm>()

  const handleCancel = async () => {
    if (!isDirty) {
      return goBack()
    }
    const isCancelConfirmed = await openDialog({ variant: 'discard' })

    if (isCancelConfirmed) {
      goBack()
    }
  }

  const handlePreview = () => {
    const { invoice, items } = normalizeInvoiceData(getValues())

    openPreview({
      type: 'proforma',
      proformaVariables: {
        ...invoice,
        invoice: invoiceUUID,
        product_bundle_list: items,
      },
      callback: goBack,
    })
  }

  const handleError = (e: unknown) => {
    if (isAxiosError(e)) {
      const error = e.response?.data as Record<string, string[]>
      Object.entries(error).forEach(([fieldName, errors]) => {
        errors.forEach((message) => {
          setError(fieldName as FieldPath<InvoiceForm>, { message })
        })
      })
    }
  }

  const submitInvoice = async (fields: InvoiceForm, isProForma: boolean) => {
    const { invoice, items } = normalizeInvoiceData(fields)

    if (!items.length) {
      toast.error('No items to invoice')
      return
    }

    try {
      if (edit) {
        await editInvoice({ items, invoice, isProForma })
      } else {
        await createInvoice({
          items,
          invoice: { ...invoice, order: orderUUID },
          isProForma,
        })
      }
      goBack()
    } catch (e) {
      handleError(e)
    }
  }

  const handleSubmitDebit = handleSubmit((fields) =>
    submitInvoice(fields, false)
  )

  const handleSubmitProForma = handleSubmit((fields) =>
    submitInvoice(fields, true)
  )

  return (
    <Footer>
      <BackButton onBack={handleCancel} />
      <Stack direction="row" gap={1}>
        {edit && (
          <Button variant="text" onClick={handlePreview} disabled={!isValid}>
            Preview proforma
          </Button>
        )}
        <Button variant="outlined" onClick={handleSubmitProForma}>
          {edit ? 'Save' : 'Create'} pro forma
        </Button>
        <Button variant="contained" onClick={handleSubmitDebit}>
          Create invoice
        </Button>
      </Stack>
    </Footer>
  )
}
