import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { ListReturnType } from 'utils/global-types'
import { addQueryParams } from 'utils/request'

type AutocompleteKey = [
  key: 'autocomplete',
  baseSearchUrl: string,
  search: string,
  filters: Record<string, string>
]

const createGetSearch = <T>(baseUrl: string) => {
  const getSearch: QueryFunction<ListReturnType<T>, AutocompleteKey> = async ({
    queryKey,
  }) => {
    const [, , search, filters] = queryKey

    const url = addQueryParams(baseUrl, {
      search: encodeURIComponent(search),
      ...filters,
    })

    const response = await axios.get<ListReturnType<T>>(url)

    return response.data
  }

  return getSearch
}

const useSearch = <T>(
  baseUrl: string,
  search: string,
  filters: Record<string, string>,
  enabled?: boolean
) => {
  return useQuery<ListReturnType<T>, AxiosError, T[], AutocompleteKey>({
    queryKey: ['autocomplete', baseUrl, search, filters],
    queryFn: createGetSearch<T>(baseUrl),
    enabled: enabled ?? search.length > 0,
    select: (data) => data.results,
    staleTime: 1000 * 5,
  })
}

export default useSearch
