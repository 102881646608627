import {
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
} from '@mui/material'
import { v4 as uuid } from 'uuid'

import TableCell from './TableCell'
import { HeadCell, TableRow } from './types'
import { getCellContent, getCellStyles } from './utils'

interface TableBodyProps<Data extends TableRow> {
  rows: Data[]
  columns: HeadCell<Data>[]
  additionalData?: Record<string, unknown>
}

export default function TableBody<Data extends TableRow>(
  props: TableBodyProps<Data>
) {
  const { rows, columns, additionalData = {} } = props

  return (
    <MuiTableBody sx={{ minHeight: '72%' }} data-testid="table-body">
      {rows?.map((row) => (
        /* eslint-disable-next-line react/jsx-key */
        <MuiTableRow key={uuid()}>
          {columns.map(({ value: columnKey, getHref, width, align }) => {
            const data =
              typeof columnKey === 'function'
                ? columnKey({ ...row, ...additionalData })
                : row[columnKey as string]
            const cellContent = getCellContent(data)

            return (
              <TableCell
                key={uuid()}
                to={getHref?.(row)}
                sx={getCellStyles({ width, align })}
              >
                {cellContent}
              </TableCell>
            )
          })}
        </MuiTableRow>
      ))}
    </MuiTableBody>
  )
}
