import { useEffect } from 'react'
import { FieldPath, SubmitErrorHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, Paper, Stack } from '@mui/material'
import { AxiosError } from 'axios'
import { forOwn, isEmpty, last, omitBy } from 'lodash'

import useEditOrder from 'api/order/useEditOrder'
import useOrder from 'api/order/useOrder'
import useUpdateOrderLabels from 'api/order/useUpdateOrderLabels'
import useOrderProductItems from 'api/product-item/useOrderProductItems'
import { BackButton, LoadingButton } from 'components/Buttons'
import { Footer } from 'components/Footer'
import { Form } from 'components/FormHelpers'
import { ButtonTabs, LinkTabI } from 'components/Tabs'
import { useDialog } from 'context/Dialog/DialogContext'
import { OrderStatus } from 'utils/global-types'
import { useGoBack } from 'utils/routing'
import { parseNullableNumber } from 'utils/validations'

import {
  pickCustomerFormValues,
  pickProductItemFormValues,
  QuoteFormFieldsI,
  QuoteProductsFormValue,
} from './utils/helpers'
import { quoteFormSchema } from './utils/schema'
import { usePartialEditOrderProductItem } from './hooks'
import ProductItemsList from './ProductItemsList'
import QuoteFormFields from './QuoteFormFields'
import SaveAsButton from './SaveAsButton'

const getQuoteTabs = (id?: string) => {
  const url = `/quotes/${id}/edit`
  const quoteTabs: LinkTabI[] = [
    {
      label: 'Customer',
      path: url + '/customer',
    },
    {
      label: 'Products',
      path: url + '/products',
    },
  ]

  return quoteTabs
}

const productItemFields = [
  'quantity_requested',
  'quantity_offered',
  'weight_overwrite',
  'lead_time_overwrite',
] as const

type ProductItemStatsI = (typeof productItemFields)[number]

export interface QuoteFormI {
  quote: QuoteFormFieldsI
  products: QuoteProductsFormValue[]
}

export type SubmitQuoteArgs = {
  formData: QuoteFormI
  status?: OrderStatus
  shouldReset?: boolean
}

type QuoteEditTab = 'customer' | 'products'

export default function QuoteForm() {
  const [openDialog] = useDialog()
  const { id } = useParams()
  const navigate = useNavigate()
  const goBack = useGoBack(`/quotes/${id}`)
  const location = useLocation()
  const tab = last(location.pathname.split('/')) || ('customer' as QuoteEditTab)

  const { data } = useOrder(id!, 'quote')
  const { order: quote } = data!
  const { data: productItems, refetch } = useOrderProductItems(id!)

  const { mutateAsync: editQuote } = useEditOrder(id!, 'quote', true)
  const {
    labels,
    addLabels,
    isLoading: isLoadingRejectQuote,
  } = useUpdateOrderLabels(id!)
  const { mutateAsync: editProductItem } = usePartialEditOrderProductItem()

  const defaultValues: QuoteFormI = {
    quote: pickCustomerFormValues(quote),
    products: productItems!.map(pickProductItemFormValues),
  }

  const form = useForm<QuoteFormI>({
    // @ts-expect-error ts(2322) Resolver infer type is not equal to form values type
    resolver: yupResolver(quoteFormSchema),
    defaultValues,
    mode: 'onBlur',
  })

  useEffect(() => {
    form.reset(defaultValues)
  }, [JSON.stringify(defaultValues)])

  const {
    setError,
    handleSubmit,
    formState: { isDirty, isSubmitting, touchedFields },
  } = form

  const onError = (error: AxiosError, index?: number) => {
    const errors = (error.response?.data || {}) as Record<
      keyof QuoteFormFieldsI | ProductItemStatsI,
      string[]
    >

    forOwn(errors, (value, key) => {
      const isProductError = productItemFields.includes(
        key as ProductItemStatsI
      )
      const fieldName = isProductError
        ? `products[${index}].${key}`
        : `quote.${key}`

      value.forEach((message) =>
        setError(fieldName as FieldPath<QuoteFormI>, { message })
      )
    })
  }

  const submitQuote = async (formData: QuoteFormI) => {
    const {
      products,
      quote: { same_billing_address, ...quote },
    } = formData

    for (let index = 0; index < products.length; index++) {
      try {
        await editProductItem({
          ...products[index],
          lead_time_overwrite: parseNullableNumber(
            products[index].lead_time_overwrite
          ),
        })
      } catch (error) {
        onError(error as AxiosError, index)
      }
    }

    await editQuote({
      ...quote,
      billing_address: same_billing_address
        ? quote.shipping_address
        : quote.billing_address,
    })
    refetch()
  }

  const handleSubmitQuote = async ({ formData }: SubmitQuoteArgs) => {
    const loadingToastId = toast.loading('Submitting quote...')

    try {
      await submitQuote({
        ...formData,
        // Remove all customer related fields from form data
        quote: omitBy(formData.quote, (_, key) =>
          key.includes('customer')
        ) as QuoteFormFieldsI,
      })
    } catch (error) {
      onError(error as AxiosError)
    } finally {
      toast.dismiss(loadingToastId)
    }
  }

  const handleBack = async () => {
    const isDiscardConfirmed =
      !isDirty ||
      isEmpty(touchedFields) ||
      (await openDialog({ variant: 'discard' }))

    if (isDiscardConfirmed) {
      goBack()
    }
  }

  const handleCancelQuote = async () => {
    await addLabels(['REJECTED'])
    goBack()
  }

  const handleValidationError: SubmitErrorHandler<QuoteFormI> = (errors) => {
    const productsTabError =
      tab === 'products' &&
      'quote' in errors &&
      !errors.quote?.discount_lump_sum &&
      !errors.quote?.margin_percent_overwrite
    const customerTabError =
      tab === 'customer' &&
      ('products' in errors ||
        errors.quote?.margin_percent_overwrite ||
        errors.quote?.discount_lump_sum)
    const shouldChangeTab = productsTabError || customerTabError

    if (shouldChangeTab) {
      navigate(`../${tab === 'customer' ? 'products' : 'customer'}`, {
        replace: true,
      })
    }
  }

  const applyChangesHandler = handleSubmit(
    (formData) => handleSubmitQuote({ formData }),
    handleValidationError
  )

  const disabledAction = isSubmitting || isLoadingRejectQuote
  const isRejectDisabled = disabledAction || labels.includes('REJECTED')

  return (
    <Form handlers={form} sx={{ display: 'block' }}>
      <ButtonTabs tabs={getQuoteTabs(id)} />
      <Paper>
        <Box sx={{ width: '100%' }}>
          <QuoteFormFields isVisible={tab === 'customer'} />
          <ProductItemsList isVisible={tab === 'products'} />
        </Box>
      </Paper>
      <Footer>
        <BackButton onBack={handleBack} />
        <Stack direction="row" gap={3}>
          <Button
            color="error"
            startIcon={<CloseIcon />}
            disabled={isRejectDisabled}
            onClick={handleCancelQuote}
          >
            Reject Quote
          </Button>
          <LoadingButton
            isLoading={isSubmitting}
            disabled={disabledAction}
            onClick={applyChangesHandler}
          >
            Apply Changes
          </LoadingButton>
          <SaveAsButton
            orderID={id!}
            disabled={disabledAction}
            onSubmit={handleSubmitQuote}
            onError={handleValidationError}
          />
        </Stack>
      </Footer>
    </Form>
  )
}
