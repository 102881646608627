import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import {
  Invoice,
  InvoiceCreate,
  InvoiceProductBundleItem,
  InvoiceProductBundleItemCreate,
} from 'utils/global-types'

type ProformaPreviewResponse = Invoice & {
  product_bundle_list: InvoiceProductBundleItem[]
}

export type ProformaPreviewVariables = Partial<InvoiceCreate> & {
  invoice?: Invoice['uuid']
  product_bundle_list: InvoiceProductBundleItemCreate[]
}

type ProformaPreviewQueryKey = ['preview-proforma', ProformaPreviewVariables]

const getPreviewProformaContext: QueryFunction<
  ProformaPreviewResponse,
  ProformaPreviewQueryKey
> = async ({ queryKey }) => {
  const [, variables] = queryKey

  const response = await axios.post<ProformaPreviewResponse>(
    `/invoices/calculate/preview-pdf/`,
    variables
  )

  return response.data
}

const usePreviewProforma = (
  variables: ProformaPreviewVariables,
  enabled?: boolean
) => {
  return useQuery<
    ProformaPreviewResponse,
    AxiosError,
    ProformaPreviewResponse,
    ProformaPreviewQueryKey
  >({
    queryKey: ['preview-proforma', variables],
    queryFn: getPreviewProformaContext,
    enabled: enabled && !!variables,
  })
}

export default usePreviewProforma
