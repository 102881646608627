import { PropsWithChildren, ReactNode } from 'react'
import { Box, Divider, styled, SxProps, Theme } from '@mui/material'
import { get } from 'lodash'

import TextWithSubtitle, {
  TextWithSubtitleProps,
} from 'components/TextWithSubtitle'

const StatsBarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
  gap: theme.spacing(3),
}))

export type DataStatsBarItemI<T> = {
  id: string
  label: string
  getHref?: (data: T) => string
  value?: (row: T) => ReactNode | string
  divider?: boolean
  valueSx?: SxProps<Theme>
}

interface StatsBarProps<T> {
  items: DataStatsBarItemI<T>[]
  data: T
  sx?: SxProps<Theme>
  subtitlesSx?: SxProps<Theme>
}

export default function DataStatsBar<Data>({
  data,
  items,
  children,
  sx,
  subtitlesSx,
}: PropsWithChildren<StatsBarProps<Data>>) {
  return (
    <StatsBarContainer sx={sx}>
      {items.map((item) => {
        const { id: dataKey, divider, label, getHref, value, valueSx } = item
        const initialText = get(data, dataKey, '')
        const fieldValue = value ? value(data) : null

        const props = {
          text: fieldValue || initialText || '-',
          subtitle: label,
          link: getHref ? getHref(data) : undefined,
        } as TextWithSubtitleProps
        return (
          <>
            <TextWithSubtitle
              {...props}
              valueSx={valueSx}
              subtitleSx={subtitlesSx}
            />
            {divider && <Divider orientation="vertical" flexItem />}
          </>
        )
      })}
      {children}
    </StatsBarContainer>
  )
}
