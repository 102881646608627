import { DefaultValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import useDeclineQuote, {
  DeclineQuoteVariables,
} from 'api/customers-portal/useDeclineQuote'
import useQuote from 'api/customers-portal/useQuote'
import CustomerActionFlow from 'components/CustomerActionView/CustomerActionFlow'
import FeedbackForm from 'components/CustomerActionView/FeedbackForm'
import { ratingNames } from 'utils/global-types'

export type DeclineQuoteFormValues = Omit<DeclineQuoteVariables, 'uuid'>

const defaultValues: DefaultValues<DeclineQuoteFormValues> = {
  product_pricing: 0,
  product_details: 0,
  shipping_details: 0,
  payment_details: 0,
  timing_of_quote: 0,
  communication: 0,
}

export default function DeclineQuotePage() {
  const { id } = useParams()
  const { data: quote } = useQuote(id)
  const { mutateAsync: declineQuote } = useDeclineQuote()

  const onSubmit = async (values: DeclineQuoteFormValues) => {
    for (const key of ratingNames) {
      values[key] = values[key] === 0 ? undefined : values[key]
    }
    await declineQuote({
      uuid: id!,
      ...values,
      reason_text: values.reason === 'other' ? values.reason_text : undefined,
    })
  }

  return (
    <CustomerActionFlow
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      formComponent={
        <FeedbackForm
          title={`Decline quote ${quote!.quote_id}`}
          submitText="Decline offer"
        />
      }
      successProps={{
        title: 'Offer declined successfully!',
        email: quote!.customer_support_email,
        description: (
          <>
            Your decision to decline the offer has been noted. <br />
            Thank you for your feedback.
          </>
        ),
      }}
    />
  )
}
