import { useParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { Box, Typography } from '@mui/material'

import useCustomer from 'api/customer/useCustomer'
import useOrganization from 'api/customer/useOrganization'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Link } from 'components/StyledLink'
import CreateQuoteButton from 'pages/CustomersList/CreateQuoteButton'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'

import CustomerForm from './CustomerForm'

export default function CustomerDetailsPage() {
  const { customerId, id: organizationid } = useParams()
  const { data: customer } = useCustomer(customerId!)
  const { data: organization } = useOrganization(
    organizationid ?? customer?.organization
  )
  const title = customerId ? `Edit ${customer?.name}` : 'Create customer'

  const organizationLink = organization ? (
    <>
      (
      <Link to={`/organizations/${organization.uuid}`}>
        {organization.name}
      </Link>
      )
    </>
  ) : null

  return (
    <PageTemplate sx={{ height: '90%' }} title={title}>
      <PageHeader>
        <Box>
          <Typography variant="h6">
            {title} {organizationLink}
          </Typography>
          <Breadcrumbs />
        </Box>
        {customer && (
          <CreateQuoteButton
            customerUUID={customer.uuid}
            variant="outlined"
            startIcon={<AddIcon />}
            enabled={customer.is_active}
            sx={{ alignSelf: 'flex-start' }}
          />
        )}
      </PageHeader>
      <CustomerForm />
    </PageTemplate>
  )
}
