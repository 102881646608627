import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { Box, Button, Divider, Paper, Stack, Typography } from '@mui/material'
import { useIsMutating } from '@tanstack/react-query'
import { capitalize } from 'lodash'

import useChangeOrderStatus from 'api/order/useChangeOrderStatus'
import useOrder from 'api/order/useOrder'
import { RelativeBackdrop } from 'components/Backdrop'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { BackButton } from 'components/Buttons'
import { Footer } from 'components/Footer'
import { LabelSelect } from 'components/LabelSelect'
import { ReadonlyField } from 'components/ReadonlyField'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import { getValidityDaysLabel, localeDate } from 'utils/date'
import { getManufacturerNamesLabel, getStatusLabel } from 'utils/order'
import { formatPrice } from 'utils/price'
import { useGoBack } from 'utils/routing'

import {
  InvoicesSection,
  OrderGeneral,
  PaymentsSection,
  ProductsSection,
  ShipmentsSection,
} from './components'

export default function OrderEdit() {
  const { id: orderID } = useParams()
  const goBack = useGoBack('/orders')

  const { data } = useOrder(orderID!)

  const isLoading = useIsMutating({ mutationKey: ['order', orderID] }) > 0
  const { mutateAsync: changeOrderStatus } = useChangeOrderStatus(orderID!)

  const { order, shippingMethod } = data!

  const handleOrderReopen = () => {
    changeOrderStatus({ status: 'REOPENED' })
  }

  const handleOrderClose = () => {
    changeOrderStatus({ status: 'CLOSED' })
  }

  const title = `Order ${order.order_id}`
  const isClosed = order.status === 'CLOSED'
  const isReopened = order.status === 'REOPENED'

  useEffect(() => {
    if (isClosed) {
      toast.success(
        'The order is closed as there is no outstanding balance remaining, and all the parts have been shipped.',
        { duration: 10_000 }
      )
    }
  }, [isClosed])

  return (
    <PageTemplate title={title}>
      <RelativeBackdrop open={isLoading} />
      <PageHeader>
        <Box>
          <Typography variant="h6">Order {order.order_id}</Typography>
          <Breadcrumbs />
        </Box>
        <Stack direction="row" columnGap={4} alignItems="center">
          <LabelSelect type="order" />
          <ReadonlyField label="Status" noPadding>
            {getStatusLabel(order.status)}
          </ReadonlyField>
        </Stack>
      </PageHeader>
      <Divider />
      <Stack
        p={3.5}
        pb={1}
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <ReadonlyField label="Channel (Source)">
          {capitalize(order.channel)} {order.source && `(${order.source})`}
        </ReadonlyField>
        <ReadonlyField label="Estimated delivery">
          {order.lead_time != null ? `${order.lead_time} days` : 'Unknown'}
        </ReadonlyField>
        <ReadonlyField label="Brands" maxWidth={400}>
          {getManufacturerNamesLabel(order.manufacturer_names)}
        </ReadonlyField>
        <ReadonlyField label="Created Date">
          {localeDate(order.created)}
        </ReadonlyField>
        <ReadonlyField label="Last Updated">
          {localeDate(order.updated)}
        </ReadonlyField>
        <ReadonlyField label="Validity">
          {getValidityDaysLabel(order.validity_days, order.expiration_date)}
        </ReadonlyField>
        <ReadonlyField label="Total amount" fontWeight={700}>
          {formatPrice(order.total_amount_relevant_display, order.currency)}
        </ReadonlyField>
      </Stack>
      <Paper>
        <OrderGeneral order={order} shippingMethod={shippingMethod} />
        <ProductsSection order={order} />
        <InvoicesSection />
        <PaymentsSection />
        <ShipmentsSection />
      </Paper>
      <Footer>
        <BackButton onBack={goBack} />
        {isClosed && (
          <Stack columnGap={3} flexDirection="row" alignItems="center">
            {order.closed_date && (
              <Typography color="text.secondary">
                Closed, {localeDate(order.closed_date)}
              </Typography>
            )}
            <Button variant="contained" onClick={handleOrderReopen}>
              Reopen
            </Button>
          </Stack>
        )}
        {isReopened && (
          <Stack columnGap={3} flexDirection="row" alignItems="center">
            {order.reopened_date && (
              <Typography color="text.secondary">
                Reopened, {localeDate(order.reopened_date)}
              </Typography>
            )}
            <Button variant="outlined" onClick={handleOrderClose}>
              Close
            </Button>
          </Stack>
        )}
      </Footer>
    </PageTemplate>
  )
}
