import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { InputAdornment, MenuItem, Stack, Typography } from '@mui/material'
import { DateTime } from 'luxon'

import { ControlledInput, DatePicker, Select } from 'components/Form'
import ControlledRadio from 'components/Form/ControlledRadio'
import { ReadonlyField } from 'components/ReadonlyField'
import { GeneralWrapper } from 'components/Wrappers'
import { Invoice, Order, Payment, PaymentType } from 'utils/global-types'
import { formatPrice, priceInputOptions } from 'utils/price'

import { useBalances } from './useBalances'

type DialogPaymentFormProps = {
  mode: 'create' | 'edit'
  payment?: Partial<Payment>
  invoiceUUID: Invoice['uuid']
  orderID: Order['order_id']
  hasPayments: Invoice['has_payments']
}

type DialogPaymentFormValues = Partial<
  Omit<Payment, 'payment_date'> & {
    payment_date: DateTime
    use_org_balance: false
    type: PaymentType
  }
>

const defaultDialogPaymentFormValues: DialogPaymentFormValues = {
  method: 'PAYPAL',
  amount: 0,
}

export default function DialogPaymentForm({
  mode,
  payment,
  orderID,
  invoiceUUID,
  hasPayments,
}: DialogPaymentFormProps) {
  const { isLoading, balances } = useBalances(invoiceUUID, orderID)
  const { reset, watch, setValue } = useFormContext()
  const [amount, type, method, useOrgBalance] = watch([
    'amount',
    'type',
    'method',
    'use_org_balance',
  ])
  const defaultAmount = Math.abs(payment?.amount ?? 0)
  useEffect(() => {
    const values: DialogPaymentFormValues = {
      ...defaultDialogPaymentFormValues,
      ...payment,
      amount: defaultAmount,
      type: 'PAYMENT',
      source: payment?.method === 'INTERNAL' ? 'BALANCE' : 'CUSTOMER', // todo: fix this
      destination: 'INVOICE',
      payment_date: payment?.payment_date
        ? DateTime.fromISO(payment.payment_date)
        : DateTime.now(),
      true_up_amount: payment?.true_up_amount ?? 0,
      add_from_balance: undefined,
      use_org_balance: false,
      notes: '',
    }

    reset(values)
  }, [mode])

  useEffect(() => {
    if (type === 'PAYMENT') {
      setValue('source', method === 'INTERNAL' ? 'BALANCE' : 'CUSTOMER')
      setValue('destination', 'INVOICE')
    } else if (type === 'REFUND') {
      setValue('source', 'INVOICE')
      setValue('destination', method === 'INTERNAL' ? 'BALANCE' : 'CUSTOMER')
      setValue('add_from_balance', undefined)
      setValue('true_up_amount', undefined)
      setValue('use_org_balance', false)
    }

    if (method === 'INTERNAL') {
      setValue('use_org_balance', false)
      setValue('add_from_balance', undefined)
      setValue('true_up_amount', undefined)
    }
  }, [method, type])

  useEffect(() => {
    if (!useOrgBalance) {
      setValue('add_from_balance', undefined)
    }
  }, [useOrgBalance])

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <Stack width={800}>
      <GeneralWrapper sx={{ pt: 2.5, pb: 1, px: 3 }}>
        <ReadonlyField label="Total invoiced" sx={{ flex: 1 }}>
          {formatPrice(balances.amountInvoiced, balances.currency)}
        </ReadonlyField>
        <ReadonlyField label="Amount Paid" sx={{ flex: 1 }}>
          {formatPrice(balances.totalPaid, balances.currency)}
        </ReadonlyField>
        <ReadonlyField label="Organization balance" sx={{ flex: 1 }}>
          {formatPrice(balances.organizationBalance, balances.currency)}
        </ReadonlyField>
        <ReadonlyField label="Order balance" sx={{ flex: 1 }}>
          {formatPrice(balances.orderBalance, balances.currency)}
        </ReadonlyField>
      </GeneralWrapper>
      <Stack mt={3}>
        {hasPayments && (
          <Stack gap={1}>
            <Typography variant="body1">
              Please select a payment type by clicking on one of the following
              options:
            </Typography>
            <ControlledRadio
              name="type"
              sx={{
                alignSelf: 'flex-start',
                flexDirection: 'row',
                columnGap: '48px',
              }}
              options={[
                {
                  label: 'Paid to Omnical',
                  value: 'PAYMENT',
                },
                {
                  label: 'Refund to customer',
                  value: 'REFUND',
                },
              ]}
            />
          </Stack>
        )}
      </Stack>
      <Stack direction="row" mt={3} gap={1}>
        <DatePicker name="payment_date" label="Date" />
        <Select name="method" label="Method" defaultValue="PAYPAL">
          <MenuItem value="PAYPAL">Paypal</MenuItem>
          <MenuItem value="IDEAL">Ideal</MenuItem>
          <MenuItem value="WIRE">Wire</MenuItem>
          <MenuItem value="STRIPE">Stripe</MenuItem>
          <MenuItem value="BRAINTREE">Braintree</MenuItem>
          <MenuItem value="INTERNAL">
            Internal ({type === 'REFUND' ? 'on' : 'from'} balance)
          </MenuItem>
        </Select>
        <ControlledInput
          name="amount"
          label="Amount"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {balances.currency.toUpperCase()}
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiFormHelperText-root:not(.Mui-error)': {
              position: 'absolute',
              top: '100%',
            },
          }}
          helperText={
            defaultAmount !== amount && type !== 'REFUND'
              ? `Invoiced: ${formatPrice(
                  balances.amountInvoiced,
                  balances.currency
                )}`
              : null
          }
          inputProps={priceInputOptions}
        />
        {type !== 'REFUND' && method !== 'INTERNAL' && (
          <ControlledInput
            name="true_up_amount"
            label="True up amount"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {balances.currency.toUpperCase()}
                </InputAdornment>
              ),
            }}
            inputProps={{ step: 0.01, min: -Infinity }}
          />
        )}
      </Stack>
      <ControlledInput sx={{ mt: 1 }} name="notes" label="Note (Optional)" />
    </Stack>
  )
}
