import toast from 'react-hot-toast'
import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { uninterceptedAxios } from 'configs/axios'

type DiscountsUploadResponse = { discounts: string }
type DiscountsUploadVariables = File

const postDiscounts: MutateFunction<
  DiscountsUploadResponse,
  AxiosError<DiscountsUploadResponse>,
  DiscountsUploadVariables
> = async (file) => {
  const formData = new FormData()
  formData.append('discounts', file)

  const response = await uninterceptedAxios.post<DiscountsUploadResponse>(
    '/products/discount-groups/upload/',
    formData
  )

  return response.data
}

const useUploadDiscounts = () => {
  const queryClient = useQueryClient()

  return useMutation<
    DiscountsUploadResponse,
    AxiosError<DiscountsUploadResponse>,
    DiscountsUploadVariables
  >({
    mutationFn: postDiscounts,
    onSuccess: () => {
      toast.success('File uploading started')
      queryClient.invalidateQueries(['config'])
    },
    meta: { useGlobalError: false },
    onError: (error) => {
      const message =
        error.response?.data?.discounts ?? 'Failed to upload supplier data'
      toast.error(message)
    },
  })
}

export default useUploadDiscounts
