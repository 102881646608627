import { HeadCell } from 'components/Table/types'
import { CustomerList, Organization } from 'utils/global-types'

import CreateQuoteButton from './CreateQuoteButton'

const customersColumns: HeadCell<CustomerList>[] = [
  {
    value: 'name',
    label: 'Name',
    width: 150,
    getHref: (row) => `/customers/${row.uuid}`,
  },
  {
    value: 'email',
    label: 'Email',
    width: 200,
  },
  {
    value: (customer) => customer.organization.name,
    label: 'Organization',
    width: 200,
    getHref: (row) => `/organizations/${row.organization.uuid}`,
  },
  {
    value: 'address',
    label: 'Location',
    width: 200,
  },
  {
    value: 'orders_count',
    label: 'No. of orders',
    width: 120,
  },
  {
    value: ({ uuid, is_active }) => (
      <CreateQuoteButton customerUUID={uuid} enabled={is_active} />
    ),
    label: '',
    width: 150,
  },
]

const organizationsColumns: HeadCell<Organization>[] = [
  {
    value: 'name',
    label: 'Organization name',
    width: 200,
    getHref: (row) => `/organizations/${row.uuid}`,
  },
  {
    value: 'address',
    label: 'Location',
    width: 200,
  },
  {
    value: 'customers_count',
    label: 'Customers',
    width: 200,
  },
  {
    value: 'shipping_method_name',
    label: 'Shipping method',
    width: 200,
  },
]

export const tableColumnsVariants = {
  customers: customersColumns,
  organizations: organizationsColumns,
} as const
