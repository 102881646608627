import useInvoice from 'api/invoice/useInvoice'
import useOrder from 'api/order/useOrder'
import useOrderProductItems from 'api/product-item/useOrderProductItems'
import { Invoice, Order } from 'utils/global-types'
import { getAvailableQuantity } from 'utils/order'

import { CombinedProductItem, InvoiceDisplayData } from './types'
import useCalculatedData from './useCalculatedData'
import { getCombinedProductItems } from './utils'

const calculateInvoiceItemTotal = (
  { calculated, invoice, order }: CombinedProductItem,
  isDisplay: boolean
): number => {
  if (calculated) {
    return calculated[isDisplay ? 'totalDisplay' : 'total']
  }
  if (invoice) {
    return invoice[
      isDisplay ? 'total_sales_price_display' : 'total_sales_price'
    ]
  }
  return order[isDisplay ? 'total_sales_price_display' : 'total_sales_price']
}

const useInvoiceData = (
  orderID: Order['order_id'],
  invoiceUUID?: Invoice['uuid']
): InvoiceDisplayData => {
  const { data: orderData } = useOrder(orderID)
  const { data: orderProductItems } = useOrderProductItems(orderID!)
  const { data: invoice } = useInvoice(invoiceUUID!)

  const { order } = orderData!
  const invoiceProductItems = invoice?.product_bundle_list

  const calculatedData = useCalculatedData(order.uuid, invoiceUUID)

  const productItems = getCombinedProductItems(
    orderProductItems!,
    invoiceProductItems,
    calculatedData?.items
  )

  const isCalculated = !!calculatedData
  const displayData: InvoiceDisplayData = {
    // order only values
    organizationUUID: order.organization.uuid,
    orderValue: order.total_amount,
    orderValueDisplay: order.subtotal_amount,
    costs: order.supplier_cost || order.subtotal_amount_in_system_currency,
    margin: isCalculated ? calculatedData.total_margin : order?.total_margin,
    marginPercentage: isCalculated
      ? calculatedData.total_margin_percent
      : order?.total_margin_percent,
    currency: order.currency,

    handlingFee: isCalculated
      ? calculatedData.handling_fee_relevant_display
      : order?.handling_fee_relevant_display,
    // calculated values only
    invoiceTotal: isCalculated
      ? calculatedData.total
      : invoice?.total_amount ?? 0,
    weight: isCalculated ? calculatedData.weight : invoice?.weight_gross ?? 0,
    shippingFee: isCalculated
      ? calculatedData.shipping_fee
      : invoice?.shipping_fee ?? 0,

    items: productItems
      .filter(({ order, invoice }) => getAvailableQuantity(order, invoice))
      .map((productItem, i) => {
        const { order, invoice } = productItem
        const totalPrice = calculateInvoiceItemTotal(productItem, false)
        const totalPriceDisplay = calculateInvoiceItemTotal(productItem, true)
        const salesPrice = isCalculated
          ? calculatedData.items[i].salesPrice
          : (invoice ?? order).sales_price
        const salesPriceRelevant = isCalculated
          ? calculatedData.items[i].salesPriceRelevant
          : (invoice ?? order).sales_price_relevant
        const salesPriceOriginal = isCalculated
          ? calculatedData.items[i].salesPriceOriginal
          : (invoice ?? order).sales_price_original

        const marginPercent = isCalculated
          ? calculatedData.items[i].marginPercent
          : (invoice ?? order).margin_percent

        return {
          listPrice: order.list_price,
          listPriceDisplay: order.list_price_display,

          buyingPrice: order.buying_price,
          buyingPriceOriginal: order.buying_price_original,

          marginPercent,
          salesPrice,
          salesPriceRelevant,
          salesPriceOriginal,
          webPriceOriginal: order.web_price_original,
          webPriceOriginalDisplay: order.web_price_original_display,

          totalPrice,
          totalPriceDisplay,
          quantityAvailable: getAvailableQuantity(order, invoice),
          order,
        }
      }),
  }

  return displayData
}

export default useInvoiceData
