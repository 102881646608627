import { DetailedHTMLProps, FormHTMLAttributes, PropsWithChildren } from 'react'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'
import {
  Box,
  Paper,
  Stack,
  StackProps,
  styled,
  SxProps,
  Typography,
} from '@mui/material'

export const FormWrapper = styled('form')(({ theme }) => ({
  height: '100%',
  display: 'block',

  '& .MuiTextField-root': {
    marginBottom: theme.spacing(3),
  },

  '& .MuiFormHelperText-root': {
    marginBottom: 0,
  },
}))

interface FormProps<T extends FieldValues>
  extends PropsWithChildren,
    DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  handlers: UseFormReturn<T>
  sx?: SxProps
}

export function Form<T extends FieldValues>({
  children,
  handlers,
  ...otherFormProps
}: FormProps<T>) {
  return (
    <FormProvider {...handlers}>
      <FormWrapper noValidate {...otherFormProps}>
        {children}
      </FormWrapper>
    </FormProvider>
  )
}

type FormSectionProps = PropsWithChildren<{
  sx?: SxProps
  title?: string
  titleSx?: SxProps
}>

export function FormSection({
  sx,
  title,
  titleSx,
  children,
}: FormSectionProps) {
  return (
    <Paper sx={{ py: 2.5, px: 2, mb: 3, ...sx }}>
      {title && (
        <Typography variant="h6" sx={{ mb: 2, ...titleSx }}>
          {title}
        </Typography>
      )}
      <Box>{children}</Box>
    </Paper>
  )
}

type FieldsGroupProps = StackProps & {
  sameWidth?: boolean
}

export const FieldsGroup = ({ sameWidth, ...props }: FieldsGroupProps) => (
  <Stack
    gap={1}
    direction="row"
    justifyContent="space-between"
    sx={{
      ...(sameWidth ? { '& > *': { flex: 1 } } : {}),
      ...props.sx,
    }}
    {...props}
  />
)
