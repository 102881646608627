import { Button } from '@mui/material'

import useDownloadReport, { ReportFilters } from 'api/reports/useDownloadReport'
import { useDialog } from 'context/Dialog/DialogContext'

import { downloadReport } from './dialogs'

export default function ReportDownloadBtn() {
  const [openDialog] = useDialog()
  const { mutateAsync: getReport } = useDownloadReport()

  const handleDownloadPopup = () => {
    openDialog(downloadReport(), async (result) => {
      if (typeof result === 'boolean') {
        return result
      }

      return await getReport(result as ReportFilters)
    })
  }

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={handleDownloadPopup}
    >
      Download excel Report
    </Button>
  )
}
