import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { Organization, StatementDetail } from 'utils/global-types'

type StatementResponse = StatementDetail
type StatementQueryKey = [
  key: 'statement',
  organizationID: Organization['uuid']
]

const getStatement: QueryFunction<
  StatementResponse,
  StatementQueryKey
> = async ({ queryKey }) => {
  const [, organizationId] = queryKey
  const response = await axiosInstance.get<StatementResponse>(
    `/customers/organizations/${organizationId}/statement-of-account-detail/`
  )

  return response.data
}

const useStatement = (organizationId?: string) => {
  return useQuery<
    StatementResponse,
    AxiosError,
    StatementResponse,
    StatementQueryKey
  >({
    queryKey: ['statement', organizationId!],
    queryFn: getStatement,
    enabled: !!organizationId,
    meta: { message: 'Failed to get statement details' },
  })
}

export default useStatement
