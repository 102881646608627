import { Container, ContainerProps, styled } from '@mui/material'

import { PageTitle } from 'components/PageTitle'

import ErrorFallback from '../ErrorFallback'

const PageContainer = styled(Container)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  maxWidth: '100% !important',
  width: '100%',
})

export default function PageTemplate({
  children,
  title,
  ...props
}: ContainerProps) {
  return (
    <ErrorFallback>
      <PageTitle title={title} />
      <PageContainer {...props}>{children}</PageContainer>
    </ErrorFallback>
  )
}
