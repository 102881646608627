import toast from 'react-hot-toast'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { Address, Customer, Organization } from 'utils/global-types'

type CreateCustomerResponse = Customer
export type CreateCustomerVariables = {
  customer: Customer
  organization: Partial<Organization>
  address?: Partial<Address> & {
    same_shipping_and_billing?: boolean
  }
}

const createCustomer: MutationFunction<
  CreateCustomerResponse,
  CreateCustomerVariables
> = async (variables) => {
  const response = await axiosInstance.post<CreateCustomerResponse>(
    '/customers/new-customer-wizard/',
    variables
  )

  return response.data
}

const useCreateCustomerWizard = () => {
  const queryClient = useQueryClient()

  return useMutation<
    CreateCustomerResponse,
    AxiosError<CreateCustomerResponse>,
    CreateCustomerVariables
  >({
    mutationFn: createCustomer,
    onSuccess: () => {
      queryClient.invalidateQueries(['customers'])
      toast.success('Customer created successfully')
    },
    meta: { message: 'Failed to create customer or organization' },
  })
}

export default useCreateCustomerWizard
