import { Button } from '@mui/material'

import useDebitInvoice from 'api/invoice/useDebitInvoice'
import usePaymentActions from 'api/payments/usePaymentActions'
import useShipmentsActions from 'api/shipping/useShipmentsActions'
import { LoadingButton } from 'components/Buttons'
import { Can } from 'context/Ability'
import { Invoice } from 'utils/global-types'

type InvoiceButtonsProps = {
  invoice: Invoice
  onDebit?: () => void
}

export default function InvoiceButtons({
  invoice,
  onDebit,
}: InvoiceButtonsProps) {
  const { handleShipInvoice } = useShipmentsActions({
    uuid: invoice.uuid,
    orderID: invoice.order_id,
  })
  const { handlePayInvoice } = usePaymentActions({
    uuid: invoice.uuid,
    orderID: invoice.order_id,
  })
  const { mutateAsync: debitInvoice, isLoading: isDebitInvoiceLoading } =
    useDebitInvoice({
      invoiceUUID: invoice.uuid,
      orderID: invoice.order_id,
    })

  const handleDebitInvoice = async () => {
    const isSuccess = await debitInvoice()

    if (isSuccess && typeof onDebit === 'function') {
      onDebit()
    }
  }

  const { is_credited, shipment_status, type, outstanding_amount } = invoice

  const isShipmentDisabled = is_credited || shipment_status === 'SHIPPED'

  if (type === 'CREDIT') {
    return null
  }

  if (type === 'DRAFT') {
    return (
      <Can I="edit" a="invoice">
        <LoadingButton
          isLoading={isDebitInvoiceLoading}
          variant="contained"
          loadingText="Converting..."
          onClick={handleDebitInvoice}
        >
          Convert to Invoice
        </LoadingButton>
      </Can>
    )
  }

  return (
    <>
      <Can I="edit" a="payment">
        <Button
          onClick={handlePayInvoice(invoice)}
          disabled={is_credited || outstanding_amount <= 0}
          variant="outlined"
        >
          Pay
        </Button>
      </Can>
      <Can I="edit" a="shipment">
        <Button
          onClick={handleShipInvoice(invoice)}
          disabled={isShipmentDisabled}
          variant="outlined"
        >
          Ship
        </Button>
      </Can>
    </>
  )
}
