import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import useOrder from 'api/order/useOrder'
import axios from 'configs/axios'
import { ListReturnType, Order, OrderProductItemList } from 'utils/global-types'

type OrderProductItemsKeys = [key: 'product-items', orderID: Order['uuid']]

export type OrderProductItemsResponse = ListReturnType<OrderProductItemList>

const getOrderProductItems: QueryFunction<
  OrderProductItemsResponse,
  OrderProductItemsKeys
> = async ({ queryKey }) => {
  const [, id] = queryKey
  const response = await axios.get<OrderProductItemsResponse>(
    '/product-items/order-products/',
    { params: { order: id } }
  )

  return response.data
}

export const orderProductItemsQueryOptions = (
  orderUUID: Order['uuid']
): WithRequired<
  UseQueryOptions<
    OrderProductItemsResponse,
    AxiosError,
    OrderProductItemList[],
    OrderProductItemsKeys
  >,
  'queryKey'
> => ({
  queryKey: ['product-items', orderUUID!],
  queryFn: getOrderProductItems,
  meta: { message: 'Failed to get order product items' },
})

const useOrderProductItems = (orderID?: Order['order_id']) => {
  const { data } = useOrder(orderID!)
  const order_uuid = data?.order?.uuid

  return useQuery<
    OrderProductItemsResponse,
    AxiosError,
    OrderProductItemList[],
    OrderProductItemsKeys
  >({
    ...orderProductItemsQueryOptions(order_uuid!),
    select: (query) => query.results,
  })
}

export default useOrderProductItems
