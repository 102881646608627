import toast from 'react-hot-toast'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Order, Supplier } from 'utils/global-types'
import { parseAPIError } from 'utils/helpers'

type SendOrderToSupplierVariables = {
  supplier: Supplier['uuid']
  order: Order['uuid']
}
type SendOrderToSupplierResponse = boolean

const sendOrderToSupplier: MutationFunction<
  SendOrderToSupplierResponse,
  SendOrderToSupplierVariables
> = async (data) => {
  const response = await axios.post(`suppliers/send/`, data)
  if (response.status === 204) {
    return true
  }

  throw 'Error occurred while sending request to supplier'
}

const useSendOrderToSupplier = (orderID?: string) => {
  const queryClient = useQueryClient()

  return useMutation<
    SendOrderToSupplierResponse,
    AxiosError,
    SendOrderToSupplierVariables
  >(sendOrderToSupplier, {
    onSuccess: () => {
      queryClient.invalidateQueries(['orders'])
      queryClient.invalidateQueries(['order', orderID])

      toast.success('Request successfully sent to supplier')
    },
    meta: {
      useGlobalError: false,
    },
    onError: parseAPIError,
  })
}

export default useSendOrderToSupplier
