import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { SyncHistory, SyncHistoryItem } from 'utils/global-types'

type SyncProductsHistoryResponse = SyncHistory
type SyncProductsHistoryKeys = ['sync-products-history']

const getSyncProductsHistory: QueryFunction<
  SyncProductsHistoryResponse,
  SyncProductsHistoryKeys
> = async () => {
  const response = await axios.get<SyncProductsHistoryResponse>(
    '/products/sync/history/'
  )

  return response.data
}

export const syncProductsHistoryQueryOptions: WithRequired<
  UseQueryOptions<
    SyncProductsHistoryResponse,
    AxiosError,
    SyncHistoryItem[],
    SyncProductsHistoryKeys
  >,
  'queryKey'
> = {
  queryKey: ['sync-products-history'],
  queryFn: getSyncProductsHistory,
  select: (response) => response.results,
}

const useSyncProductsHistory = () => {
  return useQuery(syncProductsHistoryQueryOptions)
}

export default useSyncProductsHistory
