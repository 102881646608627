import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'

import useProduct from 'api/product/useProduct'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'

import ProductForm from './ProductForm'

export default function ProductDetailsPage() {
  const { sku } = useParams()
  const isEdit = sku !== undefined

  const { data } = useProduct(sku!)

  const title = isEdit
    ? `Edit ${data?.product.manufacturer_name}`
    : 'Add new product'

  return (
    <PageTemplate title={title}>
      <PageHeader>
        <Typography variant="h6">{title}</Typography>
        <Breadcrumbs />
      </PageHeader>
      <ProductForm {...(isEdit ? data : {})} />
    </PageTemplate>
  )
}
