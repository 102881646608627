import { ReactNode } from 'react'
import toast from 'react-hot-toast'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { BlobProvider } from '@react-pdf/renderer'

import useDownloadInvoice from 'api/invoice/useDownloadInvoice'
import usePreviewInvoice from 'api/invoice/usePreviewInvoice'
import usePreviewProforma, {
  ProformaPreviewVariables,
} from 'api/invoice/usePreviewProforma'
import useDownloadQuote from 'api/order/useDownloadQuote'
import usePreviewQuote from 'api/order/usePreviewQuote'
import { Backdrop } from 'components/Backdrop'
import { Invoice as InvoiceTemplate } from 'pdf/templates/Invoice'
import { Quote as QuoteTemplate } from 'pdf/templates/Quote'
import { Invoice, Quote } from 'utils/global-types'
import { invoiceTypeToPreviewTitle } from 'utils/invoice'

import InvoiceButtons from './InvoiceButtons'
import QuoteButtons from './QuoteButtons'

const PreviewContainer = styled(Container)(({ theme }) => ({
  flex: '1 1 auto',
  padding: theme.spacing(2, 0),
  // needs to place loader right in center
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const paperStyle: SxProps<Theme> = (theme) => ({
  width: '60%',
  padding: theme.spacing(2, 3),
})

// settings for iframe PDF preview
const previewConfig = '#view=FitH&toolbar=0'

interface BlobProviderParams {
  blob: Blob | null
  url: string | null
  loading: boolean
  error: Error | null
}

type PreviewDrawerProps = {
  open: boolean
  onClose: () => void
  type: 'invoice' | 'quote' | 'proforma'
  uuid?: Invoice['uuid']
  orderID?: Quote['order_id']
  proformaVariables?: ProformaPreviewVariables
  callback?: () => void
  showViewButton?: boolean
}

export default function PreviewDrawer({
  open,
  onClose,
  type,
  uuid,
  orderID,
  showViewButton,
  proformaVariables,
  callback,
}: PreviewDrawerProps) {
  const { data: quote, isFetching: isQuoteLoading } = usePreviewQuote(
    orderID,
    type === 'quote'
  )
  const { data: invoiceData, isFetching: isInvoiceLoading } = usePreviewInvoice(
    uuid,
    type === 'invoice'
  )
  const { data: proformaData, isFetching: isProformaLoading } =
    usePreviewProforma(proformaVariables!, type === 'proforma')

  const invoice = invoiceData || proformaData

  const { organization_name } = quote ?? {}
  const {
    invoice_id,
    type: invoiceType,
    organization_name: invoiceOrganizationName,
  } = invoice ?? {}

  const { refetch: downloadInvoice } = useDownloadInvoice(
    uuid!,
    invoice_id || 'proforma',
    invoiceOrganizationName!
  )
  const { refetch: downloadQuote } = useDownloadQuote(
    orderID!,
    organization_name!
  )

  const onDownload = async () => {
    await (type === 'quote' ? downloadQuote() : downloadInvoice())
    onClose()
  }

  const onProformaDebit = () => {
    if (typeof callback === 'function') {
      callback()
      onClose()
    }
  }

  const renderPreview = ({
    error,
    loading,
    url,
  }: BlobProviderParams): ReactNode => {
    if (error) {
      toast.error('Error occurred while generating PDF')
      return 'Error'
    }
    if (loading) {
      return <CircularProgress />
    }
    return (
      <iframe
        src={`${url}${previewConfig}`}
        height="100%"
        width="100%"
        frameBorder={0}
      />
    )
  }

  const isInvoiceOrProforma = ['invoice', 'proforma'].includes(type)

  const title = invoiceType ? invoiceTypeToPreviewTitle[invoiceType] : 'quote'
  const Template = isInvoiceOrProforma ? InvoiceTemplate : QuoteTemplate
  const context = isInvoiceOrProforma ? invoice : quote

  const isLoading = isInvoiceLoading || isQuoteLoading || isProformaLoading

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: paperStyle }}
    >
      <Stack height="100%" position="relative">
        <Backdrop open={isLoading} sx={{ position: 'absolute' }} />
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Box>
            <Typography variant="h6">View the {title}</Typography>
            <Typography variant="body1">
              This is how your {title} will appear.
            </Typography>
          </Box>

          <Button
            onClick={onDownload}
            variant="outlined"
            sx={{ alignSelf: 'flex-start' }}
          >
            Download PDF
          </Button>
        </Stack>
        <PreviewContainer>
          {context !== undefined && (
            // @ts-expect-error invoice context will be render using InvoiceTemplate and quote context will be render using QuoteTemplate
            <BlobProvider document={<Template {...context} />}>
              {renderPreview}
            </BlobProvider>
          )}
        </PreviewContainer>
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Button onClick={onClose}>Close preview</Button>
          <Box sx={{ columnGap: 1, display: 'flex' }}>
            {isInvoiceOrProforma && invoice && (
              // @ts-expect-error InvoiceContext is passed here, but the difference only in one field that even not used here.
              <InvoiceButtons invoice={invoice} onDebit={onProformaDebit} />
            )}
            {type === 'quote' && quote && (
              <QuoteButtons
                showViewButton={showViewButton}
                orderID={quote.order_id}
                status={quote.status}
                onClose={onClose}
              />
            )}
          </Box>
        </Stack>
      </Stack>
    </Drawer>
  )
}
