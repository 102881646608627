import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  styled,
  TableCell as MUITableCell,
  TableCellProps as MuiTableCellProps,
  Tooltip,
} from '@mui/material'

import { Link } from 'components/StyledLink'
import { useOverflow } from 'utils/hooks'

const StyledTableCell = styled(MUITableCell)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '&.MuiTableCell-sizeMedium': {
    padding: '16px 10px',
  },
  '&:hover a:not(.MuiButtonBase-root)': {
    textDecoration: 'underline',
  },
})

type TableCellProps = MuiTableCellProps & { to?: string }

export default function TableCell(props: TableCellProps) {
  const { to, children, ...otherProps } = props
  const navigate = useNavigate()
  const cellRef = useRef<HTMLElement>()
  const isOverflowing = useOverflow(cellRef)

  const handleTableCellClick = () => {
    if (to) {
      navigate(to)
    }
  }

  const tableCell = (
    <StyledTableCell
      ref={cellRef}
      {...otherProps}
      onClick={handleTableCellClick}
      sx={{ ...otherProps.sx, cursor: to ? 'pointer' : 'auto' }}
    >
      {to !== undefined ? (
        <Link to={to} onClick={(e) => e.stopPropagation()}>
          {children}
        </Link>
      ) : (
        children
      )}
    </StyledTableCell>
  )

  if (isOverflowing) {
    return (
      <Tooltip disableFocusListener title={children}>
        {tableCell}
      </Tooltip>
    )
  }

  return tableCell
}
