import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import {
  Order,
  OrderProductItem,
  OrderProductItemCreate,
} from 'utils/global-types'
import { parseAPIError } from 'utils/helpers'

type CreateOrderProductItemVariables = OrderProductItemCreate
type CreateOrderProductItemResponse = OrderProductItem

const postOrderProductItem: MutateFunction<
  CreateOrderProductItemResponse,
  AxiosError,
  CreateOrderProductItemVariables
> = async (variables) => {
  const response = await axios.post<CreateOrderProductItemResponse>(
    '/product-items/order-products/',
    variables
  )

  return response.data
}

const useCreateOrderProductItem = (orderID: Order['order_id']) => {
  const queryClient = useQueryClient()

  return useMutation<
    CreateOrderProductItemResponse,
    AxiosError,
    CreateOrderProductItemVariables
  >({
    mutationFn: postOrderProductItem,
    meta: { message: 'Failed to add product to order' },
    onError: parseAPIError,
    onSuccess: ({ order }) => {
      queryClient.invalidateQueries(['product-items', order])
      queryClient.invalidateQueries(['order', orderID])
    },
  })
}

export default useCreateOrderProductItem
