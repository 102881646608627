import { PropsWithChildren } from 'react'
import { StyleSheet, View } from '@react-pdf/renderer'

import Typography from 'pdf/components/Typography'

const styles = StyleSheet.create({
  headCell: {
    opacity: 0.6,
    textTransform: 'uppercase',
  },
  cell: {
    fontSize: 7,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'left',
    flexWrap: 'wrap',
  },
})

type ProductItemsTableCellProps = PropsWithChildren<{
  head?: boolean
  width: number
  textAlign?: 'left' | 'center' | 'right'
}>

export default function ProductItemsTableCell({
  width,
  children,
  head = false,
  textAlign = 'left',
}: ProductItemsTableCellProps) {
  return (
    <View
      style={[styles.cell, head ? styles.headCell : {}, { width, textAlign }]}
    >
      <Typography secondary={head} medium size={7}>
        {children}
      </Typography>
    </View>
  )
}
