import { HeadCell, TableFilters } from 'components/Table/types'
import {
  PaymentStatusCell,
  ShipmentStatusCell,
} from 'components/TableStatusCell'
import { LinkTabI } from 'components/Tabs'
import { Order } from 'utils/global-types'
import { formatPrice } from 'utils/price'

export type OrdersListTab =
  | 'all'
  | 'open'
  | 'reopened'
  | 'closed'
  | 'canceled'
  | 'unconfirmed'
  | 'expired'

export const ordersTabs: LinkTabI[] = [
  {
    label: 'All orders',
    path: '/orders/all',
  },
  {
    label: 'Open',
    path: '/orders/open',
  },
  {
    label: 'Reopened',
    path: '/orders/reopened',
  },
  {
    label: 'Closed',
    path: '/orders/closed',
  },
  {
    label: 'Canceled',
    path: '/orders/canceled',
  },
  {
    label: 'Unconfirmed',
    path: '/orders/unconfirmed',
  },
  {
    label: 'Expired',
    path: '/orders/expired',
  },
]

export const mapOrderListTabToParams: Record<OrdersListTab, TableFilters> = {
  all: {
    status__in: ['ORDER', 'REOPENED', 'CLOSED'].join(','),
    ordering: '-ordered_date',
  },
  open: {
    status__in: ['ORDER', 'REOPENED'].join(','),
    labels__exclude: ['EXPIRED', 'CANCELLED'].join(','),
    ordering: '-ordered_date',
  },
  reopened: {
    status: 'REOPENED',
    ordering: '-ordered_date',
  },
  closed: {
    status: 'CLOSED',
    ordering: '-ordered_date',
  },
  canceled: {
    status: 'ORDER',
    labels__contains: 'CANCELLED',
    ordering: '-ordered_date',
  },
  unconfirmed: {
    status: 'ORDER',
    labels__exclude: ['CANCELLED', 'EXPIRED'].join(','),
    is_unconfirmed: 'true',
    ordering: '-ordered_date',
  },
  expired: {
    status: 'ORDER',
    labels__contains: 'EXPIRED',
    ordering: '-ordered_date',
  },
}

export const ordersListColumns: HeadCell<Order>[] = [
  {
    value: 'ordered_date',
    label: 'Created date',
    width: 115,
  },
  {
    value: 'order_id',
    label: 'Order',
    getHref: (order) => `/orders/${order.order_id}`,
    width: 100,
  },
  {
    value: 'supplier_order_id',
    label: 'Supplier #',
    width: 100,
  },
  {
    value: 'customer_email',
    label: 'Customer e-mail',
    width: 160,
  },
  {
    value: (order) => order.manufacturer_names.join(', '),
    label: 'Brands',
    width: 160,
  },
  {
    value: (order) =>
      formatPrice(order.total_amount, order.currency, {
        priceFirst: true,
      }),
    label: 'Amount',
    align: 'right',
    width: 150,
  },
  {
    value: ({ payment_status }) => (
      <PaymentStatusCell paymentStatus={payment_status} />
    ),
    label: 'Payment status',
    width: 120,
  },
  {
    value: ({ shipment_status }) => (
      <ShipmentStatusCell shipmentStatus={shipment_status} />
    ),
    label: 'Delivery status',
    width: 120,
  },
]
