import { toast } from 'react-hot-toast'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import useOrder from 'api/order/useOrder'
import { emptyRequestWithFetch } from 'configs/axios'
import { FetchError, Order, OrderProductItem } from 'utils/global-types'

type DeleteOrderProductItemVariables = void
type DeleteOrderProductItemResponse = boolean

function createDeleteOrderProductItem(uuid: OrderProductItem['uuid']) {
  const deleteOrderProductItem: MutationFunction<
    DeleteOrderProductItemResponse,
    DeleteOrderProductItemVariables
  > = async () => {
    const response = await emptyRequestWithFetch(
      `product-items/order-products/${uuid}/`,
      'DELETE'
    )

    return response.ok || Promise.reject(response)
  }

  return deleteOrderProductItem
}

const useDeleteOrderProductItem = (
  uuid: OrderProductItem['uuid'],
  orderID: Order['order_id']
) => {
  const queryClient = useQueryClient()

  const { data } = useOrder(orderID)
  const order = data?.order
  const { uuid: orderUUID } = order || {}

  return useMutation<
    DeleteOrderProductItemResponse,
    FetchError,
    DeleteOrderProductItemVariables
  >({
    mutationFn: createDeleteOrderProductItem(uuid),
    onSuccess: () => {
      queryClient.invalidateQueries(['product-items', orderUUID])
      toast.success('Product item deleted successfully')
    },
    onError: (error) => {
      toast.error(
        `Unable to delete product from order${
          'message' in error ? `: ${error.message}` : '.'
        }`
      )
    },
  })
}

export default useDeleteOrderProductItem
