import {
  FetchQueryOptions,
  QueryFunction,
  useQuery,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Customer, ShippingMethod } from 'utils/global-types'

export type OrderResponse = {
  order_id: string
  po_number: string
  customer_name: Customer['name']
  customer_email: Customer['email']
  expiration_date: string | null
  shipping_method_name: ShippingMethod['name']
  customer_support_email: string
}
type OrderQueryKey = ['customers-portal/order', string]

export const getOrder: QueryFunction<OrderResponse, OrderQueryKey> = async ({
  queryKey,
}) => {
  const [, orderUUID] = queryKey
  const { data: order } = await axios.get<OrderResponse>(
    `/customers-portal/orders/${orderUUID}/`
  )

  return order
}

export const orderDetailsQueryOptions = (
  orderUUID: string
): WithRequired<
  FetchQueryOptions<OrderResponse, AxiosError, OrderResponse, OrderQueryKey>,
  'queryKey'
> => ({
  queryKey: ['customers-portal/order', orderUUID],
  queryFn: getOrder,
  staleTime: 1000 * 30, // 30 seconds
  meta: { useGlobalError: false },
})

const useOrder = (orderUUID?: string) => {
  return useQuery({
    ...orderDetailsQueryOptions(orderUUID!),
    enabled: !!orderUUID,
  })
}

export default useOrder
