import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack, Tooltip } from '@mui/material'

type LabelWithTooltipProps = {
  label: string
  tooltipText?: string
}
export default function LabelWithTooltip({
  label,
  tooltipText,
}: LabelWithTooltipProps) {
  if (!tooltipText) {
    return <>{label}</>
  }

  return (
    <Stack direction="row" alignItems="center">
      {label}
      <Tooltip title={tooltipText}>
        <InfoOutlinedIcon color="inherit" fontSize="small" sx={{ ml: 1 }} />
      </Tooltip>
    </Stack>
  )
}
