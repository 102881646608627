import { Link } from 'react-router-dom'
import { Button, Stack, Typography } from '@mui/material'

import { Can } from 'context/Ability'
import ErrorFallback from 'pages/Layout/ErrorFallback'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'

import DashboardMetrics from './DashboardMetrics'
import DashboardTopOrders from './DashboardTopOrders'

export default function Dashboard() {
  return (
    <PageTemplate title="Dashboard">
      <PageHeader>
        <Typography variant="h6">Dashboard</Typography>
      </PageHeader>
      <Can I="view" a="dashboard">
        <Stack rowGap={2}>
          <ErrorFallback>
            <DashboardMetrics />
          </ErrorFallback>
          <ErrorFallback>
            <DashboardTopOrders />
          </ErrorFallback>
        </Stack>
      </Can>
      <Can not I="view" a="dashboard">
        Here is no content available for you at this time.
        <Stack direction="row" gap={3} mt={3}>
          <Can I="view" an="order">
            <Button variant="contained" component={Link} to="/quotes">
              Go to quotes
            </Button>
          </Can>
          <Can I="view" an="shipment">
            <Button variant="contained" component={Link} to="/shipments">
              Go to shipment
            </Button>
          </Can>
        </Stack>
      </Can>
    </PageTemplate>
  )
}
