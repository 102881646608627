import toast from 'react-hot-toast'
import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Order, OrderUpdate } from 'utils/global-types'

type UpdateCustomerInfoRequest = void
type UpdateCustomerInfoResponse = OrderUpdate

const postUpdateCustomerInfo = (
  orderID: Order['order_id']
): MutateFunction<
  UpdateCustomerInfoResponse,
  AxiosError,
  UpdateCustomerInfoRequest
> => {
  return async () => {
    const response = await axios.post<UpdateCustomerInfoResponse>(
      `/orders/${orderID}/update-customer-info/`
    )

    return response.data
  }
}

const useUpdateCustomerInfo = (orderID: Order['order_id']) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: postUpdateCustomerInfo(orderID),
    meta: { message: 'Failed to update customer info' },
    onSuccess: (order) => {
      queryClient.invalidateQueries(['order', orderID])
      queryClient.invalidateQueries(['customer', order.customer])
      queryClient.invalidateQueries(['organization', order.organization.uuid])

      toast.success('Customer info updated')
    },
  })
}

export default useUpdateCustomerInfo
