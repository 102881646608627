import { PropsWithChildren } from 'react'
import { Link as PDFLink } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'

type LinkProps = PropsWithChildren<{
  style?: Style
  src: string
}>

export default function Link({ style, src, children }: LinkProps) {
  return (
    <PDFLink style={{ color: '#005A96', ...style }} src={src}>
      {children}
    </PDFLink>
  )
}
