import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Stack, styled, Typography } from '@mui/material'

import { useDialog } from 'context/Dialog/DialogContext'

import { legacyTermsConfirmDialog } from './dialog'

const LegacyTermsWrapper = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.error.light}`,
  borderRadius: theme.shape.borderRadius,
  padding: '6px',
  paddingLeft: '16px',
}))

type LegacyTermsProps = {
  terms: string
  onDelete: () => void
}

export default function LegacyTerms({ terms, onDelete }: LegacyTermsProps) {
  const [openDialog] = useDialog()

  const handleDelete = async () => {
    const isConfirmed = await openDialog(legacyTermsConfirmDialog)

    if (isConfirmed) {
      onDelete()
    }
  }

  return (
    <LegacyTermsWrapper
      direction="row"
      alignItems="start"
      justifyContent="space-between"
    >
      <Stack>
        <Typography color="error" fontWeight="500" mb={0.5}>
          Legacy Terms
        </Typography>
        <Typography variant="body2" color="error">
          {terms}
        </Typography>
      </Stack>
      <IconButton onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </LegacyTermsWrapper>
  )
}
