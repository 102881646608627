import { ChangeEvent, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  QueryFunctionContext,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { debounce, mapValues } from 'lodash'

import axios from 'configs/axios'
import { ListReturnType } from 'utils/global-types'
import { useDebounce, useIsFirstRender } from 'utils/hooks'

import { TableConfig, TableFilters, TableOptions, TableRow } from './types'

type UseTableResult<Data extends TableRow> = {
  query: UseQueryResult<ListReturnType<Data>>
  queryKey: string
  options: TableOptions<Data>
  config: TableConfig
}

type TableQueryKeys = [
  queryKey: string,
  options: {
    url: string
    page: number
    itemsPerPage: number
    search: string
    filters: TableFilters
  }
]

const addParamsToUrl = (params: TableQueryKeys): string => {
  const [, { url: baseUrl, page, itemsPerPage, search, filters }] = params

  const offset = page * itemsPerPage

  let url = baseUrl
  url += url.includes('?') ? '&' : '?'
  url += `limit=${itemsPerPage}&offset=${offset}`

  if (search !== '') {
    url += `&search=${encodeURIComponent(search)}`
  }

  for (const key in filters) {
    url += `&${key}=${filters[key]}`
  }

  return url
}

export const defaultTableOptions = {
  columns: [],
  itemsPerPageOptions: [10, 25, 50, 100],
  itemsPerPage: 25,
  keepPreviousData: true,
  refetchInterval: false,
} satisfies TableOptions<TableRow>

export function getSearchParams(searchParams: URLSearchParams) {
  const {
    limit,
    page,
    search: _search,
    ...filters
  } = Object.fromEntries(searchParams.entries())

  const pageValid = parseInt(page)
  const limitValid = defaultTableOptions.itemsPerPageOptions.includes(
    parseInt(limit)
  )

  return {
    page: pageValid ? parseInt(page) - 1 : 0,
    itemsPerPage: limitValid
      ? parseInt(limit)
      : defaultTableOptions.itemsPerPage,
    filters: mapValues(filters, JSON.parse),
  }
}

export const getData = async <Data extends TableRow>({
  queryKey,
}: QueryFunctionContext<TableQueryKeys>) => {
  const url = addParamsToUrl(queryKey)
  const response = await axios.get<ListReturnType<Data>>(url)
  return response.data
}

/**
 * Hook that provide all necessary options for table component
 *
 * Usage:
 * ```
 * const table = useTable(url, options)
 *
 * <Table {...table} />
 * ```
 * @param {string} baseUrl Base URL for making data requests
 * @param {TableOptions} propsOptions displaying table options
 * @returns {UseTableResult<Data>} query with table data, props options merged with default options, config with all states and handlers
 */
const useTable = <Data extends TableRow>(
  queryKey: string,
  baseUrl: string,
  propsOptions: TableOptions<Data>
): UseTableResult<Data> => {
  const isFirstRender = useIsFirstRender()
  const [searchParams, setSearchParams] = useSearchParams()
  const options: TableOptions<Data> = {
    ...defaultTableOptions,
    ...propsOptions,
  }

  const { page, itemsPerPage, filters } = getSearchParams(searchParams)

  const [searchString, setSearchString] = useState<string>(
    searchParams.get('search') ?? ''
  )
  const debouncedSearchString = useDebounce(searchString, 500)

  const updateSearchParams = (params: Record<string, string>) => {
    for (const key in params) {
      searchParams.set(key, params[key])
    }
    setSearchParams(searchParams)
  }

  const setPage = (page: number): void => {
    updateSearchParams({ page: page.toString() })
  }
  const setRowsPerPage = (rowsPerPage: number): void => {
    updateSearchParams({ limit: rowsPerPage.toString(), page: '1' })
  }
  const setFilters = (filters: TableFilters): void => {
    setSearchParams(mapValues(filters, String))
  }

  const setSearchStringDebounced = debounce((searchString: string): void => {
    if (searchString === '') {
      searchParams.delete('search')
      setSearchParams(searchParams)
    } else {
      updateSearchParams({ search: searchString, page: '1' })
    }
  })

  useEffect(() => {
    if (!isFirstRender) {
      setSearchStringDebounced(searchString)
    }
  }, [searchString])

  const query = useQuery<
    ListReturnType<Data>,
    AxiosError,
    ListReturnType<Data>,
    TableQueryKeys
  >({
    queryKey: [
      queryKey,
      {
        url: baseUrl,
        page,
        itemsPerPage,
        search: debouncedSearchString,
        filters,
      },
    ],
    queryFn: getData,
    staleTime: 1000 * 60,
    keepPreviousData: options.keepPreviousData,
    refetchInterval: options.refetchInterval,
  })

  const onPageChange = (event: unknown, newPage: number) => setPage(newPage + 1)

  const onRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  return {
    query,
    queryKey,
    options,
    config: {
      page,
      onPageChange,
      rowsPerPage: itemsPerPage,
      onRowsPerPageChange,
      searchString,
      onSearch: setSearchString,
      setFilters,
    },
  }
}

export default useTable
