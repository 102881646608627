import { createContext, useContext } from 'react'
import { Outlet } from 'react-router-dom'
import { Box, styled, Toolbar, useMediaQuery, useTheme } from '@mui/material'

import { useBoolean } from 'utils/hooks'

import { Body } from './Body'
import { Drawer } from './Drawer'
import ErrorFallback from './ErrorFallback'
import { Header } from './Header'

type DrawerContextI = readonly [
  isOpen: boolean,
  open: () => void,
  close: () => void
]

const DrawerContext = createContext<DrawerContextI | null>(null)

export const useDrawer = () => {
  const context = useContext(DrawerContext)
  if (context === null) {
    throw new Error('useDrawer must be used within a DrawerProvider')
  }
  return context
}

const LayoutRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
}))

export default function Layout() {
  const theme = useTheme()

  // drawer is open by default on medium and up screens
  const drawerDefaultState = useMediaQuery(theme.breakpoints.up('md'))
  const drawerContext = useBoolean(drawerDefaultState)

  return (
    <DrawerContext.Provider value={drawerContext}>
      <LayoutRoot>
        <Header />
        <Drawer />
        <Body>
          <Toolbar />
          <ErrorFallback>
            <Outlet />
          </ErrorFallback>
        </Body>
      </LayoutRoot>
    </DrawerContext.Provider>
  )
}
