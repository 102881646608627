import { useMatches, useRouteError } from 'react-router-dom'
import { ErrorOutlined } from '@mui/icons-material'
import { isAxiosError } from 'axios'
import { last } from 'lodash'

import ErrorPageTemplate from 'pages/Error/ErrorPageTemplate'

import NotFoundView from './NotFoundView'

export default function ErrorView() {
  const matches = useMatches()
  const error = useRouteError()
  const email = 'support@omnical.co'

  if (isAxiosError(error) && error.response?.status === 404) {
    const type = last(matches)?.pathname.includes('order') ? 'order' : 'quote'
    return <NotFoundView type={type} />
  }

  return (
    <ErrorPageTemplate
      title="We encountered an unexpected error."
      message={
        <>
          An error occurred while processing your request.
          <br />
          Please reach out to our support team at{' '}
          <a href={`mailto:${email}`}>{email}</a>
        </>
      }
      icon={
        <ErrorOutlined
          color="error"
          fontSize="inherit"
          sx={{ mt: 8, fontSize: 40, opacity: 0.5 }}
        />
      }
    />
  )
}
