import { useLocation } from 'react-router-dom'
import { Box, Paper, Typography } from '@mui/material'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { LinkTabI, LinkTabs } from 'components/Tabs'
import { useAbility } from 'context/Ability'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'

import { ConfigsTab, ProfileTab, SyncTab } from '.'

const accountTabs: LinkTabI[] = [
  {
    label: 'Profile',
    path: '/account/profile',
  },
  {
    label: 'Configurations',
    path: '/account/configs',
  },
  {
    label: 'Products Synchronization',
    path: '/account/sync',
  },
]

type AccountTab = 'profile' | 'configs' | 'sync'

export default function AccountPage() {
  const ability = useAbility()
  const location = useLocation()

  const tab = location.pathname.split('/')[2] as AccountTab

  const isAdmin =
    ability.can('force_sync', 'product') && ability.can('edit', 'config')

  return (
    <PageTemplate title="Account">
      <PageHeader>
        <Typography variant="h6">Account page</Typography>
        <Breadcrumbs />
      </PageHeader>
      <Paper>
        {isAdmin && <LinkTabs tabs={accountTabs} />}
        <Box p={3}>
          {tab === 'profile' && <ProfileTab />}
          {tab === 'configs' && <ConfigsTab />}
          {tab === 'sync' && <SyncTab />}
        </Box>
      </Paper>
    </PageTemplate>
  )
}
