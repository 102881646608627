import toast from 'react-hot-toast'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

import { isAuthError } from './axios'

// prettier-ignore
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      if(isAuthError(error)) {
        if(window.location.pathname !== '/login') {
          window.location.href = '/login'
        }
        return
      }
      if(query?.meta?.useGlobalError === false|| query.state.data !== undefined) {
        return
      }
      // @ts-expect-error All messages must be strings
      const message = `${query?.meta?.message as string}: ${error?.response?.data?.detail ?? error?.message ?? ''}`
      toast.error(message)
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      if(mutation?.meta?.useGlobalError === false || mutation.state.data !== undefined) {
        return
      }
      // @ts-expect-error All messages must be strings
      const message = `${mutation?.meta?.message as string}: ${error?.response?.data?.detail ?? error?.response?.data?.non_field_errors?.at(0) ?? error?.message ?? ''}`
      toast.error(message)
    },
  }),
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      useErrorBoundary: (error) => !isAuthError(error),
    },
  },
})

export default queryClient
