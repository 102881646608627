import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from '@mui/material'

type BackButtonProps = {
  onBack: () => void
}

export default function BackButton({ onBack }: BackButtonProps) {
  return (
    <Button onClick={onBack} startIcon={<ArrowBackIcon />}>
      Back
    </Button>
  )
}
