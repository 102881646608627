import { useLocation, useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { Button, Container, styled, Typography } from '@mui/material'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Table, useTable } from 'components/Table'
import { HeadCell, TableFilters } from 'components/Table/types'
import { LinkTabI, LinkTabs } from 'components/Tabs'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import { ProductListItem } from 'utils/global-types'
import { addQueryParams } from 'utils/request'

import LastSync from './LastSync'

const PageHeader = styled(Container)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  paddingBottom: 24,
})

type ProductTab = 'all-products' | 'custom' | 'inactive'

const productTabs: LinkTabI[] = [
  {
    label: 'All Products',
    path: '/products',
  },
  {
    label: 'Custom',
    path: '/products/custom',
  },
  {
    label: 'Inactive',
    path: '/products/inactive',
  },
]

const mapTabToParams: Record<ProductTab, TableFilters> = {
  'all-products': {},
  custom: { is_custom: true },
  inactive: { is_active: false },
}

const defaultParams: TableFilters = {
  ordering: '-updated',
}

const columns: HeadCell<ProductListItem>[] = [
  {
    value: 'sku',
    label: 'Product SKU',
    width: 100,
    getHref: (product) => `/products/${product.sku}`,
  },
  {
    value: 'manufacturer_name',
    label: 'Manufacturer',
    width: 200,
  },
  {
    value: 'manufacturer_part_number',
    label: 'Part #',
    width: 200,
  },
  {
    value: 'type',
    label: 'Type',
    width: 200,
  },
  {
    value: 'product_category',
    label: 'Category',
    width: 200,
  },
]

export default function ProductListPage() {
  const location = useLocation()
  const navigate = useNavigate()

  const tab = (location.pathname.split('/')[2] || 'all-products') as ProductTab

  const params: TableFilters = {
    ...defaultParams,
    ...mapTabToParams[tab],
  }

  const table = useTable<ProductListItem>(
    'products',
    addQueryParams('/products/', params),
    { columns }
  )

  return (
    <PageTemplate title="Products">
      <PageHeader>
        <Typography variant="h6">Products</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate('/products/new')}
        >
          Add Product
        </Button>
        <Breadcrumbs />
      </PageHeader>
      <Table
        {...table}
        dataType="products"
        tabsComponent={<LinkTabs tabs={productTabs} />}
        extraPaginationContent={<LastSync />}
      />
    </PageTemplate>
  )
}
