import { useController } from 'react-hook-form'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'

type DatePickerProps = {
  name: string
  label: string
  defaultValue?: DateTime
  maxDate?: DateTime
  minDate?: DateTime
}

export default function DatePicker({
  name,
  label,
  defaultValue = DateTime.now(),
  maxDate,
  minDate,
}: DatePickerProps) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
  })

  return (
    <MuiDatePicker
      {...field}
      label={label}
      format="dd-LL-yyyy"
      maxDate={maxDate}
      minDate={minDate}
      inputRef={field.ref}
      slotProps={{
        textField: {
          error: error !== undefined,
          helperText: error?.message,
        },
      }}
    />
  )
}
