import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Country, ListReturnType } from 'utils/global-types'
import { addQueryParams } from 'utils/request'

type CountriesResponse = ListReturnType<Country>

const getCountries: QueryFunction<
  CountriesResponse,
  ['countries']
> = async () => {
  const response = await axios.get<CountriesResponse>(
    addQueryParams('/shipments/countries/', { limit: 300 })
  )

  return response.data
}

const useCountries = () => {
  return useQuery<CountriesResponse, AxiosError, Country[], ['countries']>({
    queryKey: ['countries'],
    queryFn: getCountries,
    placeholderData: {
      results: [],
      count: 0,
      next: null,
      previous: null,
    } as CountriesResponse,
    select: (response) => response.results,
  })
}

export default useCountries
