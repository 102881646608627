import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { uninterceptedAxios } from 'configs/axios'

type LoginResponse = boolean
export type LoginVariables = {
  email: string
  password: string
}

const postLogin: MutateFunction<
  LoginResponse,
  AxiosError,
  LoginVariables
> = async (credentials) => {
  try {
    const response = await uninterceptedAxios.post('/users/login/', credentials)

    return response.status === 204
  } catch (e) {
    return false
  }
}

const useLogin = () => {
  const queryClient = useQueryClient()
  return useMutation<LoginResponse, AxiosError, LoginVariables>({
    mutationFn: postLogin,
    onSuccess: (isLoggedIn) => {
      if (isLoggedIn) {
        queryClient.invalidateQueries(['user'])
      }
    },
  })
}

export default useLogin
