import { pick } from 'lodash'

import { OrderProductItemList, OrderUpdate } from 'utils/global-types'
import { transformNullableNumber } from 'utils/validations'

export type QuoteFormFieldsI = Pick<
  OrderUpdate,
  | 'customer'
  | 'validity_days'
  | 'rfq_id'
  | 'shipping_method'
  | 'shipping_address'
  | 'shipping_attn'
  | 'shipping_notes'
  | 'shipping_instructions'
  | 'billing_address'
  | 'is_charge_vat'
  | 'currency'
  | 'advance_limit'
  | 'upon_shipping_limit'
  | 'net_limit'
  | 'terms_notes'
  | 'quote_terms_template'
  | 'payment_terms_template'
  | 'margin_percent_overwrite'
  | 'discount_lump_sum'
  | 'shipping_fee_overwrite'
  | 'notes'
> & {
  same_billing_address: boolean
}

export const pickCustomerFormValues = (
  values: OrderUpdate
): QuoteFormFieldsI => {
  return {
    ...pick(values, [
      'customer',
      'validity_days',
      'rfq_id',
      'shipping_method',
      'shipping_fee_overwrite',
      'shipping_address',
      'shipping_attn',
      'shipping_notes',
      'shipping_instructions',
      'billing_address',
      'is_charge_vat',
      'currency',
      'advance_limit',
      'upon_shipping_limit',
      'net_limit',
      'terms_notes',
      'quote_terms_template',
      'payment_terms_template',
      'margin_percent_overwrite',
      'discount_lump_sum',
      'notes',
    ]),
    same_billing_address:
      !!values.shipping_address &&
      values.shipping_address === values.billing_address,
  }
}

export type QuoteProductsFormValue = Pick<
  OrderProductItemList,
  | 'uuid'
  | 'quantity_requested'
  | 'quantity_offered'
  | 'weight_overwrite'
  | 'buying_price'
  | 'sales_price'
  | 'details_original'
  | 'is_details'
  | 'notes'
> & { lead_time_overwrite: string }

export const pickProductItemFormValues = (
  product: OrderProductItemList
): QuoteProductsFormValue => {
  return transformNullableNumber(
    pick(product, [
      'uuid',
      'quantity_requested',
      'quantity_offered',
      'quantity_delivered',
      'quantity_reserved',
      'quantity_invoiced',
      'weight_overwrite',
      'lead_time_overwrite',
      'buying_price',
      'sales_price',
      'details_original',
      'is_details',
      'notes',
    ]),
    'lead_time_overwrite'
  )
}
