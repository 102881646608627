import toast from 'react-hot-toast'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { ProductUpdate, ProductUpdated } from 'utils/global-types'

type CreateProductResponse = ProductUpdated

export type CreateProductRequest = ProductUpdate

const createProduct: MutationFunction<
  CreateProductResponse,
  CreateProductRequest
> = async (product) => {
  const response = await axiosInstance.post<CreateProductResponse>(
    '/products/',
    product
  )

  return response.data
}

const useCreateProduct = () => {
  const queryClient = useQueryClient()

  return useMutation<
    CreateProductResponse,
    AxiosError<Record<string, string[]>>,
    CreateProductRequest
  >({
    mutationFn: createProduct,
    onSuccess: () => {
      queryClient.invalidateQueries(['products'])
      toast.success('Product created successfully')
    },
    meta: {
      message: 'Failed to create product',
      useGlobalError: false,
    },
  })
}

export default useCreateProduct
