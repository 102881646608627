import { View } from '@react-pdf/renderer'

import { Column, Divider, Link, Row, Space, Typography } from 'pdf/components'
import { InvoiceContext, QuoteContext } from 'pdf/types'
import { printPrice } from 'pdf/utils'

type TotalInfoProps = InvoiceContext | QuoteContext

const TotalInfo = (context: TotalInfoProps) => {
  const {
    currency,
    vat_number,
    is_charge_vat,
    product_bundle,
    shipping_method_name,
    shipping_fee_relevant_display,
    handling_fee_relevant_display,
    outstanding_amount,
    total_amount,
    subtotal_amount,
    default_vat_percent,
    discount_lump_sum,
    total_vat_amount,
  } = context

  const isInvoice = 'invoice_id' in context

  const outstanding = outstanding_amount
    ? printPrice(outstanding_amount, currency)
    : '-'
  const isVatNotApply = total_vat_amount === 0 || !is_charge_vat
  const vatLabel = `VAT ${default_vat_percent * 100}%${
    vat_number ? ` (${vat_number})` : ''
  }`

  return (
    <View wrap={false}>
      <Space />
      <Divider />
      <Space />
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Column width={1000} gap={3}>
          <Row>
            <Typography secondary size={7}>
              Total Products{' '}
              {(isInvoice && context.type === 'CREDIT') ||
                `(${product_bundle.length})`}
            </Typography>
            <Typography medium size={7}>
              {printPrice(subtotal_amount, currency)}
            </Typography>
          </Row>
          {discount_lump_sum > 0 && (
            <Row>
              <Typography secondary size={7}>
                Discount
              </Typography>
              <Typography medium size={7}>
                -{printPrice(discount_lump_sum, currency)}
              </Typography>
            </Row>
          )}
          <Row>
            <Typography secondary size={7}>
              Shipping Fees
              {shipping_method_name ? (
                <Typography bold size={7}>
                  {' '}
                  ({shipping_method_name})
                </Typography>
              ) : null}
            </Typography>
            <Typography medium size={7}>
              {printPrice(shipping_fee_relevant_display, currency)}
            </Typography>
          </Row>
          <Row>
            <Typography secondary size={7}>
              Handling fee orders under € 500
              {handling_fee_relevant_display === 0 && (
                <Typography bold size={7}>
                  {' '}
                  (Waived)
                </Typography>
              )}
            </Typography>
            <Typography medium size={7}>
              {printPrice(handling_fee_relevant_display, currency)}
            </Typography>
          </Row>
          <Row>
            <Typography secondary size={7}>
              {vatLabel}{' '}
              {isVatNotApply && (
                <Typography bold size={7}>
                  (not applicable)
                </Typography>
              )}
            </Typography>
            <Typography medium size={7}>
              {printPrice(total_vat_amount, currency)}
            </Typography>
          </Row>
          <Space height={4} />
          <Row>
            <Typography bold size={11}>
              Total
            </Typography>
            <Typography bold size={11}>
              {printPrice(total_amount, currency)}
            </Typography>
          </Row>
          <Space height={4} />
          {!isInvoice && context.paypal_url && (
            <Row>
              <Typography secondary>
                Pay with{' '}
                <Link src={context.paypal_url} style={{ opacity: 0.8 }}>
                  PayPal
                </Link>
              </Typography>
              <Typography medium size={7}>
                {outstanding}
              </Typography>
            </Row>
          )}
        </Column>
      </View>
    </View>
  )
}

export default TotalInfo
