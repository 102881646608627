import { useState } from 'react'
import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import {
  Invoice,
  InvoiceCreate,
  InvoiceProductBundleItem,
  InvoiceProductBundleItemCreate,
} from 'utils/global-types'

type CalculateInvoiceResponse = Invoice & {
  product_bundle_list: InvoiceProductBundleItem[]
}

type CalculateInvoiceVariables = Partial<InvoiceCreate> & {
  invoice?: Invoice['uuid']
  product_bundle_list: InvoiceProductBundleItemCreate[]
}

type CalculateInvoiceQueryKey = ['calculate-invoice', CalculateInvoiceVariables]

const postCalculateInvoice: QueryFunction<
  CalculateInvoiceResponse,
  CalculateInvoiceQueryKey
> = async ({ queryKey }) => {
  const [, variables] = queryKey

  const response = await axios.post<CalculateInvoiceResponse>(
    `/invoices/calculate/`,
    variables
  )

  return response.data
}

const useCalculateInvoice = (
  variables: CalculateInvoiceVariables,
  enabledDefault: boolean
) => {
  const [enabled, setEnabled] = useState(enabledDefault)

  const query = useQuery<
    CalculateInvoiceResponse,
    AxiosError,
    CalculateInvoiceResponse,
    CalculateInvoiceQueryKey
  >({
    queryKey: ['calculate-invoice', variables],
    queryFn: postCalculateInvoice,
    keepPreviousData: true,
    enabled,
    // Allows to avoid duplicating requests
    staleTime: Infinity,
    meta: { useGlobalError: false },
    useErrorBoundary: (error) => error.response?.status !== 400,
  })

  // provide an ability to use hook lazy
  return [setEnabled, query] as const
}

export default useCalculateInvoice
