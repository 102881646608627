import { Link } from 'react-router-dom'
import { Button } from '@mui/material'

import { BackButton } from 'components/Buttons'
import { useGoBack } from 'utils/routing'

import ErrorPageTemplate from './ErrorPageTemplate'

export default function NotFound() {
  const goBack = useGoBack('/')

  return (
    <ErrorPageTemplate
      code={404}
      title="Page not found or Access Denied"
      message="Oops! It seems like the page you're looking for doesn't exist or you don't have permission to access it."
      buttons={
        <>
          <BackButton onBack={goBack} />
          <Button variant="contained" component={Link} to="/">
            Go to Dashboard
          </Button>
        </>
      }
    />
  )
}
