import { View } from '@react-pdf/renderer'

import { DocumentMetaInfoFields } from 'pdf/types'

import { Column, Row, Typography } from '.'

const Footer = (metaInfo: DocumentMetaInfoFields) => (
  <View
    wrap={false}
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}
  >
    <Column width="35%" gap={3}>
      <Typography secondary medium upperCase size={7}>
        Bank details
      </Typography>
      <Typography>
        <Typography secondary>Name of beneficiary: </Typography>
        {metaInfo.bank_name_of_beneficiary}
      </Typography>
      <Typography>
        <Typography secondary>Bank name: </Typography>
        {metaInfo.bank_name}
      </Typography>
      <Typography>
        <Typography secondary>Bank address: </Typography>
        {metaInfo.bank_address_line_1} {metaInfo.bank_address_line_2}
      </Typography>
      <Typography>
        <Typography secondary>SWIFT / BIC code: </Typography>
        {metaInfo.bank_swift_code}
      </Typography>
      <Typography secondary medium>
        Account number / IBAN numbers:
      </Typography>
      <Typography>{metaInfo.bank_iban_number_eur} (EUR)</Typography>
      <Typography>{metaInfo.bank_iban_number_usd} (USD)</Typography>
    </Column>
    <Column width="60%">
      <Row>
        <Column>
          <Typography secondary medium>
            Chamber of commerce
          </Typography>
          <Typography>
            <Typography secondary medium>
              Registration:{' '}
            </Typography>
            {metaInfo.from_chamber_of_commerce}
          </Typography>
        </Column>
        <Column>
          <Typography secondary medium>
            VAT/Sales Tax ID/BTW/EORI
          </Typography>
          <Typography>{metaInfo.from_vat}</Typography>
        </Column>
      </Row>
    </Column>
  </View>
)

export default Footer
