import { useState } from 'react'
import { useParams } from 'react-router-dom'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { Button, IconButton, Stack, TableRow, Tooltip } from '@mui/material'
import { capitalize } from 'lodash'

import usePaymentActions from 'api/payments/usePaymentActions'
import { TableCell } from 'components/Table'
import { Can } from 'context/Ability'
import { localeDate } from 'utils/date'
import { Payment } from 'utils/global-types'
import { formatPrice } from 'utils/price'

type PaymentRowProps = {
  payment: Payment
}

export default function PaymentRow({ payment }: PaymentRowProps) {
  const [open, setOpen] = useState(false)
  const { id: orderID } = useParams()
  const {
    uuid,
    total_amount,
    currency,
    invoice_id,
    parent_invoice_id,
    source,
    destination,
    method,
    payment_date,
    subPayments,
    notes,
  } = payment

  const { handleDeletePayment } = usePaymentActions({
    uuid,
    orderID: orderID!,
  })

  const toggleDetails = () => {
    setOpen((val) => !val)
  }

  const buttons = (
    <Can I="edit" a="payment">
      <Stack direction="row" gap={1} justifyContent="flex-end">
        {/* <Button variant="outlined" onClick={handleEditPayment(payment)}>
          Edit
        </Button> */}
        <Button variant="outlined" onClick={handleDeletePayment}>
          Delete
        </Button>
      </Stack>
    </Can>
  )

  return (
    <>
      <TableRow>
        <TableCell width="30px">
          {!!subPayments?.length && (
            <IconButton onClick={toggleDetails}>
              <ArrowDropDownIcon
                sx={{
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.2s ease-in-out',
                }}
              />
            </IconButton>
          )}
        </TableCell>
        <TableCell>{localeDate(payment_date)}</TableCell>
        <TableCell>{invoice_id || parent_invoice_id}</TableCell>
        <TableCell>{capitalize(source)}</TableCell>
        <TableCell>
          {subPayments?.length
            ? subPayments
                .map((item) => capitalize(item.destination))
                .join(' + ')
            : capitalize(destination)}
        </TableCell>
        <TableCell>{capitalize(method)}</TableCell>
        <TableCell>
          {formatPrice(total_amount, currency)}
          {notes && (
            <Tooltip title={notes} placement="top">
              <IconButton
                size="small"
                sx={{
                  color: 'text.secondary',
                }}
              >
                <EventNoteIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
        <TableCell>{buttons}</TableCell>
      </TableRow>
      {open &&
        subPayments?.map((item) => (
          <TableRow
            key={item.uuid}
            sx={{
              bgcolor: (theme) => theme.palette.grey[100],
              '& > .MuiTableCell-root': {
                borderBottom: '0',
                paddingY: 1,
              },
            }}
          >
            <TableCell colSpan={4} />
            <TableCell>{capitalize(item.destination)}</TableCell>
            <TableCell>{capitalize(item.method)}</TableCell>
            <TableCell>{formatPrice(item.total_amount, currency)}</TableCell>
            <TableCell />
          </TableRow>
        ))}
    </>
  )
}
