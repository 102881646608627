import toast from 'react-hot-toast'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import useOrder from 'api/order/useOrder'
import { emptyRequestWithFetch } from 'configs/axios'
import { FetchError, Quote } from 'utils/global-types'

export type DeleteProductItemResponse = boolean
export type DeleteProductItemVariables = string

const deleteProductItem: MutationFunction<
  DeleteProductItemResponse,
  DeleteProductItemVariables
> = async (itemId) => {
  const response = await emptyRequestWithFetch(
    `product-items/order-products/${itemId}/`,
    'DELETE'
  )

  return response.ok || Promise.reject(response)
}

const useDeleteProductItem = (quoteId: Quote['order_id']) => {
  const { data } = useOrder(quoteId)
  const order_uuid = data?.order?.uuid

  const queryClient = useQueryClient()

  return useMutation<
    DeleteProductItemResponse,
    FetchError,
    DeleteProductItemVariables
  >({
    mutationFn: deleteProductItem,
    onSuccess: () => {
      queryClient.invalidateQueries(['product-items', order_uuid])
      queryClient.invalidateQueries(['order', quoteId])

      toast.success('ProductItem deleted successfully')
    },
    meta: { message: 'Failed to delete order product item' },
  })
}

export default useDeleteProductItem
