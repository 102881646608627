import { PropsWithChildren } from 'react'
import { Paper, Stack, Typography } from '@mui/material'

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'

type ErrorFallbackProps = {
  error: Error
}

function ErrorFallback({ error }: ErrorFallbackProps) {
  const reason = error.message || 'unknown'
  return (
    <Paper>
      <Stack gap={2} p={3}>
        <Typography variant="h5">Oops, something went wrong...</Typography>
        <Typography variant="body1">This is the reason: {reason}</Typography>
      </Stack>
    </Paper>
  )
}

export default ({ children }: PropsWithChildren) => (
  <ErrorBoundary fallback={ErrorFallback}>{children}</ErrorBoundary>
)
