import toast from 'react-hot-toast'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'

type ChangePasswordResponse = boolean
type ChangePasswordVariables = {
  old_password: string
  new_password: string
}

const postChangePassword: MutationFunction<
  ChangePasswordResponse,
  ChangePasswordVariables
> = async (variables) => {
  const response = await axios.post<ChangePasswordResponse>(
    '/users/password/',
    variables
  )

  return response.status == 204
}

const useChangePassword = () => {
  const queryClient = useQueryClient()
  return useMutation<
    ChangePasswordResponse,
    AxiosError,
    ChangePasswordVariables
  >({
    mutationFn: postChangePassword,
    meta: {
      useGlobalError: false,
    },
    onSuccess: () => {
      toast.success('Password changed successfully')
      queryClient.invalidateQueries(['user'])
    },
  })
}

export default useChangePassword
