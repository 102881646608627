import {
  SxProps,
  TableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from '@mui/material'
import { v4 as uuid } from 'uuid'

import { HeadCell, TableRow } from './types'
import { getCellStyles } from './utils'

const headCellSx: SxProps = {
  whiteSpace: 'pre-wrap',
  verticalAlign: 'top',

  '&.MuiTableCell-sizeMedium': {
    padding: '16px 10px',
  },
}

interface TableHeadProps<Data extends TableRow> {
  columns: HeadCell<Data>[]
}

export default function TableHead<Data extends TableRow>({
  columns,
}: TableHeadProps<Data>) {
  return (
    <MuiTableHead>
      <MuiTableRow data-testid="table-head">
        {columns.map(({ label, width, align }) => (
          <TableCell
            key={uuid()}
            sx={[
              getCellStyles({ width, align }),
              Array.isArray(headCellSx) ? headCellSx[0] : headCellSx,
            ]}
          >
            {label}
          </TableCell>
        ))}
      </MuiTableRow>
    </MuiTableHead>
  )
}
