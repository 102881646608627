import { View } from '@react-pdf/renderer'
import { trim } from 'lodash'

import { Column, Space, Typography } from 'pdf/components'
import { InvoiceContext } from 'pdf/types'
import { printDate } from 'utils/date'

type MainInfoProps = InvoiceContext

const MainInfo = (invoice: MainInfoProps) => (
  <View>
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Column width={160}>
        <Typography secondary medium upperCase size={7}>
          Bill to
        </Typography>
        <Space height={6} />
        <Typography bold>
          {invoice.billing_organization_name || invoice.organization_name}
        </Typography>
        <Typography>{invoice.billing_address_line_1}</Typography>
        <Typography>{invoice.billing_address_line_2}</Typography>
        <Typography>
          {trim(`${invoice.billing_postal_code} ${invoice.billing_city}`)}
        </Typography>
        <Typography>{invoice.billing_country}</Typography>
        <Typography>
          Tel: {invoice.billing_phone_number || invoice.customer_phone_number}
        </Typography>
      </Column>
      <Column width={160}>
        <Typography secondary medium upperCase size={7}>
          Ship to
        </Typography>
        <Space height={6} />
        <Typography bold>
          {invoice.shipping_organization_name || invoice.organization_name}
        </Typography>
        <Typography>{invoice.shipping_address_line_1}</Typography>
        <Typography>{invoice.shipping_address_line_2}</Typography>
        <Typography>
          {trim(`${invoice.shipping_postal_code} ${invoice.shipping_city}`)}
        </Typography>
        <Typography>{invoice.shipping_country}</Typography>
        <Typography>
          Tel: {invoice.shipping_phone_number || invoice.customer_phone_number}
        </Typography>
      </Column>
      <Column width={160} alignItems="flex-end">
        <Typography secondary medium upperCase size={7}>
          {invoice.invoice_id ? 'Invoice' : 'Proforma'} from:
        </Typography>
        <Space height={6} />
        <Typography bold>{invoice.invoice_from_name}</Typography>
        <Typography>
          {invoice.invoice_from_address_line_1}{' '}
          {invoice.invoice_from_address_line_2}
        </Typography>
        <Typography>
          {trim(
            `${invoice.invoice_from_postal_code} ${invoice.invoice_from_city}`
          )}
        </Typography>
        <Typography>{invoice.invoice_from_country}</Typography>
        <Typography>Tel: {invoice.invoice_from_phone}</Typography>
        <Typography>Fax: {invoice.invoice_from_fax}</Typography>
        <Typography>E-mail: {invoice.invoice_from_email}</Typography>
      </Column>
    </View>
    <Space height={8} />
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Column width={450} gap={3}>
        {invoice.po_number && (
          <Typography>
            <Typography secondary>PO: </Typography>
            {invoice.po_number}
          </Typography>
        )}
        <Typography>
          <Typography secondary>Name: </Typography>
          {invoice.customer_name}
        </Typography>
        <Typography>
          <Typography secondary>Email: </Typography>
          {invoice.customer_email}
        </Typography>
        <Typography>
          <Typography secondary>Shipping method: </Typography>
          {invoice.shipping_method_name}
          {invoice.shipping_instructions
            ? ` - ${invoice.shipping_instructions}`
            : ''}
        </Typography>
        {invoice.type !== 'CREDIT' && invoice.shipping_attn ? (
          <Typography>
            <Typography secondary>ATTN: </Typography>
            {invoice.shipping_attn}
          </Typography>
        ) : null}
        {invoice.type !== 'CREDIT' && invoice.shipping_notes ? (
          <Typography>
            <Typography secondary>Shipping instructions: </Typography>
            {invoice.shipping_notes}
          </Typography>
        ) : null}
      </Column>
      <Column width={150} gap={3} alignItems="flex-end">
        <Typography>
          <Typography secondary>Order No. </Typography>
          {invoice.order_id}
        </Typography>
        {invoice.invoice_id && (
          <Typography>
            <Typography secondary>Invoice No. </Typography>
            {invoice.invoice_id}
          </Typography>
        )}
        {invoice.created && (
          <Typography>
            <Typography secondary>Date: </Typography>
            {printDate(invoice.created)}
          </Typography>
        )}
      </Column>
    </View>
  </View>
)

export default MainInfo
