import useInvoice from 'api/invoice/useInvoice'
import useOrder from 'api/order/useOrder'
import useOrderProductItems from 'api/product-item/useOrderProductItems'
import { getFullAddressString } from 'utils/address'
import { AutocompleteOption, Invoice, Order } from 'utils/global-types'

import { InvoiceForm, InvoiceFormDefaultValues } from './types'
import { getCombinedProductItems, getDefaultValues } from './utils'

const useInvoiceDefaultFormValues = (
  orderID: Order['order_id'],
  invoiceUUID?: Invoice['uuid']
): InvoiceFormDefaultValues => {
  const { data: orderData } = useOrder(orderID!)
  const { data: orderProductItems } = useOrderProductItems(orderID!)
  const { data: invoice } = useInvoice(invoiceUUID!)

  const { order } = orderData!
  const { recommended_margin: recommendedMargin } = order
  const invoiceProductItems = invoice?.product_bundle_list

  const productItems = getCombinedProductItems(
    orderProductItems!,
    invoiceProductItems
  )

  const defaultValues: InvoiceForm = getDefaultValues(
    order,
    productItems,
    invoice
  )

  const source = invoice ?? order

  const shippingMethod: AutocompleteOption = {
    id: source.shipping_method!,
    label: source.shipping_method_name,
  }

  const shippingAddress: AutocompleteOption = {
    id: source.shipping_address!,
    label: getFullAddressString({
      organization_name: source.shipping_organization_name,
      address_line_1: source.shipping_address_line_1,
      address_line_2: source.shipping_address_line_2,
      city: source.shipping_city,
      country_name: source.shipping_country,
    }),
  }

  const billingAddress: AutocompleteOption = {
    id: source.billing_address!,
    label: getFullAddressString({
      organization_name: source.billing_organization_name,
      address_line_1: source.billing_address_line_1,
      address_line_2: source.billing_address_line_2,
      city: source.billing_city,
      country_name: source.billing_country,
    }),
  }

  return {
    ...defaultValues,
    shippingMethod,
    shippingAddress,
    billingAddress,
    recommendedMargin,
  }
}

export default useInvoiceDefaultFormValues
