import { useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Table, useTable } from 'components/Table'
import { LinkTabs } from 'components/Tabs'
import { useAbility } from 'context/Ability'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import { addQueryParams } from 'utils/request'

import {
  getInvoicesListColumns,
  InvoicesListTab,
  invoicesTabs,
  mapInvoicesListTabToParams,
} from './utils'

export default function InvoicesList() {
  const location = useLocation()
  const ability = useAbility()

  const tab = (location.pathname.split('/')[2] || 'all') as InvoicesListTab

  const table = useTable(
    'invoices',
    addQueryParams('/invoices/', mapInvoicesListTabToParams[tab]),
    {
      columns: getInvoicesListColumns({
        canViewOrder: ability.can('view', 'order'),
        canViewOrganization: ability.can('view', 'customer'),
      }),
    }
  )

  return (
    <PageTemplate title="Invoices">
      <PageHeader>
        <Typography variant="h6">Invoices</Typography>
        <Breadcrumbs />
      </PageHeader>
      <Table
        {...table}
        disableAddButton
        dataType="invoices"
        tabsComponent={<LinkTabs tabs={invoicesTabs} />}
      />
    </PageTemplate>
  )
}
