import { Outlet } from 'react-router-dom'

import { Ability, useAbility } from 'context/Ability'
import { NotFound } from 'pages/Error'

type RouteCanProps<T extends Ability = Ability> = T extends T
  ? {
      I: T[0]
      a: T[1]
    }
  : never

export default function RouteCan(props: RouteCanProps) {
  const ability = useAbility()

  // @ts-expect-error As we destructure Ability, we lose the ability
  // to type check but the props type check is still working
  if (ability.can(props.I, props.a)) {
    return <Outlet />
  }

  return <NotFound />
}
