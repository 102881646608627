import InfoIcon from '@mui/icons-material/Info'
import { alpha, Box, styled, Tooltip, Typography } from '@mui/material'

import { TermsContext } from 'utils/global-types'

import { ContextLabel } from './ContextLabel'

const TermsHeaderWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(1),
  '& MuiTooltip-tooltip': {
    whiteSpace: 'pre-line',
  },
}))

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: alpha(theme.palette.common.black, 0.5),
  cursor: 'pointer',
}))

interface TermsHeaderProps {
  title: string
  context: TermsContext
}

export default function TermsHeader({ title, context }: TermsHeaderProps) {
  const tooltipTitle = Object.entries(context).map((entries) => (
    <ContextLabel key={entries[0]} data={entries} />
  ))

  return (
    <TermsHeaderWrapper>
      <Typography fontWeight={500}>{title}</Typography>
      <Tooltip disableFocusListener title={tooltipTitle}>
        <StyledInfoIcon />
      </Tooltip>
    </TermsHeaderWrapper>
  )
}
