import { Button, Stack, TableRow } from '@mui/material'

import useShipmentsActions from 'api/shipping/useShipmentsActions'
import { TableCell } from 'components/Table'
import { Can } from 'context/Ability'
import { localeDate } from 'utils/date'
import { Shipment } from 'utils/global-types'

type ShipmentRowProps = {
  shipment: Shipment
}
export default function ShipmentRow({ shipment }: ShipmentRowProps) {
  const { handleEditShipment, handleDeleteShipment } = useShipmentsActions({
    uuid: shipment.uuid,
    orderID: shipment.order_id,
  })

  const { shipment_date, carrier_name, invoice_id, tracking_number } = shipment

  const buttons = (
    <Can I="edit" a="shipment">
      <Stack direction="row" gap={1} justifyContent="flex-end">
        <Button variant="outlined" onClick={handleEditShipment(shipment)}>
          Edit
        </Button>
        <Button variant="outlined" onClick={handleDeleteShipment}>
          Delete
        </Button>
      </Stack>
    </Can>
  )

  const date = shipment_date ? localeDate(shipment_date) : '-'

  return (
    <TableRow>
      <TableCell>{date}</TableCell>
      <TableCell>{carrier_name ?? '-'}</TableCell>
      <TableCell>{invoice_id}</TableCell>
      <TableCell>{tracking_number}</TableCell>
      <TableCell>{buttons}</TableCell>
    </TableRow>
  )
}
