import { ChangeEvent, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import DeleteIcon from '@mui/icons-material/Delete'
import SyncAltIcon from '@mui/icons-material/SyncAltOutlined'
import {
  alpha,
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { upperCase } from 'lodash'

import productPlaceholderSmall from 'assets/img/productPlaceholderSmall.png'
import { ExternalLink } from 'components/ExternalLink'
import ControlledInput from 'components/Form/ControlledInput'
import { ReadonlyTextField } from 'components/Form/EditableTextField'
import { FieldsGroup } from 'components/FormHelpers'
import { LabelWithTooltip } from 'components/LabelWithTooltip'
import { ReadonlyField } from 'components/ReadonlyField'
import { Link } from 'components/StyledLink'
import { ProductItemWrapper } from 'pages/OrderEdit/components/OrderProductItem'
import { Order, OrderProductItem } from 'utils/global-types'
import {
  buyingPriceInputOptions,
  formatPercent,
  formatPrice,
  salesPriceInputOptions,
} from 'utils/price'
import {
  getPriceLabel,
  getShortProductLabel,
  ProductOption,
} from 'utils/product'

import { QuoteProductsFormValue } from './utils/helpers'
import PriceInSystemCurrency from './PriceInSystemCurrency'
import { QuoteFormI } from './QuoteForm'

const styles: Record<string, SxProps<Theme>> = {
  totalFieldStyles: {
    minWidth: '150px',
    '& p': {
      whiteSpace: 'nowrap',
      textAlign: 'right',
    },
    '& span': {
      width: '100%',
      textAlign: 'right',
    },
  },
  priceFieldStyles: {
    '& p': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right',
    },
    '& span': {
      width: '100%',
      textAlign: 'right',
    },
  },
  detailsSwitch: {
    position: 'absolute',
    left: '54px',
    top: '-6px',
  },
  notes: {
    mb: '0!important',
    flex: 2,
  },
  weightIcon: (theme) => ({
    cursor: 'help',
    color: alpha(theme.palette.primary.light, 0.5),
    transition: theme.transitions.create('color'),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }),
}
interface ProductItemProps {
  item: OrderProductItem
  currency: Order['currency']
  index: number
  onDelete: () => void
}

export default function ProductItem({
  item,
  currency,
  index,
  onDelete,
}: ProductItemProps) {
  const { setValue } = useFormContext<QuoteFormI>()

  const fieldName = (name: keyof QuoteProductsFormValue) =>
    `products.${index}.${name}` as const

  const onQuantityRequestChange = (e: ChangeEvent<HTMLInputElement>) => {
    const requestedQuantity = Number(e.target.value)
    // Round up to the next nearest increment satisfying quantity
    const offeredQuantity =
      Math.ceil(requestedQuantity / item.increment) * item.increment

    setValue(fieldName('quantity_offered'), offeredQuantity)
    setValue(fieldName('quantity_requested'), requestedQuantity)
  }

  const [isSalesPriceOverwrite, setSalesPriceCustom] = useState(
    item.sales_price === item.sales_price_relevant
  )

  useEffect(() => {
    if (item.sales_price_original === item.sales_price) {
      setSalesPriceCustom(false)
    }
  }, [item.sales_price_original, item.sales_price])

  const prices = {
    buying: formatPrice(item.buying_price, 'eur'),
    buyingOriginal: formatPrice(item.buying_price_original, 'eur'),
    listPrice: formatPrice(item.list_price_display, currency),
    listPriceSystem: formatPrice(item.list_price, 'eur'),
    salesPrice: formatPrice(item.sales_price, currency),
    salesPriceReadonly: formatPrice(item.sales_price_relevant, currency, {
      maximumDecimals: 4,
    }),
    webPrice: formatPrice(item.web_price_original_display, currency),
    webPriceSystem: formatPrice(item.web_price_original, 'eur'),
    total: formatPrice(item.total_sales_price_display, currency),
    totalSystem: formatPrice(item.total_sales_price, 'eur'),
  } as const

  const priceLabelOptions: ProductOption = {
    // TODO: Figure out how to get the price unit
    unit: item.product_unit,
    amount: item.price_amount,
  }

  return (
    <ProductItemWrapper direction="row">
      <Box sx={{ flex: 2 }}>
        <Typography fontWeight="bold">{index + 1}</Typography>
      </Box>

      <Box sx={{ flex: 98 }}>
        <Box
          component="header"
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Card sx={{ display: 'flex', boxShadow: 'none' }}>
            <CardMedia
              width={48}
              height={48}
              component="img"
              image={item.image || productPlaceholderSmall}
              alt="product image"
              sx={{
                width: 48,
              }}
            />
            <CardContent sx={{ p: 0, ml: 2 }}>
              <Link to={`/products/${item?.sku}`}>
                <Typography
                  whiteSpace="nowrap"
                  color="primary"
                  fontWeight="600"
                >
                  {getShortProductLabel(item)}
                </Typography>
              </Link>
              <Typography variant="body2" color="text.secondary">
                {item.series} {item.type} {item.product_category}
              </Typography>
            </CardContent>
          </Card>

          <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Typography
              color="text.secondary"
              variant="body2"
              sx={{ mr: 3, mt: 1 }}
            >
              <ExternalLink href={item.omnical_link} target="_blank">
                Omnical
              </ExternalLink>{' '}
              |{' '}
              <ExternalLink href={item.van_egmond_link} target="_blank">
                Van Egmond
              </ExternalLink>{' '}
              |{' '}
              <ExternalLink href={item.octopart_link} target="_blank">
                Octopart
              </ExternalLink>
            </Typography>
            <IconButton onClick={onDelete} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>

        <FieldsGroup pb={3}>
          <ReadonlyField label="Increment">{item.increment}</ReadonlyField>
          <ReadonlyField label="Unit">
            {item.product_amount} {item.product_unit}
          </ReadonlyField>
          <ReadonlyField label="Stock">{item.stock_level}</ReadonlyField>

          <Divider orientation="vertical" flexItem />

          <ReadonlyField label="Dimensions (LWH)">
            {item.dimensions}
          </ReadonlyField>
          <ReadonlyField label="Country">
            {item.country_of_origin}
          </ReadonlyField>
          <ReadonlyField label="HS">{item.hs_code}</ReadonlyField>

          <Divider orientation="vertical" flexItem />

          <ReadonlyField
            label={getPriceLabel('List price', priceLabelOptions)}
            sx={styles.priceFieldStyles}
          >
            {prices.listPrice}
            <PriceInSystemCurrency
              systemCurrencyPrice={prices.listPriceSystem}
              originalPrice={prices.listPrice}
            />
          </ReadonlyField>
          <ReadonlyField label="Buying discount" sx={styles.priceFieldStyles}>
            {formatPercent(item.discount_value)}
            {item.discount_group && (
              <Typography variant="caption" color="text.secondary">
                {item.discount_group}
              </Typography>
            )}
          </ReadonlyField>
          <ReadonlyField label="Item margin" sx={styles.priceFieldStyles}>
            {item.margin_percent.toFixed(1)}%
          </ReadonlyField>
        </FieldsGroup>

        <FieldsGroup>
          <ControlledInput
            id={`product-quantity-${item.product}`}
            name={fieldName('quantity_requested')}
            type="number"
            size="sm"
            inputProps={{ min: 0 }}
            onChange={onQuantityRequestChange}
            label={
              <LabelWithTooltip
                label="Qty. requested"
                tooltipText={item.qty_tooltip}
              />
            }
          />
          <ControlledInput
            name={fieldName('quantity_offered')}
            type="number"
            size="sm"
            inputProps={{ min: 0 }}
            label={
              <LabelWithTooltip
                label="Qty. offered"
                tooltipText={item.qty_tooltip}
              />
            }
          />
          <ControlledInput
            name={fieldName('weight_overwrite')}
            label="Weight (g)"
            type="number"
            size="xs"
            inputProps={{ min: 0 }}
            InputProps={{
              endAdornment: (
                <Tooltip disableInteractive title="Updated for the product">
                  <SyncAltIcon sx={styles.weightIcon} />
                </Tooltip>
              ),
            }}
          />
          <ControlledInput
            name={fieldName('lead_time_overwrite')}
            size="xs"
            label="Lead days"
          />
          <ControlledInput
            name={fieldName('buying_price')}
            label={getPriceLabel('Buying price', priceLabelOptions)}
            helperText={
              <PriceInSystemCurrency
                originalPrice={prices.buying}
                systemCurrencyPrice={prices.buyingOriginal}
                additionalText="Original:"
              />
            }
            type="number"
            inputProps={buyingPriceInputOptions}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">EUR</InputAdornment>
              ),
            }}
          />
          {isSalesPriceOverwrite ? (
            <ControlledInput
              name={fieldName('sales_price')}
              label={getPriceLabel('Sales price', priceLabelOptions)}
              type="number"
              inputProps={salesPriceInputOptions}
              helperText={
                <PriceInSystemCurrency
                  originalPrice={prices.webPrice}
                  systemCurrencyPrice={prices.webPriceSystem}
                  additionalText="Original:"
                  oneRowPrice
                />
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {upperCase(currency)}
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <ReadonlyTextField
              sx={{ flex: '1 1 100%', pl: 3, mt: '-6px' }}
              handleClick={() => setSalesPriceCustom(true)}
              label={getPriceLabel('Sales price', priceLabelOptions)}
              value={prices.salesPriceReadonly}
              helperText={
                <PriceInSystemCurrency
                  originalPrice={prices.webPrice}
                  systemCurrencyPrice={prices.webPriceSystem}
                  additionalText="Original:"
                  oneRowPrice
                />
              }
            />
          )}
          <ReadonlyField label="Total" sx={styles.totalFieldStyles}>
            <Stack>
              <Typography fontSize={18} fontWeight="bold">
                {prices.total}
              </Typography>
              <PriceInSystemCurrency
                originalPrice={prices.total}
                systemCurrencyPrice={prices.totalSystem}
              />
            </Stack>
          </ReadonlyField>
        </FieldsGroup>
        <Stack direction="row" pb={3}>
          <ReadonlyField label="Details" sx={{ flex: 1, position: 'relative' }}>
            {item.details_original}

            <Controller
              name={fieldName('is_details')}
              render={({ field }) => (
                <Switch
                  {...field}
                  size="small"
                  sx={styles.detailsSwitch}
                  defaultChecked={field.value}
                />
              )}
            />
          </ReadonlyField>
          <ControlledInput
            name={fieldName('notes')}
            label="Notes (optional)"
            multiline
            sx={styles.notes}
            minRows={3}
          />
        </Stack>
      </Box>
    </ProductItemWrapper>
  )
}
