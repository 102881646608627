import { startCase } from 'lodash'

import ErrorPageTemplate from 'pages/Error/ErrorPageTemplate'

type NotFoundViewProps = {
  type: string
}

export default function NotFoundView({ type }: NotFoundViewProps) {
  const email = 'support@omnical.co'

  return (
    <ErrorPageTemplate
      code={404}
      buttons={null}
      title={`${startCase(type)} not found or Access Denied`}
      message={
        <>
          Oops! It seems like the page you&apos;re looking for doesn&apos;t
          exist or you don&apos;t have permission to access it.
          <br />
          Contact <a href={`mailto:${email}`}>{email}</a>
        </>
      }
    />
  )
}
