import { Button } from '@mui/material'

import ErrorPageTemplate from './ErrorPageTemplate'

export default function UnknownError() {
  const refresh = () => window.location.reload()

  return (
    <ErrorPageTemplate
      code={500}
      title="Something went wrong!"
      message="Something went wrong on our end. Please try again later or contact our support team for assistance."
      buttons={
        <Button variant="contained" onClick={refresh}>
          Refresh page
        </Button>
      }
    />
  )
}
