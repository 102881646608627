import { Container, styled } from '@mui/material'

export default styled(Container)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  paddingBottom: 24,
  margin: 0,
  maxWidth: '100%',
  width: '100%',
}))
