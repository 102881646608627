import { useForm } from 'react-hook-form'
import {
  Button,
  Divider,
  InputAdornment,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import { AxiosError, isAxiosError } from 'axios'

import useConfigClient from 'api/config/useConfigClient'
import useUpdateConfig from 'api/config/useUpdateConfig'
import { LoadingButton } from 'components/Buttons'
import { ControlledInput } from 'components/Form'
import { Form } from 'components/FormHelpers'
import { ConfigClient } from 'utils/global-types'
import { parseFormError } from 'utils/helpers'

import MarginFieldsArray from './MarginFieldsArray'
import UploadButtons from './UploadButtons'

const LabelWrapper = styled(Stack)({
  flex: '0 0 344px',
})

type ConfigFormValues = ConfigClient

const getDefaultConfigFormValues = (config: ConfigClient) =>
  ({
    ...config,
    vat: Number(Number((config.vat as number) * 100).toFixed(4)),
  } as ConfigFormValues)

function ConfigsTab() {
  const { data: config } = useConfigClient()
  const { mutateAsync: updateConfig, isLoading } = useUpdateConfig()

  const form = useForm<ConfigFormValues>({
    defaultValues: config ? getDefaultConfigFormValues(config) : {},
  })

  const { handleSubmit, reset, setError } = form

  const onReset = () => {
    reset(getDefaultConfigFormValues(config!))
  }

  const onError = (error: AxiosError<Record<string, unknown>>) => {
    parseFormError(error, setError)
  }

  const onSubmit = handleSubmit(async (formValues) => {
    const newConfig: ConfigClient = {
      ...formValues,
      vat: Number(Number(formValues.vat / 100).toFixed(4)),
    }
    try {
      await updateConfig(newConfig)
    } catch (error) {
      if (isAxiosError(error)) {
        onError(error)
      }
    }
  })

  return (
    <Form handlers={form} onReset={onReset} onSubmit={onSubmit}>
      <Stack gap={3} width="100%">
        <UploadButtons />
        <Divider />
        <Typography fontWeight={500}>Shipping & Handling</Typography>
        <Stack direction="row" gap={3}>
          <LabelWrapper>
            <Typography variant="subtitle1">DHL - Express Worldwide</Typography>
            <Typography variant="body1" color="text.secondary">
              Provide Fuel and Emergency Surcharges
            </Typography>
          </LabelWrapper>
          <ControlledInput
            name="fuel_surcharge"
            label="DHL Fuel surcharge"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
          />
          <ControlledInput
            name="emergency_surcharge"
            label="DHL Emergency surcharge"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">EUR</InputAdornment>
              ),
            }}
          />
        </Stack>
        <Divider />
        <Stack direction="row" gap={3}>
          <LabelWrapper>
            <Typography variant="subtitle1">BENELUX</Typography>
            <Typography variant="body1" color="text.secondary">
              Provide Shipping & Handling details of BENELUX
            </Typography>
          </LabelWrapper>
          <Stack gap={3} flex={1}>
            <ControlledInput
              name="benelux_free_shipping_cutoff"
              label="Free shipping cutoff BENELUX"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">EUR</InputAdornment>
                ),
              }}
            />
            <ControlledInput
              name="default_shipping_cutoff"
              label="International (outside BENELUX) small order handling cut off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">EUR</InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack gap={3} flex={1}>
            <ControlledInput
              name="benelux_handling_fee"
              label="Handling fee for BENELUX shipping"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">EUR</InputAdornment>
                ),
              }}
            />
            <ControlledInput
              name="handling_fee"
              label="International (outside BENELUX) small order handling fee"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">EUR</InputAdornment>
                ),
              }}
            />
          </Stack>
        </Stack>
        <Divider />
        <Typography fontWeight={500}>Footer text</Typography>
        <Stack direction="row" gap={3}>
          <LabelWrapper>
            <Typography variant="subtitle1">Default footer text</Typography>
            <Typography variant="body1" color="text.secondary">
              Fill footer text for quotes and invoices
            </Typography>
          </LabelWrapper>
          <Stack gap={3} flex="1">
            <ControlledInput
              multiline
              name="quote_terms_template"
              label="Default footer template quotes"
            />
            <ControlledInput
              multiline
              name="payment_terms_template"
              label="Default payment template quotes and invoices"
            />
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="row" gap={3}>
          <LabelWrapper>
            <Typography variant="subtitle1">VAT</Typography>
            <Typography variant="body1" color="text.secondary">
              Write VAT in %
            </Typography>
          </LabelWrapper>
          <ControlledInput
            name="vat"
            label="VAT"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
          />
        </Stack>
        <Divider />
        <Typography fontWeight={500}>Margin Rules</Typography>
        <Stack direction="row" gap={3}>
          <LabelWrapper>
            <Typography variant="body1" color="text.secondary">
              Define pricing bands and the corresponding margins to apply for
              quotes and invoices.
            </Typography>
          </LabelWrapper>
          <MarginFieldsArray />
        </Stack>
        <Divider />
        <Stack direction="row" gap={3}>
          <Button variant="outlined" type="reset">
            Reset
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            isLoading={isLoading}
          >
            Save changes
          </LoadingButton>
        </Stack>
      </Stack>
    </Form>
  )
}

export default function () {
  const { isLoading } = useConfigClient()

  if (isLoading) {
    return <>Loading...</>
  }

  return <ConfigsTab />
}
