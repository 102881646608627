import { Button, ButtonProps, styled } from '@mui/material'
import { grey } from '@mui/material/colors'

export { default as Autocomplete } from './Autocomplete'
export { default as ControlledInput } from './ControlledInput'
export { default as DatePicker } from './DatePicker'
export { default as PasswordInput } from './PasswordInput'
export { default as Select } from './Select'
export { default as Switch } from './Switch'

export const BlackButton = styled(Button)<ButtonProps>(() => ({
  color: grey[900],
  borderColor: grey[900],
  '&:hover': {
    backgroundColor: grey[200],
    color: grey[900],
    borderColor: grey[900],
  },
}))
