import { useParams } from 'react-router-dom'
import { Box, Divider, Typography } from '@mui/material'

import useOrder from 'api/order/useOrder'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { FieldsGroup } from 'components/FormHelpers'
import { ReadonlyField } from 'components/ReadonlyField'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import { getValidityDaysLabel, localeDate } from 'utils/date'
import { getManufacturerNamesLabel, getStatusLabel } from 'utils/order'
import { formatPrice } from 'utils/price'

import QuoteForm from './QuoteForm'

export default function QuoteEditPage() {
  const { id: orderID } = useParams()

  const { data } = useOrder(orderID!, 'quote')
  const { order: query } = data!

  const title = `Edit Quote ${query.order_id}`

  return (
    <PageTemplate title={title}>
      <PageHeader sx={{ alignItems: 'center' }}>
        <Box>
          <Typography variant="h6">{title}</Typography>
          <Breadcrumbs />
        </Box>
        <ReadonlyField label="Status" noPadding>
          {getStatusLabel(query.status)}
        </ReadonlyField>
      </PageHeader>
      <Divider />
      <FieldsGroup sameWidth p={3} pt={4}>
        <ReadonlyField label="Quote">{query.order_id}</ReadonlyField>
        <ReadonlyField label="Estimated delivery">
          {query.lead_time ? `${query.lead_time} days` : 'Unknown'}
        </ReadonlyField>
        <ReadonlyField label="Brands">
          {getManufacturerNamesLabel(query.manufacturer_names)}
        </ReadonlyField>
        <ReadonlyField label="Created Date">
          {localeDate(query.created)}
        </ReadonlyField>
        <ReadonlyField label="Last Updated">
          {localeDate(query.updated)}
        </ReadonlyField>
        <ReadonlyField label="Validity">
          {getValidityDaysLabel(query.validity_days, query.expiration_date)}
        </ReadonlyField>
        <ReadonlyField label="Total amount" fontWeight="700">
          {formatPrice(query.total_amount, query.currency)}
        </ReadonlyField>
      </FieldsGroup>
      <QuoteForm />
    </PageTemplate>
  )
}
