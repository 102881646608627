import { Button, Typography } from '@mui/material'

import { QuoteResponse } from 'api/customers-portal/useQuote'

type ExtendQuoteFormProps = {
  quote: QuoteResponse
}

export default function ExtendQuoteForm({ quote }: ExtendQuoteFormProps) {
  return (
    <>
      <Typography fontSize={24} fontWeight="bold">
        Extend your quote {quote.quote_id}
      </Typography>
      <Typography>
        Your quote is valid until {quote.expiration_date}. When extending your
        quote we recalculate the prices based on the most recent manufacturer
        prices while maintaining the same discount you received before.
      </Typography>
      <Typography>
        Your new quote will be valid for {quote.validity_days} days from today.
      </Typography>
      <Button type="submit" variant="contained">
        Extend quote validity
      </Button>
    </>
  )
}
