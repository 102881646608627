import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, Tooltip } from '@mui/material'

import useShipmentsActions from 'api/shipping/useShipmentsActions'
import { Shipment } from 'utils/global-types'

type ShipmentTableActionButtonsProps = {
  shipment: Shipment
}

export default function ShipmentTableActionButtons({
  shipment,
}: ShipmentTableActionButtonsProps) {
  const { handleEditShipment, handleDeleteShipment } = useShipmentsActions({
    uuid: shipment?.uuid,
    orderID: shipment?.order_id,
  })
  return (
    <>
      <Tooltip title="Edit shipment details">
        <IconButton
          size="small"
          onClick={handleEditShipment(shipment)}
          sx={{ mt: '-10px', transform: 'translateY(5px)' }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete shipment">
        <IconButton
          size="small"
          onClick={handleDeleteShipment}
          sx={{ mt: '-10px', transform: 'translateY(5px)' }}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  )
}
