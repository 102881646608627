import { alpha } from '@mui/material'

import { Backdrop } from 'components/Backdrop'

type RelativeBackdropProps = {
  open: boolean
  transparent?: boolean
}

const RelativeBackdrop = ({ open, transparent }: RelativeBackdropProps) => (
  <Backdrop
    open={open}
    iconSx={{ mt: 'calc(50vh - 20px)' }}
    sx={(theme) => ({
      position: 'absolute',
      zIndex: theme.zIndex.appBar - 1,
      alignItems: 'flex-start',
      background: transparent
        ? alpha(theme.palette.common.white, 0.88)
        : theme.palette.common.white,
    })}
  />
)

export default RelativeBackdrop
