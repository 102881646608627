import { useParams } from 'react-router-dom'
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
  SxProps,
  Theme,
} from '@mui/material'

import useUpdateOrderLabels from 'api/order/useUpdateOrderLabels'
import { getStatusLabel } from 'utils/order'

const labelsMap = {
  quote: ['ISSUED', 'REJECTED', 'NEED_REVIEW', 'NEED_DISCOUNT', 'EXPIRED'],
  order: ['EXPIRED', 'CANCELLED'],
} as const

export type LabelName = (typeof labelsMap)['quote' | 'order'][number]

const ChipsWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 4,
})

const StyledCircularProgress = styled(CircularProgress)({
  position: 'absolute',
  right: 32,
  color: 'inherit',
})

const menuStyle: SxProps<Theme> = (theme) => ({
  zIndex: theme.zIndex.appBar - 2,
})

const InputSelect = styled(OutlinedInput)(({ theme }) => ({
  height: theme.spacing(5),
  '.MuiSelect-select': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

type LabelSelectProps = {
  type?: 'quote' | 'order'
}

export default function LabelSelect({ type = 'quote' }: LabelSelectProps) {
  const { id: orderID } = useParams()
  const { labels, error, isError, isLoading, updateLabels, removeLabel } =
    useUpdateOrderLabels(orderID!)

  const handleChange = (event: SelectChangeEvent<LabelName[]>) => {
    const { value } = event.target
    const newLabels = (value as LabelName[]).reverse()
    updateLabels(newLabels as LabelName[])
  }

  const renderChip = (label: LabelName) => (
    <Chip
      size="small"
      key={label}
      label={getStatusLabel(label)}
      onDelete={() => removeLabel(label)}
      // Needs to make deleting label possible
      onMouseDown={(event) => event.stopPropagation()}
    />
  )

  // @ts-expect-error Labels is the only possible error backend can return
  const errorText = isError && error.labels[0]

  return (
    <FormControl
      size="small"
      error={isError}
      sx={{ width: 'fit-content', minWidth: 300 }}
    >
      <InputLabel>Labels</InputLabel>
      <Select
        multiple
        error={isError}
        sx={{ pr: 3, maxHeight: '56px' }}
        value={labels}
        onChange={handleChange}
        input={<InputSelect label="Labels" />}
        endAdornment={isLoading ? <StyledCircularProgress size={20} /> : null}
        MenuProps={{ sx: menuStyle }}
        renderValue={(selected) => (
          <ChipsWrapper>{selected.map(renderChip)}</ChipsWrapper>
        )}
      >
        {labelsMap[type].map((label) => (
          <MenuItem key={label} value={label}>
            {getStatusLabel(label)}
          </MenuItem>
        ))}
      </Select>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  )
}
