import { Box, CSSObject, Theme } from '@mui/material'

import logo from 'assets/img/logo-blue.svg?url'

const logoStyles = (theme: Theme): CSSObject => ({
  padding: theme.spacing(1.5, 3),
})

export default function Logo() {
  return <Box component="img" sx={logoStyles} src={logo} />
}
