import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import InboxIcon from '@mui/icons-material/Inbox'
import {
  Button,
  Container,
  styled,
  Table,
  TableBody,
  Typography,
} from '@mui/material'

import useCreateOrderProductItem from 'api/product-item/useCreateOrderProductItem'
import useOrderProductItems from 'api/product-item/useOrderProductItems'
import { ReadonlyField } from 'components/ReadonlyField'
import { StyledTableContainer } from 'components/Table/TableContainer'
import TableHead from 'components/Table/TableHead'
import { HeadCell } from 'components/Table/types'
import { GeneralStats, GeneralWrapper } from 'components/Wrappers'
import { useDialog } from 'context/Dialog/DialogContext'
import { Order, ProductItem } from 'utils/global-types'
import { formatPrice } from 'utils/price'
import { parseNullableNumber } from 'utils/validations'

import { addProductDialog } from '../dialogs'
import { AddProductFormI } from '../dialogs/AddProductForm'

import ProductItemRow from './ProductItemRow'

const ProductsContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  columnGap: 2,
  padding: theme.spacing(3, 3, 2),
}))

const BlackButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderColor: theme.palette.text.primary,
  '&:hover': {
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.primary,
  },
}))

const productsTableColumns: HeadCell<ProductItem>[] = [
  { value: 'ordering', label: 'Pos.' },
  { value: 'sku', label: 'SKU' },
  { value: 'manufacturer_name', label: 'Brand' },
  { value: 'manufacturer_part_number', label: 'Part #' },
  { value: 'quantity_requested', label: 'Ordered' },
  { value: 'quantity_reserved', label: 'Reserved' },
  { value: 'quantity_delivered', label: 'Delivered' },
  { value: 'quantity_invoiced', label: 'Invoiced' },
  { value: 'stock_level', label: 'Available' },
  { value: '', label: '' },
]

interface ProductsSectionProps {
  order: Order
}

interface CreateInvoiceFormValues {
  toInvoice: Record<number, string>
}

export default function ProductsSection({ order }: ProductsSectionProps) {
  const { currency, order_id } = order

  const { data: productItems } = useOrderProductItems(order_id)
  const [openDialog] = useDialog()
  const form = useForm<CreateInvoiceFormValues>()
  const isInvoicedMargin =
    order.invoiced_margin !== null &&
    order.invoiced_margin !== order.total_margin
  const margin = order.invoiced_margin || order.total_margin
  const marginPercent =
    order.invoiced_margin_percent || order.total_margin_percent

  const { mutateAsync: addNewProduct } = useCreateOrderProductItem(order_id)

  const handleAddProduct = async () => {
    const productToAdd = (await openDialog(addProductDialog)) as
      | AddProductFormI
      | boolean

    if (typeof productToAdd !== 'boolean') {
      const isProductAlreadyAdded = productItems?.some(
        (item) => item.product === productToAdd.product
      )

      if (isProductAlreadyAdded) {
        return
      }

      const { lead_time_overwrite } = productToAdd

      await addNewProduct({
        ...productToAdd,
        order: order.uuid,
        product: productToAdd.product!,
        lead_time_overwrite: parseNullableNumber(lead_time_overwrite ?? ''),
      })
    }
  }

  useEffect(() => {
    if (productItems) {
      form.setValue(
        'toInvoice',
        productItems.reduce(
          (acc, item) => ({
            ...acc,
            [item.ordering]: item.quantity_requested,
          }),
          {}
        )
      )
    }
  }, [productItems])

  return (
    <ProductsContainer>
      <FormProvider {...form}>
        <GeneralWrapper>
          <InboxIcon />
          <Typography pl={2} py={1} flex={1}>
            Products
          </Typography>
          <BlackButton variant="outlined" onClick={handleAddProduct}>
            Add Product
          </BlackButton>
          <GeneralStats sx={{ mt: 2 }}>
            <ReadonlyField label="Total amount">
              {formatPrice(order.total_amount, currency)}
            </ReadonlyField>
            <ReadonlyField label="Costs">
              {formatPrice(order.supplier_cost, 'eur')}
            </ReadonlyField>
            <ReadonlyField label="Lump sum">
              {formatPrice(order.discount_lump_sum, currency)}
            </ReadonlyField>
            <ReadonlyField label="Margin">
              {marginPercent}% ({formatPrice(margin, 'eur')})
              {isInvoicedMargin && (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ textDecorationLine: 'line-through' }}
                >
                  <br />
                  {order.total_margin_percent}% (
                  {formatPrice(order.total_margin, 'eur')})
                </Typography>
              )}
            </ReadonlyField>
          </GeneralStats>
        </GeneralWrapper>

        <StyledTableContainer>
          <Table stickyHeader>
            <TableHead columns={productsTableColumns} />
            <TableBody>
              {productItems?.map((item) => (
                <ProductItemRow
                  key={item.uuid}
                  product={item}
                  orderID={order.order_id}
                />
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </FormProvider>
    </ProductsContainer>
  )
}
