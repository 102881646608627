import { Container, styled } from '@mui/material'

export default styled(Container)(({ theme }) => ({
  flexBasis: '100%',
  paddingLeft: 40,
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),

  '& > *': {
    flex: '1 1 100%',
  },
}))
