import toast from 'react-hot-toast'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { Organization } from 'utils/global-types'

type EditOrganizationResponse = Organization

export type EditOrganizationVariables = Partial<Omit<Organization, 'uuid'>>

function createEditOrganization(uuid: Organization['uuid']) {
  const editOrganization: MutationFunction<
    EditOrganizationResponse,
    EditOrganizationVariables
  > = async (organization) => {
    const response = await axiosInstance.patch<EditOrganizationResponse>(
      `/customers/organizations/${uuid}/`,
      organization
    )

    return response.data
  }

  return editOrganization
}

const useEditOrganization = (uuid: Organization['uuid']) => {
  const queryClient = useQueryClient()

  return useMutation<
    Organization,
    AxiosError<Record<string, string[]>>,
    EditOrganizationVariables
  >(createEditOrganization(uuid), {
    onSuccess: () => {
      queryClient.invalidateQueries(['organizations'])
      queryClient.invalidateQueries(['organization', uuid])
      toast.success('Organization details updated successfully')
    },
    meta: {
      message: 'Failed to update organization details',
      useGlobalError: false,
    },
  })
}

export default useEditOrganization
