import { Component, PropsWithChildren, ReactNode } from 'react'

import { isForbiddenError } from 'configs/axios'

type ErrorBoundaryProps = PropsWithChildren<{
  fallback: ReactNode | (({ error }: { error: Error }) => ReactNode)
}>

type ErrorBoundaryState = {
  error: Error | null
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error }
  }

  render() {
    const { error } = this.state
    const { children, fallback } = this.props

    if (error && !isForbiddenError(error)) {
      if (typeof fallback === 'function') {
        return fallback({ error })
      }
      return fallback
    }

    return children
  }
}
