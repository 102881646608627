import { MutateFunction, useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { uninterceptedAxios } from 'configs/axios'

type LogoutResponse = boolean
type LogoutVariables = void

const postLogout: MutateFunction<
  boolean,
  AxiosError,
  LogoutVariables
> = async () => {
  const response = await uninterceptedAxios.post('/users/logout/', {})

  return response.status === 204
}

const useLogout = () => {
  return useMutation<LogoutResponse, AxiosError, LogoutVariables>({
    mutationFn: postLogout,
    onSuccess: () => {
      window.location.reload()
    },
  })
}

export default useLogout
