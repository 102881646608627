import { Typography } from '@mui/material'

type PriceInSystemCurrencyProps = {
  systemCurrencyPrice: string
  originalPrice?: string
  additionalText?: string
  oneRowPrice?: boolean
  alwaysShow?: boolean
}

export default function PriceInSystemCurrency({
  originalPrice,
  systemCurrencyPrice,
  additionalText,
  oneRowPrice,
  alwaysShow,
}: PriceInSystemCurrencyProps) {
  if (oneRowPrice) {
    return (
      <Typography variant="caption" color="text.secondary" whiteSpace="nowrap">
        {additionalText} {originalPrice}
        {originalPrice !== systemCurrencyPrice
          ? `(${systemCurrencyPrice})`
          : ''}
      </Typography>
    )
  }

  if (originalPrice === systemCurrencyPrice && !alwaysShow) {
    return null
  }

  return (
    <Typography variant="caption" color="text.secondary" whiteSpace="nowrap">
      {additionalText} {systemCurrencyPrice}
    </Typography>
  )
}
