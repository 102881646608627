import useInvoice from 'api/invoice/useInvoice'
import useOrder from 'api/order/useOrder'
import { Invoice, Order } from 'utils/global-types'

type Balances = {
  organizationBalance: Order['balance']
  amountInvoiced: Invoice['outstanding_amount']
  orderBalance: Invoice['order_outstanding_amount']
  currency: Order['currency']
  totalPaid: Invoice['total_paid_amount']
}

type UseBalancesReturn =
  | {
      isLoading: true
      balances: undefined
    }
  | {
      isLoading: false
      balances: Balances
    }

export const useBalances = (
  invoiceUUID: Invoice['uuid'],
  orderID: Order['order_id']
): UseBalancesReturn => {
  const { data: orderData, isLoading: isOrderLoading } = useOrder(orderID)
  const { data: invoice, isLoading: isInvoiceLoading } = useInvoice(invoiceUUID)

  if (isOrderLoading || isInvoiceLoading) {
    return {
      isLoading: true,
      balances: undefined,
    }
  }

  return {
    isLoading: false,
    balances: {
      organizationBalance: orderData!.order.balance,
      amountInvoiced: invoice!.outstanding_amount,
      totalPaid: invoice!.total_paid_amount,
      orderBalance: invoice!.order_balance,
      currency: orderData!.order.currency,
    },
  }
}
