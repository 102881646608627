import { SyntheticEvent } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  styled,
  Typography,
} from '@mui/material'
import { startCase } from 'lodash'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Table, useTable } from 'components/Table'
import { TableFilters } from 'components/Table/types'
import { LinkTabI, LinkTabs } from 'components/Tabs'
import { PageTemplate } from 'pages/Layout/PageTemplate'

import { tableColumnsVariants } from './utils'

const customerTabs: LinkTabI[] = [
  {
    label: 'Customers',
    path: '/customers',
  },
  {
    label: 'Organizations',
    path: '/organizations',
  },
]

const PageHeader = styled(Container)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  paddingBottom: 24,
})

export interface CustomersAndOrganizationsListPageProps {
  defaultTab: 'customers' | 'organizations'
}

export default function CustomersListPage({
  defaultTab,
}: CustomersAndOrganizationsListPageProps) {
  const [searchParams] = useSearchParams()

  const customers = useTable('customers', '/customers/customers/', {
    columns: tableColumnsVariants.customers,
  })
  const organizations = useTable('organizations', '/customers/organizations/', {
    columns: tableColumnsVariants.organizations,
  })

  const table = defaultTab === 'customers' ? customers : organizations

  const handleIncludesInactiveChange = (
    event: SyntheticEvent,
    checked: boolean
  ) => {
    const filters: TableFilters = checked ? {} : { is_active: true }
    customers.config.setFilters(filters)
    organizations.config.setFilters(filters)
  }

  const extraToolbarContent = (
    <FormControlLabel
      sx={{ pl: 2 }}
      control={<Checkbox defaultChecked={!searchParams.has('is_active')} />}
      label="Show inactive"
      onChange={handleIncludesInactiveChange}
    />
  )

  return (
    <PageTemplate title={startCase(defaultTab)}>
      <PageHeader>
        <Typography variant="h6">All {defaultTab}</Typography>
        <Box>
          <Button
            sx={{ marginRight: 1 }}
            variant="contained"
            component={Link}
            to="/customers/new"
            startIcon={<AddIcon />}
          >
            Add Customer
          </Button>
        </Box>
        <Breadcrumbs />
      </PageHeader>

      {/* TODO Fix this ts issue */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Table
        {...table}
        dataType={defaultTab}
        tabsComponent={<LinkTabs tabs={customerTabs} />}
        extraToolbarContent={extraToolbarContent}
        disableAddButton={defaultTab === 'organizations'}
      />
    </PageTemplate>
  )
}
