import { ChangeEvent } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'
import { upperCase } from 'lodash'

import productPlaceholderSmall from 'assets/img/productPlaceholderSmall.png'
import { ControlledInput } from 'components/Form'
import { FieldsGroup } from 'components/FormHelpers'
import { LabelWithTooltip } from 'components/LabelWithTooltip'
import { ReadonlyField } from 'components/ReadonlyField'
import { Link } from 'components/StyledLink'
import PriceInSystemCurrency from 'pages/QuoteEdit/PriceInSystemCurrency'
import {
  Currency,
  DiscountGroup,
  Order,
  OrderProductItemCreate,
  Product,
} from 'utils/global-types'
import {
  buyingPriceInputOptions,
  formatPrice,
  salesPriceInputOptions,
} from 'utils/price'
import {
  getPriceLabel,
  getShortProductLabel,
  ProductOption,
} from 'utils/product'

export const ProductItemWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingBottom: 0,
  marginBottom: 0,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius * 2,
}))

const styles: Record<string, SxProps<Theme>> = {
  totalFieldStyles: {
    minWidth: '150px',
    '& p': {
      whiteSpace: 'nowrap',
      textAlign: 'right',
    },
    '& span': {
      right: 0,
    },
  },
  priceFieldStyles: {
    '& p': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'right',
    },
    '& span': {
      right: 0,
    },
  },
}

type OrderProductItemProps = {
  product: Product
  currency: Currency
  discountGroup?: DiscountGroup
  exchangeRate: Order['exchange_rate']
  onDelete: () => void
}

export default function OrderProductItem({
  product,
  currency,
  discountGroup,
  exchangeRate,
  onDelete,
}: OrderProductItemProps) {
  const { setValue } = useFormContext<OrderProductItemCreate>()

  const onQuantityRequestChange = (e: ChangeEvent<HTMLInputElement>) => {
    const requestedQuantity = Number(e.target.value)
    // Round up to the next nearest increment satisfying quantity
    const offeredQuantity =
      Math.ceil(requestedQuantity / product.increment) * product.increment

    setValue('quantity_offered', offeredQuantity)
    setValue('quantity_requested', requestedQuantity)
  }

  const priceLabelOptions: ProductOption = {
    unit: product.product_unit,
    amount: product.product_amount,
  }

  const listPriceInOrderCurrency = formatPrice(
    product.list_prices[currency],
    currency
  )

  const salesPrice = useWatch({
    name: 'sales_price',
    defaultValue: product.sales_prices[currency],
  })
  const quantity = useWatch({
    name: 'quantity_offered',
    defaultValue: product.minimum_order_quantity,
  })
  const total = salesPrice * quantity
  const totalSystem = (salesPrice / exchangeRate) * quantity

  return (
    <ProductItemWrapper gap={2}>
      <Stack direction="row" gap={2}>
        <Box
          width={48}
          height={48}
          component="img"
          src={product.image || productPlaceholderSmall}
        />
        <Stack mr="auto">
          <Link to={`/products/${product.sku}`}>
            <Typography whiteSpace="nowrap" color="primary" fontWeight="600">
              {getShortProductLabel(product)}
            </Typography>
          </Link>
          <Typography variant="body2" color="text.secondary">
            {product.series} {product.type} {product.product_category}
          </Typography>
        </Stack>
        {/* TODO: Add external links */}
        <IconButton onClick={onDelete} aria-label="delete">
          <DeleteIcon />
        </IconButton>
      </Stack>
      <FieldsGroup>
        <ReadonlyField label="Increment">{product.increment}</ReadonlyField>
        <ReadonlyField label="Unit">{product.product_unit}</ReadonlyField>
        <ReadonlyField label="Stock">{product.stock_level}</ReadonlyField>
        <Divider orientation="vertical" flexItem />
        <ReadonlyField label="Dimensions (LWH)">
          {product.dimensions}
        </ReadonlyField>
        <ReadonlyField label="Country">
          {product.country_of_origin}
        </ReadonlyField>
        <ReadonlyField label="HS">{product.hs_code}</ReadonlyField>

        <Divider orientation="vertical" flexItem />

        <ReadonlyField
          label={getPriceLabel('List price', priceLabelOptions)}
          sx={styles.priceFieldStyles}
        >
          {listPriceInOrderCurrency}
          <PriceInSystemCurrency
            systemCurrencyPrice={formatPrice(product.list_price, 'eur')}
            originalPrice={listPriceInOrderCurrency}
          />
        </ReadonlyField>
        <ReadonlyField label="Discount" sx={styles.priceFieldStyles}>
          {discountGroup!.discount * 100}%
        </ReadonlyField>
      </FieldsGroup>
      <FieldsGroup>
        <ControlledInput
          name="quantity_requested"
          defaultValue={product.minimum_order_quantity}
          type="number"
          size="sm"
          inputProps={{ min: 0 }}
          onChange={onQuantityRequestChange}
          label={
            <LabelWithTooltip
              label="Qty. requested"
              tooltipText={product.qty_tooltip}
            />
          }
        />
        <ControlledInput
          name="quantity_offered"
          defaultValue={product.minimum_order_quantity}
          type="number"
          size="sm"
          inputProps={{ min: 0 }}
          label={
            <LabelWithTooltip
              label="Qty. offered"
              tooltipText={product.qty_tooltip}
            />
          }
        />
        <ControlledInput
          label="Weight (g)"
          name="weight_overwrite"
          defaultValue={product.weight_overwrite || product.weight}
          type="number"
          size="xs"
          inputProps={{ min: 0 }}
        />
        <ControlledInput
          label="Lead days"
          name="lead_time_overwrite"
          defaultValue={product.standard_lead_time}
          size="xs"
        />
        <ControlledInput
          name="buying_price"
          defaultValue={product.buying_price}
          label={getPriceLabel('Buying price', priceLabelOptions)}
          type="number"
          inputProps={buyingPriceInputOptions}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">EUR</InputAdornment>
            ),
          }}
        />
        <ControlledInput
          name="sales_price"
          defaultValue={product.sales_prices[currency]}
          label={getPriceLabel('Sales price', priceLabelOptions)}
          type="number"
          inputProps={salesPriceInputOptions}
          helperText={
            <PriceInSystemCurrency
              originalPrice={formatPrice(
                product.sales_prices[currency],
                currency
              )}
              systemCurrencyPrice={formatPrice(product.web_price, 'eur')}
              additionalText="Original:"
              oneRowPrice
            />
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {upperCase(currency)}
              </InputAdornment>
            ),
          }}
        />
        <ReadonlyField label="Total" sx={styles.totalFieldStyles}>
          <Stack>
            <Typography fontSize={18} fontWeight="bold">
              {formatPrice(total, currency)}
            </Typography>
            <PriceInSystemCurrency
              originalPrice={formatPrice(total, currency)}
              systemCurrencyPrice={formatPrice(totalSystem, 'eur')}
            />
          </Stack>
        </ReadonlyField>
      </FieldsGroup>
    </ProductItemWrapper>
  )
}
