import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'

import CustomersProvider from 'app/CustomersProvider'
import CustomerActionView from 'components/CustomerActionView'
import CancelOrderPage from 'pages/CancelOrderPage'
import DeclineQuotePage from 'pages/DeclineQuote'
import ExtendQuotePage from 'pages/ExtendQuote'
import RequestPaymentPage from 'pages/RequestPayment'

import ErrorView from './ErrorView'
import { loaders } from './loaders'
import NotFoundView from './NotFoundView'

export const routerConfig = createRoutesFromElements(
  <Route element={<CustomersProvider />}>
    <Route element={<CustomerActionView />}>
      <Route path=":id" errorElement={<ErrorView />}>
        <Route index element={<NotFoundView type="page" />} />
        <Route path="*" element={<NotFoundView type="page" />} />
        <Route
          path="request-invoice"
          element={<RequestPaymentPage />}
          loader={loaders.quote}
        />
        <Route
          path="decline-quote"
          loader={loaders.quote}
          element={<DeclineQuotePage />}
        />
        <Route
          path="extend-quote"
          loader={loaders.quote}
          element={<ExtendQuotePage />}
        />
        <Route
          path="cancel-order"
          loader={loaders.order}
          element={<CancelOrderPage />}
        />
      </Route>
    </Route>
  </Route>
)

const router = createBrowserRouter(routerConfig)

export default router
