import { orderDetailsQueryOptions } from 'api/customers-portal/useOrder'
import { quoteDetailsQueryOptions } from 'api/customers-portal/useQuote'
import { createLoader } from 'routing/common'

const order = createLoader(async ({ params }, queryClient) => {
  if (params.id) {
    return await queryClient.ensureQueryData(
      orderDetailsQueryOptions(params.id)
    )
  }
  return true
})

const quote = createLoader(async ({ params }, queryClient) => {
  if (params.id) {
    return await queryClient.ensureQueryData(
      quoteDetailsQueryOptions(params.id)
    )
  }
  return true
})

export const loaders = {
  order,
  quote,
} as const
