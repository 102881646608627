import * as yup from 'yup'

import { nanableNumber, numberOrNull } from 'utils/validations'

const productItemSchema = yup.object({
  quantity_requested: yup.number().typeError('Should be number'),
  quantity_offered: yup.number().typeError('Should be number'),
  weight_overwrite: nanableNumber,
  lead_time_overwrite: numberOrNull,
  buying_price: yup.number().typeError('Should be number'),
  sales_price: yup.number().typeError('Should be number'),
  is_details: yup.boolean(),
  notes: yup.string(),
})

const quoteSchema = yup.object({
  customer: yup.string().required(),
  validity_days: yup.number().typeError('Validity days should be number'),
  rfq_id: yup.string(),
  shipping_method: yup.string().nullable(),
  shipping_address: yup.string().nullable(),
  shipping_instructions: yup.string(),
  billing_address: yup.string().nullable(),
  is_charge_vat: yup.boolean(),
  currency: yup.string(),
  advance_limit: yup.number().typeError('Should be number'),
  upon_shipping_limit: yup.number().typeError('Should be number'),
  net_limit: yup.number().typeError('Should be number'),
  terms_notes: yup.string(),
  quote_terms_template: yup.string(),
  payment_terms_template: yup.string(),
  discount_lump_sum: yup.number().typeError('Should be number'),
  shipping_fee_overwrite: yup.string().nullable(),
})

export const quoteFormSchema = yup.object({
  products_search: yup.string(),
  customer_search: yup.string(),
  quote: quoteSchema,
  products: yup.array().of(productItemSchema),
})
