import { useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  styled,
} from '@mui/material'
import { get, intersectionBy } from 'lodash'

import useOrder from 'api/order/useOrder'
import useCreateOrderProductItem from 'api/product-item/useCreateOrderProductItem'
import useOrderProductItems from 'api/product-item/useOrderProductItems'
import ControlledInput from 'components/Form/ControlledInput'
import { ReadonlyField } from 'components/ReadonlyField'
import { Search } from 'components/Search'
import { Product, ProductListItem } from 'utils/global-types'
import { useFocusSearch, usePrevious } from 'utils/hooks'
import { formatPrice, marginPercentsInputOptions } from 'utils/price'
import { getProductLabel } from 'utils/product'

import { useDeleteProductItem } from './hooks'
import { QuoteFormI } from './QuoteForm'
import ProductItem from './QuoteProductItem'
import WarningMargin from './WarningMargin'

const ProductsGeneralInfo = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2.5, 3.5, 0),
  backgroundColor: theme.palette.action.hover,
  borderRadius: 8,

  '& .MuiTextField-root': {
    marginBottom: 0,
  },
}))

export default function ProductItemList({ isVisible }: { isVisible: boolean }) {
  const { id: orderID } = useParams()
  const searchRef = useFocusSearch()
  const { getValues, control, setValue, watch } = useFormContext<QuoteFormI>()
  const { fields, append, remove } = useFieldArray<QuoteFormI>({
    control,
    name: 'products',
  })
  const { data: productItems } = useOrderProductItems(orderID)
  const { data } = useOrder(orderID!, 'quote')
  const { order: quoteQueryData } = data ?? {}
  const quoteUUID = quoteQueryData?.uuid
  const previousProductItems = usePrevious(productItems)

  const {
    total_margin = 0,
    total_margin_percent = 0,
    subtotal_amount = 0,
    total_buying_amount = 0,
    currency = 'eur',
    recommended_margin,
  } = quoteQueryData ?? {}

  const marginOverwrite = watch('quote.margin_percent_overwrite')
  const isMarginRecommended = marginOverwrite === recommended_margin
  const margin_info = `${total_margin_percent.toFixed(1)}% (${formatPrice(
    total_margin,
    'eur'
  )})`

  const { mutateAsync: createProductItem } = useCreateOrderProductItem(orderID!)
  const { mutateAsync: deleteProductItem } = useDeleteProductItem(orderID!)

  const onProductAdd = (value: ProductListItem) => {
    addItem(value.uuid)
  }

  const addItem = async (productID: Product['uuid']) => {
    const customer = getValues('quote.customer')

    if (!customer) {
      toast.error('Please select a customer first')
      return
    }

    const isAlreadySelectedItem = productItems?.some(
      (item) => item.product === productID
    )

    if (isAlreadySelectedItem) {
      toast.error('This product is already in the list')
      const input = document.querySelector<HTMLInputElement>(
        `#product-quantity-${productID}`
      )
      input?.focus()
      return
    }

    try {
      const createdItem = await createProductItem({
        order: quoteUUID!,
        product: productID,
      })

      if (createdItem) {
        append({
          ...createdItem,
          lead_time_overwrite: String(createdItem.lead_time_overwrite),
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    /* 
      Hook is used here, because we don't know when productItem will be rendered on the page.
      And we can't scroll to the bottom of the page before it's rendered.
    */
    if (
      isVisible &&
      get(previousProductItems, 'length', 0) < get(productItems, 'length', 0)
    ) {
      scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [productItems, previousProductItems, isVisible])

  const removeItem = (uuid: string, index: number) => async () => {
    try {
      const isItemDeleted = await deleteProductItem(uuid)

      if (isItemDeleted) {
        remove(index)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const setRecommendedMargin = () => {
    if (recommended_margin) {
      setValue('quote.margin_percent_overwrite', recommended_margin)
    }
  }

  const items = intersectionBy(productItems, fields, 'uuid')

  return (
    <Stack p={3.5} gap={3} width="100%" display={isVisible ? 'flex' : 'none'}>
      <ProductsGeneralInfo container columnSpacing={1} columns={10}>
        <Grid item xs={2}>
          <ReadonlyField label="Order Value">
            {formatPrice(subtotal_amount, currency)}
          </ReadonlyField>
        </Grid>
        <Grid item xs={2}>
          <ReadonlyField label="Costs">
            {formatPrice(total_buying_amount, 'eur')}
          </ReadonlyField>
        </Grid>
        <Grid item xs={2}>
          <ReadonlyField label="Margin">{margin_info}</ReadonlyField>
        </Grid>
        <Grid item xs={2}>
          <ControlledInput
            name="quote.margin_percent_overwrite"
            label="Margin overwrite %"
            type="number"
            inputProps={marginPercentsInputOptions}
          />
        </Grid>
        <Grid item xs={2}>
          <ControlledInput
            name="quote.discount_lump_sum"
            label="Lump sum discount"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {currency.toUpperCase()}
                </InputAdornment>
              ),
            }}
            inputProps={{ min: 0 }}
          />
        </Grid>
        {recommended_margin && !isMarginRecommended && (
          <Grid container item xs={10} columnSpacing={1} columns={10} mb={1}>
            <Grid item xs={6} />
            <Grid item xs={4}>
              <Button size="small" onClick={setRecommendedMargin}>
                Use recommended margin {recommended_margin}%
              </Button>
            </Grid>
          </Grid>
        )}
      </ProductsGeneralInfo>
      {items.length > 0 && (
        <WarningMargin marginPercent={total_margin_percent} />
      )}
      {items.map((item, index) => (
        <ProductItem
          key={item.uuid + item.sales_price}
          item={{
            ...item,
            lead_time_overwrite: Number(item.lead_time_overwrite),
          }}
          currency={currency}
          index={index}
          onDelete={removeItem(item.uuid, index)}
        />
      ))}
      <Divider />
      <Search
        inputRef={searchRef}
        isProductSearch
        name="products_search"
        label="Find a product"
        url="/products/search/"
        size="medium"
        onChange={onProductAdd}
        getOptionLabel={getProductLabel}
        getOptionDisabled={({ is_active }) => !is_active}
      />
    </Stack>
  )
}
