import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { IconButton, Tooltip } from '@mui/material'

import useShipmentsActions from 'api/shipping/useShipmentsActions'
import { Invoice } from 'utils/global-types'

type CreateShipmentButtonProps = {
  invoice: Invoice
}

export default function CreateShipmentButton({
  invoice,
}: CreateShipmentButtonProps) {
  const { handleShipInvoice } = useShipmentsActions({
    uuid: invoice.uuid,
    orderID: '',
  })

  const shipmentIsNotAvailable =
    invoice.is_credited ||
    invoice.type !== 'DEBIT' ||
    invoice.shipment_status === 'SHIPPED' ||
    invoice.is_blocked_shipping

  if (shipmentIsNotAvailable) {
    return null
  }

  return (
    <Tooltip title="Create shipment">
      <IconButton
        onClick={handleShipInvoice(invoice)}
        sx={{ mt: '-10px', transform: 'translateY(5px)' }}
      >
        <AddCircleOutlineIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}
