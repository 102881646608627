import { ReactElement } from 'react'
import {
  Paper,
  styled,
  Table,
  TableContainer as MuiTableContainer,
} from '@mui/material'
import { useIsFetching, UseQueryResult } from '@tanstack/react-query'

import { RelativeBackdrop } from 'components/Backdrop'
import { ListReturnType } from 'utils/global-types'

import NoResultsPlaceholder from './NoResultsPlaceholder'
import TableBody from './TableBody'
import TableHead from './TableHead'
import TablePagination from './TablePagination'
import TableToolbar from './TableToolbar'
import { TableConfig, TableOptions, TableRow } from './types'

export type TableProps<Data extends TableRow> = {
  query: UseQueryResult<ListReturnType<Data>>
  queryKey: string
  options: TableOptions<Data>
  config?: TableConfig
  dataType: string
  tabsComponent?: ReactElement
  extraToolbarContent?: ReactElement
  extraPaginationContent?: ReactElement
  disableAddButton?: boolean
  searchPlaceholder?: string
  actionButton?: ReactElement
  hideSearch?: boolean
  noElevation?: boolean
  hidePagination?: boolean
}

const StyledTablePaper = styled(Paper)({
  flexGrow: 100,
})

export const StyledTableContainer = styled(MuiTableContainer)({
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
})

export default function TableContainer<Data extends TableRow>(
  props: TableProps<Data>
) {
  const {
    query,
    queryKey,
    options,
    config,
    dataType,
    tabsComponent,
    extraToolbarContent,
    extraPaginationContent,
    disableAddButton,
    searchPlaceholder,
    actionButton,
    hideSearch,
    noElevation,
    hidePagination,
  } = props

  const { columns } = options
  const rows = query.data?.results || []

  const isLoading = !!useIsFetching({ queryKey: [queryKey] })

  return (
    <StyledTablePaper elevation={noElevation ? 0 : 2}>
      <StyledTableContainer>
        {tabsComponent}
        {!hideSearch && config && (
          <TableToolbar
            value={config.searchString}
            onChange={config.onSearch}
            placeholder={searchPlaceholder}
            actionButton={actionButton}
          />
        )}
        {extraToolbarContent}
        <Table
          sx={{
            padding: noElevation ? 0 : '0 16px',
            '& .MuiTableRow-root:last-of-type > .MuiTableCell-root':
              hidePagination ? { borderBottom: 'none' } : {},
          }}
          stickyHeader
          size="small"
        >
          <TableHead columns={columns} />
          <TableBody rows={rows} columns={columns} />
        </Table>
        {isLoading && <RelativeBackdrop open transparent />}
        {!isLoading && rows.length === 0 && (
          <NoResultsPlaceholder
            disableAddButton={disableAddButton}
            dataType={dataType}
          />
        )}
        {!hidePagination && config && (
          <TablePagination
            config={config!}
            count={query.data?.count ?? 0}
            extraContent={extraPaginationContent}
          />
        )}
      </StyledTableContainer>
    </StyledTablePaper>
  )
}
