import * as yup from 'yup'

import { nanableNumber } from 'utils/validations'

export const organizationSchema = yup.object({
  is_active: yup.boolean(),
  slug: yup.string(),
  name: yup.string().required(),
  email_domain: yup.string(),
  notes: yup.string(),
  currency: yup.string().required().default('cad'),
  advance_limit: nanableNumber,
  upon_shipping_limit: nanableNumber,
  net_limit: nanableNumber,
  max_outstanding_amount: nanableNumber,
  max_outstanding_days: nanableNumber,
  vat_number: yup.string(),
  order_confirmation_email: yup.string().email(),
  invoice_email: yup.string().email(),
})

export const addressSchema = yup.object({
  organization_name: yup.string(),
  address_line_1: yup.string().required('Address line 1 is required'),
  address_line_2: yup.string(),
  city: yup.string().required('City is required'),
  postal_code: yup
    .string()
    .test(
      'len',
      'Postal code must be less than 255 characters',
      (val) => val === undefined || val.length <= 255
    ),
  country: yup.string().required('Country is required'),
  phone_number: yup.string(),
  notes: yup
    .string()
    .test(
      'len',
      'Notes must be less than 255 characters',
      (val) => val === undefined || val.length <= 255
    ),
  type: yup.string().required('Type is required'),
  is_preferred: yup.boolean(),
  is_active: yup.boolean(),
})
