import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useLocation } from 'react-router-dom'

import { ProformaPreviewVariables } from 'api/invoice/usePreviewProforma'
import { Invoice, Quote } from 'utils/global-types'

import PreviewDrawer from './PreviewDrawer'

type PreviewConfig = (
  | {
      type: 'invoice'
      uuid: Invoice['uuid']
    }
  | {
      type: 'quote'
      showViewButton?: boolean
      orderID: Quote['order_id']
    }
  | {
      type: 'proforma'
      proformaVariables: ProformaPreviewVariables
    }
) & { callback?: () => void }

type PreviewContextI = (config: PreviewConfig) => void

const PreviewContext = createContext<PreviewContextI | null>(null)

const PreviewProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation()
  const [config, setConfig] = useState<PreviewConfig | null>(null)

  const isOpen = config !== null

  const close = () => {
    setConfig(null)
  }

  useEffect(() => {
    close()
  }, [location.pathname])

  return (
    <PreviewContext.Provider value={setConfig}>
      {children}
      {/* @ts-expect-error config may be passed as undefined, but drawer wont be opened until config becomes valid */}
      <PreviewDrawer open={isOpen} onClose={close} {...config} />
    </PreviewContext.Provider>
  )
}

const usePreviewContext = () => {
  const context = useContext(PreviewContext)

  if (context === null) {
    throw new Error('usePreviewContext must be used within a PreviewProvider')
  }

  return context
}

export { usePreviewContext }
export default PreviewProvider
