import { useController } from 'react-hook-form'
import {
  FormControlLabel,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from '@mui/material'

type SwitchProps = {
  name: string
  label: string
  defaultValue?: boolean
} & Omit<MuiSwitchProps, 'defaultValue'>

export default function Switch({
  name,
  label,
  defaultValue,
  sx,
  readOnly,
  ...props
}: SwitchProps) {
  const { field } = useController({
    name,
    defaultValue,
  })
  return (
    <FormControlLabel
      sx={{
        height: 'fit-content',
        ...sx,
        pointerEvents: readOnly ? 'none' : 'auto',
      }}
      label={label}
      control={
        <MuiSwitch
          checked={field.value}
          {...field}
          {...props}
          inputRef={field.ref}
        />
      }
    />
  )
}
