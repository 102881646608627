import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, MenuItem, Stack } from '@mui/material'
import { AxiosError } from 'axios'

import useEditOrganization from 'api/customer/useEditOrganization'
import useShippingMethod from 'api/shipping/useShippingMethod'
import { ActiveSwitch } from 'components/ActiveSwitch'
import { BackButton, LoadingButton } from 'components/Buttons'
import { Footer } from 'components/Footer'
import { Autocomplete, Select } from 'components/Form'
import ControlledInput from 'components/Form/ControlledInput'
import { FieldsGroup, Form, FormSection } from 'components/FormHelpers'
import { LegacyTerms } from 'components/LegacyTerms'
import { useAbility } from 'context/Ability'
import { useDialog } from 'context/Dialog/DialogContext'
import { mapShippingMethodOption } from 'utils/address'
import { AutocompleteOption, Organization } from 'utils/global-types'
import { percentageInputOptions } from 'utils/price'
import { useGoBack } from 'utils/routing'

import AddressesSection from './AddressesSection'
import { organizationSchema } from './validation'

export type OrganizationFormProps = {
  organization: Organization
}

export default function OrganizationForm({
  organization,
}: OrganizationFormProps) {
  const goBack = useGoBack('/organizations')
  const ability = useAbility()
  const [openDialog] = useDialog()

  const form = useForm<Organization>({
    // @ts-expect-error ts(2322) Resolver infer type is not equal to form values type
    resolver: yupResolver(organizationSchema),
    defaultValues: organization,
  })

  const {
    watch,
    setError,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = form

  const shippingMethodUUID = watch('shipping_method')
  const { data: shippingMethod } = useShippingMethod(shippingMethodUUID)

  const { mutateAsync: editOrganization } = useEditOrganization(
    organization.uuid
  )

  const onMutationError = (e: AxiosError<Record<string, string[]>>) => {
    const error = e.response!.data
    if ('slug' in error) {
      toast.error(
        'Organization with this slug already exists. Please choose another organization name.'
      )
    }

    Object.entries(error).forEach(([key, value]) => {
      value.forEach((e: string) =>
        setError(key as keyof Organization, { message: e })
      )
    })
  }

  const onCancel = async () => {
    if (!isDirty) {
      return goBack()
    }

    const isDiscardConfirmed = await openDialog({ variant: 'discard' })

    if (isDiscardConfirmed) {
      goBack()
    }
  }

  const handleDeleteTerms = async () => {
    await editOrganization({ terms_notes: '' })
  }

  const handleEditOrganization = async (organization: Organization) => {
    await editOrganization(organization, {
      onError: onMutationError,
    })
  }

  const onSubmit = handleSubmit(async (organization) => {
    await handleEditOrganization(organization)
    goBack()
  })

  const shippingMethodDefaultOption: AutocompleteOption | undefined =
    organization?.shipping_method
      ? {
          id: organization.shipping_method,
          label: organization.shipping_method_name,
        }
      : undefined

  const canChangeTerms = ability.can('change_terms_of', 'customer')
  const showShippingInstructions =
    shippingMethod?.rate_calculation_type === 'no-fees'
  const termsHelperText = canChangeTerms
    ? undefined
    : `You can't change organization terms`

  return (
    <Form handlers={form} onSubmit={onSubmit}>
      <Stack direction="row" gap={3}>
        <Box sx={{ flex: 7 }}>
          <FormSection title="Details">
            <ControlledInput label="Name" required name="name" />
            <ControlledInput label="Email domain" name="email_domain" />

            <FieldsGroup>
              <ControlledInput
                label="Order confirmation email"
                type="email"
                name="order_confirmation_email"
              />
              <ControlledInput
                label="Invoice email address"
                type="email"
                name="invoice_email"
              />
            </FieldsGroup>
            <Autocomplete
              name="shipping_method"
              label="Shipping method"
              url="customers/shipping-methods/"
              enabled
              defaultValue={shippingMethodDefaultOption}
              mapOptions={mapShippingMethodOption}
            />
            {showShippingInstructions && (
              <ControlledInput
                label="Shipping account number"
                name="shipping_instructions"
              />
            )}
            <ControlledInput label="Note" name="notes" multiline />
          </FormSection>
          <AddressesSection organizationID={organization.uuid} />
        </Box>
        <Box sx={{ flex: 3 }}>
          <ActiveSwitch defaultChecked={organization.is_active} />
          <FormSection title="Payment and billing">
            <Select label="Currency" name="currency">
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="eur">EUR</MenuItem>
              <MenuItem value="cad">CAD</MenuItem>
            </Select>
            <ControlledInput
              label="Advance %"
              type="number"
              inputProps={percentageInputOptions}
              name="advance_limit"
            />
            <ControlledInput
              label="Upon shipping %"
              type="number"
              inputProps={percentageInputOptions}
              name="upon_shipping_limit"
            />
            <ControlledInput
              label="NET %"
              type="number"
              name="net_limit"
              disabled={!canChangeTerms}
              helperText={termsHelperText}
            />
            <ControlledInput
              label="Max outstanding amount"
              type="number"
              inputProps={{ min: 0 }}
              name="max_outstanding_amount"
              disabled={!canChangeTerms}
              helperText={termsHelperText}
            />
            <ControlledInput
              label="Max outstanding days"
              type="number"
              inputProps={{ min: 0 }}
              name="max_outstanding_days"
              disabled={!canChangeTerms}
              helperText={termsHelperText}
            />
            <ControlledInput label="VAT number" name="vat_number" />
            {organization.terms_notes && (
              <LegacyTerms
                terms={organization.terms_notes}
                onDelete={handleDeleteTerms}
              />
            )}
          </FormSection>
        </Box>
      </Stack>
      <Footer>
        <BackButton onBack={onCancel} />
        <Stack direction="row" gap={2}>
          <LoadingButton
            type="submit"
            variant="contained"
            isLoading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Stack>
      </Footer>
    </Form>
  )
}
