import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Checkbox,
  Container,
  FormControlLabel,
  MenuItem,
  styled,
  Switch,
  Typography,
} from '@mui/material'

import useCountries from 'api/useCountries'
import { Select } from 'components/Form'
import ControlledInput from 'components/Form/ControlledInput'
import { Address } from 'utils/global-types'

type DialogAddressFormProps =
  | {
      mode: 'create'
    }
  | {
      mode: 'edit'
      address: Address
    }

const Row = styled(Container)({
  display: 'flex',
  gap: 16,
  '& > *': {
    flexBasis: '50%',
  },
})

const addressFormDefaultValues: Partial<Address> = {
  address_line_1: '',
  address_line_2: '',
  city: '',
  postal_code: '',
  country: '',
  phone_number: '',
  notes: '',
  type: 'shipping',
  is_preferred: false,
  is_active: true,
}

export default function DialogAddressForm(props: DialogAddressFormProps) {
  const values =
    props.mode === 'create' ? addressFormDefaultValues : props.address

  const { control, reset } = useFormContext()
  const { data: countries } = useCountries()

  useEffect(() => {
    if (props.mode === 'edit') {
      reset(values)
      return
    }

    reset(addressFormDefaultValues)
  }, [props.mode])

  return (
    <Container sx={{ maxWidth: '444px' }}>
      <ControlledInput
        name="organization_name"
        label="Alternative Organization Name (Optional)"
        helperText={
          <Typography
            variant="caption"
            color="text.secondary"
            fontStyle="italic"
          >
            For deliveries to another organization, fill in this field.
          </Typography>
        }
        sx={{
          '&.MuiTextField-root': {
            mb: 0,
          },
        }}
      />
      <ControlledInput name="address_line_1" label="Address line 1" />
      <ControlledInput name="address_line_2" label="Address line 2" />
      <ControlledInput name="city" label="City" />
      <Row>
        <ControlledInput name="postal_code" label="Postal code" />
        <Select name="country" label="Country" defaultValue="">
          {countries!.map((country) => (
            <MenuItem key={country.uuid} value={country.uuid}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </Row>
      <ControlledInput name="phone_number" label="Phone number" />
      <ControlledInput name="attn" label="ATTN" />
      <ControlledInput name="notes" label="Notes" />
      <Row>
        <Select name="type" label="Type" defaultValue={values.type}>
          <MenuItem value="billing">Billing</MenuItem>
          <MenuItem value="shipping">Shipping</MenuItem>
        </Select>
        <FormControlLabel
          label="Set as preferred"
          control={
            <Controller
              control={control}
              name="is_preferred"
              defaultValue={values.is_preferred}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  // make checkbox big
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                />
              )}
            />
          }
        />
      </Row>
      <FormControlLabel
        label="Active"
        control={
          <Controller
            control={control}
            name="is_active"
            defaultValue={values.is_active}
            render={({ field }) => <Switch {...field} checked={field.value} />}
          />
        }
      />
    </Container>
  )
}
