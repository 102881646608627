import { Button } from '@mui/material'

import Warning from 'components/WarningMessage'

type WarningCopyProductProps = {
  label: string
  onClick: () => void
}

const WarningProductExists = ({ label, onClick }: WarningCopyProductProps) => {
  return (
    <Warning>
      {label} already exist in your order.{' '}
      <Button onClick={onClick}>Change product quantity</Button>
    </Warning>
  )
}

export default WarningProductExists
