import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import useOrder from 'api/order/useOrder'
import axios from 'configs/axios'
import { Invoice, ListReturnType, Order } from 'utils/global-types'

type InvoicesKey = ['invoices', Order['uuid'] | null]
type InvoicesResponse = ListReturnType<Invoice>

const getInvoices: QueryFunction<InvoicesResponse, InvoicesKey> = async ({
  queryKey,
}) => {
  const [, orderUUID] = queryKey

  const response = await axios.get<InvoicesResponse>(
    `/invoices/?order=${orderUUID}&ordering=-created`
  )

  return response.data
}

export const invoicesQueryOptions = (
  orderUUID: InvoicesKey[1]
): WithRequired<
  UseQueryOptions<InvoicesResponse, AxiosError, Invoice[], InvoicesKey>,
  'queryKey'
> => ({
  queryKey: ['invoices', orderUUID],
  queryFn: getInvoices,
  meta: { message: 'Failed to get invoices' },
})

const useInvoices = (orderID?: Order['order_id']) => {
  const { data } = useOrder(orderID as string)
  const orderUUID = data?.order?.uuid

  return useQuery<InvoicesResponse, AxiosError, Invoice[], InvoicesKey>({
    ...invoicesQueryOptions(orderUUID || null),
    enabled: !!orderUUID,
    select: (data) => data.results,
  })
}

export default useInvoices
