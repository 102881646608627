import { useEffect, useState } from 'react'
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  BaseTextFieldProps,
  CircularProgress,
  TextField,
  TextFieldProps,
} from '@mui/material'

import { useDebounce } from 'utils/hooks'

import useSearch from './useSearch'

type SearchOption = {
  uuid: string
}

type SearchProps<T extends SearchOption> = Omit<
  TextFieldProps,
  'label' | 'onChange'
> & {
  url: string
  name: string
  label: string
  filters?: Record<string, string>
  isProductSearch?: boolean
  onChange: (value: T) => void
  getOptionLabel: (option: T) => string
  getOptionDisabled?: (option: T) => boolean
  size?: BaseTextFieldProps['size']
}

export default function Search<T extends SearchOption>({
  url,
  name,
  label,
  filters,
  isProductSearch,
  onChange,
  getOptionLabel,
  getOptionDisabled,
  size = 'small',
  ...textFieldProps
}: SearchProps<T>) {
  const [inputValue, setInputValue] = useState<string>('')

  const debouncedInputValue = useDebounce(inputValue, 500)

  const {
    data: options,
    refetch: search,
    isInitialLoading: isLoading,
  } = useSearch<T>({
    url,
    name,
    filters,
    search: debouncedInputValue,
    isProductSearch,
  })

  useEffect(() => {
    if (debouncedInputValue) {
      search()
    }
  }, [debouncedInputValue])

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      size={size}
      label={label}
      {...textFieldProps}
      InputProps={{
        ...params.InputProps,
        ...textFieldProps.InputProps,
        endAdornment: (
          <>
            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  )

  return (
    <Autocomplete
      blurOnSelect
      value={null}
      onChange={(event, newValue) => {
        newValue && onChange(newValue)
        setInputValue('')
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      options={options || []}
      filterOptions={(options) => options}
      getOptionLabel={getOptionLabel}
      loading={isLoading}
      noOptionsText={
        inputValue.length === 0 ? 'Type to search' : 'No results found'
      }
      getOptionDisabled={getOptionDisabled}
      isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
      renderInput={renderInput}
    />
  )
}
