import {
  Box,
  Button,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { capitalize } from 'lodash'
import { DateTime } from 'luxon'

import useDownloadQuote from 'api/order/useDownloadQuote'
import useEditOrder from 'api/order/useEditOrder'
import useOrder from 'api/order/useOrder'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { LoadingButton } from 'components/Buttons'
import { FieldsGroup } from 'components/FormHelpers'
import { LabelSelect } from 'components/LabelSelect'
import { usePreviewContext } from 'components/PreviewDrawer/context'
import { PreviewIcon } from 'components/PreviewTableCell'
import { ReadonlyField } from 'components/ReadonlyField'
import { useDialog } from 'context/Dialog/DialogContext'
import { PageHeader } from 'pages/Layout/PageHeader'
import { getValidityDaysLabel, localeDate } from 'utils/date'
import { Order } from 'utils/global-types'
import { getManufacturerNamesLabel } from 'utils/order'
import { formatPrice } from 'utils/price'

import CalendarAddIcon from './CalendarAddIcon'
import { extendQuote } from './dialogs'

type QuoteDetailsHeaderProps = {
  id: Order['order_id']
}

export default function QuoteDetailsHeader({ id }: QuoteDetailsHeaderProps) {
  const [openDialog] = useDialog()
  const openPreview = usePreviewContext()

  const { data } = useOrder(id, 'quote')
  const { order: quote } = data!

  const { mutateAsync: editQuote } = useEditOrder(id!, 'quote', true)
  const { refetch: downloadQuote, isFetching } = useDownloadQuote(
    id,
    quote?.organization?.name
  )

  const handleClick = () => {
    openPreview({ type: 'quote', orderID: id })
  }

  const handleDownloadPDF = () => {
    downloadQuote()
  }

  const handleExtendQuote = async () => {
    const isExtended = await openDialog(extendQuote(quote.order_id))

    if (isExtended) {
      editQuote({
        expiration_date: DateTime.now().plus({ days: 30 }).toISODate(),
      })
    }
  }

  return (
    <>
      <PageHeader sx={{ alignItems: 'center', justifyContent: 'end', gap: 1 }}>
        <Box mr="auto">
          <Typography variant="h6">Quote {id}</Typography>
          <Breadcrumbs />
        </Box>
        <LabelSelect />
        <ReadonlyField label="Status" noPadding sx={{ pr: 1, pl: 3 }}>
          {capitalize(quote?.status)}
        </ReadonlyField>
        <Button
          variant="outlined"
          onClick={handleClick}
          startIcon={<PreviewIcon />}
        >
          Preview
        </Button>
        <LoadingButton
          variant="outlined"
          isLoading={isFetching}
          onClick={handleDownloadPDF}
        >
          Download PDF
        </LoadingButton>
      </PageHeader>
      <Divider />
      <FieldsGroup sameWidth pt={3} px={3}>
        <ReadonlyField label="Channel (Source)">
          {capitalize(quote.channel)} {quote.source && `(${quote.source})`}
        </ReadonlyField>
        <ReadonlyField label="Estimated delivery">
          {quote?.lead_time ? `${quote.lead_time} days` : 'Unknown'}
        </ReadonlyField>
        <ReadonlyField label="Brands">
          {getManufacturerNamesLabel(quote.manufacturer_names)}
        </ReadonlyField>
        <ReadonlyField label="Created Date">
          {localeDate(quote.created)}
        </ReadonlyField>
        <ReadonlyField label="Last Updated">
          {localeDate(quote.updated)}
        </ReadonlyField>
        <ReadonlyField label="Validity">
          {getValidityDaysLabel(quote.validity_days, quote.expiration_date)}
          {quote.status === 'QUOTE' && (
            <Tooltip title="Extend Quote Period">
              <IconButton size="small" onClick={handleExtendQuote}>
                <CalendarAddIcon />
              </IconButton>
            </Tooltip>
          )}
        </ReadonlyField>
        <ReadonlyField label="Total amount" fontWeight="700">
          {formatPrice(quote.total_amount, quote.currency)}
        </ReadonlyField>
      </FieldsGroup>
    </>
  )
}
