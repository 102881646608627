import { Button } from '@mui/material'
import { useIsMutating } from '@tanstack/react-query'

export default function ShippingRatesFormSubmitBtn() {
  const isLoading = useIsMutating({ mutationKey: ['ratesUpload'] })

  return (
    <Button type="submit" variant="contained" disabled={!!isLoading}>
      {isLoading ? 'Uploading...' : 'Upload files'}
    </Button>
  )
}
