import { PropsWithChildren } from 'react'

import useUser from 'api/user/useUser'
import { Backdrop } from 'components/Backdrop'

export default function AuthWrapper({ children }: PropsWithChildren) {
  const { isLoading, isFetched } = useUser()

  return (
    <>
      <Backdrop open={isLoading} />
      {isFetched && children}
    </>
  )
}
