import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { ConfigClient } from 'utils/global-types'

type ConfigResponse = ConfigClient
type ConfigQueryKey = ['config/client']

const getConfig: QueryFunction<ConfigResponse, ConfigQueryKey> = async () => {
  const response = await axios.get<ConfigResponse>('/config/client/')

  return response.data
}

export const configQueryOptions: WithRequired<
  UseQueryOptions<ConfigResponse, AxiosError, ConfigResponse, ConfigQueryKey>,
  'queryKey'
> = {
  queryKey: ['config/client'],
  queryFn: getConfig,
  meta: { message: 'Failed to get config' },
}

const useConfigClient = () => {
  return useQuery<ConfigResponse, AxiosError, ConfigResponse, ConfigQueryKey>(
    configQueryOptions
  )
}

export default useConfigClient
