import * as yup from 'yup'

import { Product } from 'utils/global-types'
import { optionalNumber } from 'utils/validations'

export const productSchema = yup.object({
  sku: yup
    .string()
    .required()
    .test(
      'len',
      'Sku must be less than or equal 10 characters',
      (val) => val !== undefined && val.length <= 10
    ),
  manufacturer_name: yup.string(),
  manufacturer_part_number: yup.string(),
  ean_code: yup.string(),
  product_category: yup.string(),
  series: yup.string(),
  type: yup.string(),
  stock_level: optionalNumber.required('This field is required'),
  standard_lead_time: optionalNumber,
  minimum_order_quantity: optionalNumber.required('This field is required'),
  increment: optionalNumber,
  product_: optionalNumber,
  product_unit: yup.string(),
  product_amount: optionalNumber,
  price_conversion_factor: optionalNumber,
  price_amount: optionalNumber,
  packaging: yup.string(),
  dimensions: yup.string(),
  weight: optionalNumber,
  weight_overwrite: optionalNumber,
  hs_code: yup.string(),
  hs_code_overwrite: yup.string(),
  country_of_origin: yup.string(),
  country_of_origin_overwrite: yup.string(),
  image: yup.string().nullable(),
  list_price: optionalNumber.required('This field is required'),
  web_price: optionalNumber.required('This field is required'),
  discount_group: yup.string().required('This field is required'),
  manufacturer_discount_group: yup.string(),
})

export const getProductDefaultValues = (product?: Product) =>
  product
    ? ({
        ...product,
        weight_overwrite: product.weight_overwrite || product.weight,
        hs_code_overwrite: product.hs_code_overwrite || product.hs_code,
        country_of_origin_overwrite:
          product.country_of_origin_overwrite || product.country_of_origin,
      } satisfies Product)
    : {
        stock_level: 0,
        minimum_order_quantity: 1,
        increment: 1,
        price_conversion_factor: 1,
        product_amount: 1,
        price_amount: 1,
        product_unit: 'PCE',
      }
