import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { uninterceptedAxios } from 'configs/axios'
import { User } from 'utils/global-types'

type UserResponse = User | null
type UserQueryKey = ['user']

const getUser: QueryFunction<UserResponse, UserQueryKey> = async () => {
  const response = await uninterceptedAxios.get<User | undefined>('/users/me/')

  return response.data ?? null
}

export const userQueryOptions: WithRequired<
  UseQueryOptions<UserResponse, AxiosError, UserResponse, UserQueryKey>,
  'queryKey'
> = {
  queryKey: ['user'],
  queryFn: getUser,
}

const useUser = () => {
  return useQuery<UserResponse, AxiosError, UserResponse, UserQueryKey>(
    userQueryOptions
  )
}

export default useUser
