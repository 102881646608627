import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Address } from 'utils/global-types'

type EditAddressResponse = Address

function deleteAddress(uuid: Address['uuid']) {
  const deleteAddressMutation: MutationFunction<
    EditAddressResponse
  > = async () => {
    const response = await axios.delete<EditAddressResponse>(
      `/customers/addresses/${uuid}/`
    )

    return response.data
  }

  return deleteAddressMutation
}

const useDeleteAddress = (id: Address['uuid']) => {
  const queryClient = useQueryClient()

  return useMutation<EditAddressResponse, AxiosError>({
    mutationFn: deleteAddress(id),
    meta: { message: 'Failed to delete address' },
    onSuccess: ({ organization }) => {
      queryClient.invalidateQueries(['addresses', { organization }])
    },
  })
}

export default useDeleteAddress
