import { PropsWithChildren } from 'react'
import { StyleSheet, View } from '@react-pdf/renderer'

import Divider from 'pdf/components/Divider'

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: 5,
  },
})

type ProductItemTableRowProps = PropsWithChildren<{
  head?: boolean
}>

export default function ProductItemTableRow({
  children,
  head = false,
}: ProductItemTableRowProps) {
  return (
    <>
      <View style={styles.row} wrap={false}>
        {children}
      </View>
      {!head && <Divider />}
    </>
  )
}
