import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { QuoteContext } from 'pdf/types'
import { Quote } from 'utils/global-types'

type PreviewQuoteResponse = QuoteContext
type PreviewQuoteQueryKey = ['preview-quote', Quote['order_id']]

const getPreviewQuoteContext: QueryFunction<
  PreviewQuoteResponse,
  PreviewQuoteQueryKey
> = async ({ queryKey }) => {
  const [, quoteID] = queryKey

  const response = await axios.get<PreviewQuoteResponse>(
    `/orders/${quoteID}/preview-pdf/`
  )

  return response.data
}

const usePreviewQuote = (quoteID?: Quote['order_id'], enabled?: boolean) => {
  return useQuery<
    PreviewQuoteResponse,
    AxiosError,
    PreviewQuoteResponse,
    PreviewQuoteQueryKey
  >({
    queryKey: ['preview-quote', quoteID!],
    queryFn: getPreviewQuoteContext,
    enabled: enabled && !!quoteID,
  })
}

export default usePreviewQuote
