import { MutationFunction, useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { DeclineReason, FutureOffersOption, Ratings } from 'utils/global-types'

export type CancelOrderResponse = Record<string, never>

export type CancelOrderVariables = {
  uuid: string
  reason?: DeclineReason
  reason_text?: string
  future_offers: FutureOffersOption
  other_feedback?: string
} & Partial<Ratings>

const cancelOrder: MutationFunction<
  CancelOrderResponse,
  CancelOrderVariables
> = async ({ uuid, ...variables }) => {
  const response = await axiosInstance.post<CancelOrderResponse>(
    `/customers-portal/orders/${uuid}/decline/`,
    variables
  )

  return response.data
}

const useCancelOrder = () => {
  return useMutation<
    CancelOrderResponse,
    AxiosError<Record<string, string[]>>,
    CancelOrderVariables
  >({
    useErrorBoundary: true,
    mutationFn: cancelOrder,
    meta: { useGlobalError: false },
  })
}

export default useCancelOrder
