import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import {
  CustomerList,
  DetailCustomer,
  ListReturnType,
} from 'utils/global-types'

type CustomersResponse = ListReturnType<CustomerList>
type CustomerQueryKeys = ['customer', string]

const getCustomers: QueryFunction<
  CustomersResponse,
  CustomerQueryKeys
> = async ({ queryKey }) => {
  const [, customerEmail] = queryKey

  const response = await axiosInstance.get<CustomersResponse>(
    `/customers/customers/?email=${customerEmail}`
  )

  return response.data
}

const useIsCustomerExists = (customerEmail?: DetailCustomer['email']) => {
  return useQuery<CustomersResponse, AxiosError, boolean, CustomerQueryKeys>({
    queryKey: ['customer', customerEmail!],
    queryFn: getCustomers,
    enabled: false,
    select: (data) => data.results.length > 0,
    meta: { message: 'Failed to check customer email' },
  })
}

export default useIsCustomerExists
