import { Address, AutocompleteOption, ShippingMethod } from './global-types'

export function getAddressContent(
  address_line_1?: string,
  address_line_2?: string
) {
  if (!address_line_1) {
    if (!address_line_2) {
      return '-'
    }
    return address_line_2
  }

  return (
    <>
      {address_line_1}
      {address_line_2 ? (
        <>
          <br />
          {address_line_2}
        </>
      ) : null}
    </>
  )
}

export function getAddressString(
  address_line_1?: string,
  address_line_2?: string
) {
  if (!address_line_1) {
    if (!address_line_2) {
      return '-'
    }
    return address_line_2
  }

  return `${address_line_1}${address_line_2 ? `, ${address_line_2}` : ''}`
}

type FullAddress = {
  address_line_1?: string
  address_line_2?: string
  organization_name?: string
  city: string
  country_name: string
}

export function getFullAddressString(item: FullAddress) {
  const street = getAddressString(item.address_line_1, item.address_line_2)
  if (street === '-' && !item.city && !item.country_name) {
    return ''
  }

  return `${
    item.organization_name ? `${item.organization_name}, ` : ''
  }${street}, ${item.city}, ${item.country_name}`
}

export const mapAddressOption = (option: Address): AutocompleteOption => ({
  label: getFullAddressString(option),
  id: option.uuid,
  isDisabled: !option.is_active,
})

export const mapShippingMethodOption = (option: ShippingMethod) => ({
  label: option.name_display,
  id: option.uuid,
})

export const getIsShippingMethodDefault = (option: ShippingMethod) =>
  option.is_default

type AddressOptions = {
  address_line_1?: string
  address_line_2?: string
  postal_code?: string
  city?: string
  country?: string
  organization_name?: string
}

export function getFullAddressContent({
  address_line_1,
  address_line_2,
  postal_code,
  city,
  country,
  organization_name,
}: AddressOptions) {
  return (
    <>
      {organization_name ? (
        <>
          {organization_name},<br />
        </>
      ) : null}
      {getAddressContent(address_line_1, address_line_2)}
      {city || postal_code ? (
        <>
          <br />
          {postal_code} {city}
        </>
      ) : null}
      {country ? (
        <>
          <br />
          {country}
        </>
      ) : null}
    </>
  )
}
