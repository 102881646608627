import { DialogConfig } from 'context/Dialog/DialogContext'
import { Invoice, Order, Payment, Shipment } from 'utils/global-types'

import AddProductForm from './AddProductForm'
import RefundForm from './AddRefundForm'
import DialogPaymentForm from './DialogPaymentForm'
import DialogShipmentForm from './DialogShipmentForm'
import EditPaymentForm from './EditPaymentForm'

export const deleteProductDialog: DialogConfig = {
  title: 'Delete the product item?',
  content: 'This action cannot be reverted.',
  actions: { confirm: 'Yes, delete' },
}

export const addProductDialog: DialogConfig = {
  title: 'Add new product',
  content: <AddProductForm />,
  actions: { confirm: 'Add product' },
}

export const creditConfirmationDialog = (
  invoiceID: Invoice['invoice_id']
): DialogConfig => ({
  title: `Credit the invoice ${invoiceID}`,
  content: 'Are you sure you want to credit this invoice?',
  actions: { confirm: 'Credit invoice' },
})

export const deleteConfirmationDialog = (
  invoiceID: Invoice['invoice_id']
): DialogConfig => ({
  title: `Delete the invoice ${invoiceID}`,
  content: 'Are you sure you want to delete this invoice?',
  actions: { confirm: 'Delete invoice' },
})

export const addPaymentDialog = ({
  outstanding_amount,
  invoice_id,
  uuid,
  order_id,
  has_payments,
}: Invoice & Pick<Order, 'order_id'>): DialogConfig => ({
  title: `Add payment to invoice ${invoice_id}`,
  content: (
    <DialogPaymentForm
      mode="create"
      payment={{ amount: outstanding_amount }}
      hasPayments={has_payments}
      orderID={order_id}
      invoiceUUID={uuid}
    />
  ),
  actions: { confirm: 'Add payment' },
})

export const editPaymentDialog = (payment: Payment): DialogConfig => ({
  title: `Edit payment for invoice ${payment.invoice_id}`,
  content: <EditPaymentForm payment={payment} orderID={payment.order_id} />,
  actions: { confirm: 'Save changes' },
})

export const refundInvoiceDialog = (
  invoice: Invoice,
  orderID: Order['order_id']
): DialogConfig => ({
  title: `Add refund to Invoice ${invoice.invoice_id}`,
  content: (
    <RefundForm
      orderID={orderID}
      payment={{
        amount: -invoice.total_paid_amount,
        invoice: invoice.uuid,
      }}
    />
  ),
  actions: { confirm: 'Add refund' },
})

export const editRefundDialog = (payment: Payment): DialogConfig => ({
  title: `Edit refund for invoice ${payment.invoice_id}`,
  content: <EditPaymentForm payment={payment} orderID={payment.order_id} />,
  actions: { confirm: 'Save changes' },
})

export const deletePaymentDialog: DialogConfig = {
  title: 'Delete the payment',
  content: 'This action cannot be reverted.',
  actions: { confirm: 'Yes, delete' },
}

export const addShipmentDialog = (invoice: Invoice): DialogConfig => ({
  title: `Add shipment to invoice ${invoice.invoice_id}`,
  content: (
    <DialogShipmentForm
      mode="create"
      shipmentMethod={invoice.shipping_method!}
      shipmentMethodName={invoice.shipping_method_name}
    />
  ),
  actions: { confirm: 'Save' },
})

export const editShipmentDialog = (shipment: Shipment): DialogConfig => ({
  title: `Edit shipment for invoice ${shipment.invoice_id}`,
  content: (
    <DialogShipmentForm
      mode="edit"
      shipmentMethod={shipment.carrier!}
      shipmentMethodName={shipment.carrier_name!}
      shipmentDate={shipment.shipment_date!}
      trackingNumber={shipment.tracking_number!}
    />
  ),
  actions: { confirm: 'Save' },
})

export const deleteShipmentDialog: DialogConfig = {
  title: 'Are you sure you want to delete this shipment?',
  content: 'Status will be changed to "Unshipped".',
  actions: { confirm: 'Delete' },
}
