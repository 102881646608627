import { ReactNode } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextFieldProps,
} from '@mui/material'

const defaultOptions = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
]

type Option = {
  label: string
  value: string
}

type ControlledRadioProps = {
  name: string
  label?: ReactNode
  options?: Option[]
} & Omit<TextFieldProps, 'size'>

export default function ControlledRadio({
  sx,
  name,
  label,
  options = defaultOptions,
}: ControlledRadioProps) {
  const { control } = useFormContext()
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  })
  return (
    <FormControl error={!!error?.message} {...field} sx={{ px: '0.75rem' }}>
      {label && <FormLabel>{label}</FormLabel>}
      <RadioGroup
        name="controlled-radio-buttons-group"
        onChange={field.onChange}
        sx={sx}
        value={String(field.value)}
      >
        {options?.map((option) => {
          return (
            <FormControlLabel
              key={String(option.value)}
              control={<Radio />}
              label={option.label}
              value={option.value}
            />
          )
        })}
      </RadioGroup>

      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  )
}
