import { useParams } from 'react-router-dom'
import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import useOrder from 'api/order/useOrder'
import { emptyRequestWithFetch } from 'configs/axios'
import { FetchError, Invoice } from 'utils/global-types'

type DeleteInvoiceResponse = boolean
type DeleteInvoiceVariables = void

const deleteInvoice = (invoiceUUID: Invoice['uuid']) => {
  const deleteInvoiceMutation: MutateFunction<
    DeleteInvoiceResponse,
    DeleteInvoiceVariables
  > = async () => {
    const response = await emptyRequestWithFetch(
      `invoices/${invoiceUUID}/`,
      'DELETE'
    )

    return response.status === 204 || Promise.reject(response)
  }

  return deleteInvoiceMutation
}

const useDeleteInvoice = (invoiceUUID: Invoice['uuid']) => {
  const queryClient = useQueryClient()

  const { id: orderID } = useParams()
  const { data } = useOrder(orderID!)
  const { uuid: orderUUID } = data!.order

  return useMutation<DeleteInvoiceResponse, FetchError, DeleteInvoiceVariables>(
    {
      mutationFn: deleteInvoice(invoiceUUID),
      onSuccess: () => {
        queryClient.invalidateQueries(['invoices'])
        queryClient.invalidateQueries(['invoice', invoiceUUID])
        queryClient.invalidateQueries(['order', orderID])
        queryClient.invalidateQueries(['product-items', orderUUID])
        queryClient.invalidateQueries(['invoice-product-items', invoiceUUID])
      },
      meta: { message: 'Failed to delete invoice' },
    }
  )
}

export default useDeleteInvoice
