import { useParams } from 'react-router-dom'
import { Typography } from '@mui/material'

import useOrganization from 'api/customer/useOrganization'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'

import OrganizationForm from './OrganizationForm'

export default function OrganizationEditPage() {
  const { id } = useParams()

  const {
    error,
    isInitialLoading,
    isFetched,
    data: organization,
  } = useOrganization(id!)

  const title = `Edit ${organization?.name}`

  return (
    <PageTemplate title={title} sx={{ height: '100%' }}>
      <PageHeader>
        <Typography variant="h6">{title}</Typography>
        <Breadcrumbs />
      </PageHeader>

      {isInitialLoading && <div>Loading...</div>}
      {isFetched ? <OrganizationForm organization={organization!} /> : null}
      {error && <div>Something went wrong</div>}
    </PageTemplate>
  )
}
