import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FieldValues, Resolver, UseFormReturn } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { ButtonTypeMap } from '@mui/material'
import { isAxiosError } from 'axios'

import DialogContainer from 'components/Dialog'
import getBaseDialogConfig from 'components/Dialog/dialogVariants'
import { useToggle } from 'utils/hooks'

type DialogContextI = [DialogShowHandler, DialogToggleOpen]

const DialogContext = createContext<DialogContextI | null>(null)

export type DialogVariants = 'discard'

export type BaseDialogConfig = {
  title: string
  actions?: {
    cancel?: string
    confirm?: string
  }
  submitButtonVariant?: ButtonTypeMap['props']['variant']
  submitButtonColor?: ButtonTypeMap['props']['color']
  customActionButtons?: ReactNode
  content?: ReactNode
  resolver?: Resolver
  mutationKeys?: string[]
}

export type VariantDialogConfig = Partial<BaseDialogConfig> & {
  variant: DialogVariants
}

export type DialogConfig = BaseDialogConfig | VariantDialogConfig

export type DialogReturn = boolean | FieldValues
export type DialogShowHandler = (
  config: DialogConfig,
  onSubmit?: DialogSubmitHandler
) => Promise<DialogReturn>
export type DialogToggleOpen = (result: DialogReturn) => void

export type DialogSubmitHandler = (
  result: DialogReturn,
  formMethods?: UseFormReturn
) => DialogReturn | Promise<DialogReturn>

export default function DialogProvider({ children }: PropsWithChildren) {
  const resolver = useRef<DialogToggleOpen>()
  const onSubmitRef = useRef<DialogSubmitHandler>()
  const dialogFormMethods = useRef<UseFormReturn>()

  const location = useLocation()

  const [open, toggleOpen] = useToggle(false)
  const [dialogConfig, setDialogConfig] = useState<Partial<DialogConfig>>({})

  const showDialog: DialogShowHandler = (config, onSubmit) => {
    onSubmitRef.current = onSubmit
    toggleOpen()
    const baseConfig = getBaseDialogConfig(config)
    setDialogConfig(baseConfig)

    return new Promise((resolve) => {
      resolver.current = resolve
    })
  }

  const toggleState: DialogToggleOpen = (result) => {
    if (resolver.current) {
      resolver.current(result)
    }
    toggleOpen()
  }

  const onDialogSubmit: DialogToggleOpen = async (result) => {
    let newResult = result

    if (onSubmitRef.current) {
      try {
        newResult = await onSubmitRef.current(result)
      } catch (error) {
        if (isAxiosError(error)) {
          Object.entries(error.response?.data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
              value.forEach((e: string) =>
                dialogFormMethods.current?.setError(key, { message: e })
              )
            }
          })
        }

        return false
      }
    }

    // if onSubmit returns false then dialog will not close until user clicks cancel
    return !!newResult && toggleState(newResult)
  }

  useEffect(() => {
    if (open) {
      toggleOpen()
    }
  }, [location.pathname])

  return (
    <DialogContext.Provider value={[showDialog, toggleState]}>
      {children}
      <DialogContainer
        toggleState={toggleState}
        onSubmit={onDialogSubmit}
        open={open}
        title=""
        {...dialogConfig}
        formMethods={dialogFormMethods}
      />
    </DialogContext.Provider>
  )
}

export const useDialog = () => {
  const context = useContext(DialogContext)

  if (context === null) {
    throw new Error('useDialog must be used within a DialogProvider')
  }

  return context
}
