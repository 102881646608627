import { UseMutationResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { LabelName } from 'components/LabelSelect/LabelSelect'
import { Order } from 'utils/global-types'

import useEditOrder, {
  EditOrderResponse,
  EditOrderVariables,
} from './useEditOrder'
import useOrder from './useOrder'

type UseUpdateOrderLabels = {
  labels: LabelName[]
  error: AxiosError | null
  isError: boolean
  isLoading: boolean
  addLabels: (newLabels: LabelName[]) => Promise<void>
  updateLabels: (newLabels: LabelName[]) => Promise<void>
  removeLabel: (labelToRemove: LabelName) => Promise<void>
  mutation: UseMutationResult<EditOrderResponse, AxiosError, EditOrderVariables>
}

const useUpdateOrderLabels = (id: Order['order_id']): UseUpdateOrderLabels => {
  const orderQuery = useOrder(id!)
  const { order } = orderQuery.data || {}
  const { labels } = order || {}
  const mutation = useEditOrder(id)
  const { mutateAsync: editOrder } = mutation

  const updateLabels = async (labels: LabelName[]) => {
    await editOrder({ labels })
  }

  const addLabels = async (newLabels: LabelName[]) => {
    await updateLabels([...order!.labels, ...newLabels])
  }

  const removeLabel = async (labelToRemove: LabelName) => {
    await updateLabels(order!.labels.filter((label) => label !== labelToRemove))
  }

  const error = mutation.error || orderQuery.error
  const isError = mutation.isError || orderQuery.isError
  const isLoading = mutation.isLoading || orderQuery.isLoading

  return {
    labels: labels || [],
    error,
    isError,
    isLoading,
    mutation,
    addLabels,
    updateLabels,
    removeLabel,
  }
}

export default useUpdateOrderLabels
