import { useState } from 'react'
import { Paper, SelectChangeEvent, Stack, Typography } from '@mui/material'

import useOrderMetricsYears from 'api/reports/useOrderMetricsYears'
import ReportPeriodSelector from 'pages/Reports/ReportPeriodSelector'

import DashboardMetricsTable from './DashboardMetricsTable'

export default function DashboardMetrics() {
  const { data: availableYears } = useOrderMetricsYears()
  const [selectedYear, setYear] = useState(availableYears![0])

  const handleYearChange = (event: SelectChangeEvent<string>) => {
    setYear(event.target.value)
  }

  return (
    <Paper sx={{ padding: '16px 24px' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2.25}
      >
        <Typography variant="h6">Order metrics</Typography>
        <ReportPeriodSelector
          handleYearChange={handleYearChange}
          selectedYear={selectedYear}
        />
      </Stack>
      <DashboardMetricsTable selectedYear={selectedYear} />
    </Paper>
  )
}
