import { ReactElement } from 'react'
import { useMatches } from 'react-router-dom'
import { Box, Breadcrumbs as MuiBreadcrumbs, styled } from '@mui/material'

import { Match } from 'routing/common'

/**
 * Takes matches and transform them into crumbs route provide
 * @param {Match[]} matches usMatches hook return value
 * @returns {ReactElement} array of links leading to each of match
 */
function getCrumbs(matches: Match[]): ReactElement[] {
  return (
    matches
      // filter match where crumb is not defined and map matches into crumbs (links)
      .reduce((crumbs, match) => {
        if (!match.handle?.crumb) {
          return crumbs
        }
        const crumb = match.handle.crumb(match)
        return [...crumbs, <Box key={match.id}>{crumb}</Box>]
      }, [] as ReactElement[])
  )
}

const StyledBreadcrumbs = styled(MuiBreadcrumbs)({
  flexBasis: '100%',
})

export default function Breadcrumbs() {
  const matches = useMatches() as Match[]
  const crumbs = getCrumbs(matches)
  return <StyledBreadcrumbs aria-label="Breadcrumb">{crumbs}</StyledBreadcrumbs>
}
