import { Typography } from '@mui/material'

import { ShipmentStatus } from 'utils/global-types'
import { getShipmentStatusColor, getStatusLabel } from 'utils/order'

type ShipmentStatusCellProps = {
  shipmentStatus: ShipmentStatus | null
}

export default function ShipmentStatusCell({
  shipmentStatus,
}: ShipmentStatusCellProps) {
  if (!shipmentStatus) {
    return <></>
  }

  return (
    <Typography
      variant="inherit"
      fontWeight={500}
      color={getShipmentStatusColor(shipmentStatus)}
      textTransform="uppercase"
    >
      {getStatusLabel(shipmentStatus)}
    </Typography>
  )
}
