import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Stack } from '@mui/material'
import { AxiosError } from 'axios'
import * as yup from 'yup'

import useCreateCustomer from 'api/customer/useCreateCustomer'
import useCustomer from 'api/customer/useCustomer'
import useEditCustomer from 'api/customer/useEditCustomer'
import useOrganization from 'api/customer/useOrganization'
import useCreateQuote from 'api/order/useCreateQuote'
import { ActiveSwitch } from 'components/ActiveSwitch'
import { BackButton, LoadingButton } from 'components/Buttons'
import { Footer } from 'components/Footer'
import { Autocomplete, ControlledInput, Switch } from 'components/Form'
import Checkbox from 'components/Form/Checkbox'
import { Form, FormSection } from 'components/FormHelpers'
import { useDialog } from 'context/Dialog/DialogContext'
import {
  AutocompleteOption,
  DetailCustomer,
  Organization,
} from 'utils/global-types'
import { useToggle } from 'utils/hooks'
import { useGoBack } from 'utils/routing'

const customerSchema = yup
  .object({
    is_active: yup.boolean().required(),
    email: yup
      .string()
      .email('Email is not valid')
      .max(255, 'Email is too long. Max email length is 255 characters')
      .required('Email is required'),
    name: yup.string().required('Name is required'),
    is_reviews: yup.boolean(),
    phone_number: yup.string(),
    organization: yup.string().typeError('Please choose an organization'),
    notes: yup.string(),
  })
  .required()

export default function CustomerForm() {
  const { customerId, id: organizationId } = useParams()
  const navigate = useNavigate()
  const goBack = useGoBack('/customers')
  const [openDialog] = useDialog()
  const isEdit = !!customerId

  const [isCreateQuoteAfterSave, toggleCreateQuoteAfterSave] = useToggle(false)

  const { data: customer } = useCustomer(customerId)
  const { mutateAsync: createCustomer } = useCreateCustomer()
  const { mutateAsync: createQuote } = useCreateQuote()
  const { data: organization } = useOrganization(
    customer?.organization || organizationId!
  )

  const form = useForm<DetailCustomer>({
    // @ts-expect-error ts(2322) Resolver infer type is not equal to form values type
    resolver: yupResolver(customerSchema),
    defaultValues: customer || {
      is_reviews: true,
    },
  })

  const {
    setError,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = form

  const { mutateAsync: editCustomer } = useEditCustomer()

  const onMutationError = (error: AxiosError<Record<string, string[]>>) => {
    Object.entries(error.response?.data ?? {}).forEach(([key, value]) =>
      value.forEach((e: string) =>
        setError(key as keyof DetailCustomer, { message: e })
      )
    )
  }

  const onBack = async () => {
    if (!isDirty) {
      goBack()
      return
    }

    const isDiscardConfirmed = await openDialog({ variant: 'discard' })

    if (isDiscardConfirmed) {
      goBack()
    }
  }

  const createNewCustomer = async (customer: DetailCustomer) => {
    const createdCustomer = await createCustomer(
      {
        ...customer,
        organization: organizationId!,
      },
      { onError: onMutationError }
    )
    if (isCreateQuoteAfterSave) {
      const createdQuote = await createQuote({
        customer: createdCustomer.uuid,
      })
      navigate(`/quotes/${createdQuote.order_id}/edit`, { replace: true })
    } else {
      goBack()
    }
  }

  const updateCustomer = async (customer: DetailCustomer) => {
    await editCustomer(
      { ...customer, customerId: customerId! },
      { onError: onMutationError }
    )

    goBack()
  }

  const onSubmit = handleSubmit(async (customer: DetailCustomer) => {
    if (isEdit) {
      return await updateCustomer(customer)
    }

    await createNewCustomer(customer)
  })

  const organizationDefaultValue: AutocompleteOption | undefined = {
    label: organization!.name,
    id: organization!.uuid,
  }

  return (
    <Form handlers={form} onSubmit={onSubmit}>
      <Stack direction="row" gap={3}>
        <Box sx={{ flex: 7 }}>
          <FormSection title="Customer details">
            <ControlledInput
              name="email"
              label="Email address"
              type="email"
              required
            />
            <ControlledInput name="name" label="Name" required />

            {!organizationId && (
              <Autocomplete
                name="organization"
                defaultValue={organizationDefaultValue}
                label="Organization"
                url="/customers/organizations/"
                mapOptions={(option: Organization) => ({
                  label: option.name,
                  id: option.uuid,
                })}
              />
            )}

            <ControlledInput name="phone_number" label="Phone number" />

            <Switch label="Allow reviews" name="is_reviews" />
          </FormSection>
        </Box>
        <Box sx={{ flex: 3 }}>
          <ActiveSwitch defaultChecked={customer?.is_active || !isEdit} />
          <FormSection title="Customer notes">
            <ControlledInput label="Note" name="notes" multiline />
          </FormSection>
        </Box>
      </Stack>
      <Footer>
        <BackButton onBack={onBack} />
        <Stack direction="row" gap={2}>
          {!isEdit && (
            <Checkbox
              label="Create quote after save"
              checked={isCreateQuoteAfterSave}
              onChange={toggleCreateQuoteAfterSave}
            />
          )}
          <LoadingButton
            type="submit"
            variant="contained"
            isLoading={isSubmitting}
          >
            Save
          </LoadingButton>
        </Stack>
      </Footer>
    </Form>
  )
}
