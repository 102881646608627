import {
  DependencyList,
  EffectCallback,
  ReducerWithoutAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import { isEqual } from 'lodash'

export const useToggle = (defaultValue: boolean) => {
  return useReducer<ReducerWithoutAction<boolean>>(
    (value) => !value,
    defaultValue
  )
}

export const useBoolean = (defaultValue?: boolean) => {
  const [value, setValue] = useState<boolean>(!!defaultValue)

  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])

  return [value, setTrue, setFalse] as const
}

const useDeepCompareMemo = <T>(value: T) => {
  const ref = useRef<T>()

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export const useDeepCompareEffect = (
  callback: EffectCallback,
  dependencies: DependencyList
) => {
  useEffect(callback, useDeepCompareMemo(dependencies))
}

export const useMenuAnchor = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const open = anchorEl !== null

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return {
    anchorEl,
    open,
    handleClick,
    handleClose,
  }
}

type milliseconds = number
export const useDebounce = <T>(value: T, delay: milliseconds): T => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useDeepCompareEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

export const useOverflow = (ref: React.RefObject<HTMLElement | undefined>) => {
  const [isOverflowing, setIsOverflowing] = useState(false)

  const checkOverflow = () => {
    const element = ref.current

    if (element) {
      if (element.scrollWidth > element.clientWidth) {
        setIsOverflowing(true)
      }
    }
  }

  useLayoutEffect(checkOverflow, [ref])

  return isOverflowing
}

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useIsFirstRender(): boolean {
  const isFirst = useRef(true)

  if (isFirst.current) {
    isFirst.current = false

    return true
  }

  return isFirst.current
}

export const useFocusSearch = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleFocusOnSlash = (event: KeyboardEvent): void => {
      const { activeElement } = document
      if (event.key === '/' && inputRef.current !== activeElement) {
        event.preventDefault()
        inputRef?.current?.focus()
      }
    }

    document.addEventListener('keydown', handleFocusOnSlash)

    return () => document.removeEventListener('keydown', handleFocusOnSlash)
  }, [])

  return inputRef
}
