import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import useCalculateInvoice from 'api/invoice/useCalculateInvoice'
import { Invoice, Order } from 'utils/global-types'
import { useDebounce } from 'utils/hooks'

import { CalculatedInvoiceData, InvoiceForm, InvoiceFormItem } from './types'

const useCalculatedData = (
  orderUUID: Order['uuid'],
  invoiceUUID?: Invoice['uuid']
): CalculatedInvoiceData | null => {
  const {
    watch,
    formState: { isDirty, isValid },
  } = useFormContext<InvoiceForm>()

  const formValues = watch([
    'margin_percent_overwrite',
    'discount_lump_sum',
    'invoice_items',
    'shipping_method',
    'shipping_address',
    'handling_fee',
    'shipping_fee_overwrite',
  ])

  const debouncedFormValues = useDebounce(formValues, 500)

  const [
    margin_percent_overwrite,
    discount_lump_sum,
    invoice_items,
    shipping_method,
    shipping_address,
    handling_fee,
    shipping_fee_overwrite,
  ] = debouncedFormValues

  const isInvoiceExists = !!invoiceUUID
  const product_bundle_list = invoice_items.map<InvoiceFormItem>((item) => ({
    sales_price: item.sales_price || 0,
    buying_price: item.buying_price || 0,
    quantity_offered: item.quantity_offered || 0,
    order_product_item: item.order_product_item,
    invoice_product_item: item.invoice_product_item,
  }))

  const [setDynamicCalculationEnabled, { data }] = useCalculateInvoice(
    {
      order: isInvoiceExists ? undefined : orderUUID,
      invoice: invoiceUUID,
      margin_percent_overwrite,
      discount_lump_sum,
      shipping_method,
      shipping_address,
      handling_fee,
      shipping_fee_overwrite,
      product_bundle_list,
    },
    // if invoice exists, we don't need to calculate it immediately
    !isInvoiceExists
  )

  useEffect(() => {
    if (isDirty) {
      setDynamicCalculationEnabled(true)
    }
  }, [isDirty])

  useEffect(() => {
    setDynamicCalculationEnabled(isValid)
  }, [isValid])

  if (!data) {
    return null
  }

  return {
    total: data.total_amount,
    shipping_fee: data.shipping_fee,
    handling_fee_relevant_display: data.handling_fee_relevant_display,
    weight: data.weight_gross || 0,
    total_margin: data.total_margin,
    total_margin_percent: data.total_margin_percent,
    items: data.product_bundle_list.map((item) => ({
      sku: item.sku,
      total: item.total_sales_price,
      salesPrice: item.sales_price,
      salesPriceRelevant: item.sales_price_relevant,
      salesPriceOriginal: item.sales_price_original,
      totalDisplay: item.total_sales_price_display,
      marginPercent: item.margin_percent,
    })),
  }
}

export default useCalculatedData
