import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { keyBy } from 'lodash'

import axios from 'configs/axios'
import { Config, ConfigItem, ListReturnType } from 'utils/global-types'

type ConfigResponse = ListReturnType<ConfigItem>
type ConfigQueryKey = ['config']

const getConfig: QueryFunction<ConfigResponse, ConfigQueryKey> = async () => {
  const response = await axios.get<ConfigResponse>('/config/')

  return response.data
}

export const configQueryOptions: WithRequired<
  UseQueryOptions<ConfigResponse, AxiosError, Config, ConfigQueryKey>,
  'queryKey'
> = {
  queryKey: ['config'],
  queryFn: getConfig,
  meta: { message: 'Failed to get config' },
}

const useConfig = (refetchInterval: false | number = false) => {
  return useQuery<ConfigResponse, AxiosError, Config, ConfigQueryKey>({
    ...configQueryOptions,
    refetchInterval,
    select: (data) => keyBy(data.results, 'key'),
  })
}

export default useConfig
