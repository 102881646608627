import { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Paper, Stack, Typography } from '@mui/material'
import { isAxiosError } from 'axios'
import { forOwn } from 'lodash'

import useCreateCustomerWizard, {
  CreateCustomerVariables,
} from 'api/customer/useCreateCustomerWizard'
import useCreateQuote from 'api/order/useCreateQuote'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { BackButton } from 'components/Buttons'
import { Footer } from 'components/Footer'
import Checkbox from 'components/Form/Checkbox'
import { Form } from 'components/FormHelpers'
import { useDialog } from 'context/Dialog/DialogContext'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import {
  Address,
  AutocompleteOption,
  DetailCustomer,
  NestedKeyOf,
  Organization,
} from 'utils/global-types'
import { useToggle } from 'utils/hooks'
import { useGoBack } from 'utils/routing'

import { CustomerStep, OrganizationStep, WizardSection } from './components'
import { discardConfirmationDialog } from './dialogs'
import { customerWizardSchema } from './schema'

export type CustomerWizardForm = {
  customer: DetailCustomer
  organization: Partial<Organization> & {
    autocomplete?: AutocompleteOption | null
  }
  address: Partial<Address> & {
    same_shipping_and_billing?: boolean
  }
  billing_address?: Partial<Address>
}

type NestedFields = NestedKeyOf<CustomerWizardForm>

export default function CustomerCreatePage() {
  const [openDialog] = useDialog()
  const [currentStep, setCurrentStep] = useState(0)
  const [isCreateQuoteAfterSave, toggleCreateQuoteAfterSave] = useToggle(false)
  const goBack = useGoBack('/customers')
  const navigate = useNavigate()
  const { mutateAsync: createCustomer } = useCreateCustomerWizard()
  const { mutateAsync: createQuote } = useCreateQuote()

  const handlers = useForm<CustomerWizardForm>({
    // @ts-expect-error ts(2322) Resolver infer type is not equal to form values type
    resolver: yupResolver(customerWizardSchema),
    mode: 'onBlur',
    defaultValues: {
      organization: {
        max_outstanding_days: 30,
        currency: 'eur',
        advance_limit: 100,
      },
      address: { same_shipping_and_billing: true },
    },
  })

  const { setError, setValue, handleSubmit } = handlers

  const [organizationName, organizationUuid, customerPhone] = useWatch({
    control: handlers.control,
    name: ['organization.name', 'organization.uuid', 'customer.phone_number'],
  })

  const isSubmitEnabled =
    (currentStep === 1 && !!organizationName) || !!organizationUuid

  const goToNextStep = () => {
    if (customerPhone) {
      setValue('address.phone_number', customerPhone)
    }
    setCurrentStep((step) => step + 1)
  }
  const gotToPreviousStep = async () => {
    const requiredFieldsFilled = !!organizationName || !!organizationUuid
    const isDiscardConfirmed =
      !requiredFieldsFilled ||
      (await openDialog(discardConfirmationDialog('continue')))

    if (isDiscardConfirmed) {
      setValue('organization.name', '')
      setValue('organization.uuid', '')
      setValue('address.phone_number', '')
      setCurrentStep((step) => step - 1)
    }
  }

  const submitForm = handleSubmit(async (values) => {
    let organization: Partial<Organization>
    if (organizationUuid) {
      organization = { uuid: organizationUuid }
    } else {
      const { autocomplete, ...organizationData } = values.organization
      organization = organizationData
    }

    const address = organizationUuid ? undefined : values.address

    if (address?.same_shipping_and_billing) {
      values.billing_address = undefined
    }

    const data: CreateCustomerVariables = { ...values, organization, address }
    try {
      const createdCustomer = await createCustomer(data)
      if (isCreateQuoteAfterSave) {
        const createdQuote = await createQuote({
          customer: createdCustomer.uuid,
        })
        navigate(`/quotes/${createdQuote.order_id}/edit`)
      } else {
        goBack()
      }
    } catch (e) {
      if (!isAxiosError(e)) {
        throw e
      }
      const errorsByCategories = e.response?.data as Record<
        string,
        Record<string, string[]>
      >
      forOwn(errorsByCategories, (categoryErrors, category) => {
        forOwn(categoryErrors, (fieldErrors, field) => {
          if (Array.isArray(fieldErrors)) {
            fieldErrors.forEach((error) => {
              setError(`${category}.${field}` as NestedFields, {
                message: error,
              })
            })
          } else {
            toast.error(fieldErrors, { duration: 5000 })
          }
        })
      })
    }
  }, console.error)

  return (
    <PageTemplate sx={{ height: '90%' }} title="Create customer">
      <PageHeader>
        <Typography variant="h6">Create customer</Typography>
        <Breadcrumbs />
      </PageHeader>
      <Form handlers={handlers} onSubmit={submitForm}>
        <Paper sx={{ px: 2, py: 5, width: '100%' }}>
          <WizardSection
            step={0}
            currentStep={currentStep}
            title="Add new customer"
            activeContent={<CustomerStep onContinue={goToNextStep} />}
            onEdit={gotToPreviousStep}
          />
          <WizardSection
            step={1}
            currentStep={currentStep}
            title="Organization details"
            activeContent={<OrganizationStep />}
          />
        </Paper>
        <Footer>
          <BackButton onBack={goBack} />
          <Stack direction="row" spacing={2}>
            <Checkbox
              label="Create quote after save"
              checked={isCreateQuoteAfterSave}
              onChange={toggleCreateQuoteAfterSave}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isSubmitEnabled}
            >
              Save
            </Button>
          </Stack>
        </Footer>
      </Form>
    </PageTemplate>
  )
}
