import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Invoice, InvoiceProductBundleItem } from 'utils/global-types'

type InvoiceResponse = Invoice & {
  product_bundle_list: InvoiceProductBundleItem[]
}

type InvoiceQueryKey = ['invoice', Invoice['uuid']]

const getInvoice: QueryFunction<InvoiceResponse, InvoiceQueryKey> = async ({
  queryKey,
}) => {
  const [, invoiceUUID] = queryKey

  const response = await axios.get<InvoiceResponse>(`/invoices/${invoiceUUID}/`)

  return response.data
}

export const invoiceDetailsQueryOptions = (
  invoiceUUID: Invoice['uuid']
): WithRequired<
  UseQueryOptions<
    InvoiceResponse,
    AxiosError,
    InvoiceResponse,
    InvoiceQueryKey
  >,
  'queryKey'
> => ({
  queryKey: ['invoice', invoiceUUID!],
  queryFn: getInvoice,
  meta: { message: 'Failed to get invoice details' },
})

const useInvoice = (invoiceUUID: Invoice['uuid']) => {
  return useQuery({
    ...invoiceDetailsQueryOptions(invoiceUUID),
    enabled: !!invoiceUUID,
  })
}

export default useInvoice
