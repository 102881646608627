import { MutateFunction, useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { DateTime } from 'luxon'

import axios from 'configs/axios'
import { ReportType } from 'utils/global-types'
import { downloadFile } from 'utils/helpers'
import { addQueryParams } from 'utils/request'

export type ReportFilters = {
  type: ReportType
  start: string
  end: string
}
type DownloadReportResponse = File

const getReportFile: MutateFunction<
  DownloadReportResponse,
  AxiosError,
  ReportFilters
> = async (filters) => {
  const startDate = DateTime.fromJSDate(new Date(filters.start)).toFormat(
    'yyyy.MM.dd'
  )
  const endDate = DateTime.fromJSDate(new Date(filters.end)).toFormat(
    'yyyy.MM.dd'
  )
  const response = await axios.get<DownloadReportResponse>(
    addQueryParams(`/reports/download/`, {
      type: filters.type,
      start: startDate,
      end: endDate,
    }),
    {
      responseType: 'blob',
    }
  )

  const downloadURL = window.URL.createObjectURL(response.data)

  downloadFile(downloadURL, `${filters.type}-${startDate}_${endDate}.csv`)

  window.URL.revokeObjectURL(downloadURL)

  return response.data
}

const useDownloadReport = () => {
  return useMutation<DownloadReportResponse, AxiosError, ReportFilters>({
    mutationKey: ['downloadReport'],
    mutationFn: getReportFile,
    meta: { message: 'Failed to download report' },
  })
}

export default useDownloadReport
