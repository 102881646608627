import { useLocation, useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { Button, Container, styled, Typography } from '@mui/material'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Table, useTable } from 'components/Table'
import { LinkTabs } from 'components/Tabs'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import { addQueryParams } from 'utils/request'

import {
  mapQuotesListTabToParams,
  quotesListColumns,
  QuotesListTab,
  quotesListTabs,
} from './utils'

const PageHeader = styled(Container)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  paddingBottom: 24,
})

export default function QuoteListPage() {
  const location = useLocation()
  const navigate = useNavigate()

  const tab = (location.pathname.split('/')[2] || 'all') as QuotesListTab

  const table = useTable(
    'orders',
    addQueryParams('/orders/', mapQuotesListTabToParams[tab]),
    { columns: quotesListColumns }
  )

  return (
    <PageTemplate title="Quotes">
      <PageHeader>
        <Typography variant="h6">Quotes</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate('/quotes/new')}
        >
          Add Quote
        </Button>
        <Breadcrumbs />
      </PageHeader>
      <Table
        {...table}
        dataType="quotes"
        searchPlaceholder="Quote ID, etc..."
        tabsComponent={<LinkTabs tabs={quotesListTabs} />}
      />
    </PageTemplate>
  )
}
