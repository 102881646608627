import { PropsWithChildren } from 'react'
import { View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'

type ColumnProps = PropsWithChildren<{
  gap?: Style['gap']
  width?: Style['width']
  alignItems?: Style['alignItems']
}>

const Column = ({ width, alignItems, gap, children }: ColumnProps) => (
  <View style={{ width, alignItems, gap }}>{children}</View>
)

export default Column
