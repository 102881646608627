import * as yup from 'yup'

const reportDownloadSchema = yup.object({
  type: yup.string().required('Report type is required'),
  start: yup
    .date()
    .typeError('Invalid date')
    .max(yup.ref('end'), 'Start date must be before end date')
    .required('Start date is required'),
  end: yup
    .date()
    .typeError('Invalid date')
    .min(yup.ref('start'), 'End date must be after start date')
    .required('End date is required'),
})

export { reportDownloadSchema }
