import toast from 'react-hot-toast'
import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { emptyRequestWithFetch } from 'configs/axios'

type SyncProductsResponse = boolean
type SyncProductsVariables = void

const postSyncProducts: MutateFunction<
  SyncProductsResponse,
  Error,
  SyncProductsVariables
> = async () => {
  const response = await emptyRequestWithFetch('products/sync/')

  return response.ok || Promise.reject(new Error(response.status.toString()))
}

const useSyncProducts = () => {
  const queryClient = useQueryClient()
  return useMutation<SyncProductsResponse, Error, SyncProductsVariables>({
    mutationFn: postSyncProducts,
    onSuccess: () => {
      queryClient.invalidateQueries(['sync-products-history'])
      queryClient.invalidateQueries(['config'])
      toast.success('Products sync was initiated successfully')
    },
    onError: (error) => {
      const message =
        error.message === '400'
          ? 'Products sync already in progress'
          : 'Unable to initiate products sync'
      toast.error(message)
    },
  })
}

export default useSyncProducts
