import { AbilityBuilder, MongoQuery, PureAbility } from '@casl/ability'

import { Role } from 'utils/global-types'

type UltimateAction = 'manage'
type UltimateSubject = 'all'

type AbilityTemplate<A extends string, S extends string> = [
  UltimateAction | A,
  UltimateSubject | S
]

export type Ability =
  | AbilityTemplate<'view' | 'download' | 'change_terms_of', 'customer'>
  | AbilityTemplate<'view' | 'download', 'report'>
  | AbilityTemplate<'view' | 'force_sync', 'product'>
  | AbilityTemplate<'view' | 'place', 'order'>
  | AbilityTemplate<'view' | 'edit', 'invoice'>
  | AbilityTemplate<'view' | 'edit', 'config'>
  | AbilityTemplate<'view' | 'edit', 'shipment'>
  | AbilityTemplate<'view' | 'edit', 'payment'>
  | AbilityTemplate<'view', 'user'>
  | AbilityTemplate<'view', 'dashboard'>

export type AppAbility = PureAbility<Ability, MongoQuery>
export type AppAction = Ability[0]
export type AppSubject = Ability[1]

type AbilityBuilderCallback = (
  builder: AbilityBuilder<AppAbility>,
  isManager?: boolean
) => void

export const rolePermissions: Record<Role, AbilityBuilderCallback> = {
  Logistics: ({ can }) => {
    can('manage', 'shipment')
    can('view', 'invoice')
  },
  'Quote team': ({ can }) => {
    can('view', 'product')
    can('view', 'invoice')
    can('view', 'customer')
    can('view', 'order')
  },
  'Customer representatives': ({ can, cannot }, isManager) => {
    can('manage', 'all')
    cannot('edit', 'config')
    cannot('force_sync', 'product')
    /*
      It's possible to have multiple roles. (no reason for that, but possible)
      And this "cannot" can block manager from doing this actions.
    */
    if (!isManager) {
      cannot('change_terms_of', 'customer')
      cannot('download', 'report')
    }
  },
  Managers: ({ can, cannot }) => {
    can('manage', 'all')
    cannot('edit', 'config')
    cannot('force_sync', 'product')
  },
}
