import { ReactElement } from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { AuthWrapper } from 'components/AuthWrapper'
import NotificationsProvider from 'components/NotificationsProvider'
import PreviewProvider from 'components/PreviewDrawer/context'
import queryClient from 'configs/query'
import { AbilityProvider } from 'context/Ability'
import DialogProvider from 'context/Dialog/DialogContext'

import defaultTheme, { globalStyles } from './theme'

export default function AppProvider(): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={defaultTheme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <AuthWrapper>
            <AbilityProvider>
              <DialogProvider>
                <PreviewProvider>
                  <Outlet />
                  <CssBaseline />
                  <GlobalStyles styles={globalStyles} />
                  <NotificationsProvider />
                </PreviewProvider>
              </DialogProvider>
            </AbilityProvider>
          </AuthWrapper>
        </LocalizationProvider>
      </ThemeProvider>
      <ReactQueryDevtools />
      <ScrollRestoration />
    </QueryClientProvider>
  )
}
