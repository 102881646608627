import { Button, ButtonProps, CircularProgress } from '@mui/material'

interface LoadingButtonProps extends ButtonProps {
  isLoading?: boolean
  loadingText?: string
}

export default function LoadingButton(props: LoadingButtonProps) {
  const { children, isLoading, loadingText = 'Loading...' } = props
  return (
    <Button
      {...props}
      disabled={props.disabled || isLoading}
      startIcon={isLoading && <CircularProgress color="inherit" size={16} />}
    >
      {isLoading ? loadingText : children}
    </Button>
  )
}
