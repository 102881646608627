import { Document, View } from '@react-pdf/renderer'

import {
  Divider,
  Footer,
  Header,
  Page,
  Pagination,
  Space,
  TotalInfo,
  Typography,
} from 'pdf/components'
import { ProductItemsTable } from 'pdf/components/ProductItemsTable'
import { QuoteContext } from 'pdf/types'
import { getDocumentMetaInfoFields } from 'pdf/utils'

import MainInfo from './MainInfo'

export default function Quote(quote: QuoteContext) {
  return (
    <Document title="Quote">
      <Page>
        <Header title="Quote" subtitle={quote.order_id} />
        <Space height={24} />
        <MainInfo {...quote} />
        <Space height={8} />
        <Divider />
        <Space height={4} />
        <ProductItemsTable {...quote} />
        <Space height={4} />
        <TotalInfo {...quote} />
        <Space height={24} />
        <Divider />
        <Space height={18} />
        <View>
          <Typography medium size={7}>
            {quote.quote_terms_display}
          </Typography>
          <Typography medium size={7}>
            {quote.payment_terms_display}
          </Typography>
        </View>
        <Space height={16} />
        <Divider />
        <Space height={16} />
        <Footer {...getDocumentMetaInfoFields(quote)} />
        <Pagination />
      </Page>
    </Document>
  )
}
