import { useParams } from 'react-router-dom'
import { Stack, Table, TableBody, Typography } from '@mui/material'

import { useShipments } from 'api/shipping/useShipments'
import { StyledTableContainer } from 'components/Table/TableContainer'
import TableHead from 'components/Table/TableHead'
import { HeadCell } from 'components/Table/types'
import { Shipment } from 'utils/global-types'

import ShipmentRow from './ShipmentRow'

const shipmentsTableColumns: HeadCell<Shipment>[] = [
  { label: 'Date', value: 'created' },
  { label: 'Shipping method', value: 'shipping_method' },
  { label: 'Invoice ID', value: 'invoice_id' },
  { label: 'Tracking number', value: 'tracking_number' },
  { label: '', value: '' },
]

export default function ShipmentsSection() {
  const { id: orderID } = useParams()

  const { data: shipments } = useShipments(orderID!)

  if (!shipments?.length) {
    return null
  }

  return (
    <Stack gap={2} pt={3} px={3} pb={2}>
      <Typography>Shipments</Typography>
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead columns={shipmentsTableColumns} />
          <TableBody>
            {shipments.map((shipment) => (
              <ShipmentRow key={shipment.uuid} shipment={shipment} />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Stack>
  )
}
