import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'

export type OrderMetricsYearsResponse = {
  years: number[]
}
type OrderMetricsYearsQueryKey = ['order-metrics-years']

const getOrderMetricsYears: QueryFunction<
  OrderMetricsYearsResponse,
  OrderMetricsYearsQueryKey
> = async ({ queryKey }) => {
  const response = await axios.get<OrderMetricsYearsResponse>(
    '/reports/available-years/'
  )

  return response.data
}

export const orderMetricsYearQueryOptions: WithRequired<
  UseQueryOptions<
    OrderMetricsYearsResponse,
    AxiosError,
    string[],
    OrderMetricsYearsQueryKey
  >,
  'queryKey'
> = {
  queryKey: ['order-metrics-years'],
  queryFn: getOrderMetricsYears,
  meta: { message: 'Failed to get years list' },
}

const useOrderMetricsYears = () => {
  return useQuery({
    ...orderMetricsYearQueryOptions,
    select: (data) => data.years.map(String),
  })
}

export default useOrderMetricsYears
