import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton } from '@mui/material'

import useDeleteAddress from 'api/address/useDeleteAddress'
import { useDialog } from 'context/Dialog/DialogContext'
import { Address } from 'utils/global-types'

type DeleteAddressProps = {
  addressUUID: Address['uuid']
}

export default function DeleteAddress({ addressUUID }: DeleteAddressProps) {
  const [openDialog] = useDialog()
  const { mutateAsync: deleteAddress } = useDeleteAddress(addressUUID)
  const handleDelete = async () => {
    const isDeletingConfirmed = await openDialog({
      title: 'Are you sure to delete this address?',
      content: 'This action cannot be reverted.',
      actions: {
        cancel: 'Cancel',
        confirm: 'Yes, delete',
      },
    })

    if (isDeletingConfirmed) {
      deleteAddress()
    }
  }

  return (
    <IconButton onClick={handleDelete}>
      <DeleteIcon />
    </IconButton>
  )
}
