import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import DashboardSharpIcon from '@mui/icons-material/DashboardSharp'
import {
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  styled,
  Toolbar,
  Tooltip,
} from '@mui/material'

import { useDrawer } from '../Layout'

import ListItem from './ListItem'
import { listIcons, listItems } from './listItems'
import Logo from './Logo'

const StyledDrawer = styled(MuiDrawer)(({ theme, open }) => ({
  flexShrink: 0,
  boxSizing: 'border-box',
  zIndex: theme.zIndex.mobileStepper,

  '.MuiDrawer-paper': {
    overflowX: 'hidden',
  },

  ...(open
    ? {
        width: theme.shape.drawerWidth.max,
        ...theme.mixins.drawer.open,
        '& .MuiDrawer-paper': {
          width: theme.shape.drawerWidth.max,
          ...theme.mixins.drawer.open,
        },
      }
    : {
        width: theme.shape.drawerWidth.min,
        ...theme.mixins.drawer.close,
        '& .MuiDrawer-paper': {
          width: theme.shape.drawerWidth.min,
          ...theme.mixins.drawer.close,
        },
      }),
}))

export default function Drawer() {
  const [isOpen, , close] = useDrawer()

  return (
    <StyledDrawer open={isOpen} variant="permanent" data-testid="drawer">
      <Toolbar disableGutters>
        <Logo />
        {isOpen && (
          <Tooltip title="Collapse menu">
            <IconButton onClick={close}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      <List sx={{ px: 0.75 }}>
        <ListItem exact url="/" label="Dashboard" icon={DashboardSharpIcon} />
        <Divider />
        {listItems.map(({ id, ...item }) => (
          <ListItem key={id} {...item} icon={listIcons[id]} />
        ))}
      </List>
    </StyledDrawer>
  )
}
