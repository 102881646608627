import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { ShippingMethod } from 'utils/global-types'

type ShippingMethodResponse = ShippingMethod
type ShippingMethodQueryKey = ['shippingMethod', ShippingMethod['uuid']]

const getShippingMethod: QueryFunction<
  ShippingMethodResponse,
  ShippingMethodQueryKey
> = async ({ queryKey }) => {
  const [, shippingMethodUUID] = queryKey
  const response = await axiosInstance.get<ShippingMethodResponse>(
    `/customers/shipping-methods/${shippingMethodUUID}/`
  )

  return response.data
}

const useShippingMethod = (shippingMethodUUID?: ShippingMethod['uuid']) => {
  return useQuery<
    ShippingMethodResponse,
    AxiosError,
    ShippingMethodResponse,
    ShippingMethodQueryKey
  >({
    queryKey: ['shippingMethod', shippingMethodUUID!],
    queryFn: getShippingMethod,
    enabled: !!shippingMethodUUID,
    meta: { message: 'Failed to get shipping method details' },
  })
}

export default useShippingMethod
