import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'

import useOrderMetricsYears from 'api/reports/useOrderMetricsYears'

type ReportPeriodSelectorProps = {
  handleYearChange: (event: SelectChangeEvent<string>) => void
  selectedYear: string
}

export default function ReportPeriodSelector({
  handleYearChange,
  selectedYear,
}: ReportPeriodSelectorProps) {
  const { data: availableYears } = useOrderMetricsYears()

  return (
    <FormControl>
      <InputLabel id="year-label">Period</InputLabel>
      <Select
        name="year"
        size="small"
        labelId="year-label"
        label="Period"
        value={selectedYear}
        onChange={handleYearChange}
        sx={{ width: 110 }}
      >
        {availableYears?.map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
