import { ReactNode, useRef } from 'react'
import {
  Container,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material'

import { useOverflow } from 'utils/hooks'

export type ReadonlyFieldProps = TypographyProps & {
  label: ReactNode
  noPadding?: boolean
}

const NoLimitTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    top: '-20px',
    maxWidth: 'none',
  },
})

const ReadonlyFieldContainer = styled(Container)({
  width: 'unset',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 50,
  marginLeft: 0,
  marginRight: 0,
  position: 'relative',
  gap: 6,
})

const ReadonlyFieldContent = styled(Typography)(({ theme }) => ({
  overflow: 'hidden',
  whiteSpace: 'pre-line',
  textOverflow: 'ellipsis',
  marginTop: -4,
}))

export const ReadonlyFieldLabel = styled(Typography)({
  display: 'inline-block',
})

ReadonlyFieldLabel.defaultProps = {
  variant: 'caption',
  color: 'text.secondary',
  lineHeight: 1,
}

const ReadonlyField = ({
  sx,
  label,
  children,
  noPadding,
  ...typographyProps
}: ReadonlyFieldProps) => {
  const contentRef = useRef<HTMLSpanElement>(null)
  const isOverflowing = useOverflow(contentRef)
  const content = children == null || children === '' ? '-' : children

  return (
    <ReadonlyFieldContainer sx={sx}>
      <ReadonlyFieldLabel>{label}</ReadonlyFieldLabel>
      <NoLimitTooltip
        arrow
        disableInteractive
        disableHoverListener={!isOverflowing}
        title={content}
      >
        <ReadonlyFieldContent
          ref={contentRef}
          {...typographyProps}
          sx={{
            paddingBottom: (theme) => (noPadding ? 0 : theme.spacing(1.25)),
          }}
        >
          {content}
        </ReadonlyFieldContent>
      </NoLimitTooltip>
    </ReadonlyFieldContainer>
  )
}

export default ReadonlyField
