import { ChangeEvent } from 'react'
import {
  FormControl,
  FormLabel,
  Switch as MuiSwitch,
  SwitchProps,
} from '@mui/material'

import { useToggle } from 'utils/hooks'

interface Props extends SwitchProps {
  label: string
}

export default function Switch({
  sx,
  label,
  defaultChecked = false,
  onChange = () => undefined,
  readOnly,
}: Props) {
  const [checked, toggleChecked] = useToggle(defaultChecked)

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    if (readOnly) {
      return
    }
    toggleChecked()
    onChange(event, checked)
  }

  return (
    <FormControl
      sx={{
        ...sx,
        pointerEvents: readOnly ? 'none' : 'auto',
      }}
    >
      <FormLabel sx={{ fontSize: '0.75rem' }}>{label}</FormLabel>
      <MuiSwitch
        checked={checked}
        readOnly={readOnly}
        onChange={handleChange}
      />
    </FormControl>
  )
}
