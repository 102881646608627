import { ChangeEvent, FocusEvent } from 'react'
import { RegisterOptions, useController, useFormContext } from 'react-hook-form'
import {
  NumberFormatValues,
  NumericFormat,
  SourceInfo,
} from 'react-number-format'
import { SxProps, TextField, TextFieldProps, Theme } from '@mui/material'
import { noop } from 'lodash'

const sizeToSx: Record<ControlledInputSize, SxProps<Theme>> = {
  xs: {
    maxWidth: '10%',
    minWidth: 88,
  },
  sm: {
    maxWidth: '12%',
    minWidth: 120,
  },
  md: {},
}

type ControlledInputSize = 'xs' | 'sm' | 'md'

type ControlledInputProps = {
  name: string
  defaultValue?: unknown
  rules?: RegisterOptions
  size?: ControlledInputSize
} & Omit<TextFieldProps, 'size'>

export default function ControlledInput(props: ControlledInputProps) {
  const {
    name,
    rules,
    defaultValue,
    helperText,
    size = 'md',
    type,
    inputProps,
    ...restProps
  } = props

  const { control } = useFormContext()
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: number | string = e.target.value
    field.onChange(value)
    props.onChange?.(e)
  }

  const onNumericValueChange = (
    values: NumberFormatValues,
    source: SourceInfo
  ) => {
    field.onChange(values.floatValue ?? '')

    if (typeof props.onChange === 'function') {
      const sourceEvent = source.event as ChangeEvent<HTMLInputElement>

      const event = {
        ...sourceEvent,
        target: {
          ...(sourceEvent?.target ?? {}),
          value: values.value,
        },
      }
      props.onChange(event)
    }
  }

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    field.onBlur()
    props?.onBlur?.(e)
  }

  if (type === 'number') {
    return (
      <NumericFormat
        customInput={TextField}
        {...field}
        value={field.value ?? ''}
        onValueChange={onNumericValueChange}
        onBlur={onBlur}
        inputRef={field.ref}
        InputLabelProps={{ shrink: true }}
        thousandSeparator
        error={error !== undefined}
        isAllowed={(values) => {
          const { floatValue } = values
          if (inputProps?.max && floatValue !== undefined) {
            return floatValue <= inputProps.max
          }
          return true
        }}
        allowNegative={inputProps?.min < 0}
        helperText={error?.message ?? helperText ?? ''}
        {...restProps}
        decimalScale={inputProps?.decimalScale ?? 2}
        onChange={noop}
      />
    )
  }

  return (
    <TextField
      {...field}
      sx={[
        sizeToSx[size],
        ...(Array.isArray(restProps.sx) ? restProps.sx : [restProps.sx]),
      ]}
      value={field.value ?? ''}
      onChange={onChange}
      onBlur={onBlur}
      inputRef={field.ref}
      InputLabelProps={{ shrink: true }}
      error={error !== undefined}
      helperText={error?.message ?? helperText ?? ''}
      {...restProps}
    />
  )
}
