import { DialogConfig } from 'context/Dialog/DialogContext'

export const legacyTermsConfirmDialog: DialogConfig = {
  title: 'Remove legacy terms?',
  content:
    "You've checked payment terms details for the organization and ready to remove legacy terms message?",
  actions: {
    confirm: 'Remove',
  },
}
