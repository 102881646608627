import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { InputAdornment, MenuItem, Stack } from '@mui/material'
import { DateTime } from 'luxon'

import { ControlledInput, DatePicker, Select } from 'components/Form'
import { ReadonlyField } from 'components/ReadonlyField'
import { GeneralWrapper } from 'components/Wrappers'
import { Order, Payment, PaymentSource } from 'utils/global-types'
import { formatPrice, priceInputOptions } from 'utils/price'

import { useBalances } from './useBalances'

type EditPaymentFormProps = {
  payment: Partial<Payment>
  orderID: Order['order_id']
}

export type EditPaymentFormValues = Partial<{
  payment_date: DateTime
  source: PaymentSource
  amount: number
}>

export default function EditPaymentForm({
  payment,
  orderID,
}: EditPaymentFormProps) {
  const { isLoading, balances } = useBalances(
    (payment.invoice || payment.parent_invoice) as string,
    orderID
  )

  const { reset } = useFormContext()

  useEffect(() => {
    reset({
      method: payment.method,
      true_up_amount: payment.true_up_amount,
      payment_date: payment?.payment_date
        ? DateTime.fromISO(payment.payment_date)
        : DateTime.now(),
      amount: payment.amount,
    })
  }, [orderID, payment.invoice])

  if (isLoading) {
    return <>Loading...</>
  }

  return (
    <Stack gap={3}>
      <GeneralWrapper sx={{ pt: 2.5, pb: 0 }}>
        <ReadonlyField label="Organization balance" sx={{ flex: 1 }}>
          {formatPrice(balances.organizationBalance, balances.currency)}
        </ReadonlyField>
        <ReadonlyField label="Amount invoiced" sx={{ flex: 1 }}>
          {formatPrice(balances.amountInvoiced, balances.currency)}
        </ReadonlyField>
        <ReadonlyField label="Order balance" sx={{ flex: 1 }}>
          {formatPrice(balances.orderBalance, balances.currency)}
        </ReadonlyField>
      </GeneralWrapper>
      <Stack direction="row" gap={1}>
        <DatePicker name="payment_date" label="Date" />
        <Select name="method" label="Method" defaultValue="PAYPAL">
          <MenuItem value="PAYPAL">Paypal</MenuItem>
          <MenuItem value="IDEAL">Ideal</MenuItem>
          <MenuItem value="WIRE">Wire</MenuItem>
          <MenuItem value="STRIPE">Stripe</MenuItem>
          <MenuItem value="BRAINTREE">Braintree</MenuItem>
          <MenuItem value="INTERNAL">internal</MenuItem>
        </Select>
        <ControlledInput
          name="amount"
          label="Amount"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {balances.currency.toUpperCase()}
              </InputAdornment>
            ),
          }}
          inputProps={priceInputOptions}
        />

        <ControlledInput
          name="true_up_amount"
          label="True up amount"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {balances.currency.toUpperCase()}
              </InputAdornment>
            ),
          }}
          inputProps={{ step: 0.01, min: -Infinity }}
        />
      </Stack>
    </Stack>
  )
}
