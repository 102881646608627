import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Invoice, Shipment, ShipmentUpdate } from 'utils/global-types'

type CreateShipmentResponse = Shipment
type CreateShipmentVariables = Partial<ShipmentUpdate>

const createShipment = (invoiceID: Invoice['uuid']) => {
  const postShipment: MutationFunction<
    CreateShipmentResponse,
    CreateShipmentVariables
  > = async (variables) => {
    const response = await axios.post<CreateShipmentResponse>(
      '/shipments/shipments/',
      { ...variables, invoice: invoiceID }
    )

    return response.data
  }

  return postShipment
}

const useCreateShipment = (invoiceID: Invoice['uuid']) => {
  const queryClient = useQueryClient()

  return useMutation<
    CreateShipmentResponse,
    AxiosError,
    CreateShipmentVariables
  >({
    mutationFn: createShipment(invoiceID),
    onSuccess: ({ order_id: orderID }) => {
      queryClient.invalidateQueries(['shipments'])
      queryClient.invalidateQueries(['invoices'])
      queryClient.invalidateQueries(['orders'])
      queryClient.invalidateQueries(['order', orderID])
      queryClient.invalidateQueries(['preview-invoice', invoiceID])
    },
    meta: {
      message: 'Failed to create shipment',
      useGlobalError: false,
    },
  })
}

export default useCreateShipment
