import * as yup from 'yup'

const invoiceProductItemSchema = yup.object().shape({
  quantity_available: yup.number(),
  quantity_offered: yup
    .number()
    .typeError('Quantity must be a number')
    .integer('Quantity must be an integer number')
    .required('This field is required')
    .when(['quantity_available'], (available, schema) =>
      schema
        .min(0, 'Quantity must be greater than or equal to 0')
        .max(available, 'Quantity must be less than or equal to available')
    ),
  sales_price: yup
    .number()
    .typeError('Sales price must be a number')
    .min(0, 'Sales price should be greater than 0')
    .required('This field is required'),
  buying_price: yup
    .number()
    .typeError('Buying price must be a number')
    .min(0, 'Buying price should be greater than 0'),
})

export const invoiceSchema = yup.object().shape({
  discount_lump_sum: yup.number().min(0).typeError('Lump sum must be a number'),
  invoice_items: yup.array().of(invoiceProductItemSchema).required(),
  shipping_method: yup
    .string()
    .typeError('Shipping method must be a selected')
    .uuid()
    .required(),
  shipping_address: yup
    .string()
    .typeError('Shipping address must be a selected')
    .uuid()
    .required(),
  handling_fee: yup.number().typeError('Handling fee must be a number'),
  shipping_fee_overwrite: yup.string().nullable(),
  shipping_instructions: yup.string(),
  billing_address: yup
    .string()
    .typeError('Billing address must be a selected')
    .uuid()
    .required(),
  notes: yup.string(),
})
