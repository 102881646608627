import { useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Table, useTable } from 'components/Table'
import { LinkTabs } from 'components/Tabs'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import { Order } from 'utils/global-types'
import { addQueryParams } from 'utils/request'

import {
  mapOrderListTabToParams,
  ordersListColumns,
  OrdersListTab,
  ordersTabs,
} from './utils'

export default function OrderList() {
  const location = useLocation()

  const tab = (location.pathname.split('/')[2] || 'all') as OrdersListTab

  const table = useTable<Order>(
    'orders',
    addQueryParams('/orders/', mapOrderListTabToParams[tab]),
    { columns: ordersListColumns }
  )

  return (
    <PageTemplate title="Orders">
      <PageHeader>
        <Typography variant="h6">Orders</Typography>
        <Breadcrumbs />
      </PageHeader>
      <Table
        {...table}
        disableAddButton
        dataType="orders"
        tabsComponent={<LinkTabs tabs={ordersTabs} />}
      />
    </PageTemplate>
  )
}
