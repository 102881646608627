import { Text } from '@react-pdf/renderer'

export default function Pagination() {
  return (
    <Text
      fixed
      style={{
        left: 0,
        right: 48,
        bottom: 16,
        fontSize: 7,
        opacity: 0.6,
        color: 'black',
        fontWeight: 700,
        position: 'absolute',
        textAlign: 'right',
        textTransform: 'uppercase',
      }}
      render={({ pageNumber, totalPages }) =>
        totalPages > 1 && `Page ${pageNumber} `
      }
    />
  )
}
