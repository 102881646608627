import { InputBaseComponentProps } from '@mui/material'
import { capitalize } from 'lodash'

import { Currency, PaymentMethod } from './global-types'

type FormatPriceOptions = {
  priceFirst?: boolean
  maximumDecimals?: number
}

export function formatPriceValue(price: number, maximumDecimals = 2) {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: maximumDecimals,
  }).format(price)
}

export function formatPrice(
  price: number,
  currency: Currency,
  options: FormatPriceOptions = { priceFirst: false }
) {
  const formattedPrice = formatPriceValue(price, options.maximumDecimals)

  return options.priceFirst
    ? `${formattedPrice} ${currency.toUpperCase()}`
    : `${currency.toUpperCase()} ${formattedPrice}`
}

export function formatPercent(percent: number) {
  return `${(percent * 100).toFixed(2)}%`
}

export function shortPrice(price: number) {
  return new Intl.NumberFormat('en-US', { notation: 'compact' }).format(price)
}

export const priceInputOptions: InputBaseComponentProps = {
  min: 0,
  decimalScale: 2,
}

export const buyingPriceInputOptions: InputBaseComponentProps = {
  min: 0,
  decimalScale: 4,
}

export const salesPriceInputOptions: InputBaseComponentProps = {
  min: 0,
  decimalScale: 4,
}

export const percentageInputOptions: InputBaseComponentProps = {
  min: 0,
  max: 100,
  decimalScale: 2,
}

export const marginPercentsInputOptions: InputBaseComponentProps = {
  min: -9999.99,
  max: 99.99,
  decimalScale: 2,
}

export const getPaymentMethodLabel = (method: PaymentMethod) => {
  if (method === 'PAYPAL') {
    return 'PayPal'
  }

  return capitalize(method)
}
