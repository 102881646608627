import { CustomerList, Organization } from './global-types'

export const getCustomerLabel = (option: CustomerList) =>
  // eslint-disable-next-line max-len
  `${option.name} | ${option.email} | ${option.organization.name} | ${
    option.phone_number || '-'
  }`

export const mapCustomerOptions = (option: CustomerList) => ({
  label: getCustomerLabel(option),
  id: option.uuid,
  isDisabled: !option.is_active,
})

export const getOrganizationLabel = (option: Organization) => `${option.name}`

export const mapOrganizationOptions = (option: Organization) => ({
  label: getOrganizationLabel(option),
  id: option.uuid,
})
