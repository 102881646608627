import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form'
import toast from 'react-hot-toast'
import { AxiosError } from 'axios'
import {
  isArray,
  isObject,
  isString,
  mapValues,
  omitBy,
  startCase,
  words,
} from 'lodash'

import {
  Invoice,
  InvoiceType,
  Order,
  Organization,
  ShipmentStatus,
} from './global-types'

/**
 * Returns the difference between two plain objects
 * @param {T} a Source object
 * @param {T} b Object to compare against
 * @returns {T} Difference between the two objects
 */
export const differenceRecord = <T extends Record<string, unknown>>(
  a: T,
  b: T
): T => {
  return omitBy(a, (value, key) => b[key] === value) as T
}

export const generateSlug = (orgName: string) => {
  return orgName
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/ /g, '-')
}

export const isUUID = (uuid: string): boolean => {
  const pattern = new RegExp(
    '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}'
  )
  return pattern.test(uuid)
}

export const copyToClipboard = async (value: string) => {
  try {
    await navigator.clipboard.writeText(value)

    toast.loading('Copied to clipboard', { duration: 2000 })
  } catch {
    toast.error('Error occured while adding value to clipboard')
  }
}

export const getEmailDomain = (email?: string) => {
  return email?.split('@')[1]
}

export const downloadFile = (url: string, filename: string) => {
  const link = document.createElement('a')
  link.href = url
  link.download = filename

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const convertShipmentStatus = (
  shipmentStatus: ShipmentStatus
): string => {
  return shipmentStatus === 'SHIPPED' ? 'Yes' : 'No'
}

export const convertInvoiceType = (invoiceType: InvoiceType): string => {
  if (invoiceType === 'CREDIT') {
    return 'Credited'
  }

  if (invoiceType === 'DEBIT') {
    return 'Invoiced'
  }

  return 'Proforma'
}

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (!+bytes) {
    return '0 Kb'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const parsePDFError = async (
  error: AxiosError<unknown, unknown>
): Promise<string> => {
  try {
    // @ts-expect-error - text is available for Blob
    const errorText = JSON.parse(await error?.text())

    if (errorText?.non_field_errors?.length) {
      const { errors } = JSON.parse(
        errorText.non_field_errors[0].split('PDF service error: ')[1]
      )
      toast.error(`Field ${errors[0].name} - ${errors[0].message}`)
    }
    return errorText
  } catch (e) {
    toast.error('Something went wrong. Please try again later.')
    throw new Error(`Error parsing PDF error`)
  }
}

export const formatGramsToKg = (grams: number): string => {
  return Intl.NumberFormat('en-US').format(grams / 1000)
}

export const parseAPIError = (error: AxiosError) => {
  try {
    if (error?.response?.data) {
      Object.entries(error!.response!.data).forEach(([key, value]) => {
        toast.error(`Error: ${key} ${value[0]}`, {
          duration: 5000,
        })
      })
    } else if (error.status === 500) {
      toast.error('Something went wrong. Please try again later.')
    } else if (typeof error === 'string') {
      toast.error(error)
    }
  } catch (e) {
    toast.error('Something went wrong. Please try again later.')
  }
}

const parseErrorBody = <T extends FieldValues>(
  setError: UseFormSetError<T>,
  body: unknown,
  name = ''
) => {
  if (isObject(body)) {
    mapValues(body, (value, key) => {
      parseErrorBody(setError, value, name ? `${name}.${key}` : key)
    })
  }
  if (isArray(body)) {
    body.forEach((value, index) => {
      if (isString(value)) {
        setError(name as FieldPath<T>, { message: value })
      } else {
        parseErrorBody(setError, value, `${name}.${index}`)
      }
    })
  }
}

export const parseFormError = <T extends FieldValues>(
  error: AxiosError,
  setError: UseFormSetError<T>
) => {
  try {
    if (error?.response?.data) {
      parseErrorBody(setError, error.response.data)
    } else if (error.status === 500) {
      toast.error('Something went wrong. Please try again later.')
    } else if (typeof error === 'string') {
      toast.error(error)
    }
  } catch (e) {
    toast.error('Something went wrong. Please try again later.')
  }
}

type GeneratePDFNameProps = {
  fileType: 'Invoice' | 'Quote' | 'Proforma'
  organizationName: Organization['name']
  id: Order['order_id'] | Invoice['invoice_id']
}

export const generatePDFName = ({
  fileType,
  organizationName,
  id,
}: GeneratePDFNameProps) => {
  const organizationSlug = words(startCase(organizationName || '')).join('_')
  const fileName = `Omnical_${fileType}_${organizationSlug}`

  if (fileType === 'Proforma') {
    return `${fileName}.pdf`
  }

  return `${fileName}_${id}.pdf`
}
