import { Button, Typography } from '@mui/material'

import { QuoteResponse } from 'api/customers-portal/useQuote'
import { ControlledInput } from 'components/Form'
import { InfoWrapper } from 'components/Wrappers'

type RequestPaymentFormProps = {
  quote: QuoteResponse
}

export default function RequestPaymentForm({ quote }: RequestPaymentFormProps) {
  return (
    <>
      <Typography fontSize={24} fontWeight="bold">
        Invoice for quote {quote.quote_id}
      </Typography>
      <InfoWrapper>
        <Typography variant="body1">
          Quote:{' '}
          <Typography component="span" fontWeight={500}>
            {quote.quote_id}
          </Typography>
        </Typography>
        <Typography variant="body1">
          Name:{' '}
          <Typography component="span" fontWeight={500}>
            {quote.customer_name}
          </Typography>
        </Typography>
        <Typography variant="body1">
          Shipping Method:{' '}
          <Typography component="span" fontWeight={500}>
            {quote.shipping_method_name}
          </Typography>
        </Typography>
      </InfoWrapper>
      <Typography variant="body1">
        You will receive an invoice at <b>{quote.customer_email}</b>
      </Typography>
      <Typography variant="body1">
        If you have a PO number for this order, please add it below and we will
        add it to your invoice.
      </Typography>
      <ControlledInput name="po_number" label="PO Number" />
      <Button type="submit" variant="contained">
        Send Invoice
      </Button>
    </>
  )
}
