import { startCase } from 'lodash'

import { HeadCell, TableFilters } from 'components/Table/types'
import { LinkTabI } from 'components/Tabs'
import { Quote } from 'utils/global-types'
import { formatPrice } from 'utils/price'

import PreviewQuotePdfCell from './PreviewQuotePdfCell'

export type QuotesListTab =
  | 'all'
  | 'draft'
  | 'review'
  | 'discount'
  | 'issued'
  | 'ordered'
  | 'rejected'
  | 'expired'

export const quotesListTabs: LinkTabI[] = [
  {
    label: 'All Quotes',
    path: '/quotes',
  },
  {
    label: 'Draft',
    path: '/quotes/draft',
  },
  {
    label: 'Need Review',
    path: '/quotes/review',
  },
  {
    label: 'Need Discount',
    path: '/quotes/discount',
  },
  {
    label: 'Issued',
    path: '/quotes/issued',
  },
  {
    label: 'Ordered',
    path: '/quotes/ordered',
  },
  {
    label: 'Rejected',
    path: '/quotes/rejected',
  },
  {
    label: 'Expired',
    path: '/quotes/expired',
  },
]

export const mapQuotesListTabToParams: Record<QuotesListTab, TableFilters> = {
  all: { status__in: ['DRAFT', 'QUOTE'].join(',') },
  draft: { status: 'DRAFT', labels__exclude: 'REJECTED' },
  review: {
    status__in: ['DRAFT', 'QUOTE'].join(','),
    labels__contains: 'NEED_REVIEW',
    labels__exclude: 'REJECTED',
  },
  discount: {
    status__in: ['DRAFT', 'QUOTE'].join(','),
    labels__contains: 'NEED_DISCOUNT',
    labels__exclude: 'REJECTED',
  },
  issued: {
    status: 'QUOTE',
    labels__contains: 'ISSUED',
    labels__exclude: 'REJECTED',
  },
  ordered: { status: 'ORDER' },
  rejected: {
    status: 'QUOTE',
    labels__contains: 'REJECTED',
  },
  expired: {
    status: 'QUOTE',
    labels__contains: 'EXPIRED',
    labels__exclude: 'REJECTED',
  },
}

export const quotesListColumns: HeadCell<Quote>[] = [
  {
    value: 'order_id',
    label: 'Quote',
    getHref: (row) =>
      row.status === 'ORDER'
        ? `/orders/${row.order_id}`
        : `/quotes/${row.order_id}`,
    width: 150,
  },
  {
    value: 'updated',
    label: 'Last Updated',
    width: 100,
  },
  {
    value: 'customer_email',
    label: 'Customer email',
    width: 200,
  },
  {
    value: (quote) =>
      formatPrice(quote.total_amount, quote.currency, {
        priceFirst: true,
      }),
    label: 'Amount in customer currency',
    align: 'right',
    width: 200,
  },
  {
    value: (quote) => startCase(quote.status.toLowerCase()),
    label: 'Status',
    width: 100,
  },
  {
    value: ({ order_id }) => <PreviewQuotePdfCell orderID={order_id} />,
    label: '',
  },
]
