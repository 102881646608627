import { SubmitErrorHandler, useFormContext, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { ButtonProps, Menu, MenuItem } from '@mui/material'

import useChangeOrderStatus from 'api/order/useChangeOrderStatus'
import useOrder from 'api/order/useOrder'
import { LoadingButton } from 'components/Buttons'
import { useMenuAnchor } from 'utils/hooks'

import { QuoteFormI, SubmitQuoteArgs } from './QuoteForm'

type SaveAsButtonProps = Omit<ButtonProps, 'onSubmit' | 'onError'> & {
  orderID: string
  onSubmit: (args: SubmitQuoteArgs) => Promise<void>
  onError: SubmitErrorHandler<QuoteFormI>
}

export default function SaveAsButton({
  orderID,
  onError,
  onSubmit,
  ...props
}: SaveAsButtonProps) {
  const navigate = useNavigate()
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext<QuoteFormI>()

  const customer = useWatch({ name: 'quote.customer' })

  const { data } = useOrder(orderID)
  const { order: quote } = data || {}

  const { mutateAsync: changeQuoteStatus } = useChangeOrderStatus(orderID)

  const { anchorEl, handleClose, open, handleClick } = useMenuAnchor()

  const handleSaveAsDraft = handleSubmit(async (formData) => {
    await onSubmit({ formData })
    navigate(`/quotes/${orderID}`)
  }, onError)

  const handleSaveAsQuote = handleSubmit(async (formData) => {
    await onSubmit({ formData })
    if (quote?.status !== 'QUOTE') {
      await changeQuoteStatus({ status: 'QUOTE' })
    }
    navigate(`/quotes/${orderID}`)
  }, onError)

  const handleConvertToOrder = handleSubmit(async (formData) => {
    await onSubmit({ formData })
    await changeQuoteStatus({ status: 'ORDER' })
    navigate(`/orders/${orderID}`)
  }, onError)

  const isSubmitDisabled = !customer
  const isQuote = quote?.status === 'QUOTE'

  return (
    <>
      <LoadingButton
        disableElevation
        variant="contained"
        isLoading={isSubmitting}
        disabled={isSubmitDisabled}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        {...props}
      >
        Save as
      </LoadingButton>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {!isQuote && (
          <MenuItem onClick={handleSaveAsDraft}>Save as draft</MenuItem>
        )}
        <MenuItem onClick={handleSaveAsQuote}>Save as quote</MenuItem>
        {isQuote && (
          <MenuItem onClick={handleConvertToOrder}>
            Save and convert to order
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
