import { yupResolver } from '@hookform/resolvers/yup'

import { DialogConfig } from 'context/Dialog/DialogContext'
import { Address } from 'utils/global-types'

import DialogAddressForm from './DialogAddressForm'
import { addressSchema } from './validation'

export const addAddressDialog: DialogConfig = {
  title: 'Add new Address',
  content: <DialogAddressForm mode="create" />,
  actions: {
    confirm: 'Add new address',
  },
  // @ts-expect-error ts(2322) Resolver infer type is not equal to form values type
  resolver: yupResolver(addressSchema),
}
export const createEditAddressDialog: (address: Address) => DialogConfig = (
  address
) => ({
  title: 'Edit Address',
  content: <DialogAddressForm mode="edit" address={address} />,
  actions: {
    cancel: 'Discard changes',
    confirm: 'Save changes',
  },
  // @ts-expect-error ts(2322) Resolver infer type is not equal to form values type
  resolver: yupResolver(addressSchema),
})
