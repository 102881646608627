import { Typography } from '@mui/material'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'

import ReportData from './ReportData'

export default function Reports() {
  return (
    <PageTemplate title="Reports">
      <PageHeader>
        <Typography variant="h6">Reports</Typography>
        <Breadcrumbs />
      </PageHeader>
      <ReportData />
    </PageTemplate>
  )
}
