import { DefaultValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import useCancelOrder, {
  CancelOrderVariables,
} from 'api/customers-portal/useCancelOrder'
import useOrder from 'api/customers-portal/useOrder'
import CustomerActionFlow from 'components/CustomerActionView/CustomerActionFlow'
import FeedbackForm from 'components/CustomerActionView/FeedbackForm'
import { ratingNames } from 'utils/global-types'

export type CancelOrderFormValues = Omit<CancelOrderVariables, 'uuid'>

const defaultValues: DefaultValues<CancelOrderFormValues> = {
  product_pricing: 0,
  product_details: 0,
  shipping_details: 0,
  payment_details: 0,
  timing_of_quote: 0,
  communication: 0,
}

export default function CancelOrderPage() {
  const { id } = useParams()
  const { data: order } = useOrder(id)
  const { mutateAsync: cancelOrder } = useCancelOrder()

  const onSubmit = async (values: CancelOrderFormValues) => {
    for (const key of ratingNames) {
      values[key] = values[key] === 0 ? undefined : values[key]
    }
    await cancelOrder({
      uuid: id!,
      ...values,
      reason_text: values.reason === 'other' ? values.reason_text : undefined,
    })
  }

  return (
    <CustomerActionFlow
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      formComponent={
        <FeedbackForm
          title={`Cancel order ${order!.order_id}`}
          submitText="Cancel order"
        />
      }
      successProps={{
        email: order!.customer_support_email,
        title: 'Your order has been successfully cancelled!',
        description:
          'Thank you for your feedback. Your order has been successfully cancelled.',
      }}
    />
  )
}
