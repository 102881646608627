import { Link, useParams } from 'react-router-dom'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { Button, Stack, Table, TableBody, Typography } from '@mui/material'
import { filter } from 'lodash'

import useInvoices from 'api/invoice/useInvoices'
import useOrder from 'api/order/useOrder'
import { LabelWithTooltip } from 'components/LabelWithTooltip'
import { ReadonlyField } from 'components/ReadonlyField'
import { StyledTableContainer } from 'components/Table/TableContainer'
import TableHead from 'components/Table/TableHead'
import { HeadCell } from 'components/Table/types'
import Warning from 'components/WarningMessage'
import { GeneralStats, GeneralWrapper } from 'components/Wrappers'
import { Can } from 'context/Ability'
import { Invoice } from 'utils/global-types'
import { formatPrice } from 'utils/price'

import InvoiceRow from './InvoiceRow'

const invoicesTableColumns: HeadCell<Invoice>[] = [
  { value: 'created', label: 'Date' },
  { value: 'invoice_id', label: 'Invoice ID', width: 100 },
  { value: 'total_amount', label: 'Amount' },
  { value: 'total_paid_amount', label: 'Paid' },
  { value: 'outstanding_amount', label: 'Outstanding' },
  { value: 'shipment_status', label: 'Ship date', width: 100 },
  { value: '', label: '' },
]

export default function InvoicesSection() {
  const { id: orderID } = useParams()
  const { data: invoices } = useInvoices(orderID!)
  const { data: orderQueryData } = useOrder(orderID!)
  const order = orderQueryData!.order

  const isInvoiceCreatable = !order.is_all_products_invoiced_or_proforma

  const showOutstandingAmountWarning =
    orderQueryData?.order?.outstanding_amount &&
    orderQueryData.order.outstanding_amount < 0

  // check all invoices if some of them are credited but have paid amount return true
  const showCreditedInvoiceWarning =
    filter(
      invoices,
      (invoice) => invoice.is_credited && invoice.total_paid_amount > 0
    ).length > 0

  return (
    <Stack gap={2} pt={3} px={3} pb={2}>
      <GeneralWrapper>
        <ReceiptIcon />
        <Typography pl={2} py={1} flex={1}>
          Invoices
        </Typography>
        <Can I="edit" a="invoice">
          <Button
            variant="contained"
            disabled={!isInvoiceCreatable}
            component={Link}
            to="invoice/new"
          >
            Create Invoice
          </Button>
        </Can>
        <GeneralStats sx={{ mt: 2 }}>
          <ReadonlyField label="Amount invoiced">
            {formatPrice(order.invoiced_amount, order.currency)}
          </ReadonlyField>
          <ReadonlyField label="Amount paid">
            {formatPrice(order.paid_amount, order.currency)}
          </ReadonlyField>
          <ReadonlyField label="Organization balance">
            {formatPrice(order.balance, order.currency)}
          </ReadonlyField>
          {order.post_invoiced_difference_display > 0 && (
            <ReadonlyField
              label={
                <LabelWithTooltip
                  label="Extra Shipping"
                  tooltipText="Extra Shipping and Handling Fees due to the inclusion of multiple invoices, including VAT."
                />
              }
            >
              {formatPrice(
                order.post_invoiced_difference_display,
                order.currency
              )}
            </ReadonlyField>
          )}
          <ReadonlyField label="Order balance">
            {formatPrice(order.order_balance, order.currency)}
          </ReadonlyField>
        </GeneralStats>
      </GeneralWrapper>
      {!!showOutstandingAmountWarning && (
        <Warning mb={0}>
          Outstanding amount is negative. Please check the invoices and
          payments.
        </Warning>
      )}
      {!!showCreditedInvoiceWarning && (
        <Warning mb={0}>
          Some of the invoices was credited but still has related paiments.
        </Warning>
      )}
      {!!invoices?.length && (
        <StyledTableContainer>
          <Table stickyHeader>
            <TableHead columns={invoicesTableColumns} />
            <TableBody>
              {invoices.map((invoice) => (
                <InvoiceRow invoice={invoice} key={invoice.uuid} />
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </Stack>
  )
}
