import { Box, styled } from '@mui/material'

type LegendIconProps = {
  legendColor: string
}

const LegendIcon = styled(Box)<LegendIconProps>(({ legendColor }) => ({
  width: 12,
  height: 12,
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: 8,
  backgroundColor: legendColor,
}))

export default LegendIcon
