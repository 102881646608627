import { PropsWithChildren } from 'react'
import { Box, Divider, Stack, Typography } from '@mui/material'

type QuoteDetailsSectionProps = PropsWithChildren<{
  title: string
  flex?: string
}>

export default function QuoteDetailsSection({
  title,
  children,
  flex,
}: QuoteDetailsSectionProps) {
  return (
    <Box flex={flex}>
      <Typography variant="h6" component="h2" sx={{ fontSize: '1rem' }}>
        {title}
      </Typography>
      <Divider sx={{ pb: 2 }} />
      <Stack pt={2} gap={1.5}>
        {children}
      </Stack>
    </Box>
  )
}
