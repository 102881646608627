import {
  FetchQueryOptions,
  QueryFunction,
  useQuery,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Customer, ShippingMethod } from 'utils/global-types'

export type QuoteResponse = {
  quote_id: string
  po_number: string
  customer_name: Customer['name']
  customer_email: Customer['email']
  expiration_date: string
  shipping_method_name?: ShippingMethod['name']
  validity_days: number
  customer_support_email: string
}
type QuoteQueryKey = ['customers-portal/quote', string]

export const getQuery: QueryFunction<QuoteResponse, QuoteQueryKey> = async ({
  queryKey,
}) => {
  const [, quoteUUID] = queryKey
  const { data: quote } = await axios.get<QuoteResponse>(
    `/customers-portal/quotes/${quoteUUID}/`
  )

  return quote
}

export const quoteDetailsQueryOptions = (
  quoteUUID: string
): WithRequired<
  FetchQueryOptions<QuoteResponse, AxiosError, QuoteResponse, QuoteQueryKey>,
  'queryKey'
> => ({
  queryKey: ['customers-portal/quote', quoteUUID],
  queryFn: getQuery,
  staleTime: 1000 * 30, // 30 seconds
  meta: { useGlobalError: false },
})

const useQuote = (quoteUUID?: string) => {
  return useQuery({
    ...quoteDetailsQueryOptions(quoteUUID!),
    enabled: !!quoteUUID,
  })
}

export default useQuote
