import { Image, View } from '@react-pdf/renderer'

import logo from 'assets/img/logo-blue.png'
import { getSrc } from 'pdf/utils'

import { Typography } from '.'

interface HeaderProps {
  title: string
  subtitle: string | null
}

const Header = ({ subtitle, title }: HeaderProps) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <View style={{ display: 'flex' }}>
      <Typography size={25} medium>
        {title}
      </Typography>
      {subtitle && (
        <Typography secondary medium size={11}>
          {subtitle}
        </Typography>
      )}
    </View>
    <Image
      src={getSrc(logo)}
      style={{ height: 32, width: 140, marginTop: -15 }}
    />
  </View>
)

export default Header
