import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, IconButton, styled, Toolbar, Tooltip } from '@mui/material'

import { useDrawer } from '../Layout'

import UserSettings from './UserSettings'

type HeaderProps = {
  open: boolean
}

const StyledAppBar = styled(AppBar)<HeaderProps>(({ theme, open }) => ({
  height: 64,

  color: theme.palette.text.primary,
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.mobileStepper + 1,

  ...(open && {
    width: `calc(100% - ${theme.shape.drawerWidth.max}px)`,
    marginLeft: theme.shape.drawerWidth.max,
  }),
  ...theme.mixins.drawer[open ? 'open' : 'close'],
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  paddingLeft: theme.spacing(1.75),

  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(1.75),
  },
}))

export default function Header() {
  const [isOpen, open] = useDrawer()

  return (
    <StyledAppBar open={isOpen} position="fixed">
      <StyledToolbar>
        {!isOpen && (
          <Tooltip title="Open menu">
            <IconButton onClick={open}>
              <MenuIcon />
            </IconButton>
          </Tooltip>
        )}
        <UserSettings />
      </StyledToolbar>
    </StyledAppBar>
  )
}
