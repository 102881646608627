import { useParams } from 'react-router-dom'
import { Stack, Table, TableBody, Typography } from '@mui/material'

import usePayments from 'api/payments/usePayments'
import { StyledTableContainer } from 'components/Table/TableContainer'
import TableHead from 'components/Table/TableHead'
import { HeadCell } from 'components/Table/types'
import { Payment } from 'utils/global-types'

import PaymentRow from './PaymentRow'

const paymentsTableColumns: HeadCell<Payment>[] = [
  { label: '', value: '' },
  { label: 'Date', value: 'created' },
  { label: 'Invoice ID', value: 'invoice_id' },
  { label: 'Source', value: 'source' },
  { label: 'Destination', value: 'destination' },
  { label: 'Method', value: 'method' },
  {
    value: 'amount',
    label: (
      <Stack>
        <Typography fontWeight="500" fontSize="0.875rem">
          Amount
        </Typography>
        <Typography variant="caption" color="text.secondary">
          Received + true up amount
        </Typography>
      </Stack>
    ),
  },
  { label: '', value: '' },
]

export default function PaymentsSection() {
  const { id: orderID } = useParams()

  const { data: payments } = usePayments(orderID!)

  if (!payments?.length) {
    return null
  }

  return (
    <Stack gap={2} pt={3} px={3} pb={2}>
      <Typography>Transactions</Typography>
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead columns={paymentsTableColumns} />
          <TableBody>
            {payments.map((payment) => (
              <PaymentRow key={payment.uuid} payment={payment} />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Stack>
  )
}
