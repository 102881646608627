import { PropsWithChildren, ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  SxProps,
  Typography,
} from '@mui/material'

import { DataStatsBar, DataStatsBarItemI } from 'components/StatsBar'
import { TableRow } from 'components/Table/types'

import { stepToFormMap, stepToStatsMap } from '../utils'

const statsBarStyles: SxProps = {
  mb: 2,
  bgcolor: 'divider',
  borderRadius: 2,
}

const chipStyles: SxProps = {
  flex: 1,
  lineHeight: '100%',
}

type WizardSectionProps = PropsWithChildren<{
  currentStep: number
  step: number
  title: string
  activeContent: ReactNode
  onEdit?: () => void
}>

export default function WizardSection({
  currentStep,
  step,
  title,
  activeContent,
  onEdit = () => undefined,
}: WizardSectionProps) {
  const isActive = currentStep === step
  const isCompleted = currentStep > step
  const isVisited = isActive || isCompleted
  const chipColor = isVisited ? 'primary' : 'default'

  const { getValues } = useFormContext()

  const stats = stepToStatsMap[step]
  const statsData = getValues(stepToFormMap[step])

  const chipContent = isCompleted ? '' : step + 1
  const chipIcon = isCompleted ? <CheckIcon fontSize="small" /> : undefined
  const chipSx = isCompleted
    ? { ...chipStyles, '& > span': { px: 0.75 } }
    : chipStyles

  return (
    <Stack gap={1} marginBottom={1}>
      <Stack gap={1} flexDirection="row" alignItems="center" marginBottom={1}>
        <Chip
          color={chipColor}
          sx={chipSx}
          label={chipContent}
          icon={chipIcon}
        />
        <Typography flex={99} marginTop="0 !important" variant="h6">
          {title}
        </Typography>
      </Stack>

      {isVisited && (
        <Stack gap={2.75} flexDirection="row" alignItems="center">
          <Divider orientation="vertical" flexItem sx={{ flex: 1 }} />
          <Box sx={{ flex: 99, pb: 2, maxWidth: 'calc(100% - 37px)' }}>
            {isActive ? (
              activeContent
            ) : (
              <DataStatsBar
                items={stats as DataStatsBarItemI<TableRow>[]}
                data={statsData}
                sx={statsBarStyles}
                subtitlesSx={{ color: 'text.secondary' }}
              >
                <Button
                  startIcon={<EditIcon />}
                  sx={{ lineHeight: '100%', ml: 2 }}
                  onClick={onEdit}
                >
                  Edit
                </Button>
              </DataStatsBar>
            )}
          </Box>
        </Stack>
      )}
    </Stack>
  )
}
