import { useFormContext } from 'react-hook-form'
import { Button, Stack } from '@mui/material'
import { get } from 'lodash'

type ClearSelectionButtonProps = {
  names: readonly string[]
}

const ClearSelectionButton = ({ names }: ClearSelectionButtonProps) => {
  const {
    resetField,
    watch,
    formState: { defaultValues },
  } = useFormContext()

  const values = watch(names)

  if (values.every((value, i) => value === get(defaultValues, names[i]))) {
    return null
  }

  const onClick = () => {
    names.forEach((name) => resetField(name))
  }

  return (
    <Stack direction="row" justifyContent="flex-end">
      <Button onClick={onClick} sx={{ textTransform: 'none' }}>
        Clear Selection
      </Button>
    </Stack>
  )
}

export default ClearSelectionButton
