import toast from 'react-hot-toast'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { DetailCustomer, UpdateCustomer } from 'utils/global-types'

type EditCustomerResponse = DetailCustomer

export type EditCustomerVariables = UpdateCustomer & {
  customerId: string
}

const editCustomer: MutationFunction<
  EditCustomerResponse,
  EditCustomerVariables
> = async ({ customerId, ...customer }) => {
  const response = await axiosInstance.put<EditCustomerResponse>(
    `/customers/customers/${customerId}/`,
    customer
  )

  return response.data
}

const useEditCustomer = () => {
  const queryClient = useQueryClient()
  return useMutation<
    EditCustomerResponse,
    AxiosError<Record<string, string[]>>,
    EditCustomerVariables
  >({
    mutationFn: editCustomer,
    onSuccess: (customer) => {
      queryClient.invalidateQueries(['customers'])
      queryClient.invalidateQueries(['customer', customer.uuid])
      toast.success('Customer edited successfully')
    },
    meta: {
      message: 'Failed to update customer',
      useGlobalError: false,
    },
  })
}

export default useEditCustomer
