import { ReactNode } from 'react'
import { Alert, AlertColor } from '@mui/material'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'

export interface IToast {
  message: ReactNode
  severity?: SeverityProp
  state: SnackbarOrigin
  open: boolean
  reference: (el: HTMLDivElement) => void
  offset: number
  id: string
  duration: number
}
type SeverityProp = AlertColor | 'blank' | 'loading' | 'custom'

const toastToAlertMap: Record<SeverityProp, AlertColor> = {
  error: 'error',
  blank: 'info',
  loading: 'info',
  success: 'success',
  info: 'info',
  warning: 'warning',
  custom: 'info',
}

const BasicToast = ({
  open,
  state,
  message,
  severity = 'info',
  reference,
  offset,
  id,
  duration,
}: IToast) => {
  const { vertical, horizontal } = state
  const alertColor = toastToAlertMap[severity]
  return (
    <Snackbar
      autoHideDuration={duration}
      ref={reference}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      key={id}
      sx={{
        transform: `translateY(-${offset}px)!important`,
      }}
    >
      <Alert
        sx={{ fontSize: '0.875rem' }}
        variant="filled"
        icon={false}
        severity={alertColor}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default BasicToast
