import Warning from 'components/WarningMessage'
import { Order } from 'utils/global-types'

type WarningMarginProps = {
  marginPercent: Order['total_margin_percent']
}

const MIN_MARGIN_PERCENT = 15

export default function WarningMargin({ marginPercent }: WarningMarginProps) {
  if (marginPercent >= MIN_MARGIN_PERCENT) {
    return null
  }
  return <Warning>Margin is less than {MIN_MARGIN_PERCENT}%</Warning>
}
