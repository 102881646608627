import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { emptyRequestWithFetch } from 'configs/axios'
import { FetchError, Order, Shipment } from 'utils/global-types'

type DeleteShipmentResponse = boolean
type DeleteShipmentVariables = void

const deleteShipment = (shipmentID: Shipment['uuid']) => {
  const deleteShipmentMutation: MutateFunction<
    DeleteShipmentResponse,
    DeleteShipmentVariables
  > = async () => {
    const response = await emptyRequestWithFetch(
      `shipments/shipments/${shipmentID}/`,
      'DELETE'
    )

    return response.status === 204 || Promise.reject(response)
  }

  return deleteShipmentMutation
}

const useDeleteShipment = (
  shipmentID: Shipment['uuid'],
  orderID: Order['order_id']
) => {
  const queryClient = useQueryClient()

  return useMutation<
    DeleteShipmentResponse,
    FetchError,
    DeleteShipmentVariables
  >({
    mutationFn: deleteShipment(shipmentID),
    onSuccess: () => {
      queryClient.invalidateQueries(['shipments'])
      queryClient.invalidateQueries(['invoices'])
      queryClient.invalidateQueries(['orders'])
      queryClient.invalidateQueries(['order', orderID])
    },
    meta: { message: 'Error deleting shipment' },
  })
}

export default useDeleteShipment
