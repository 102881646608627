import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { MenuItem, Stack, SxProps, Typography } from '@mui/material'
import { DateTime } from 'luxon'

import { DatePicker, Select } from 'components/Form'
import { ReportType } from 'utils/global-types'

const datePickerRowStyles: SxProps = {
  '& .MuiFormControl-root': {
    mb: 0,
  },
  '& .MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    top: '100%',
  },
  mb: 3,
}
type SelectOptions = { value: ReportType; title: string }[]

const typeOptions: SelectOptions = [
  { value: 'reviews_io', title: 'Reviews IO' },
  { value: 'order_metrics', title: 'Order metrics' },
  { value: 'orders', title: 'Orders' },
  { value: 'orders_with_items', title: 'Orders with items' },
  { value: 'invoices', title: 'Invoices' },
  { value: 'transactions', title: 'Transactions' },
  { value: 'decline_feedback', title: 'Decline feedback' },
]

export type FormValues = {
  start: DateTime
  end: DateTime
  type: ReportType
}

export default function DownloadReport() {
  const { setValue, watch } = useFormContext<FormValues>()
  const endDate = watch('end')

  useEffect(() => {
    setValue('type', 'reviews_io')
    setValue('start', DateTime.now().minus({ week: 2 }))
    setValue('end', DateTime.now())
  }, [setValue])

  return (
    <Stack rowGap={2} width="800px">
      <Stack
        columnGap={1}
        flexDirection="row"
        alignSelf="flex-start"
        sx={datePickerRowStyles}
      >
        <DatePicker name="start" label="From" maxDate={endDate} />
        <Typography>—</Typography>
        <DatePicker name="end" label="To" maxDate={DateTime.now()} />
      </Stack>
      <Select name="type" label="Type" defaultValue="reviews_io">
        {typeOptions.map(({ value, title }) => (
          <MenuItem key={value} value={value}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  )
}
