import { Link, useNavigate, useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import EditIcon from '@mui/icons-material/Edit'
import StickyNoteIcon from '@mui/icons-material/StickyNote2Outlined'
import { Button, Stack, Tooltip, Typography } from '@mui/material'

import useChangeOrderStatus from 'api/order/useChangeOrderStatus'
import useOrder from 'api/order/useOrder'
import useUpdateOrderLabels from 'api/order/useUpdateOrderLabels'
import { BackButton, LoadingButton } from 'components/Buttons'
import { Footer } from 'components/Footer'
import { useDialog } from 'context/Dialog/DialogContext'
import { localeDate } from 'utils/date'
import { useGoBack } from 'utils/routing'

import {
  convertDraftToQuote,
  convertQuoteToOrder,
  rejectQuote,
  reopenQuote,
} from './dialogs'

export default function QuoteDetailsFooter() {
  const navigate = useNavigate()
  const goBack = useGoBack('/quotes')
  const { id } = useParams()
  const { data } = useOrder(id!, 'quote')
  const [openDialog] = useDialog()
  const { order: quote } = data!

  const {
    labels,
    addLabels,
    removeLabel,
    isLoading: isLabelsUpdating,
  } = useUpdateOrderLabels(id!)

  const { mutateAsync: changeQuoteStatus, isLoading: isStatusChanging } =
    useChangeOrderStatus(id!)
  const handleOpenQuote = async () => {
    const isConfirmed = await openDialog(convertDraftToQuote(quote.order_id))

    if (isConfirmed) {
      await changeQuoteStatus({ status: 'QUOTE' })
    }
  }

  const handleRejectQuote = async () => {
    const isRejected = await openDialog(rejectQuote(quote.order_id))

    if (isRejected) {
      addLabels(['REJECTED'])
    }
  }

  const handleOpenOrder = async () => {
    const isConfirmed = await openDialog(convertQuoteToOrder(quote.order_id))

    if (isConfirmed) {
      await changeQuoteStatus({ status: 'ORDER' })
      navigate(`/orders/${id}`)
    }
  }

  const handleReopen = async () => {
    const isReopened = await openDialog(reopenQuote(quote.order_id))

    if (isReopened) {
      removeLabel('REJECTED')
    }
  }

  const isDraft = quote?.status === 'DRAFT'
  const isLoading = isLabelsUpdating || isStatusChanging

  return (
    <Footer>
      <BackButton onBack={goBack} />
      <Stack direction="row" gap={3} alignItems="center">
        {quote.rejected_date ? (
          <>
            <Stack direction="row" gap={1}>
              <Typography color="error.main">
                Rejected, {localeDate(quote.rejected_date)}
              </Typography>
              {quote.notes && (
                <Tooltip title={`Note: ${quote.notes}`}>
                  <StickyNoteIcon color="error" />
                </Tooltip>
              )}
            </Stack>
            <Button variant="outlined" onClick={handleReopen}>
              Reopen
            </Button>
          </>
        ) : (
          <>
            {quote.status !== 'ORDER' && (
              <LoadingButton
                color="error"
                isLoading={isLoading}
                startIcon={<CloseIcon />}
                onClick={handleRejectQuote}
                disabled={labels.includes('CANCELLED')}
              >
                Reject quote
              </LoadingButton>
            )}
            <LoadingButton
              variant="outlined"
              isLoading={isLoading}
              startIcon={<DoneIcon />}
              onClick={isDraft ? handleOpenQuote : handleOpenOrder}
            >
              Convert to {isDraft ? 'quote' : 'order'}
            </LoadingButton>
            <Button
              variant="contained"
              component={Link}
              to="edit"
              startIcon={<EditIcon />}
            >
              Edit Quote
            </Button>
          </>
        )}
      </Stack>
    </Footer>
  )
}
