import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Shipment, ShipmentUpdate } from 'utils/global-types'

type EditShipmentResponse = Shipment
type EditShipmentVariables = Partial<ShipmentUpdate>

const editShipment = (shipmentID: Shipment['uuid']) => {
  const patchShipment: MutationFunction<
    EditShipmentResponse,
    EditShipmentVariables
  > = async (variables) => {
    const response = await axios.patch<EditShipmentResponse>(
      `/shipments/shipments/${shipmentID}/`,
      variables
    )

    return response.data
  }

  return patchShipment
}

const useEditShipment = (shipmentID: Shipment['uuid']) => {
  const queryClient = useQueryClient()

  return useMutation<EditShipmentResponse, AxiosError, EditShipmentVariables>({
    mutationFn: editShipment(shipmentID),
    onSuccess: () => {
      queryClient.invalidateQueries(['invoices'])
      queryClient.invalidateQueries(['shipments'])
      queryClient.invalidateQueries(['orders'])
    },
    meta: {
      message: 'Failed to update shipment',
      useGlobalError: false,
    },
  })
}

export default useEditShipment
