import { UseQueryResult } from '@tanstack/react-query'

import { Table } from 'components/Table'
import { HeadCell } from 'components/Table/types'
import { getMonthName } from 'utils/date'
import { ListReturnType, ReportData } from 'utils/global-types'
import { formatPrice } from 'utils/price'

function getColumns(selectedYear: string): HeadCell<ReportData>[] {
  const year = Number(selectedYear)
  return [
    {
      value: ({ month }) => getMonthName(month),
      label: 'Month',
      width: 100,
    },
    {
      value: ({ revenue_comparing_year }) =>
        formatPrice(revenue_comparing_year, 'eur', { priceFirst: true }),
      label: `Revenue ${year - 1}`,
      align: 'right',
    },
    {
      value: ({ revenue_selected_year }) =>
        formatPrice(revenue_selected_year, 'eur', { priceFirst: true }),
      label: `Revenue ${year}`,
      align: 'right',
    },
    {
      value: ({ revenue_percent }) => `${revenue_percent}%`,
      label: `Year per year`,
    },
    {
      value: ({ margin_comparing_year }) =>
        formatPrice(margin_comparing_year, 'eur', { priceFirst: true }),
      label: `GM ${year - 1}`,
      align: 'right',
    },
    {
      value: ({ margin_selected_year }) =>
        formatPrice(margin_selected_year, 'eur', { priceFirst: true }),
      label: `GM ${year}`,
      align: 'right',
    },
    {
      value: ({ margin_percent }) => `${margin_percent}%`,
      label: `Year over year`,
    },
  ]
}

type ReportTableProps = {
  reportDataQuery: UseQueryResult<ListReturnType<ReportData>>
  selectedYear: string
}

export default function ReportTable({
  reportDataQuery,
  selectedYear,
}: ReportTableProps) {
  return (
    <Table
      queryKey="report-data"
      query={reportDataQuery}
      options={{
        columns: getColumns(selectedYear),
      }}
      dataType="report data"
      disableAddButton
      hideSearch
      hidePagination
      noElevation
    />
  )
}
