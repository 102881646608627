import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { Breadcrumbs } from 'components/Breadcrumbs'
import { PageHeader } from 'pages/Layout/PageHeader'
import { PageTemplate } from 'pages/Layout/PageTemplate'

import InvoiceForm from './InvoiceForm'

export default function InvoiceCreateEdit() {
  const { id, invoiceId } = useParams()
  const isEdit = !!invoiceId && invoiceId !== 'new'

  const title = `${
    isEdit ? 'Edit pro forma' : 'Create'
  } invoice for order ${id}`

  return (
    <PageTemplate title={title}>
      <PageHeader>
        <Box>
          <Typography variant="h6">{title}</Typography>
          <Breadcrumbs />
        </Box>
      </PageHeader>
      <InvoiceForm edit={isEdit} orderID={id!} invoiceUUID={invoiceId} />
    </PageTemplate>
  )
}
