import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'

import useOrder from 'api/order/useOrder'
import axios from 'configs/axios'
import { ListReturnType, Order, Shipment } from 'utils/global-types'

type ShipmentsResponse = ListReturnType<Shipment>
type ShipmentsQueryKey = ['shipments', Order['uuid'] | null]

const getShipments: QueryFunction<
  ShipmentsResponse,
  ShipmentsQueryKey
> = async ({ queryKey }) => {
  const [, orderID] = queryKey

  const response = await axios.get<ShipmentsResponse>('/shipments/shipments/', {
    params: { invoice__order: orderID ?? undefined },
  })

  return response.data
}

export const shipmentsQueryOptions = (
  orderUUID: ShipmentsQueryKey[1]
): WithRequired<
  UseQueryOptions<ShipmentsResponse, Error, Shipment[], ShipmentsQueryKey>,
  'queryKey'
> => ({
  queryKey: ['shipments', orderUUID],
  queryFn: getShipments,
  meta: { message: 'Failed to get shipments' },
})

export const useShipments = (orderID: Order['order_id']) => {
  const { data } = useOrder(orderID)
  const orderUUID = data?.order?.uuid

  return useQuery<ShipmentsResponse, Error, Shipment[], ShipmentsQueryKey>({
    ...shipmentsQueryOptions(orderUUID || null),
    select: (data) => data.results,
  })
}
