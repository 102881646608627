import { QueryFunction, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { ListReturnType } from 'utils/global-types'
import { addQueryParams } from 'utils/request'

type SearchOption = {
  uuid: string
}

type SearchKey = [key: 'search', name: string, filters: Record<string, string>]

function createGetOptions<T extends SearchOption>(baseUrl: string) {
  const getOptions: QueryFunction<ListReturnType<T>, SearchKey> = async ({
    queryKey,
  }) => {
    const [, , filters] = queryKey
    const url = addQueryParams(baseUrl, filters)
    const response = await axios.get<ListReturnType<T>>(url)

    return response.data
  }

  return getOptions
}

type UseSearchProps = {
  url: string
  name: string
  search: string
  filters?: Record<string, string>
  isProductSearch?: boolean
  enabled?: boolean
}

const useSearch = <T extends SearchOption>({
  url,
  name,
  search,
  filters,
  isProductSearch,
  enabled,
}: UseSearchProps) => {
  const searchKey = isProductSearch ? 'q' : 'search'

  return useQuery<ListReturnType<T>, AxiosError, T[], SearchKey>({
    queryKey: [
      'search',
      name,
      {
        [searchKey]: search,
        ...filters,
      },
    ],
    queryFn: createGetOptions(url),
    enabled,
    select: (data) => data.results,
  })
}

export default useSearch
