import { Box, SxProps, Theme } from '@mui/material'

const styles: SxProps<Theme> = {
  height: '100%',
  width: '4px',
  borderRadius: 2,
}

type InvoiceProductItemStatusLineProps = {
  noQuantityAvailable: boolean
}

export default function InvoiceProductItemStatusLine({
  noQuantityAvailable,
}: InvoiceProductItemStatusLineProps) {
  return (
    <Box
      sx={{
        ...styles,
        backgroundColor: (theme) =>
          noQuantityAvailable
            ? theme.palette.success.main
            : theme.palette.info.light,
      }}
    />
  )
}
