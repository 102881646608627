import * as yup from 'yup'

// pass only number or '?' (for unknown)
const numberOrNullRegex = /^(\d+|\?)$/

export const numberOrNull = yup
  .string()
  .matches(numberOrNullRegex, 'Value should be a number or "?"')

export const parseNullableNumber = (formValue: string): number | null =>
  formValue !== '?' ? parseInt(formValue) : null

export const formatNullableNumber = (
  value: boolean | string | number | null
): string => {
  return value ? value.toString() : '?'
}

export const transformNullableNumber = <
  T extends Record<string, boolean | string | number | null>,
  K extends keyof T
>(
  item: T,
  key: K
): Omit<T, K> & { [key in K]: string } => ({
  ...item,
  [key]: formatNullableNumber(item[key]),
})

export const nanableNumber = yup
  .number()
  .transform((value) => (isNaN(value) ? null : value))
  .nullable()

export const optionalNumber = yup
  .number()
  .transform((value) => (isNaN(value) ? undefined : value))
  .nullable()
