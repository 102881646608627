import { PropsWithChildren } from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { alpha, Stack, SxProps, Typography } from '@mui/material'

const warningMarginStyles: SxProps = {
  background: alpha('#ED6C02', 0.1),
  borderRadius: 1,
  padding: '12px 16px',
}

const warningAmberIconStyles: SxProps = {
  color: 'warning.main',
  fontSize: '1.5rem',
}

type Props = PropsWithChildren<{
  mb?: number
}>

export default function Warning({ children, mb = 3 }: Props) {
  if (!children) {
    return null
  }
  return (
    <Stack
      sx={warningMarginStyles}
      direction="row"
      mb={mb}
      alignItems="center"
      spacing={1.5}
    >
      <WarningAmberIcon sx={warningAmberIconStyles} />
      <Typography
        variant="body1"
        color="#5F2B01"
        fontWeight="500"
        letterSpacing="0.15px"
      >
        {children}
      </Typography>
    </Stack>
  )
}
