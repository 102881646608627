import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Stack } from '@mui/material'
import { DateTime } from 'luxon'

import { Autocomplete, ControlledInput, DatePicker } from 'components/Form'
import { mapShippingMethodOption } from 'utils/address'
import { AutocompleteOption, ShippingMethod } from 'utils/global-types'

type AddShipmentFormProps = {
  mode: 'create' | 'edit'
  shipmentMethod: ShippingMethod['uuid']
  shipmentMethodName: ShippingMethod['name']
  shipmentDate?: string
  trackingNumber?: string
}

export default function DialogShipmentForm({
  mode,
  shipmentMethod,
  shipmentMethodName,
  shipmentDate,
  trackingNumber,
}: AddShipmentFormProps) {
  const { reset } = useFormContext()

  useEffect(() => {
    if (mode === 'edit') {
      const values = {
        shipment_date: DateTime.fromISO(shipmentDate!),
        carrier: shipmentMethod,
        tracking_number: trackingNumber,
      } as const
      return reset(values)
    }

    reset({
      shipment_date: DateTime.now(),
      carrier: shipmentMethod,
      tracking_number: '',
    })
  }, [mode])

  const defaultShipmentMethodOption: AutocompleteOption = {
    label: shipmentMethodName,
    id: shipmentMethod,
  }

  return (
    <Stack gap={2} minWidth={600}>
      <Autocomplete
        name="carrier"
        label="Shipment method"
        url="customers/shipping-methods/"
        disabled
        defaultValue={defaultShipmentMethodOption}
        mapOptions={mapShippingMethodOption}
      />
      <Stack
        direction="row"
        gap={1}
        sx={{ mb: (theme) => `-${theme.spacing(3)}` }}
      >
        <DatePicker name="shipment_date" label="Shipment date" />
        <ControlledInput name="tracking_number" label="Tracking number" />
      </Stack>
    </Stack>
  )
}
