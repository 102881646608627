import { MenuItem } from '@mui/material'

import useCountries from 'api/useCountries'
import { ControlledInput, Select } from 'components/Form'
import Checkbox from 'components/Form/Checkbox'
import { FieldsGroup } from 'components/FormHelpers'

type AddressFieldsProps = {
  prefix: 'address' | 'billing_address'
}

export default function AddressFields({ prefix }: AddressFieldsProps) {
  const { isInitialLoading, data: countries } = useCountries()

  return (
    <>
      <ControlledInput
        name={`${prefix}.address_line_1`}
        label="Address line 1"
        required
      />
      <ControlledInput
        name={`${prefix}.address_line_2`}
        label="Address line 2"
      />
      <FieldsGroup mb={2}>
        <ControlledInput name={`${prefix}.city`} label="City" required />
        <ControlledInput name={`${prefix}.postal_code`} label="Postal code" />
        <Select
          name={`${prefix}.country`}
          label="Country"
          defaultValue=""
          required
          disabled={isInitialLoading}
          placeholder={isInitialLoading ? 'Loading countries list' : ''}
        >
          {(countries ?? []).map((country) => (
            <MenuItem key={country.uuid} value={country.uuid}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FieldsGroup>

      <ControlledInput name={`${prefix}.phone_number`} label="Phone number" />

      {prefix === 'address' && (
        <>
          <FieldsGroup sameWidth>
            <ControlledInput name={`${prefix}.attn`} label="ATTN" />
            <ControlledInput
              name={`${prefix}.notes`}
              label="Shipping instructions"
              multiline
            />
          </FieldsGroup>
          <Checkbox
            name={`${prefix}.same_shipping_and_billing`}
            label="Shipping and billing address are the same"
          />
        </>
      )}
    </>
  )
}
