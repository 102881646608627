import { useParams } from 'react-router-dom'
import { SxProps, Typography } from '@mui/material'

import useStatement from 'api/statement/useStatement'
import { DataStatsBar } from 'components/StatsBar'
import { formatPrice } from 'utils/price'

import { StatementStats as StatementStatsI } from './types'
import { statementStats } from './utils'

const statementStatsStyles: SxProps = {
  mb: 3,
  mx: 2,
  bgcolor: 'divider',
  borderRadius: 2,
}

export function StatementStats() {
  const { id } = useParams()
  const { isInitialLoading, data } = useStatement(id!)

  if (isInitialLoading) {
    return <Typography>Loading...</Typography>
  }

  if (data === undefined) {
    return <Typography>Statement data not found</Typography>
  }

  const statementData: StatementStatsI = {
    total_amount: formatPrice(data.total_amount ?? 0, data.currency, {
      priceFirst: true,
    }),
    total_paid: formatPrice(data.total_paid ?? 0, data.currency, {
      priceFirst: true,
    }),
    total_unpaid: formatPrice(data.total_unpaid ?? 0, data.currency, {
      priceFirst: true,
    }),
    terms: data.terms,
  }

  if (statementData !== undefined) {
    return (
      <DataStatsBar
        sx={statementStatsStyles}
        subtitlesSx={{ color: 'text.secondary' }}
        items={statementStats}
        data={statementData}
      />
    )
  }

  return <Typography>Statement data not found</Typography>
}
