import { Typography } from '@mui/material'

import { PaymentStatus } from 'utils/global-types'
import { getPaymentStatusColor, getStatusLabel } from 'utils/order'

type PaymentStatusCellProps = {
  paymentStatus: PaymentStatus | null
}

export default function PaymentStatusCell({
  paymentStatus,
}: PaymentStatusCellProps) {
  if (!paymentStatus) {
    return <>-</>
  }

  return (
    <Typography
      variant="inherit"
      fontWeight={500}
      color={getPaymentStatusColor(paymentStatus)}
      textTransform="uppercase"
    >
      {getStatusLabel(paymentStatus)}
    </Typography>
  )
}
