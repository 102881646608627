import { capitalize } from 'lodash'

import { PreviewTableCell } from 'components/PreviewTableCell'
import { HeadCell } from 'components/Table/types'
import { Payment } from 'utils/global-types'
import { formatPrice, getPaymentMethodLabel } from 'utils/price'

export const paymentsListColumns: HeadCell<Payment>[] = [
  {
    label: 'Created Date',
    value: 'created',
    width: 115,
  },
  {
    label: 'Order ID',
    value: 'order_id',
    getHref: (payment) => `/orders/${payment.order_id}`,
  },
  {
    label: 'Invoice ID',
    value: ({ invoice_id, invoice }) => (
      <PreviewTableCell uuid={invoice} invoice_id={invoice_id} />
    ),
  },
  {
    label: 'Customer email',
    value: 'customer_email',
  },
  {
    label: 'Amount',
    align: 'right',
    value: ({ total_amount, currency }) =>
      formatPrice(total_amount, currency, { priceFirst: true }),
  },
  {
    label: 'Source',
    value: ({ source }) => capitalize(source),
  },
  {
    label: 'Destination',
    value: ({ destination }) => capitalize(destination),
  },
  {
    label: 'Method',
    value: ({ method }) => getPaymentMethodLabel(method),
  },
]
