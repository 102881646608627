import { useFormContext } from 'react-hook-form'
import { Button, Stack, Typography } from '@mui/material'

import { ControlledInput } from 'components/Form'
import ControlledRadio from 'components/Form/ControlledRadio'
import ControlledRating from 'components/Form/ControlledRating'
import { InfoWrapper } from 'components/Wrappers'
import { ratingNames } from 'utils/global-types'

import ClearSelectionButton from './ClearSelectionButton'
import {
  declineReasonOptions,
  futureOffersOptions,
  ratingLabelMap,
} from './utils'

type FeedbackFormProps = {
  title: string
  submitText: string
}

export default function FeedbackForm({ title, submitText }: FeedbackFormProps) {
  const { watch } = useFormContext()

  const reason = watch('reason')

  return (
    <>
      <Typography fontSize={24} fontWeight="bold">
        {title}
      </Typography>
      <Typography>
        We value your feedback and would appreciate knowing the reason for your
        decision.
      </Typography>
      <InfoWrapper>
        <Typography fontWeight={500} mb={2.5}>
          Please select a reason for declining (optional):
        </Typography>
        <ControlledRadio name="reason" options={declineReasonOptions} />
        {reason === 'other' && (
          <>
            <Typography mb={1}>Please specify the reason</Typography>
            <ControlledInput
              name="reason_text"
              sx={{ '.MuiInputBase-root': { background: 'white' } }}
            />
          </>
        )}
        <ClearSelectionButton names={['reason', 'reason_text']} />
      </InfoWrapper>
      <InfoWrapper>
        <Typography fontWeight={500} mb={2.5}>
          How would you rate the quote you received from us?
        </Typography>
        <Stack gap={2} alignItems="center">
          {ratingNames.map((name) => (
            <Stack
              gap={1}
              key={name}
              width="100%"
              alignItems={{ sm: 'center' }}
              justifyContent="space-between"
              direction={{ xs: 'column', sm: 'row' }}
            >
              <Typography fontWeight={500} fontSize={14} sx={{ opacity: 0.7 }}>
                {ratingLabelMap[name]}
              </Typography>
              <ControlledRating name={name} />
            </Stack>
          ))}
        </Stack>
        <ClearSelectionButton names={ratingNames} />
      </InfoWrapper>
      <InfoWrapper>
        <Typography fontWeight={500} mb={2.5}>
          Will you consider Omnical for future quotes?
        </Typography>
        <ControlledRadio name="future_offers" options={futureOffersOptions} />
        <ClearSelectionButton names={['future_offers']} />
      </InfoWrapper>
      <InfoWrapper>
        <Typography fontWeight={500} mb={2.5}>
          Do you have any other feedback for us?
        </Typography>
        <ControlledInput
          name="feedback"
          multiline
          sx={{ '.MuiInputBase-root': { background: 'white' } }}
        />
      </InfoWrapper>
      <Button type="submit" variant="contained">
        {submitText}
      </Button>
    </>
  )
}
