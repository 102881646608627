import { PropsWithChildren } from 'react'
import { View } from '@react-pdf/renderer'

const Row = ({ children }: PropsWithChildren) => (
  <View
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }}
  >
    {children}
  </View>
)

export default Row
