import { ReactNode } from 'react'
import { Stack, styled, Typography } from '@mui/material'

import { PageTemplate } from 'pages/Layout/PageTemplate'

const ErrorCodeHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: 200,
  fontWeight: 900,
}))

type ErrorPageTemplateProps = {
  code?: 404 | 500
  title: string
  message: ReactNode
  buttons?: ReactNode
  icon?: ReactNode
}

export default function ErrorPageTemplate({
  code,
  icon,
  title,
  message,
  buttons,
}: ErrorPageTemplateProps) {
  return (
    <PageTemplate sx={{ alignItems: 'center' }}>
      <Stack alignItems="center" gap={4} maxWidth={537} textAlign="center">
        {icon ?? <ErrorCodeHeading variant="h1">{code}</ErrorCodeHeading>}
        <Typography fontSize={32} fontWeight="700">
          {title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {message}
        </Typography>
        <Stack direction="row" gap={2}>
          {buttons}
        </Stack>
      </Stack>
    </PageTemplate>
  )
}
