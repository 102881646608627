import { ReactNode } from 'react'
import { Stack, Typography } from '@mui/material'

export type SuccessViewProps = {
  email: string
  title: ReactNode
  description: ReactNode
}

export default function SuccessView({
  email,
  title,
  description,
}: SuccessViewProps) {
  return (
    <Stack alignItems="center" textAlign="center" gap={2}>
      <Typography fontWeight={700} fontSize={24}>
        {title}
      </Typography>
      <Typography variant="body1">{description}</Typography>
      <Typography variant="body1">
        If you have any questions or need further assistance, please{' '}
        <a href={`mailto:${email}`}>contact us</a>.
      </Typography>
    </Stack>
  )
}
