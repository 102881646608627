import { useFormContext, useWatch } from 'react-hook-form'
import { Button } from '@mui/material'

import useIsCustomerExists from 'api/customer/useIsCustomerExists'
import { ControlledInput } from 'components/Form'
import { FieldsGroup } from 'components/FormHelpers'

import { CustomerWizardForm } from '../CustomerCreate'
import { customerExistsErrorMessage } from '../schema'

type CustomerStepFieldsProps = {
  onContinue: () => void
}

export default function CustomerStepFields({
  onContinue,
}: CustomerStepFieldsProps) {
  const {
    setError,
    formState: { errors },
  } = useFormContext<CustomerWizardForm>()

  const [email, name] = useWatch({
    name: ['customer.email', 'customer.name'],
  })

  const {
    isInitialLoading,
    isRefetching,
    refetch: checkIsCustomerExists,
  } = useIsCustomerExists(email)

  const isLoading = isInitialLoading || isRefetching

  const hasCustomerValidationErrors =
    !!errors.customer &&
    errors.customer.email?.message !== customerExistsErrorMessage

  const isRequiredFieldsFilled = name && email

  const isContinueEnabled =
    isRequiredFieldsFilled && !hasCustomerValidationErrors && !isLoading

  const onCustomerFormContinue = async () => {
    const { data: exists } = await checkIsCustomerExists()

    const isCustomerAbsent = exists === false

    if (isCustomerAbsent) {
      return onContinue()
    }

    setError('customer.email', {
      message: customerExistsErrorMessage,
    })
  }

  return (
    <>
      <ControlledInput
        name="customer.email"
        type="email"
        label="Email"
        required
        helperText={isLoading ? 'Checking if email is unique' : undefined}
      />
      <FieldsGroup>
        <ControlledInput name="customer.name" label="Name" required />
        <ControlledInput name="customer.phone_number" label="Phone number" />
      </FieldsGroup>
      <ControlledInput name="customer.notes" label="Notes" multiline />
      <Button
        variant="contained"
        color="primary"
        onClick={onCustomerFormContinue}
        disabled={!isContinueEnabled}
      >
        Continue
      </Button>
    </>
  )
}
