import { useEffect } from 'react'
import { FieldPath, useForm, useFormContext } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  styled,
  Typography,
} from '@mui/material'

import useShippingMethod from 'api/shipping/useShippingMethod'
import { CalculatedShippingFee } from 'components/CalculatedShippingFee'
import { Autocomplete, ControlledInput } from 'components/Form'
import { FieldsGroup, Form } from 'components/FormHelpers'
import { ReadonlyField } from 'components/ReadonlyField'
import { mapAddressOption, mapShippingMethodOption } from 'utils/address'
import { AutocompleteOption, Invoice, Order } from 'utils/global-types'
import { formatGramsToKg } from 'utils/helpers'
import {
  formatPrice,
  marginPercentsInputOptions,
  priceInputOptions,
} from 'utils/price'

import InvoiceFooter from './InvoiceFooter'
import InvoiceProductItem from './InvoiceProductItem'
import { invoiceSchema } from './schema'
import { InvoiceForm as InvoiceFormI } from './types'
import useInvoiceData from './useInvoiceData'
import useInvoiceDefaultFormValues from './useInvoiceDefaultValues'

const PagePaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 3),

  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}))

const GeneralInfo = styled(Stack)(({ theme }) => ({
  padding: 16,
  paddingTop: 24,
  borderRadius: 8,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.grey[100],

  '& .MuiTextField-root': {
    marginBottom: 0,
  },
}))

type InvoiceFormProps = {
  edit: boolean
  orderID: Order['order_id']
  invoiceUUID?: Invoice['uuid']
}

function InvoiceForm({ orderID, invoiceUUID }: InvoiceFormProps) {
  const defaultValues = useInvoiceDefaultFormValues(orderID, invoiceUUID)
  const {
    billingAddress,
    shippingAddress,
    recommendedMargin,
    shippingMethod: shippingMethodDefault,
  } = defaultValues

  const { setValue, watch } = useFormContext<InvoiceFormI>()

  const shippingMethodUUID = watch('shipping_method')
  const marginOverwrite = watch('margin_percent_overwrite')
  const { data: shippingMethod } = useShippingMethod(
    shippingMethodUUID ?? undefined
  )

  const displayData = useInvoiceData(orderID, invoiceUUID)
  const { currency, items } = displayData

  const onAutocompleteChange =
    (fieldName: FieldPath<InvoiceFormI>) =>
    (value?: AutocompleteOption | null) => {
      if (value === null) {
        setValue(fieldName, null)
      }
    }

  const setRecommendedMargin = () => {
    if (recommendedMargin) {
      setValue('margin_percent_overwrite', recommendedMargin)
    }
  }

  const isMarginRecommended = marginOverwrite === recommendedMargin
  const showAccountNumber = shippingMethod?.rate_calculation_type === 'no-fees'

  useEffect(() => {
    if (!showAccountNumber) {
      setValue('shipping_instructions', '')
    }
  }, [showAccountNumber])

  return (
    <Stack gap={2} width="100%">
      <PagePaper>
        <GeneralInfo sx={{ pb: 0 }}>
          <Grid container spacing={1} width="100%">
            <Grid item xs={2}>
              <ReadonlyField label="Invoice total">
                {formatPrice(displayData.invoiceTotal, currency)}
              </ReadonlyField>
            </Grid>
            <Grid item xs={2}>
              <ReadonlyField label="Order value">
                {formatPrice(displayData.orderValueDisplay, currency)}
              </ReadonlyField>
            </Grid>
            <Grid item xs={2}>
              <ReadonlyField label="Costs">
                {formatPrice(displayData.costs, 'eur')}
              </ReadonlyField>
            </Grid>
            <Grid item xs={2}>
              <ReadonlyField label="Margin">
                {displayData.marginPercentage.toFixed(1)}% (
                {formatPrice(displayData.margin, 'eur')})
              </ReadonlyField>
            </Grid>
            <Grid item xs={2}>
              <ControlledInput
                label="Margin overwrite %"
                name="margin_percent_overwrite"
                type="number"
                inputProps={marginPercentsInputOptions}
              />
            </Grid>
            <Grid item xs={2}>
              <ControlledInput
                label="Lump sum discount"
                name="discount_lump_sum"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {currency.toUpperCase()}
                    </InputAdornment>
                  ),
                }}
                inputProps={priceInputOptions}
              />
            </Grid>
          </Grid>
          {recommendedMargin && !isMarginRecommended && (
            <Grid container spacing={1} mb={1}>
              <Grid item xs={8} />
              <Grid item xs={4}>
                <Button size="small" onClick={setRecommendedMargin}>
                  Use recommended margin {recommendedMargin}%
                </Button>
              </Grid>
            </Grid>
          )}
        </GeneralInfo>

        {!!items.length && (
          <>
            <Divider />
            {items.map((item, index) => (
              <InvoiceProductItem
                key={item.order.uuid}
                index={index}
                currency={currency}
                {...item}
              />
            ))}
          </>
        )}
        <Divider />

        <Container>
          <Typography variant="h6" py={2.5}>
            Shipping
          </Typography>
          <FieldsGroup sameWidth gap={3}>
            <Autocomplete
              name="shipping_method"
              label="Shipping method"
              url="customers/shipping-methods/"
              enabled
              defaultValue={shippingMethodDefault}
              mapOptions={mapShippingMethodOption}
              onChange={onAutocompleteChange('shipping_method')}
            />
            <ReadonlyField label="Total weight (kg)">
              {formatGramsToKg(displayData.weight)}
            </ReadonlyField>
            <CalculatedShippingFee
              value={displayData.shippingFee}
              currency={currency}
            />
            <ControlledInput
              label="Alternative shipping fee"
              name="shipping_fee_overwrite"
              type="number"
              inputProps={priceInputOptions}
              helperText="Field change affects total amount. Leave empty to use calculated fee."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currency.toUpperCase()}
                  </InputAdornment>
                ),
              }}
            />
            <ReadonlyField label="Handling fee">
              {formatPrice(displayData.handlingFee, currency)}
            </ReadonlyField>
          </FieldsGroup>
          <ControlledInput
            label="Shipping account number"
            name="shipping_instructions"
            sx={{
              width: 'calc(20% - 12.8px)',
              display: !showAccountNumber ? 'none' : undefined,
            }}
          />
          <Autocomplete
            enabled
            name="shipping_address"
            label="Shipping address"
            url="customers/addresses/"
            mapOptions={mapAddressOption}
            hideClearIcon
            defaultValue={shippingAddress}
            onChange={onAutocompleteChange('shipping_address')}
            filters={{
              organization: displayData.organizationUUID,
              type: 'shipping',
            }}
          />
          <FieldsGroup sameWidth>
            <ControlledInput label="ATTN" name="shipping_attn" />
            <ControlledInput
              label="Shipping instructions"
              name="shipping_notes"
            />
          </FieldsGroup>

          <Typography variant="h6" pb={2.5}>
            Billing
          </Typography>
          <Autocomplete
            enabled
            name="billing_address"
            label="Billing address"
            url="customers/addresses/"
            mapOptions={mapAddressOption}
            hideClearIcon
            defaultValue={billingAddress}
            onChange={onAutocompleteChange('billing_address')}
            filters={{
              organization: displayData.organizationUUID,
              type: 'billing',
            }}
          />

          <Typography variant="h6" pb={2.5}>
            Payment terms
          </Typography>
          <ControlledInput multiline label="Notes" name="payment_terms" />
        </Container>
      </PagePaper>
      <PagePaper>
        <ControlledInput multiline label="Notes" name="notes" />
      </PagePaper>
    </Stack>
  )
}

export default function InvoiceFormWrapper(props: InvoiceFormProps) {
  const { orderID, invoiceUUID, edit } = props
  const defaultValues = useInvoiceDefaultFormValues(orderID, invoiceUUID)
  const {
    billingAddress,
    shippingAddress,
    shippingMethod,
    ...formDefaultValues
  } = defaultValues

  const handlers = useForm<InvoiceFormI>({
    defaultValues: formDefaultValues,
    // @ts-expect-error ts(2322) Resolver infer type is not equal to form values type
    resolver: yupResolver(invoiceSchema),
    mode: 'onChange',
  })

  return (
    <Form handlers={handlers}>
      <InvoiceForm {...props} />
      <InvoiceFooter edit={edit} orderID={orderID} invoiceUUID={invoiceUUID} />
    </Form>
  )
}
