import { useParams } from 'react-router-dom'

import useQuote from 'api/customers-portal/useQuote'
import useRequestInvoice from 'api/customers-portal/useRequestInvoice'
import CustomerActionFlow from 'components/CustomerActionView/CustomerActionFlow'

import RequestPaymentForm from './form'

type RequestPaymentFormValues = {
  po_number: string
}

export default function RequestPaymentPage() {
  const { id } = useParams()
  const { data: quote } = useQuote(id)
  const { mutateAsync: requestInvoice } = useRequestInvoice()

  const onSubmit = ({ po_number }: RequestPaymentFormValues) =>
    requestInvoice({ po_number, uuid: id! })

  return (
    <CustomerActionFlow
      onSubmit={onSubmit}
      formComponent={<RequestPaymentForm quote={quote!} />}
      successProps={{
        email: quote!.customer_support_email,
        title: 'Thank You for Your Order!',
        description: (
          <>
            Thank you for placing your order. Your invoice will be sent to your
            email <b>{quote!.customer_email}</b> shortly.
          </>
        ),
      }}
    />
  )
}
