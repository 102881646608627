import { useFormContext } from 'react-hook-form'
import { FormControlLabel, Switch } from '@mui/material'

import { FormSection } from 'components/FormHelpers'

interface ActiveSwitchProps {
  defaultChecked?: boolean
}

export default function ActiveSwitch({ defaultChecked }: ActiveSwitchProps) {
  const { register } = useFormContext()

  return (
    <FormSection sx={{ px: 0 }}>
      <FormControlLabel
        label="Active"
        labelPlacement="start"
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
        componentsProps={{
          typography: {
            variant: 'h6',
          },
        }}
        control={
          <Switch
            sx={{ mr: 3 }}
            {...register('is_active')}
            defaultChecked={defaultChecked}
          />
        }
      />
    </FormSection>
  )
}
