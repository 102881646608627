import { Box, Container, styled, Typography } from '@mui/material'

import logo from 'assets/img/logo.png'

const StyledPanel = styled(Container)(({ theme }) => ({
  margin: 0,
  width: 480,
  minWidth: 480,
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.primary.main,
}))

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(6),
  color: theme.palette.common.white,
}))

export default function LeftPanel() {
  return (
    <StyledPanel>
      <StyledContainer>
        <Box component="img" src={logo} sx={{ width: 150, mb: 5 }} />
        <Typography variant="h3">
          Welcome! <br /> To continue, please log in first.
        </Typography>
      </StyledContainer>
    </StyledPanel>
  )
}
