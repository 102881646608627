import { Link, useParams } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Divider,
  Paper,
  Table,
  TableContainer,
} from '@mui/material'

import useOrder from 'api/order/useOrder'
import useOrderProductItems from 'api/product-item/useOrderProductItems'
import { FieldsGroup } from 'components/FormHelpers'
import { ReadonlyField } from 'components/ReadonlyField'
import NoResultsPlaceholder from 'components/Table/NoResultsPlaceholder'
import TableBody from 'components/Table/TableBody'
import TableHead from 'components/Table/TableHead'
import { HeadCell } from 'components/Table/types'
import { PageTemplate } from 'pages/Layout/PageTemplate'
import { Order, OrderProductItem } from 'utils/global-types'
import { formatGramsToKg } from 'utils/helpers'
import { formatPrice } from 'utils/price'

import QuoteDetailsFooter from './QuoteDetailsFooter'
import QuoteDetailsGeneral from './QuoteDetailsGeneral'
import QuoteDetailsHeader from './QuoteDetailsHeader'

const productTableColumns: HeadCell<
  OrderProductItem & Pick<Order, 'currency'>
>[] = [
  {
    value: 'ordering',
    label: 'Pos.',
  },
  {
    value: 'sku',
    label: 'Product SKU',
  },
  {
    value: 'manufacturer_name',
    label: 'Manufacturer',
  },
  {
    value: 'manufacturer_part_number',
    label: 'Part #',
  },
  {
    value: 'type',
    label: 'Type',
  },
  {
    value: 'product_category',
    label: 'Category',
  },
  {
    value: 'quantity_offered',
    label: 'Qty',
  },
  {
    label: 'Total',
    value: (product) =>
      formatPrice(product.total_sales_price_display, product.currency!),
  },
  {
    value: ({ total_weight }) => formatGramsToKg(total_weight),
    label: 'Weight (kg)',
  },
  {
    label: 'Details',
    width: 200,
    value: (product) => product.details_overwrite || product.details_original,
  },
]

export default function QuoteDetails() {
  const { id } = useParams()
  const { data } = useOrder(id!, 'quote')
  const { order: quote } = data!

  const { data: productItemsData } = useOrderProductItems(id)

  const { currency } = quote

  const margin_info = `${quote.total_margin_percent?.toFixed(2)}% ${formatPrice(
    quote.total_margin,
    'eur'
  )}`

  const productItems = productItemsData!.map((item) => ({
    ...item,
    currency,
  }))

  return (
    <PageTemplate title={`Quote ${quote.order_id}`}>
      <QuoteDetailsHeader id={id!} />

      <Paper sx={{ borderRadius: 2, overflow: 'hidden', mt: 1, p: 3 }}>
        <QuoteDetailsGeneral id={id!} />
        <Divider sx={{ mt: 3, mb: 5 }} />

        <FieldsGroup>
          <ReadonlyField label="Order value">
            {formatPrice(quote.subtotal_amount!, currency)}
          </ReadonlyField>
          <ReadonlyField label="Shipping costs">
            {formatPrice(quote.shipping_fee_relevant_display!, currency)}
          </ReadonlyField>
          <ReadonlyField label="Margin">{margin_info}</ReadonlyField>
          <ReadonlyField label="Lump sum">
            {quote.discount_lump_sum}
          </ReadonlyField>

          <Button
            variant="text"
            component={Link}
            to="edit/products"
            startIcon={<EditIcon />}
          >
            Edit products
          </Button>
        </FieldsGroup>

        <TableContainer sx={{ mt: 2 }}>
          <Table stickyHeader size="small">
            <TableHead columns={productTableColumns} />
            {productItems.length > 0 && (
              <TableBody rows={productItems} columns={productTableColumns} />
            )}
          </Table>
          {productItemsData?.length === 0 && (
            <NoResultsPlaceholder
              dataType="product items"
              url="edit/products"
            />
          )}
        </TableContainer>

        <Box sx={{ mt: 3 }}>
          <ReadonlyField label="Quote terms" sx={{ mb: 2 }}>
            {quote.quote_terms_display}
          </ReadonlyField>
          <ReadonlyField label="Payment terms">
            {quote.payment_terms_display}
          </ReadonlyField>
        </Box>
      </Paper>

      <QuoteDetailsFooter />
    </PageTemplate>
  )
}
