import {
  AbilityBuilder,
  createMongoAbility,
  FieldMatcher,
  MongoQuery,
} from '@casl/ability'

import { User } from 'utils/global-types'

import { Ability, AppAbility, rolePermissions } from './permissions'

const fieldMatcher: FieldMatcher = (fields) => (field) => fields.includes(field)

export function defineRulesFor(user?: User | null) {
  const builder = new AbilityBuilder<AppAbility>(createMongoAbility)
  const { can, cannot, rules } = builder

  if (!user) {
    cannot('manage', 'all')
  } else if (user.is_staff) {
    can('manage', 'all')
  } else {
    const { groups } = user
    const isManager = groups.includes('Managers')
    for (const role of groups) {
      if (role in rolePermissions) {
        rolePermissions[role](builder, isManager)
      } else {
        throw Error(`Trying to use unknown role "${role}"`)
      }
    }
  }

  return rules
}

export default function defineAbilityFor(user?: User | null) {
  return createMongoAbility<Ability, MongoQuery>(defineRulesFor(user), {
    fieldMatcher,
  })
}
