import { RouterProvider } from 'react-router-dom'
import { last } from 'lodash'

import customersRouter from './customers/routerConfig'
import portalRouter from './platform/routerConfig'

const getSubdomain = (location: string) => {
  const parts = location.split('.')
  const isLocalhost = last(parts) === 'localhost'
  const subdomains = parts.slice(0, isLocalhost ? -1 : -2)

  return subdomains[0]
}

const getRouter = () => {
  const subdomain = getSubdomain(window.location.hostname)

  if (subdomain === 'customers') {
    return customersRouter
  }
  return portalRouter
}

export default function Router() {
  return <RouterProvider router={getRouter()} />
}
