import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Avatar, Button, Divider, Stack, Typography } from '@mui/material'
import { isAxiosError } from 'axios'
import * as yup from 'yup'

import useChangePassword from 'api/user/useChangePassword'
import useUser from 'api/user/useUser'
import { PasswordInput } from 'components/Form'
import { Form } from 'components/FormHelpers'

const passwordRegex = /^(?=.*[a-z])(?=.*\d)[a-z\d]+$/i

const passwordLengthMessage = 'Password must be at least 8 characters'
const passwordRegexMessage =
  'Password must contain at least one letter and one number'

const changePasswordSchema = yup.object({
  old_password: yup
    .string()
    .required('Old password is required')
    .min(8, passwordLengthMessage)
    .matches(passwordRegex, passwordRegexMessage),
  new_password: yup
    .string()
    .required('New password is required')
    .min(8, passwordLengthMessage)
    .matches(passwordRegex, passwordRegexMessage),
  confirm_password: yup
    .string()
    .min(8, passwordLengthMessage)
    .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
})

type ChangePasswordFormValues = {
  old_password: string
  new_password: string
  confirm_password: string
}

const changePasswordFormDefaultValues: ChangePasswordFormValues = {
  old_password: '',
  new_password: '',
  confirm_password: '',
}

export default function ProfileTab() {
  const { data } = useUser()
  const user = data!

  const { mutateAsync: changePassword } = useChangePassword()

  const handlers = useForm<ChangePasswordFormValues>({
    // @ts-expect-error ts(2322) Resolver infer type is not equal to form values type
    resolver: yupResolver(changePasswordSchema),
    defaultValues: changePasswordFormDefaultValues,
  })

  const { handleSubmit, reset, setError } = handlers

  const onErrors = (errors: Record<string, string[]>) => {
    Object.entries(errors).forEach(([key, value]) => {
      setError(key as keyof ChangePasswordFormValues, { message: value[0] })
    })
  }

  const handleReset = () => {
    reset(changePasswordFormDefaultValues)
  }

  const handleChangePassword = async (values: ChangePasswordFormValues) => {
    try {
      const isPasswordChanged = await changePassword(values)
      if (isPasswordChanged) {
        handleReset()
      }
    } catch (e) {
      if (isAxiosError(e)) {
        onErrors(e.response?.data)
      }
    }
  }

  const onSubmit = handleSubmit(handleChangePassword, console.error)

  return (
    <Stack>
      <Stack direction="row" gap={3} pb={3}>
        <Avatar sx={{ width: 128, height: 128 }} />
        <Stack direction="column" justifyContent="center">
          <Typography variant="h4">
            {user.first_name} {user.last_name}
          </Typography>
          <Typography variant="h6" color="text.secondary">
            {user.is_staff ? 'Administrator' : user.groups?.join(', ')}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {user.email}
          </Typography>
        </Stack>
      </Stack>
      <Divider />
      <Form handlers={handlers} onSubmit={onSubmit} onReset={handleReset}>
        <Stack direction="column" pt={2} minWidth={340}>
          <Typography variant="h6" my={2} fontSize={16}>
            Change password
          </Typography>
          <PasswordInput name="old_password" label="Current password" />
          <PasswordInput name="new_password" label="New password" />
          <PasswordInput name="confirm_password" label="Confirm new password" />
          <Stack direction="row" justifyContent="space-between">
            <Button type="reset" variant="outlined">
              Reset
            </Button>
            <Button type="submit" variant="contained">
              Save changes
            </Button>
          </Stack>
        </Stack>
      </Form>
    </Stack>
  )
}
