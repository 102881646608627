import { Helmet } from 'react-helmet'

interface PageTitleProps {
  title?: string
}

export default function PageTitle({ title }: PageTitleProps) {
  return (
    <Helmet
      title={title}
      defaultTitle="Relay by Omnical"
      titleTemplate="%s | Relay by Omnical"
    />
  )
}
