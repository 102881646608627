import { DialogConfig } from 'context/Dialog/DialogContext'

export const discardConfirmationDialog = (
  mode: 'change' | 'continue'
): DialogConfig => ({
  title: `Are you sure you want to ${
    mode === 'change' ? 'change this setting' : 'continue'
  }?`,
  content: 'You will lose part of the data that you’ve added.',
})
