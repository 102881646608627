import { Link, styled } from '@mui/material'

const ExternalLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export default ExternalLink
