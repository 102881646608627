import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import {
  Invoice,
  InvoiceCreate,
  InvoiceProductBundleItem,
  InvoiceProductBundleItemCreate,
  InvoiceType,
  Order,
} from 'utils/global-types'

type CreateInvoiceResponse = Invoice & {
  product_bundle_list: InvoiceProductBundleItem[]
}

type CreateInvoiceVariables = {
  invoice: Partial<InvoiceCreate>
  items: InvoiceProductBundleItemCreate[]
  isProForma?: boolean
}

const postInvoice: MutateFunction<
  CreateInvoiceResponse,
  AxiosError,
  CreateInvoiceVariables
> = async ({ invoice, items, isProForma }) => {
  const type: Exclude<InvoiceType, 'CREDIT'> = isProForma ? 'DRAFT' : 'DEBIT'

  const response = await axios.post<CreateInvoiceResponse>('/invoices/', {
    ...invoice,
    type,
    product_bundle_list: items,
  })

  return response.data
}

const useCreateInvoice = (orderID: Order['order_id']) => {
  const queryClient = useQueryClient()

  return useMutation<CreateInvoiceResponse, AxiosError, CreateInvoiceVariables>(
    {
      mutationFn: postInvoice,
      onSuccess: ({ uuid: invoiceUUID, order: orderUUID }) => {
        queryClient.invalidateQueries(['order', orderID])
        queryClient.invalidateQueries(['invoices'])
        queryClient.invalidateQueries(['product-items', orderUUID])
        queryClient.invalidateQueries(['invoice-product-items', invoiceUUID])
      },
    }
  )
}

export default useCreateInvoice
