import toast from 'react-hot-toast'
import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { ConfigClient } from 'utils/global-types'

type UpdateConfigResponse = ConfigClient
type UpdateConfigVariables = ConfigClient

const putConfig: MutateFunction<
  UpdateConfigResponse,
  AxiosError,
  UpdateConfigVariables
> = async (variables) => {
  const response = await axios.put<UpdateConfigResponse>(
    '/config/client/',
    variables
  )

  return response.data
}

const useUpdateConfig = () => {
  const queryClient = useQueryClient()

  return useMutation<UpdateConfigResponse, AxiosError, UpdateConfigVariables>({
    mutationFn: putConfig,
    onSuccess: () => {
      toast.success('Config updated successfully')
      queryClient.invalidateQueries(['config'])
    },
    meta: { message: 'Failed to update config' },
  })
}

export default useUpdateConfig
