import { toast } from 'react-hot-toast'
import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { emptyRequestWithFetch } from 'configs/axios'
import { Order, OrderStatusChange } from 'utils/global-types'

type ChangeOrderStatusResponse = boolean
type ChangeOrderStatusVariables = { status: OrderStatusChange }

const statusToUrlMap: Record<OrderStatusChange, string> = {
  CLOSED: 'close-order',
  ORDER: 'create-order',
  QUOTE: 'create-quote',
  REOPENED: 'reopen-order',
}

const statusToSuccessMessageMap: Record<OrderStatusChange, string> = {
  CLOSED: 'Order closed successfully',
  ORDER: 'Order created successfully',
  QUOTE: 'Quote created successfully',
  REOPENED: 'Order reopened successfully',
}

const changeOrderStatus = (orderID: Order['order_id']) => {
  const postChangeOrderStatus: MutateFunction<
    ChangeOrderStatusResponse,
    Error,
    ChangeOrderStatusVariables
  > = async ({ status }) => {
    const response = await emptyRequestWithFetch(
      `orders/${orderID}/${statusToUrlMap[status]}/`
    )

    if (response.ok) {
      return true
    }

    const responseBody = await response.json()

    throw new Error(
      responseBody?.non_field_errors[0] ||
        'Error occurred while changing status'
    )
  }

  return postChangeOrderStatus
}

const useChangeOrderStatus = (orderID: Order['order_id']) => {
  const queryClient = useQueryClient()
  return useMutation<
    ChangeOrderStatusResponse,
    Error,
    ChangeOrderStatusVariables
  >({
    mutationFn: changeOrderStatus(orderID),
    onSuccess: (_, { status }) => {
      toast.success(statusToSuccessMessageMap[status])
      queryClient.invalidateQueries(['orders'])
      queryClient.invalidateQueries(['order', orderID])
    },
    meta: { message: 'Failed to change order status' },
  })
}

export default useChangeOrderStatus
