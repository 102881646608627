import { amber, green } from '@mui/material/colors'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { getMonthName } from 'utils/date'
import { ReportData } from 'utils/global-types'
import { formatPriceValue, shortPrice } from 'utils/price'

type ModifiedReportData = ReportData & {
  monthVerbose?: string
}

type ReportChartProps = {
  reportData: ModifiedReportData[]
  selectedYear: number
}

export default function ReportChart({
  reportData,
  selectedYear,
}: ReportChartProps) {
  const data = reportData.map((item) => {
    item.monthVerbose = getMonthName(item.month, 'short')
    return item
  })
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{ top: 16, right: 16, bottom: 16, left: 0 }}
      >
        <Line
          type="monotone"
          dataKey="revenue_selected_year"
          name={`Revenue ${selectedYear}`}
          stroke={green[800]}
        />
        <Line
          type="monotone"
          dataKey="revenue_comparing_year"
          name={`Revenue ${selectedYear - 1}`}
          fontSize={12}
          stroke={amber[600]}
        />
        <CartesianGrid stroke="#e7e7e7" strokeDasharray="3" vertical={false} />
        <XAxis
          dataKey="monthVerbose"
          fontSize={12}
          color="rgba(0, 0, 0, 0.38)"
        />
        <YAxis
          fontSize={12}
          format="number"
          tickFormatter={(value) => shortPrice(Number(value))}
          color="rgba(0, 0, 0, 0.38)"
        />
        <Tooltip
          itemStyle={{
            fontSize: '12px',
          }}
          formatter={(value) => formatPriceValue(Number(value))}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}
