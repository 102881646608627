import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { ListReturnType, ReportData } from 'utils/global-types'

type ReportDataResponse = ListReturnType<ReportData>
type ReportDataQueryKey = ['report-data', string?]

const getReportData: QueryFunction<
  ReportDataResponse,
  ReportDataQueryKey
> = async ({ queryKey }) => {
  const [, year] = queryKey
  const response = await axios.get<ReportDataResponse>(
    '/reports/year-by-year/',
    { params: { year } }
  )

  return response.data
}

export const reportQueryOptions = (
  year: string
): WithRequired<
  UseQueryOptions<
    ReportDataResponse,
    AxiosError,
    ReportDataResponse,
    ReportDataQueryKey
  >,
  'queryKey'
> => ({
  queryKey: ['report-data', year],
  queryFn: getReportData,
  meta: { message: 'Failed to get report data' },
})

const useReportData = (year: string) => {
  return useQuery(reportQueryOptions(year))
}

export default useReportData
