import {
  FetchQueryOptions,
  QueryFunction,
  useQuery,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Order, OrderUpdate, ShippingMethod } from 'utils/global-types'

export type OrderQueryResponse = {
  order: OrderUpdate
  shippingMethod?: ShippingMethod
}
type OrderQueryKeys = ['order', Order['order_id']]

export const getQuery: QueryFunction<
  OrderQueryResponse,
  OrderQueryKeys
> = async ({ queryKey }) => {
  const [, orderID] = queryKey
  const { data: order } = await axios.get<OrderUpdate>(`/orders/${orderID}/`)

  if (!order.shipping_method) {
    return {
      order,
    }
  }

  const { data: shippingMethod } = await axios.get<ShippingMethod>(
    `/customers/shipping-methods/${order.shipping_method}/`
  )

  return {
    order,
    shippingMethod,
  }
}

export const orderDetailsQueryOptions = (
  orderID: Order['order_id'],
  type?: 'quote' | 'order'
): WithRequired<
  FetchQueryOptions<
    OrderQueryResponse,
    AxiosError,
    OrderQueryResponse,
    OrderQueryKeys
  >,
  'queryKey'
> => ({
  queryKey: ['order', orderID],
  queryFn: getQuery,
  meta: { message: `Failed to get ${type ?? 'order'}` },
})

const useOrder = (orderID: Order['order_id'], type?: 'quote' | 'order') => {
  return useQuery({
    ...orderDetailsQueryOptions(orderID, type),
    enabled: !!orderID,
  })
}

export default useOrder
