import { DateTime } from 'luxon'

import { useDialog } from 'context/Dialog/DialogContext'
import {
  addShipmentDialog,
  deleteShipmentDialog,
  editShipmentDialog,
} from 'pages/OrderEdit/dialogs'
import { Invoice, Shipment } from 'utils/global-types'

import useCreateShipment from './useCreateShipment'
import useDeleteShipment from './useDeleteShipment'
import useEditShipment from './useEditShipment'

type ShipmentsActionsProps = {
  uuid: Shipment['uuid']
  orderID: Shipment['order_id']
}

export default function useShipmentsActions({
  uuid,
  orderID,
}: ShipmentsActionsProps) {
  const [openDialog] = useDialog()

  const { mutateAsync: editShipment } = useEditShipment(uuid)
  const { mutateAsync: createShipment } = useCreateShipment(uuid)
  const { mutateAsync: deleteShipment } = useDeleteShipment(uuid, orderID)

  const handleEditShipment = (shipment: Shipment) => () => {
    openDialog(editShipmentDialog(shipment), async (result) => {
      if (typeof result === 'boolean') {
        return result
      }

      return await editShipment({
        ...result,
        shipment_date: (result.shipment_date as DateTime).toISODate(),
      })
    })
  }

  const handleDeleteShipment = async () => {
    const isDeleteConfirmed = await openDialog(deleteShipmentDialog)

    if (isDeleteConfirmed) {
      await deleteShipment()
    }
  }

  const handleShipInvoice = (invoice: Invoice) => async () => {
    openDialog(addShipmentDialog(invoice), async (result) => {
      if (typeof result === 'boolean') {
        return result
      }

      return await createShipment({
        ...result,
        shipment_date: (result.shipment_date as DateTime).toISODate(),
      })
    })
  }

  return {
    handleShipInvoice,
    handleEditShipment,
    handleDeleteShipment,
  }
}
