import { tail } from 'lodash'

export const addQueryParams = (
  baseURL: string,
  params: Record<string, string | number | boolean>
): string => {
  const paramsEntries = Object.entries(params)

  if (paramsEntries.length === 0) {
    return baseURL
  }

  let url = baseURL

  url += `?${paramsEntries[0][0]}=${paramsEntries[0][1]}`

  if (paramsEntries.length === 1) {
    return url
  }

  tail(paramsEntries).forEach(([key, value]) => {
    url += `&${key}=${value}`
  })

  return url
}
