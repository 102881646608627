import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Address, AddressUpdate } from 'utils/global-types'

type CreateAddressResponse = Address
type CreateAddressVariables = AddressUpdate

const postAddress: MutationFunction<
  CreateAddressResponse,
  CreateAddressVariables
> = async (variables) => {
  const response = await axios.post<CreateAddressResponse>(
    '/customers/addresses/',
    variables
  )

  return response.data
}

const useCreateAddress = () => {
  const queryClient = useQueryClient()

  return useMutation<CreateAddressResponse, AxiosError, CreateAddressVariables>(
    {
      mutationFn: postAddress,
      meta: { message: 'Failed to create address' },
      onSuccess: (newAddress) => {
        const { organization } = newAddress

        queryClient.invalidateQueries(['addresses', { organization }])
      },
    }
  )
}

export default useCreateAddress
