import { Navigate, useLocation, useRouteError } from 'react-router-dom'
import { isAxiosError } from 'axios'

import { isAuthError } from 'configs/axios'
import { NotFound, UnknownError } from 'pages/Error'

export default function RouteErrorBoundary() {
  const location = useLocation()
  const error = useRouteError()

  if (isAxiosError(error)) {
    if ([404, 403].includes(error.response?.status ?? 0)) {
      return <NotFound />
    }
    if (error.response?.status === 500) {
      return <UnknownError />
    }
    if (isAuthError(error)) {
      return <Navigate to="/login" state={{ from: location.pathname }} />
    }
  }

  throw error
}
