import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axios from 'configs/axios'
import { Address, AddressUpdate } from 'utils/global-types'

type EditAddressResponse = Address
type EditAddressVariables = AddressUpdate

function createPatchAddress(uuid: Address['uuid']) {
  const patchAddress: MutationFunction<
    EditAddressResponse,
    EditAddressVariables
  > = async (body) => {
    const response = await axios.patch<EditAddressResponse>(
      `/customers/addresses/${uuid}/`,
      body
    )

    return response.data
  }

  return patchAddress
}

const useEditAddress = (id: Address['uuid']) => {
  const queryClient = useQueryClient()

  return useMutation<EditAddressResponse, AxiosError, EditAddressVariables>({
    mutationFn: createPatchAddress(id),
    meta: { message: 'Failed to update address' },
    onSuccess: ({ organization }) => {
      queryClient.invalidateQueries(['addresses', { organization }])
    },
  })
}

export default useEditAddress
