import { PropsWithChildren } from 'react'
import { useController } from 'react-hook-form'
import { InputLabelProps, TextField } from '@mui/material'

// TODO Add propper types

interface HookFormSelectProps {
  name: string
  defaultValue?: string
  label: string
  helperText?: string
  disabled?: boolean
  placeholder?: string
  required?: boolean
  InputLabelProps?: Partial<InputLabelProps>
}

export default function HookFormSelect({
  name,
  defaultValue,
  label,
  children,
  helperText = '',
  disabled,
  placeholder,
  required,
  InputLabelProps,
}: PropsWithChildren<HookFormSelectProps>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
  })

  return (
    <TextField
      select
      label={label}
      {...field}
      value={field.value ?? null}
      inputRef={field.ref}
      error={error !== undefined}
      helperText={error?.message ?? helperText}
      disabled={disabled}
      placeholder={placeholder}
      required={required}
      InputLabelProps={InputLabelProps}
    >
      {children}
    </TextField>
  )
}
