import { merge } from 'lodash'

import { BaseDialogConfig, DialogConfig } from 'context/Dialog/DialogContext'

const discardDialogDefaultConfig: BaseDialogConfig = {
  title: 'You have unsaved changes',
  actions: {
    confirm: 'Discard changes',
    cancel: 'Continue editing',
  },
}

export default function getBaseDialogConfig(
  config: DialogConfig
): BaseDialogConfig {
  if ('variant' in config) {
    const { variant, ...userConfig } = config

    if (config.variant === 'discard') {
      return merge(userConfig, discardDialogDefaultConfig)
    }
  } else {
    return config
  }

  return {
    title: '',
  }
}
