import { useController } from 'react-hook-form'
import VisibilityOnIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'

import { useToggle } from 'utils/hooks'

type PasswordProps = {
  name: string
} & TextFieldProps

export default function PasswordInput({
  name,
  defaultValue,
  helperText,
  ...props
}: PasswordProps) {
  const [showPassword, togglePassword] = useToggle(false)

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
  })

  return (
    <TextField
      {...props}
      {...field}
      error={error !== undefined}
      helperText={error?.message ?? helperText}
      type={showPassword ? 'text' : 'password'}
      inputRef={field.ref}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePassword}
              edge="end"
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}
