import { PropsWithChildren, ReactElement, ReactNode, useRef } from 'react'
import { Box, styled, SxProps, Theme, Tooltip, Typography } from '@mui/material'

import { StatsBarItemI } from 'components/StatsBar'
import { Link } from 'components/StyledLink'
import { useOverflow } from 'utils/hooks'

const TextWithSubtitleContainer = styled(Box)({
  flex: '1',
  overflow: 'hidden',
  '& > *': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  '&:last-child': {
    flex: '0 1 auto',
  },
})

const TextWithSubtitleContent = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  '&:last-child': {
    marginRight: 0,
  },
}))

type TooltipWrapperProps = PropsWithChildren<{
  isOverflowing: boolean
  title?: ReactNode
}>

const TooltipWrapper = ({
  isOverflowing,
  title,
  children,
}: TooltipWrapperProps) => {
  if (isOverflowing) {
    return (
      <Tooltip disableFocusListener title={title}>
        {children as ReactElement<unknown, string>}
      </Tooltip>
    )
  }
  return <>{children}</>
}

type LinkWrapperProps = {
  link?: string
  text: ReactNode
  valueSx?: SxProps<Theme>
}

const LinkWrapper = ({ link, text, valueSx }: LinkWrapperProps) => {
  if (link) {
    return (
      <Link to={link} sx={valueSx}>
        {text}
      </Link>
    )
  }
  return (
    <Typography sx={valueSx} component="span">
      {text}
    </Typography>
  )
}
export interface TextWithSubtitleProps extends Omit<StatsBarItemI, 'divider'> {
  subtitleSx?: SxProps<Theme>
  valueSx?: SxProps<Theme>
}

export default function TextWithSubtitle({
  subtitle,
  subtitleSx,
  text,
  link,
  valueSx,
}: TextWithSubtitleProps) {
  const actualText = text || '-'

  const ref = useRef<HTMLSpanElement>(null)
  const isOverflowing = useOverflow(ref)

  return (
    <TooltipWrapper isOverflowing={isOverflowing} title={actualText}>
      <TextWithSubtitleContainer>
        <Typography sx={{ fontSize: 14, ...subtitleSx }}>{subtitle}</Typography>
        <TextWithSubtitleContent ref={ref}>
          <LinkWrapper link={link} text={actualText} valueSx={valueSx} />
        </TextWithSubtitleContent>
      </TextWithSubtitleContainer>
    </TooltipWrapper>
  )
}
