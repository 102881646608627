import { Paper, Stack, Typography } from '@mui/material'

import { Table, useTable } from 'components/Table'
import { HeadCell } from 'components/Table/types'
import {
  PaymentStatusCell,
  ShipmentStatusCell,
} from 'components/TableStatusCell'
import { localeDate } from 'utils/date'
import { TopOrders } from 'utils/global-types'
import { formatPrice } from 'utils/price'

const columns: HeadCell<TopOrders>[] = [
  {
    value: ({ created }) => localeDate(created),
    label: 'Created Date',
  },
  {
    value: 'order_id',
    getHref: (order) => `/orders/${order.order_id}`,
    label: 'Order number',
    width: 120,
  },
  {
    value: 'customer_email',
    label: 'Customer e-mail',
    width: 230,
  },
  {
    value: ({ manufacturer_names }) => manufacturer_names.join(', '),
    label: 'Brands',
    width: 230,
  },
  {
    value: ({ total_amount, currency }) =>
      formatPrice(total_amount, currency, { priceFirst: true }),
    label: 'Amount',
    align: 'right',
  },
  {
    value: ({ payment_status }) => (
      <PaymentStatusCell paymentStatus={payment_status} />
    ),
    label: 'Payment status',
  },
  {
    value: ({ shipment_status }) => (
      <ShipmentStatusCell shipmentStatus={shipment_status} />
    ),
    label: 'Delivery status',
  },
]

export default function DashboardTopOrders() {
  const table = useTable('top-orders', '/reports/top-orders/', {
    columns,
  })

  return (
    <Paper sx={{ padding: '16px 24px' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={2.25}
      >
        <Typography variant="h6">Top 20 orders</Typography>
        <Typography
          variant="body2"
          color="action.active"
          letterSpacing={0.4}
          textTransform="uppercase"
          fontSize={12}
          fontWeight={600}
        >
          Last 30 days
        </Typography>
      </Stack>
      <Table
        {...table}
        dataType="order metrics"
        disableAddButton
        hideSearch
        hidePagination
        noElevation
      />
    </Paper>
  )
}
