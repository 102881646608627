import { ElementType } from 'react'
import { Link, useLocation, useMatch, useMatches } from 'react-router-dom'
import {
  ListItem as MuiListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material'

import { useAbility } from 'context/Ability'

import { useDrawer } from '../Layout'

import { NavigationLink } from './listItems'

export interface ListItemProps extends Omit<NavigationLink, 'id'> {
  icon?: ElementType
  exact?: boolean
}

export default function ListItem({
  label,
  roots,
  url = '',
  icon: Icon,
  subject,
  exact = false,
}: ListItemProps) {
  const location = useLocation()
  const ability = useAbility()
  const [isDrawerOpen] = useDrawer()

  const matches = useMatches()
  const match = useMatch(url) !== null

  if (subject && ability.cannot('view', subject)) {
    return null
  }

  const itemRoots = roots ?? [url]
  let selected = matches.some((match) => itemRoots.includes(match.pathname))
  if (exact) {
    const exactMatch = match && location.pathname === url
    selected = exactMatch
  }

  const color = selected ? 'primary.main' : 'info'
  const fontWeight = selected ? '500' : '400'

  return (
    <Tooltip
      disableInteractive
      disableHoverListener={isDrawerOpen}
      disableFocusListener={isDrawerOpen}
      title={label}
    >
      <MuiListItem disablePadding>
        <ListItemButton component={Link} to={url} selected={selected}>
          <ListItemIcon data-testid="icon" sx={{ color }}>
            {Icon !== undefined && <Icon />}
          </ListItemIcon>
          <ListItemText
            sx={{ fontWeight }}
            disableTypography
            secondary={label}
          />
        </ListItemButton>
      </MuiListItem>
    </Tooltip>
  )
}
