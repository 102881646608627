import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  WithRequired,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { DetailCustomer } from 'utils/global-types'

type CustomersResponse = DetailCustomer

const getCustomer: QueryFunction<
  CustomersResponse,
  ['customer', string]
> = async ({ queryKey }) => {
  const [, customerId] = queryKey
  const response = await axiosInstance.get<CustomersResponse>(
    `/customers/customers/${customerId}/`
  )

  return response.data
}

export const customerDetailsQueryOptions = (
  customerID: DetailCustomer['uuid']
): WithRequired<
  UseQueryOptions<
    CustomersResponse,
    AxiosError,
    CustomersResponse,
    ['customer', string]
  >,
  'queryKey'
> => ({
  queryKey: ['customer', customerID],
  queryFn: getCustomer,
  meta: { message: 'Failed to get customer details' },
})

const useCustomer = (customerId?: DetailCustomer['uuid']) => {
  return useQuery({
    ...customerDetailsQueryOptions(customerId!),
    enabled: !!customerId,
  })
}

export default useCustomer
