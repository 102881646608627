import { useParams } from 'react-router-dom'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import useOrder from 'api/order/useOrder'
import axios from 'configs/axios'
import {
  Invoice,
  InvoiceProductBundleItem,
  InvoiceProductBundleItemUpdate,
  InvoiceType,
  InvoiceUpdate,
} from 'utils/global-types'

type EditInvoiceResponse = Invoice & {
  product_bundle_list: InvoiceProductBundleItem[]
}

type EditInvoiceVariables = {
  invoice: Partial<InvoiceUpdate>
  items: InvoiceProductBundleItemUpdate[]
  isProForma?: boolean
}

const editInvoice = (invoiceUUID: Invoice['uuid']) => {
  const patchInvoice: MutationFunction<
    EditInvoiceResponse,
    EditInvoiceVariables
  > = async ({ invoice, items, isProForma }) => {
    const type: Exclude<InvoiceType, 'CREDIT'> = isProForma ? 'DRAFT' : 'DEBIT'

    const response = await axios.patch<EditInvoiceResponse>(
      `/invoices/${invoiceUUID}/`,
      {
        ...invoice,
        type,
        product_bundle_list: items,
      }
    )

    return response.data
  }

  return patchInvoice
}

const useEditInvoice = (invoiceUUID: Invoice['uuid']) => {
  const queryClient = useQueryClient()
  const { id: orderID } = useParams()
  const { data } = useOrder(orderID!)
  const orderUUID = data?.order?.uuid

  return useMutation<EditInvoiceResponse, AxiosError, EditInvoiceVariables>({
    mutationFn: editInvoice(invoiceUUID),
    onSuccess: () => {
      queryClient.invalidateQueries(['invoice', invoiceUUID])
      queryClient.invalidateQueries(['invoices'])
      queryClient.invalidateQueries(['order', orderID])
      queryClient.invalidateQueries(['product-items', orderUUID])
      queryClient.invalidateQueries(['invoice-product-items', invoiceUUID])
    },
    meta: { message: 'Fail to edit invoice' },
  })
}

export default useEditInvoice
