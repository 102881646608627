import {
  MutateFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import useOrder from 'api/order/useOrder'
import axios from 'configs/axios'
import {
  Order,
  OrderProductItem,
  OrderProductItemOverwrite,
} from 'utils/global-types'

type EditOrderProductItemResponse = OrderProductItem
type EditOrderProductItemVariables = Partial<OrderProductItemOverwrite>

function createPatchOrderProductItem(uuid: OrderProductItem['uuid']) {
  const patchOrderProductItem: MutateFunction<
    EditOrderProductItemResponse,
    AxiosError,
    EditOrderProductItemVariables
  > = async (body) => {
    const response = await axios.patch<EditOrderProductItemResponse>(
      `/product-items/order-products/${uuid}/`,
      body
    )
    return response.data
  }

  return patchOrderProductItem
}

const useEditOrderProductItem = (
  uuid: OrderProductItem['uuid'],
  orderID: Order['order_id'],
  useGlobalError = true
) => {
  const queryClient = useQueryClient()

  const { data } = useOrder(orderID)
  const order = data?.order
  const { uuid: orderUUID } = order || {}

  return useMutation<
    EditOrderProductItemResponse,
    AxiosError,
    EditOrderProductItemVariables
  >({
    mutationFn: createPatchOrderProductItem(uuid),
    onSuccess: () => {
      queryClient.invalidateQueries(['order', orderID])
      queryClient.invalidateQueries(['product-items', orderUUID])
    },
    meta: { message: 'Failed to edit order product item', useGlobalError },
  })
}

export default useEditOrderProductItem
