import toast from 'react-hot-toast'
import {
  MutationFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'

import axiosInstance from 'configs/axios'
import { DetailCustomer, UpdateCustomer } from 'utils/global-types'

export type CreateCustomerResponse = DetailCustomer
export type CreateCustomerVariables = UpdateCustomer

const createCustomer: MutationFunction<
  CreateCustomerResponse,
  CreateCustomerVariables
> = async (customer) => {
  const response = await axiosInstance.post<CreateCustomerResponse>(
    '/customers/customers/',
    customer
  )

  return response.data
}

const useCreateCustomer = () => {
  const queryClient = useQueryClient()

  return useMutation<
    CreateCustomerResponse,
    AxiosError<Record<string, string[]>>,
    CreateCustomerVariables
  >({
    mutationFn: createCustomer,
    onSuccess: () => {
      queryClient.invalidateQueries(['customers'])
      toast.success('Customer created successfully')
    },
    meta: {
      message: 'Failed to create customer',
      useGlobalError: false,
    },
  })
}

export default useCreateCustomer
