import { ReactElement } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Stack, SxProps, TableRow } from '@mui/material'
import { DateTime } from 'luxon'

import useCreditInvoice from 'api/invoice/useCreditInvoice'
import useDeleteInvoice from 'api/invoice/useDeleteInvoice'
import useCreatePayment from 'api/payments/useCreatePayment'
import usePaymentActions from 'api/payments/usePaymentActions'
import useShipmentsActions from 'api/shipping/useShipmentsActions'
import { usePreviewContext } from 'components/PreviewDrawer/context'
import { PreviewIcon } from 'components/PreviewTableCell'
import { TableCell } from 'components/Table'
import { Can, useAbility } from 'context/Ability'
import { useDialog } from 'context/Dialog/DialogContext'
import { localeDate } from 'utils/date'
import { Invoice, InvoiceType } from 'utils/global-types'
import { getStatusLabel } from 'utils/order'
import { formatPrice } from 'utils/price'

import {
  creditConfirmationDialog,
  deleteConfirmationDialog,
  refundInvoiceDialog,
} from '../dialogs'
import { RefundFormValues } from '../dialogs/AddRefundForm'

const typeToColorMap: Record<InvoiceType, string> = {
  CREDIT: 'error.main',
  DEBIT: 'success.main',
  DRAFT: 'info.main',
}

type InvoiceProps = {
  invoice: Invoice
}

export default function InvoiceRow({ invoice }: InvoiceProps) {
  const { id: orderID } = useParams()
  const ability = useAbility()
  const [openDialog] = useDialog()
  const openPreview = usePreviewContext()

  const {
    uuid,
    type,
    created,
    currency,
    invoice_id,
    is_credited,
    total_paid_amount,
    total_amount,
    payment_status,
    shipment_status,
    outstanding_amount,
    is_blocked_shipping,
  } = invoice

  const { mutateAsync: creditInvoice } = useCreditInvoice(uuid, orderID!)
  const { mutateAsync: deleteInvoice } = useDeleteInvoice(uuid)
  const { mutateAsync: createPayment } = useCreatePayment(uuid)
  const { handlePayInvoice } = usePaymentActions({ uuid, orderID: orderID! })
  const { handleShipInvoice } = useShipmentsActions({
    uuid,
    orderID: orderID!,
  })

  const submitDeleteInvoice = async () => await deleteInvoice()

  const handleDeleteInvoice = async () =>
    await openDialog(deleteConfirmationDialog(invoice_id), submitDeleteInvoice)

  const handlePreviewInvoice = () => {
    openPreview({ type: 'invoice', uuid })
  }

  const handleCreditInvoice = async () => {
    const isCreditConfirmed = await openDialog(
      creditConfirmationDialog(invoice_id)
    )

    if (isCreditConfirmed) {
      await creditInvoice()
    }
  }

  const refundInvoice = async (
    refundFormValues: RefundFormValues | boolean
  ) => {
    if (typeof refundFormValues === 'boolean') {
      return refundFormValues
    }

    return await createPayment({
      ...refundFormValues,
      payment_date: (refundFormValues.payment_date as DateTime).toISODate(),
    })
  }

  const handleRefundInvoice = async () => {
    await openDialog(refundInvoiceDialog(invoice, orderID!), refundInvoice)
  }

  const color = typeToColorMap[type]
  const disabledColor: SxProps = { opacity: is_credited ? 0.5 : 1 }
  const outstandingAmount = formatPrice(outstanding_amount ?? 0, currency)

  const isShipmentDisabled =
    is_credited || shipment_status === 'SHIPPED' || is_blocked_shipping
  const showRefundButton =
    ability.can('edit', 'payment') && payment_status !== 'UNPAID'

  const previewButton = (
    <Button
      variant="outlined"
      onClick={handlePreviewInvoice}
      startIcon={<PreviewIcon />}
    >
      View
    </Button>
  )

  const typeToButtonsMap: Record<InvoiceType, ReactElement> = {
    CREDIT: (
      <>
        {showRefundButton && (
          <Can I="edit" a="payment">
            <Button
              variant="outlined"
              // disabled={outstanding_amount === 0}
              onClick={handleRefundInvoice}
            >
              Refund
            </Button>
          </Can>
        )}
        {previewButton}
      </>
    ),
    DEBIT: (
      <>
        <Can I="edit" a="payment">
          <Button variant="outlined" onClick={handlePayInvoice(invoice)}>
            Pay
          </Button>
        </Can>
        <Can I="edit" a="shipment">
          <Button
            variant="outlined"
            disabled={isShipmentDisabled}
            onClick={handleShipInvoice(invoice)}
          >
            Ship
          </Button>
        </Can>
        {previewButton}
        <Can I="edit" a="invoice">
          <Button
            variant="outlined"
            disabled={is_credited}
            onClick={handleCreditInvoice}
          >
            Credit
          </Button>
        </Can>
      </>
    ),
    DRAFT: (
      <>
        {previewButton}
        <Can I="edit" a="invoice">
          <Button
            variant="outlined"
            component={Link}
            to={`invoice/${invoice.uuid}`}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDeleteInvoice}
          >
            Delete
          </Button>
        </Can>
      </>
    ),
  }

  const buttons = typeToButtonsMap[type]

  return (
    <TableRow>
      <TableCell>{localeDate(created)}</TableCell>
      <TableCell sx={disabledColor}>{invoice_id}</TableCell>
      <TableCell sx={{ color, ...disabledColor }}>
        {formatPrice(total_amount, currency)}
      </TableCell>
      <TableCell sx={disabledColor}>
        {formatPrice(total_paid_amount, currency)}
      </TableCell>
      <TableCell sx={disabledColor}>
        {type === 'DEBIT' ? outstandingAmount : '-'}
      </TableCell>
      <TableCell sx={disabledColor}>
        {type === 'DEBIT' && shipment_status
          ? getStatusLabel(shipment_status)
          : ''}
      </TableCell>
      <TableCell align="right">
        <Stack direction="row" gap={1} justifyContent="flex-end">
          {buttons}
        </Stack>
      </TableCell>
    </TableRow>
  )
}
