import { useParams } from 'react-router-dom'

import useExtendQuote from 'api/customers-portal/useExtendQuote'
import useQuote from 'api/customers-portal/useQuote'
import CustomerActionFlow from 'components/CustomerActionView/CustomerActionFlow'

import ExtendQuoteForm from './form'

export default function ExtendQuotePage() {
  const { id } = useParams()
  const { data: quote } = useQuote(id)
  const { mutateAsync: extendQuote } = useExtendQuote()

  const onSubmit = async () => {
    await extendQuote({ uuid: id! })
  }

  return (
    <CustomerActionFlow
      formComponent={<ExtendQuoteForm quote={quote!} />}
      onSubmit={onSubmit}
      successProps={{
        email: quote!.customer_support_email,
        title: 'Quote period extended successfully',
        description:
          'Your quote period has been successfully extended. The updated quote with the new expiration date will be sent to your email shortly.',
      }}
    />
  )
}
