import { PropsWithChildren } from 'react'
import { Font, Page as PDFPage } from '@react-pdf/renderer'

import robotoBold from 'assets/fonts/Roboto/Roboto-Bold.ttf'
import robotoMedium from 'assets/fonts/Roboto/Roboto-Medium.ttf'
import robotoRegular from 'assets/fonts/Roboto/Roboto-Regular.ttf'
import { getSrc } from 'pdf/utils'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: getSrc(robotoRegular),
    },
    {
      src: getSrc(robotoBold),
      fontWeight: 700,
    },
    {
      src: getSrc(robotoMedium),
      fontWeight: 500,
    },
  ],
})

export default function Page({ children }: PropsWithChildren) {
  return (
    <PDFPage
      size="A4"
      style={{
        fontFamily: 'Roboto',
        fontWeight: 500,
        paddingHorizontal: 48,
        paddingTop: 32,
        paddingBottom: 40,
      }}
    >
      {children}
    </PDFPage>
  )
}
