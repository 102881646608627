import { PropsWithChildren, ReactNode } from 'react'
import { Box, Divider, styled, SxProps, Theme } from '@mui/material'

import TextWithSubtitle from 'components/TextWithSubtitle'

const StatsBarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
}))

export interface StatsBarItemI {
  subtitle: string
  text: ReactNode
  divider?: boolean
  link?: string
}

interface StatsBarProps {
  items: StatsBarItemI[]
  sx?: SxProps<Theme>
  subtitlesSx?: SxProps<Theme>
}

export default function StatsBar({
  items,
  children,
  sx,
  subtitlesSx,
}: PropsWithChildren<StatsBarProps>) {
  return (
    <StatsBarContainer sx={sx}>
      {items.map(({ divider, ...rest }) => {
        return (
          <>
            <TextWithSubtitle {...rest} subtitleSx={subtitlesSx} />
            {divider && <Divider orientation="vertical" flexItem />}
          </>
        )
      })}
      {children}
    </StatsBarContainer>
  )
}
